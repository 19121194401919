import { useRef, useEffect } from 'react';
import FocusTrap from 'focus-trap-react';
import BlockContent from '@sanity/block-content-to-react';
import serializers from 'serializers/justLinks';
import { useState } from 'react';
import { add } from 'martha';
import Chevron from 'components/Icons/Chevron';
import cx from 'classnames';
import {
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	useAccordionItemContext,
} from '@reach/accordion';

export default function PromoModal({ content, toggles, modalId, activeModalId, setActiveModalId }) {
  const [indices, setIndices] = useState( [] );
  const modalRef = useRef( null );

  function AccordionButtonItem({ children }) {
    const { isExpanded } = useAccordionItemContext();

    return (
      <AccordionButton
        className={cx( 'text-16 font-medium flex justify-between items-center w-full' )}
      >
        {children}
        <span
          className={cx( 'w-10', {
            'transform rotate-180': isExpanded,
          } )}
        >
          <Chevron direction="down"/>
        </span>
      </AccordionButton>
    );
  }

  function toggleItem(toggledIndex) {
    if ( indices.includes( toggledIndex ) ) {
      setIndices( indices.filter( (currentIndex) => currentIndex !== toggledIndex ) );
    } else {
      setIndices( [...indices, toggledIndex].sort() );
    }
  }

  useEffect( () => {
    if ( activeModalId === modalId ) {
      add( document.body, 'overflow-hidden' );
      document.addEventListener( 'mousedown', handleClickOutside );
    } else {
      document.removeEventListener( 'mousedown', handleClickOutside );
    }

    return () => {
      document.removeEventListener( 'mousedown', handleClickOutside );
    };
  }, [activeModalId] );

  const handleClickOutside = (event) => {
    if ( modalRef.current && !modalRef.current.contains( event.target ) ) {
      setActiveModalId( null );
    }
  };

  return (
    <FocusTrap active={activeModalId === modalId}>
      <div
        className={cx( 'promo-modal fixed top-0 left-0 w-full h-full pt-50 z-20', {
          hidden: activeModalId !== modalId,
        } )}
      >
        <div
          ref={modalRef}
          className="promo-modal-inner overflow-auto s:max-h-90p relative bg-white w-full h-full s:w-auto s:h-auto max-w-auto s:max-w-720 transform -translate-x-1/2 -translate-y-1/2 left-50p top-50p"
        >
          <button
            className="sticky block ml-auto -mb-16 w-16 absolute right-24 top-24 bg-white z-1"
            onClick={() => setActiveModalId( null )}
          >
            <CloseIcon/>
          </button>
          <div className="pt-25 s:pt-50 px-30 s:px-62 pb-30 s:pb-62">
            <BlockContent blocks={content} serializers={serializers}/>
            {toggles && (
              <Accordion index={indices} onChange={toggleItem} className="mt-40">
                {toggles.map( (item) => {
                  return (
                    <AccordionItem
                      key={item._key}
                      className="toggleItem py-20 border border-l-0 border-r-0 border-t-0 first:border-t border-opacity-25 border-navy-blue-muted"
                    >
                      <AccordionButtonItem>{item.title}</AccordionButtonItem>
                      <AccordionPanel className="mt-10">
                        <BlockContent blocks={item.content} serializers={serializers}/>
                      </AccordionPanel>
                    </AccordionItem>
                  );
                } )}
              </Accordion>
            )}
          </div>
        </div>
      </div>
    </FocusTrap>
  );
}

function CloseIcon() {
  return (
    <svg viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.06043 1.06043C1.64622 0.474647 2.59597 0.474647 3.18175 1.06043L21.5665 19.4452C22.1523 20.031 22.1523 20.9807 21.5665 21.5665C20.9807 22.1523 20.031 22.1523 19.4452 21.5665L1.06043 3.18175C0.474647 2.59597 0.474647 1.64622 1.06043 1.06043Z"
        className="fill-current"
      />
      <path
        d="M22.0604 1.06043C21.4746 0.474647 20.5249 0.474647 19.9391 1.06043L1.55434 19.4452C0.968551 20.031 0.968551 20.9807 1.55434 21.5665C2.14012 22.1523 3.08987 22.1523 3.67566 21.5665L22.0604 3.18175C22.6462 2.59597 22.6462 1.64622 22.0604 1.06043Z"
        className="fill-current"
      />
    </svg>
  );
}
