import MenuItem from 'components/Navigation/NavigationOld/DesktopOld/DesktopMenuItem'
import cx from 'classnames'
import CartToggle from 'components/MobileCartToggle'
import Link from 'components/Link'
import { useEffect, useState } from 'react'
import { getCookie } from './Product/Product.helper'

export default function DesktopSecondaryMenu({ items, isNoNavigation, homepageCta, toggle }) {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
  const searchIcon = () => {
    return (
      <svg
        width="18px"
        height="18px"
        viewBox="0 0 18 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="DesktopIcon"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            id="BLU---Search-v2---Field"
            transform="translate(-1050.000000, -58.000000)"
            stroke="#003057"
            strokeWidth="2"
          >
            <g id="Group-2" transform="translate(1051.000000, 56.000000)">
              <g id="search" transform="translate(0.000000, 3.000000)">
                <circle id="Oval" cx="7.11111111" cy="7.11111111" r="7.11111111"></circle>
                <line x1="16" y1="16" x2="12.1333333" y2="12.1333333" id="Path"></line>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }

  useEffect(() => {
    const getUserData = getCookie('userData')
    setIsUserLoggedIn(getUserData ? true : false)
  }, [])

  return (
    <div className="h-full">
      <ul className="h-full flex xl:space-x-10">
        {!isNoNavigation && items
          ?.filter(item => item.title !== 'Account')
          ?.map((item) => (
            <MenuItem key={item._key} link={item}>
              {item.title}
            </MenuItem>
          ))}
        <li onClick={() => toggle()} className={'relative h-full flex items-center cursor-pointer'}>
          <span
            className={
              'flex block text-16 xl:text-18 leading-100 tracking-1 font-medium pt-11 pb-10 px-15 rounded focus:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-royal-blue hover:bg-alice-blue hover:text-royal-blue transition'
            }
          >
            {searchIcon()}
          </span>
        </li>
        {items?.map((item) => (
          item.title === 'Account' ? (
            <li key={item.title} className={'relative h-full flex items-center cursor-pointer'}>
              <Link link={{ ...item, url: isUserLoggedIn ? '/account' : item.url }}>
                <span
                  className={
                    'flex block text-16 xl:text-18 leading-100 tracking-1 font-medium py-7 px-15 rounded focus:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-royal-blue hover:bg-alice-blue hover:text-royal-blue transition'
                  }
                >
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 21.5V19.5C20 18.4391 19.5786 17.4217 18.8284 16.6716C18.0783 15.9214 17.0609 15.5 16 15.5H8C6.93913 15.5 5.92172 15.9214 5.17157 16.6716C4.42143 17.4217 4 18.4391 4 19.5V21.5" stroke="#003057" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M12 11.5C14.2091 11.5 16 9.70914 16 7.5C16 5.29086 14.2091 3.5 12 3.5C9.79086 3.5 8 5.29086 8 7.5C8 9.70914 9.79086 11.5 12 11.5Z" stroke="#003057" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
              </Link>
            </li>
          ) : null
        ))}
        <DesktopCtaButton />
      </ul>
    </div>
  )
}

function DesktopCtaButton() {
  return (
    <li className="h-full flex items-center pl-15 l:pl-0">
      <div className="relative w-full flex items-center">
        <div className={cx('inset-0 z-1')}>
          <CartToggle />
        </div>
      </div>
    </li>
  )
}
