import ActionButton from 'components/ActionButton'
import { useRef, useState } from 'react'
import { useRect } from '@reach/rect'
import cx from 'classnames'
import { useGiftMessage } from 'context/globalStore'
import TextButton from 'components/TextButton'

const States = ActionButton.States

export default function CartGiftMessageForm({ characterLimit, ctaText, inputLabel }) {
  const buttonRef = useRef()
  const buttonRect = useRect(buttonRef)
  const drawerRef = useRef()
  const drawerRect = useRect(drawerRef)
  const { giftMessage, setGiftMessage } = useGiftMessage()
  const [saveButtonStatus, setSaveButtonStatus] = useState(States.IDLE)
  const [clearButtonStatus, setClearButtonStatus] = useState(States.IDLE)
  const [isExpanded, setIsExpanded] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [value, setValue] = useState(giftMessage ?? '')

  function handleClick() {
    setIsExpanded(!isExpanded)
  }

  function handleFocus() {
    setIsFocused(true)
  }

  function handleBlur() {
    if (value.length) return
    setIsFocused(false)
  }

  function handleChange(ev) {
    setValue(ev.target.value)
  }

  async function handleClear() {
    if (!giftMessage.length) {
      setValue('')
      setIsExpanded(false)
      return
    } else {
      if (isExpanded) {
        setClearButtonStatus(States.LOADING)
      }
      await setGiftMessage('')
      setValue('')
      if (isExpanded) {
        setClearButtonStatus(States.SUCCESS)
        setIsFocused(false)
      }
    }
  }

  async function handleSave() {
    setSaveButtonStatus(States.LOADING)
    await setGiftMessage(value)
    setSaveButtonStatus(States.SUCCESS)
  }

  function handleAfterSuccess() {
    setIsExpanded(false)
  }

  return (
    <div
      className="overflow-hidden px-30 s:px-45 pb-16 s:pb-20"
      style={{ minHeight: buttonRect ? buttonRect.height + 16 : null }}
    >
      <div className="relative">
        <div
          className={cx('absolute flex', {
            'opacity-0 pointer-events-none': isExpanded,
          })}
          inert={isExpanded ? '' : null}
        >
          <button
            className="text-left w-full text-12 s:text-14 py-5 focus:outline-none transition"
            onClick={handleClick}
            ref={buttonRef}
          >
            {giftMessage?.length ? (
              <span className="font-medium">{giftMessage}</span>
            ) : (
              <span className="font-medium text-navy-blue-muted underline">{ctaText}</span>
            )}
          </button>
          {giftMessage?.length ? (
            <button onClick={handleClear} className="w-20 h-20 relative mt-4">
              <span className="sr-only">Clear gift message</span>
              <div className="w-7 absolute-center text-navy-blue-muted">
                <ClearIcon />
              </div>
            </button>
          ) : null}
        </div>
        <div
          className={cx('transition-all ease-in-out-cubic', {
            'opacity-0': !isExpanded,
          })}
          style={{ maxHeight: isExpanded ? drawerRect?.height : 0 }}
        >
          <div className="bg-white pt-10" ref={drawerRef} inert={isExpanded ? null : ''}>
            <div className="relative">
              <div
                className={cx(
                  'absolute px-5 s:px-10 left-15 top-0 bg-white text-11 text-navy-blue-muted font-medium transform -translate-y-6 transition',
                  {
                    'opacity-0': !isFocused,
                  },
                )}
              >
                {inputLabel}
              </div>
              <textarea
                className="block text-12 s:text-14 leading-130 font-medium w-full rounded border border-navy-blue p-15 placeholder-opacity-100 placeholder-navy-blue-muted focus:placeholder-opacity-0 focus:outline-none resize-none transition-colors"
                name="gift_message"
                id="gift-message-entry"
                rows="4"
                maxLength={characterLimit}
                placeholder={ctaText}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
                value={value}
              ></textarea>
            </div>
            <div className="flex justify-between mt-10">
              <div className="flex items-center space-x-10">
                <ActionButton
                  status={saveButtonStatus}
                  onChange={setSaveButtonStatus}
                  onClick={handleSave}
                  afterSuccess={handleAfterSuccess}
                  successDelay={1000}
                >
                  Save
                </ActionButton>
                <ActionButton
                  status={clearButtonStatus}
                  onChange={setClearButtonStatus}
                  onClick={handleClear}
                  afterSuccess={handleAfterSuccess}
                  successDelay={1000}
                  as={TextButton}
                >
                  Clear
                </ActionButton>
              </div>
              <div className="text-12 text-navy-blue-muted">
                {value.length}/{characterLimit} characters
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function ClearIcon() {
  return (
    <svg viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.06059 6.71774C0.865326 6.913 0.548742 6.913 0.353479 6.71774C0.158215 6.52248 0.158215 6.2059 0.353479 6.01064L6.01036 0.353809C6.20562 0.158548 6.52221 0.158547 6.71747 0.353809C6.91273 0.54907 6.91273 0.865651 6.71747 1.06091L1.06059 6.71774Z"
        className="fill-current"
      />
      <path
        d="M0.353554 1.06058C0.158291 0.86532 0.158291 0.548739 0.353554 0.353477C0.548817 0.158216 0.8654 0.158216 1.06066 0.353477L6.71754 6.01031C6.9128 6.20557 6.9128 6.52216 6.71754 6.71742C6.52227 6.91268 6.20569 6.91268 6.01043 6.71742L0.353554 1.06058Z"
        className="fill-current"
      />
    </svg>
  )
}
