interface CheckmarkIconProps {
  width?: string
  height?: string
  color?: string
}

export default function CheckmarkIcon({ width, height, color }: CheckmarkIconProps) {
  if (!width && !height) {
    return (
      <svg viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.7246 7.8246C1.4071 7.5071 0.892318 7.5071 0.574812 7.8246C0.257308 8.14211 0.257309 8.65688 0.574813 8.97439L7.32835 15.7279L21.1529 1.90332C21.4705 1.58582 21.4705 1.07104 21.1529 0.753534C20.8354 0.436029 20.3207 0.43603 20.0032 0.753535L7.32835 13.4283L1.7246 7.8246Z"
          {...(color ? { fill: color } : { className: "fill-current" })}
        />
      </svg>
    )
  }
  else {
    return (
      <svg width={width ?? "11"} height={height ?? "7"} viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.5 3.69231L4.02632 6L9.5 1" stroke="white" strokeWidth="1.8" strokeLinecap="round" {...(color ? { fill: color } : {})} strokeLinejoin="round" />
      </svg>
    )
  }
}
