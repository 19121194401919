import Chevron from 'components/Icons/Chevron'
import {useState} from "react";
import cx from "classnames";

export default function PrevButton({ onClick }) {
  const [isClicked, setIsClicked] = useState( false );
  const [isDisabled, setIsDisabled] = useState( false );
  const handleClick = () => {
    if ( !isDisabled ) {
      setIsClicked( true );
      setIsDisabled( true );
      onClick();
      setTimeout( () => {
        setIsClicked( false );
        setIsDisabled( false );
      }, 300 );
    }
  };
  return (
    <button className="absolute left-0 h-full px-10 group focus:outline-none" onClick={handleClick}>
      <span className="sr-only">Previous promotional</span>
      <div className={cx( "w-6 ",
        {
          'group rounded ring-1 ring-offset-4 outline-none ring-navy-blue': isClicked
        } )}>
        <Chevron direction="left"/>
      </div>
    </button>
  )
}