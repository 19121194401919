import getPrice from '../../../lib/getPrice'
import formatPrice from '../../../lib/formatPrice'
import CartRemoveIconItem from './CartRemoveIconItem'
import { extraFreeProductsUtils } from '../helpers/extraFreeProductUtils'

export default function CartLineExtraItem({
  item,
  lineItem,
  removingItem,
  enableAutoDiscount,
  afterDiscountAmount,
  appliedLineItemDiscount,
  cartPromoFromArray,
  primaryColorForPromo,
  handleRemoveClick,
}) {
  const { original } = getPrice(lineItem.variant, item.quantity, (price) => price)
  const { getParentsFromExtraFreeProduct, checkIsExtraRemoved } = extraFreeProductsUtils()
  const isParentRemoved = checkIsExtraRemoved(lineItem.variant.id, item)
  const parents = getParentsFromExtraFreeProduct(lineItem)
  const isSubscription = item.customAttributes.some(attr => attr.key === 'subscription_id')
  const parentAttrObj = parents.find((parent) => parent.parentVariantId === item.variant.id && parent.isSubscription === isSubscription)

  const titleWithQuantity = (() => {
    return `${item.quantity * (parentAttrObj?.quantityPerParentItem ?? 1)} × ${lineItem.title}`
  })()

  const isValidProduct = parentAttrObj && !isParentRemoved
  if (!isValidProduct) {
    return null
  }

  return (
    <div className="flex mt-8 items-start space-x-8 s:space-x-20 relative rounded p-8 pr-15 s:py-18 s:px-24 bg-white">
      <div className="w-44 xs:w-55 s:w-55 h-44 xs:h-55 s:h-55 flex-shrink-0 rounded overflow-hidden">
        <img
          src={lineItem?.variant?.image?.src}
          alt={lineItem?.variant?.image?.altText || 'Blueland: Eco-Friendly Cleaning Products'}
          width={54}
          height={54}
          placeholder={false}
          sizes="70px"
          className="w-full h-full temp-class-name"
        />
      </div>
      <div className="w-full">
        <div className="space-y-6 s:space-y-6 mb-6 s:w-70p text-13 leading-13 s:text-16 s:leading-23 font-medium">
          {titleWithQuantity}
        </div>
        <div className="text-11 leading-13 s:text-13 mb-5 text-navy-blue-muted s:w-70p">
          For {item.title}
        </div>
      </div>
      <div
        className="flex h-44 xs:h-55 s:h-55 items-center pt-12 s:pt-18 text-13 leading-13 s:text-16 s:leading-23 font-medium ml-auto l:mr-4 text-right"
        style={{
          color:
            (enableAutoDiscount && afterDiscountAmount > 0 && appliedLineItemDiscount) ||
            (cartPromoFromArray?.enableAutoDiscount &&
              afterDiscountAmount > 0 &&
              appliedLineItemDiscount)
              ? primaryColorForPromo ?? '#3369FF'
              : undefined,
        }}
      >
        {original === 0
          ? 'Free'
          : formatPrice({ price: { amount: original, currencyCode: 'USD' } })}
      </div>
      <div className="absolute top-4 right-0 l:top-16 l:right-16">
        <button
          className={`block rounded p-4 text-navy-blue-muted focus:outline-none focus-visible:ring-2 focus-visible:ring-royal-blue ${
            removingItem ? 'pointer-events-none' : 'pointer-events-auto'
          }`}
          onClick={() => handleRemoveClick(true, lineItem, item)}
        >
          <div className="w-10 m-4">
            <CartRemoveIconItem />
          </div>
        </button>
      </div>
    </div>
  )
}
