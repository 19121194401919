import MenuItem from './MobileMenuItemV2'
import Badge from 'components/ProductBadge'
import { useStore } from 'context/globalStore'
import { updateSubscriptionLink } from '../../../../lib/abTestUtils'

export default function MobilePrimaryMenuV2({ items, isGetSubscriptionPage }) {
  const { store } = useStore()
  const { countryCode } = store
  return (
    <ul className="pt-24 pb-32 px-30 bg-alice-blue">
      {items?.filter(
        (item) => !(countryCode !== 'US' && item?.menu?.reference?.slug === 'subscriptions')
      ).map((item) => {
        const link = updateSubscriptionLink(item.menu, isGetSubscriptionPage);
        return (
          <MenuItem seperator={item.seperator} key={item.menu._key} link={link}>
            {item.menu.title}
            {item.badge && item.badge.label ? (
              <span className="inline-block ml-10">
                <Badge background={item.badge.background} size="small">
                  {item.badge.label}
                </Badge>
              </span>
            ) : null}
          </MenuItem>
        )
      })}
    </ul>
  )
}
