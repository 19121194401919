import { useState, useCallback, useEffect, useMemo } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import { useAddCartItems } from 'context/globalStore'
import Image from 'components/Image'
import ActionButton from 'components/ActionButton'
import Chevron from 'components/Icons/Chevron'
import getLineItemDescription from 'lib/getLineItemDescription'
import useShopifyProductPlus from 'hooks/useShopifyProductPlus'
import formatPrice from 'lib/formatPrice'
import * as analytics from 'lib/analytics'
import usePLPAutoDiscount from 'hooks/usePLPAutoDiscount'
import { findLowestPriceVariant } from 'hooks/usePriceNotation'
import { formatNumberAsPrice } from 'lib/formatPrice'
import { usePromoConfig } from 'hooks/usePromoConfig'
import useCartPrices from 'hooks/useCartPrices'


export default function CartUpsellCarousel({ items, globals }) {
  const [ref, embla] = useEmblaCarousel({
    loop: true,
    align: 'center',
  })

  useEffect(() => {
    embla?.on('select', () => analytics.trackButtonClick('ExploreCartUpsell'))
  }, [embla])

  const scrollPrev = useCallback(() => embla?.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla?.scrollNext(), [embla])

  useEffect(() => {
    if (items && !items.length) return
    if (embla) {
      embla.scrollTo(0, true)
      embla.reInit()
    }
  }, [items])

  return (
    <div className="pb-12 s:pb-20 cart-you-may-also-like">
      <div className="text-navy-blue text-13 leading-13 s:text-16 s:leading-23 font-medium px-10 s:px-25 pb-10">
        You may also like
      </div>
      <div ref={ref} className="cart-upsells relative overflow-hidden">
        <ul className="flex relative pointer-events-none z-1">
          {items &&
            items.map((item) => {
              const defaultVariant = item.product.defaultVariant ?
                item?.variants.filter(variant => variant.variantId === item.product.defaultVariant.variantId)[0]
                : null
              const variant = defaultVariant && defaultVariant?.available !== 'false' && defaultVariant?.disableForOneTime !== true && defaultVariant ? defaultVariant : findLowestPriceVariant(item?.variants)
              return (
                <CartUpsellCarouselItem
                  key={variant._id || variant.variantId}
                  product={item.product}
                  variant={variant}
                  globals={globals}
                />
              )
            })}
        </ul>
        {items && items.length > 1 && (
          <>
            <PrevButton onClick={scrollPrev} />
            <NextButton onClick={scrollNext} />
          </>
        )}
      </div>
    </div>
  )
}

const States = ActionButton.States

function getDiscountedPrice(oneTimePrice, autoDiscount) {
  const otp = Number(oneTimePrice?.match(/[\d.]+/g)?.[0])
  const { autoDiscountAmount = 0 } = autoDiscount || {}
  const discountedPrice = formatNumberAsPrice(
    (otp * (1 - autoDiscountAmount / 100)).toFixed(2).replace(/\.00$/, '')
  )
  return discountedPrice
}

function CartUpsellCarouselItem({ product, variant, globals }) {
  const [status, setStatus] = useState(States.IDLE)
  const { enableAutoDiscount } = usePLPAutoDiscount(product)
  const addCartItems = useAddCartItems()
  const { variants } = useShopifyProductPlus(product)
  const shopifyVariant = variants.find((v) => v.cleanId === variant.variantId)
  const buttonPrice = formatNumberAsPrice(shopifyVariant?.price?.amount || variant?.price, shopifyVariant?.price?.currencyCode || 'USD');
  const description = getLineItemDescription(product.options, variant)
  const promoConfig = usePromoConfig({ product, location: 'PDP' })
  const { isPromoActivated } = useCartPrices()
  const autoDiscount = {
    autoDiscountAmount: promoConfig?.oneTimeDiscountPct || 0,
    enableAutoDiscount,
    hideStrikeoutPrice: promoConfig?.hideStrikeoutPrice,
  }
  const discountedPrice = getDiscountedPrice(`${buttonPrice}`, autoDiscount)

  async function handleClick() {
    setStatus(States.LOADING)

    analytics.trackButtonClick('AddCartUpsell')

    await addCartItems(
      {
        product,
        shouldSetIsAdding: false,
        payload: {
          variant,
          planProducts: [{ ...product, variants: [variant] }],
          quantity: 1,
        },
      },
      false,
      true
    )

    analytics.trackAddToCart({
      product,
      variant,
      quantity: 1,
    })

    setStatus(States.SUCCESS)
  }

  const price = formatPrice(shopifyVariant)

  return (
    <li className="relative min-w-full px-25 s:px-45">
      <div className="p-8 s:p-15 bg-alice-blue border border-pattens-blue rounded flex items-center justify-between space-x-10 pointer-events-auto">
        <div className="flex items-center space-x-10">
          <div className="w-45 h-45 rounded overflow-hidden">
            <Image
              image={variant?.siloImage ?? product?.shopifyImage}
              width={50}
              height={50}
              fit="contain"
              fill={true}
              placeholder={false}
              sizes="45px"
            />
          </div>
          <div className="space-y-6 s:space-y-8">
            <div className="text-11 s:text-13 font-medium leading-13">{product.title}</div>
            <div className="text-11 leading-13 s:text-13 text-navy-blue-muted">{description}</div>
          </div>
        </div>
        <ActionButton status={status} onChange={setStatus} onClick={handleClick}>
          <span className="hidden s:block">
            Add{' '}
            <span className="mx-8" aria-hidden="true">
              |
            </span>{' '}
            <span className="space-x-4">
              {
                enableAutoDiscount && isPromoActivated
                  ? <>
                    <span className='line-through'>{buttonPrice}</span>
                    <span style={{ color: promoConfig?.promoTheme?.primaryColor?.hex ?? '#3369FF' }}>{discountedPrice}</span>
                  </>
                  : <>{buttonPrice}</>
              }
            </span>
          </span>
          <span className="s:hidden text-12">
            + <span className="space-x-4">
              {
                enableAutoDiscount && isPromoActivated
                  ? <>
                    <span className='line-through'>{buttonPrice}</span>
                    <span style={{ color: promoConfig?.promoTheme?.primaryColor?.hex ?? '#3369FF' }}>{discountedPrice}</span>
                  </>
                  : <>{buttonPrice}</>
              }
            </span>
          </span>
        </ActionButton>
      </div>
    </li>
  )
}

function PrevButton({ onClick }) {
  return (
    <button
      className="absolute inset-y-0 left-0 px-10 s:px-25 group focus:outline-none"
      onClick={onClick}
    >
      <span className="sr-only">Previous item</span>
      <div className="w-6 rounded group-focus:ring-1 group-focus:ring-offset-4 group-focus:ring-navy-blue">
        <Chevron direction="left" />
      </div>
    </button>
  )
}

function NextButton({ onClick }) {
  return (
    <button
      className="absolute inset-y-0 right-0 px-10 s:px-25 group focus:outline-none"
      onClick={onClick}
    >
      <span className="sr-only">Next item</span>
      <div className="w-6 rounded group-focus:ring-1 group-focus:ring-offset-4 group-focus:ring-navy-blue">
        <Chevron direction="right" />
      </div>
    </button>
  )
}
