import { useRef, useEffect } from 'react'

export default function useDelayedCallback(callback, ms = 3000) {
  const timeoutRef = useRef()

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  return function delayedCallback() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null
      requestAnimationFrame(() => {
        callback()
      })
    }, ms)
  }
}
