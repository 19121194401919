import getColorStyle from 'lib/getColorStyle'
import cx from 'classnames'
import { formatNumberAsPrice } from 'lib/formatPrice'
import CollectionProduct from 'components/CollectionProduct'
import { useRect } from '@reach/rect'
import ChevronButton from 'components/ChevronButton'
import useCarousel from 'hooks/useCarousel'
import Link from 'next/link'
import { useEffect, useRef, useState } from 'react'
import Image from 'components/Image'
import useABVariation from 'hooks/useABVariation'
import { getSpacing } from './helpers'

export default function ProductCarouselGridScroll({ module, globals, mode = 'normal' }) {
  const { headline: heading, products, subTitle, background, mobileLayout } = module
  const { ref, prevProps, nextProps, pagiProps } = useCarousel({
    containScroll: 'trimSnaps',
  })
  const image1 = useRef()
  const image1Dom = useRect(image1)

  const image2 = useRef()
  const image2Dom = useRect(image2)

  const [paddingTopMobile, setPaddingTopMobile] = useState(module?.spacingTopMobile ?? 60)
  const [paddingBottomMobile, setPaddingBottomMobile] = useState(module?.spacingBottomMobile ?? 60)
  const [paddingBottomTablet, setPaddingBottomTablet] = useState(module?.spacingBottomTablet ?? 120)
  const [paddingTopTablet, setPaddingTopTablet] = useState(module?.spacingTopTablet ?? 90)
  const [paddingBottomDesktop, setPaddingBottomDesktop] = useState(module?.spacingBottomDesktop ?? 120)
  const [paddingTopDesktop, setPaddingTopDesktop] = useState(module?.spacingTopDesktop ?? 90)
  const [paddingTop, setPaddingTop] = useState(0)
  const [paddingBottom, setPaddingBottom] = useState(0)

  useEffect(() => {
    module?.spacingTopMobile !== undefined && setPaddingTopMobile(module.spacingTopMobile)
    module?.spacingBottomMobile !== undefined && setPaddingBottomMobile(module.spacingBottomMobile)
    module?.spacingBottomTablet !== undefined && setPaddingBottomTablet(module.spacingBottomTablet)
    module?.spacingTopTablet !== undefined && setPaddingTopTablet(module.spacingTopTablet)
    module?.spacingBottomDesktop !== undefined && setPaddingBottomDesktop(module.spacingBottomDesktop)
    module?.spacingTopDesktop !== undefined && setPaddingTopDesktop(module.spacingTopDesktop)
    setPaddingTop(getSpacing(paddingTopMobile, paddingTopTablet, paddingTopDesktop))
    setPaddingBottom(getSpacing(paddingBottomMobile, paddingBottomTablet, paddingBottomDesktop))
  }, [module])

  const {
    ref: ref1,
    prevProps: prevProps1,
    nextProps: nextProps1,
    pagiProps: pagiProps1,
  } = useCarousel({
    containScroll: 'trimSnaps',
  })

  const { variation } = useABVariation('pdp-plp-hero-image')

  const buildItems = (isMobile, isDesktop, ref) => {
    return products?.map((product, i) => {

      return (
        <li
          key={product._key}
          className={cx('group flex-shrink-0', {
            'px-4': isMobile,
            'w-25p': isMobile && mobileLayout === 1,
            'w-25p': isMobile && [2, 3].includes(mobileLayout),
            'w-50p px-6 m:w-25p mb-47 l:mb-0': isDesktop,
          })}
        >
          <div className="relative text-left">
            <div
              style={{ background: getColorStyle(background) }}
              className="relative w-full h-full rounded-3 overflow-hidden"
            >
              <div
                ref={!i ? ref : null}
                className="relative w-160 s:w-160 m:w-full h-full justify-center items-center"
              >
                  <CollectionProduct
                    key={product.id}
                    bottomPrice={false}
                    starRatingStyle={0}
                    starRatingColor={undefined}
                    inlineAddingState={true}
                    withClass={false}
                    imageHeight={undefined}
                    imageWidth={undefined}
                    as="div"
                    product={product}
                    collection={{ title: 'my collection' }}
                    index={i}
                    enableQuickAdd={true}
                    buttonClass=""
                    mobileButton={{
                      variant: 'contained',
                      size: 'large',
                    }}
                    mode={mode}
                    // showTitleAndPriceRow={false}
                    showProductBadges={false}
                    showQuickAddPrice={false}
                    // enableProductLink={true}
                    priceClass={'text-navy-blue-muted font-light block mt-3 xl:mt-0 space-x-3'}
                    titleClass={'text-13 w-150 l:w-100p leading-16 l:text-16 xl:flex xl:justify-between xl:text-left text-wrap flex-wrap text-left font-medium transition group-hover:text-royal-blue'}
                    atcOptionClass="relative group-btn font-medium text-14 l:text-18 inline-block px-10 s:px-10 l:px-15 py-8 first:border-l-0 border border-l-1 border-r-0 border-t-0 border-b-0 border-navy-blue pointer-events-auto z-10"
                    globals={globals}
                    textStyle={{fontSize: !isDesktop ? '8px' : '12px'}}
                    starSize={ !isDesktop ? 13 : 14}
                    variation={variation}
                    showQuantitySelector={false}
                  />
              </div>
            </div>
          </div>

        </li>
      )
    })
  }

  return (
    <div
      className="relative m:px-20 l:px-0 m:overflow-hidden home-categories"
      style={{ background: getColorStyle(background), paddingTop: `${paddingTop}px`, paddingBottom: `${paddingBottom}px` }}
    >
      <h2 className="text-26 text-center l:text-40 font-medium mb-20 m:mb-20 l:mb-30 leading-28 m:leading-40">
        {heading}
      </h2>
      {!!subTitle && <p
        className={cx(
          'text-16 l:text-20 text-center text-navy-blue-default m:mb-42 leading-2-13 m:leading-28',
          {
            'mb-28': mobileLayout === 3,
            'mb-20': [1, 2].includes(mobileLayout),
          },
        )}
      >
        {subTitle}
      </p>}

      <div className="relative l:px-88 m:px-50">
        <div className="-mx-6 mr-30 m:-mr-16 m:-mx-16 m:overflow-hidden " ref={products?.length > 4 ? ref1 : null}>
          <ul
            className={cx('-mb-47 l:mb-0', {
              'hidden m:flex': mobileLayout !== 3,
              'flex flex-wrap': mobileLayout === 3,
              'm:flex-nowrap': products?.length > 4,
              'l:justify-center': products?.length < 4,
            })}
          >
            {buildItems(false, true, image1)}
          </ul>
        </div>

        {products?.length > 4 ? (
          <>
            <div
              className={cx('hidden m:block absolute -left-15 l:left-8 z-3 -mt-30 transform -translate-y-1/2')}
              style={{ top: image1Dom?.height / 2 }}
            >
              <ChevronButton className='border-none' chevronIconClassName='w-15' direction="left" label="Previous slide" {...prevProps1} />
            </div>
            <div
              className={cx('hidden m:block absolute -right-15 l:right-8 z-3 -mt-30 transform -translate-y-1/2')}
              style={{ top: image1Dom?.height / 2 }}
            >
              <ChevronButton className='border-none' chevronIconClassName='w-15' direction="right" label="Next slide" {...nextProps1} />
            </div>
          </>
        ) : null}
      </div>

      {/* Desktop Fader */}
      {products?.length > 4 ? (
        <>
          <div
            className={cx(
              'hidden m:block h-full w-40 l:w-60 xl:w-80 absolute top-0 left-0 z-2 bg-gradient-to-l from-transparent-white to-white transition pointer-events-none',
              {
                'opacity-0': prevProps1.disabled,
              },
            )}
          ></div>
          <div
            className={cx(
              'hidden m:block h-full w-40 l:w-60 xl:w-80 absolute top-0 right-0 z-2 bg-gradient-to-r from-transparent-white to-white transition pointer-events-none',
              {
                'opacity-0': nextProps1.disabled,
              },
            )}
          ></div>
        </>
      ) : null}

      {/* Mobile Fader */}
      <div
        className={cx(
          'hidden h-full w-40 l:w-60 xl:w-80 absolute top-0 left-0 z-2 bg-gradient-to-l from-transparent-white to-white transition pointer-events-none',
          {
            // 'opacity-0': prevProps.disabled || mobileLayout === 3,
          },
        )}
      ></div>
      <div
        className={cx(
          'm:hidden h-full w-40 l:w-60 xl:w-80 absolute top-0 right-0 z-2 bg-gradient-to-r from-transparent-white to-white transition pointer-events-none',
          {
            // 'opacity-0': nextProps.disabled || mobileLayout === 3,
          },
        )}
      ></div>

      {mobileLayout !== 3 ? (
        <>
          <div className="m:hidden relative no-scrollbar overflow-scroll">
            <div className="-mx-6 mr-20">
              <ul className="flex px-20">{buildItems(true, false, image2)}</ul>
            </div>
            {/* <div
              className={cx('absolute -left-10 z-1 transform -translate-y-1/2')}
              style={{ top: image2Dom?.height / 2 }}
            >
              <ChevronButton className='border-none bg-none' chevronIconClassName='w-15' direction="left" label="Previous slide" {...prevProps} />
            </div>
            <div
              className={cx('absolute -right-10 z-1 transform -translate-y-1/2')}
              style={{ top: image2Dom?.height / 2 }}
            >
              <ChevronButton className='border-none bg-none' chevronIconClassName='w-15' direction="right" label="Next slide" {...nextProps} />
            </div> */}
          </div>

          {/* <div className="m:hidden mt-55">
            <CarouselPagination count={items?.length} {...pagiProps} />
          </div> */}
        </>
      ) : null}
    </div>
  )
}

