import StarRating from 'components/StarRating'
import cx from 'classnames'
import formatNumber from 'lib/formatNumber'

const ratingTemplate = '$count Reviews'
function getRatingTemplate(temp, count) {
  const _temp = !temp ? ratingTemplate : temp
  return count ? _temp.replace('$count', count) : 0
}
export default function Bottomline({
  averageScore,
  totalReviews,
  reviewUnderline,
  scoreInDecimal,
  className = '',
  displayTotal = true,
  size = 'large',
  starColor = 'text-royal-blue',
  textStyle = {},
  starStyle = {},
  ratingNumberTemplate = '',
  mode = 'normal',
}) {
  const formattedTotal =
    typeof totalReviews === 'number' ? formatNumber(totalReviews) : totalReviews
  const score = averageScore && averageScore.toFixed(1)
  return (
    <div className={cx(className || 'flex items-center space-x-10')}>
      <StarRating 
        style={starStyle} 
        // fill={starColor} 
        score={averageScore} 
        size={size} 
      />

      {score > 0 && scoreInDecimal && (
        <span
          className="font-medium text-navy-blue text-14 s:text-15 pr-10 border-navy-blue-muted border-r leading-14"
          style={{ fontWeight: 600, marginLeft: 5, '--tw-border-opacity': 0.25 }}
        >
          {score}
        </span>
      )}

      {mode === 'search' ? (
        <div
          className={cx('text-11 text-navy-blue-muted leading-100', {
            'sr-only': !displayTotal,
            underline: reviewUnderline,
          })}
          style={textStyle}
        >
          {totalReviews ? `${formattedTotal} Reviews` : `No Reviews Yet`}
        </div>
      ) : (
        <div
          className={cx(' leading-100', {
            'sr-only': !displayTotal,
            underline: reviewUnderline,
            'text-13': mode === 'reviews',
            'text-11': mode !== 'reviews',
            'text-navy-blue-muted': mode !== 'reviews',
            'text-navy-blue-default': mode === 'reviews'
          })}
          style={textStyle}
        >
          {totalReviews
            ? getRatingTemplate(ratingNumberTemplate, formattedTotal)
            : `No Reviews Yet`}
        </div>
      )}
      {totalReviews && averageScore ? (
        <div className="sr-only">{averageScore.toFixed(2)} average score</div>
      ) : null}
    </div>
  )
}
