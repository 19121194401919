export default function formatPrice(variants, quantity = 1, returnRawPrice = false) {
  const aggregatedPrice = getVariantAggregatePrice(variants, quantity)
  if (!aggregatedPrice) return aggregatedPrice

  if (returnRawPrice) {
    return aggregatedPrice.number
  }

  return formatNumberAsPrice(aggregatedPrice.number, aggregatedPrice.currency)
}

export const getVariantAggregatePrice = (variants, quantity = 1) => {
  if (!variants) return ''
  variants = [].concat(variants)

  if (variants.length === 0) return ''

  const currency = variants[0]?.price?.currencyCode || 'USD'

  if (!currency) return ''

  const number = variants.reduce((sum, variant) => {
    return sum + parseFloat(variant?.price?.amount || variant.price) * quantity
  }, 0)

  return { number, currency }
}

export const formatNumberAsPrice = (number: number, currency: string = 'USD') => {
  if (!number || !currency) return ''

  const formatted = (+number).toLocaleString('en', {
    style: 'currency',
    currency: currency,
  })

  return formatted.replace('.00', '')
}

export const getPriceAsNumber = (price: string): number | null => {
  if (!price) return null

  const numericString = `${price}`.replace(/[^\d.-]/g, '') // Remove non-numeric characters except for '-' and '.'

  if (!numericString) return null

  return parseFloat(numericString)
}

export function getSubscriptionPrice(subProduct, quantity = 1) {
  return subProduct
    ? (() => {
        let parts = subProduct.price.toString().split('.')
        let decimalIsZero = Number(parts[1]) === 0
        let price = (Number(subProduct.price) * quantity).toFixed(2)
        return decimalIsZero ? `$${price.split('.')[0]}` : `$${price}`
      })()
    : ''
}
