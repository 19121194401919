import cx from 'classnames'
import CartToggle from 'components/MobileCartToggle'
import SearchIcon from 'public/icons/search.svg'
import UserIcon from 'public/icons/user.svg'
import React, { useRef, useState, useEffect } from "react";
import * as analytics from "lib/analytics";
import Chevron from "components/Icons/Chevron";
import { screens } from "lib/constants";
import useMedia from "use-media";
import Link from "components/Link";

export default function DesktopRightMenu({ closeSearchBar, globalNavigation, toggle, isUnderline = true }) {
  const iconHover = 'rounded focus:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-royal-blue hover:bg-alice-blue hover:text-royal-blue transition'
  const [isExpand, setIsExpand] = useState( false )
  const rectHeight = (globalNavigation?.secondaryMenu?.length * 50);
  const screenL = useMedia( { minWidth: screens.m } );
  const screenXL = useMedia( { maxWidth: screens.l } );
  const isTablet = screenL && screenXL;
  const submenuRef = useRef();
  const isDesktop = useMedia( { minWidth: screens.l } );
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  useEffect( () => {
    const handleClickOutside = (event) => {
      if ( submenuRef.current && !submenuRef.current.contains( event.target ) ) {
        setIsExpand( false );
      }
    };

    document.addEventListener( 'mousedown', handleClickOutside );
    return () => {
      document.removeEventListener( 'mousedown', handleClickOutside );
    };
  }, [submenuRef, setIsExpand] );

  useEffect(() => {
    const getUserData = getCookie('userData')
    setIsUserLoggedIn(getUserData ? true : false)
  }, [])

  const openMenu = () => {
    closeSearchBar()
    setIsExpand( true )
  }

  const toggleMenu = () => {
    closeSearchBar()
    setIsExpand( !isExpand )
  }

  return (
    <div className="h-full">
      <ul className="h-full relative flex items-center">
        {globalNavigation?.secondaryMenu?.map( (item) => {
          return (
            item.title === 'How It Works' && isDesktop ? (
              <li key={item._key} className="relative h-full flex items-center group mr-26 xl:mr-32"
                onClick={() => setIsExpand( false )}
              >
                <Link link={item}
                  className={'block text-16 xl:text-18 leading-100 tracking-1 font-medium transition group cursor-pointer'}
                  onClick={() => analytics.trackNavClick( item.title )}
                >
                  {item.title}
                </Link>
                {isUnderline && <span
                  className="absolute w-full h-3 bottom-0 bg-navy-blue z-2 opacity-0 transition-opacity duration-300 group-hover:opacity-100"></span>
                }
              </li>
            ) : null
          )
        })}
        <li className="relative h-full flex mr-12 group"
            onMouseEnter={!isTablet ? openMenu : null}
            onMouseLeave={!isTablet ? () => setIsExpand( false ) : null}
            onClick={isTablet ? toggleMenu : null}
        >
          <div className={'relative'} ref={submenuRef}>
            <div className={'h-full flex items-center '}>
              <h5 className={'text-16 xl:text-18 font-medium'}>{'Mission'}</h5>
              <div className={cx( 'w-9 mt-2 ml-5 transition-transform' )}>
                <Chevron direction={isExpand ? 'up' : 'down'}/>
              </div>
            </div>
            <div className={cx( '',
              { 'isolate': !isExpand }
            )}>
              <ul
                className={cx( 'absolute bg-white left-0 top-full z-1 transition-all ease-in-out-cubic overflow-y-hidden shadow-overlay'
                )}
                style={{
                  minWidth: '290px',
                  maxHeight: isExpand ? rectHeight : 0
                }}>
                {globalNavigation?.secondaryMenu?.map( (item) => {
                  return (
                    item.title === 'How It Works' && isDesktop ? null : (
                      <li key={item._key} className="relative h-full flex items-center group first:pt-24"
                          onClick={() => setIsExpand( false )}
                      >
                        <Link link={item}
                              className={'block text-16 xl:text-18 leading-100 tracking-1 font-medium pb-24 px-24 transition group hover:text-bright-blue cursor-pointer'}
                              onClick={() => analytics.trackNavClick( item.title )}
                        >
                          {item.title}
                        </Link>
                      </li>
                    )
                  )
                } )}
              </ul>
            </div>
          </div>
          <span
            className="absolute w-full h-3 bottom-0 bg-navy-blue z-2 opacity-0 transition-opacity duration-300 group-hover:opacity-100"></span>
        </li>
        <li onClick={() => toggle()} className={'relative h-full flex items-center cursor-pointer mr-12'}
            id={'search-icon-ga'}>
          <span className={`flex block w-full text-16 leading-100 tracking-1 font-medium p-10 ${iconHover}`}>
            <SearchIcon className={'w-20 h-20'}/>
          </span>
        </li>
        <li className="relative h-full flex items-center mr-12" key={globalNavigation?.accountLink?._key}>
          <Link
            className={`p-10 ${iconHover}`}
            link={{ ...globalNavigation?.accountLink, url: isUserLoggedIn ? '/account' : globalNavigation?.accountLink.url }}
            onClick={() => analytics.trackNavClick( globalNavigation?.accountLink.title )}
          >
            <UserIcon className={`w-24 h-24`}/>
          </Link>
        </li>
        <li className="h-full flex items-center">
          <span className={`leading-100 tracking-1 font-medium ${iconHover}`}>
            <CartToggle className={"w-18 h-18"}/>
          </span>
        </li>
      </ul>
    </div>
  )
}
