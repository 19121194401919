export default function Chevron({ direction, props = {} }) {
  switch (direction) {
    case 'up':
      return (
        <svg viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.50003 0L0.574921 3.92511C0.257416 4.24261 0.257416 4.75739 0.574921 5.07489C0.892426 5.3924 1.4072 5.3924 1.72471 5.07489L4.50003 2.29957L7.27535 5.07489C7.59285 5.3924 8.10763 5.3924 8.42513 5.07489C8.74264 4.75739 8.74264 4.24261 8.42513 3.92511L4.50003 0Z"
            fill="#003057"
            className="fill-current"
          />
        </svg>
      )
    case 'right':
      return (
        <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 4.49991L2.07489 0.574799C1.75739 0.257294 1.24261 0.257294 0.925107 0.574799C0.607602 0.892304 0.607602 1.40708 0.925107 1.72459L3.70043 4.49991L0.925108 7.27523C0.607603 7.59273 0.607602 8.10751 0.925107 8.42501C1.24261 8.74252 1.75739 8.74252 2.07489 8.42501L6 4.49991Z"
            fill="#003057"
            className="fill-current"
          />
        </svg>
      )
    case 'down':
      return (
        <svg viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.49997 6L8.42508 2.07489C8.74258 1.75739 8.74258 1.24261 8.42508 0.925107C8.10757 0.607602 7.5928 0.607602 7.27529 0.925107L4.49997 3.70043L1.72465 0.925107C1.40715 0.607603 0.892371 0.607602 0.574866 0.925107C0.257361 1.24261 0.257361 1.75739 0.574866 2.07489L4.49997 6Z"
            fill="#003057"
            className="fill-current"
          />
        </svg>
      )
    case 'left':
      return (
        <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M-4.96461e-08 4.49991L3.92511 0.574799C4.24261 0.257294 4.75739 0.257294 5.07489 0.574799C5.3924 0.892304 5.3924 1.40708 5.07489 1.72459L2.29957 4.49991L5.07489 7.27523C5.3924 7.59273 5.3924 8.10751 5.07489 8.42501C4.75739 8.74252 4.24261 8.74252 3.92511 8.42501L-4.96461e-08 4.49991Z"
            fill="#003057"
            className="fill-current"
          />
        </svg>
      )
    default:
      return (
        <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 4.49991L2.07489 0.574799C1.75739 0.257294 1.24261 0.257294 0.925107 0.574799C0.607602 0.892304 0.607602 1.40708 0.925107 1.72459L3.70043 4.49991L0.925108 7.27523C0.607603 7.59273 0.607602 8.10751 0.925107 8.42501C1.24261 8.74252 1.75739 8.74252 2.07489 8.42501L6 4.49991Z"
            fill="#003057"
            className="fill-current"
          />
        </svg>
      )
  }
}

export const ThinChevron = ({direction, props = {}}) => {

  switch (direction) {
    case 'left':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none" {...props}>
          <path d="M9 17L1 9L9 1" stroke="#003057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
    case 'right':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none" {...props}>
          <path d="M1 1L9 9L0.999999 17" stroke="#003057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
    default:
      return(
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none" {...props}>
          <path d="M1 1L9 9L0.999999 17" stroke="#003057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
  }
}
