let uidinc = 0
export default function generateUID() {
  const tick = Date.now().toString().split('')
  if(uidinc > 16) uidinc = 0;
  const baseChar = 65 + uidinc
  uidinc++;
  return tick.reduce((uuid, num) => {
    uuid += num + String.fromCharCode(baseChar + Number(num))
    return uuid
  }, '')
}