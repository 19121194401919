import Submenu from './DesktopSubmenu'
import Link from 'components/Link'
import * as analytics from 'lib/analytics'

export default function DesktopTextualSubmenu(props) {
  return (
    <Submenu {...props} data-test-id={props.title + '-submeny-link'}>
      <ul className="py-15 px-10">
        {props.childLinks.map((link) => (
          <li key={link._key}>
            <Link
              link={link}
              className="block text-16 xl:text-18 leading-100 tracking-1 font-medium pt-15 pb-10 px-15 whitespace-nowrap rounded transition focus:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-royal-blue hover:bg-alice-blue hover:text-royal-blue"
              onClick={() => analytics.trackNavClick(link.title)}
            >
              {link.title}
            </Link>
          </li>
        ))}
      </ul>
    </Submenu>
  )
}
