import React from 'react'
import styles from './Modal.module.css'
import Button from 'components/Button'

const ModalComponent = ({ headerText, content, ctaText, modalClose, ctaCallback }) => {
  return (
    <div className={`absolute z-10 w-full h-full ${styles.modalContainer}`}>
      <div className={`${styles.modalContent}`}>
        <div className={`${styles.closeIcon}`} onClick={() => modalClose()}>
          <svg
            width="23px"
            height="24px"
            viewBox="0 0 23 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="Hawaii/Alaska-site-pop-up"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="320---Hawaii/Alaska-Pop-Up"
                transform="translate(-265.000000, -131.000000)"
                fill="#003057"
                fillRule="nonzero"
              >
                <g id="pop-up" transform="translate(20.000000, 120.000000)">
                  <g
                    id="icon---close"
                    transform="translate(256.649916, 23.178512) rotate(-45.000000) translate(-256.649916, -23.178512) translate(248.649916, 15.178511)"
                  >
                    <path
                      d="M8.3333398,2.91749062e-07 C8.88562455,3.8842596e-07 9.33333972,0.447715717 9.33333962,1.00000047 L9.333,6.666 L15,6.66666682 C15.5522847,6.6666668 16,7.11438203 16,7.66666678 L16.0000001,8.33333249 C16.0000001,8.88561724 15.5522849,9.33333251 15.0000001,9.33333253 L9.333,9.333 L9.33333717,15.0000005 C9.33333708,15.5522852 8.88562175,16.0000004 8.333337,16.0000003 L7.66666652,16.0000002 C7.11438177,16.0000001 6.66666659,15.5522847 6.66666669,15 L6.666,9.333 L1.00000012,9.33333315 C0.447715367,9.33333317 9.69915965e-08,8.88561794 7.28509067e-08,8.33333319 L4.37105582e-08,7.66666748 C1.95698684e-08,7.11438273 0.44771525,6.66666746 1,6.66666743 L6.666,6.666 L6.66666914,1 C6.66666924,0.44771525 7.11438457,7.8372157e-08 7.66666932,1.75049055e-07 L8.3333398,2.91749062e-07 Z"
                      id="Combined-Shape"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <h2
          className={`${styles.headerText} text-navy-blue font-medium text-20 leading-20 l:text-26 l:leading-26 mb-0 text-center`}
        >
          {headerText}
        </h2>
        <div className={`${styles.horizontalLine}`}></div>
        <div className="w-full flex items-center justify-center">
          <p
            className={`${styles.content} text-center font-normal text-navy-blue text-13 leading-18.46 l:text-16 l:leading-22.72`}
          >
            {content}
          </p>
        </div>
        <div className="w-full flex items-center justify-center">
          <Button
            className={`${styles.cta} mt-20 bg-white l:text-16 font-medium`}
            variant="contained"
            size="large"
            onClick={() => ctaCallback()}
          >
            {ctaText}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ModalComponent
