import { useState, useEffect } from 'react'
import { useStore, useCartItems } from 'context/globalStore'
import CartCrossellItem from 'components/CartExtraSellItem'
import AdvProductCarousel from 'modules/AdvProductCarousel'
import { screens } from 'lib/constants'
import useMedia from 'use-media'

export default function ExtraSell({ products, parentStyle, setExtraSellVisibility }) {
  const { lineItems } = useCartItems()
  const [crosSellProducts, setCrosSellProducts] = useState(products)
  const [crosSellDOMItems, setCrosSellDOMItems] = useState([])
  const { store } = useStore()
  const isScreenLarge = useMedia({ minWidth: screens.l })

  const Styles = {
    mainParent: parentStyle ? parentStyle : 'pt-1 pb-15 px-15 mt-25 bg-white l:p-25',
    itemWrap: 'flex flex-nowrap overflow-auto hide-scrollbar',
  }

  useEffect(() => {
    if (!store.isInit) return

    const _products = products.filter(({ variant }) => {
      const vid = variant.variantId
      return !lineItems.find(({ variant }) => {
        const _vid = Number(variant.id.replace('gid://shopify/ProductVariant/', ''))
        return _vid === vid
      })
    }) //.slice(0, 4)

    setCrosSellProducts(_products)
  }, [lineItems, store.isInit])

  useEffect(() => {
    const items = crosSellProducts?.map(({ product, variant }, index) => {
      const key = Date.now() + index
      return (
        <CartCrossellItem
          key={key}
          product={product}
          variant={variant}
          charLen={isScreenLarge ? 60 : 50}
        />
      )
    })
    setCrosSellDOMItems(items)
    setExtraSellVisibility(!!items.length)
  }, [crosSellProducts])

  if (isScreenLarge) {
    return (
      <AdvProductCarousel
        module={{
          headline: 'You may also love',
          headlineSizeClasses: 'text-32',
          imageHeight: 116,
          imageWidth: 115.5,
          products: crosSellProducts.map((x) => x.product),
          headlinePosition: 'left',
        }}
      />
    )
  } else {
    return (
      <>
        <div className={`${Styles.mainParent}`}>
          <div className="text-20 s:text-24 mt-20 mb-25 font-medium leading-100 mt-1">
            You may also love
          </div>
          <div className={`${Styles.itemWrap}`}> {crosSellDOMItems} </div>
        </div>
      </>
    )
  }
}
