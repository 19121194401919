import React, { Component } from 'react'
import ComponentFallback from './ComponentFallback/ComponentFallback';
import PageFallback from './PageFallback/PageFallback';

interface ErrorBoundaryProps { 
    footer?: any, 
    globals?: any, 
    navigation?: any, 
    promoBar?:any
}

interface ErrorBoundaryState {
    hasError: boolean
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {

    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false
        }
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.log( 'ErrorBoundary ===>>>', error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                Object.keys(this.props).length > 1 ?
                <PageFallback {...this.props} />
                :
                <ComponentFallback />
            )
        }

        return this.props.children
    }
}
