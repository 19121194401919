import React, { useEffect, useState, useRef, useCallback } from 'react'
import 'styles/index.css'
import 'focus-visible'
import { StoreContextProvider, useCartTotals, useCartItems } from 'context/globalStore'
import CookieNotice from 'components/CookieNotice'
import PromoBar from 'components/PromoBar'
import SkipToContent from 'components/SkipToContent'
import Cart from 'components/Cart'
import { useRouter } from 'next/router'
import ModalComponent from 'components/Modal/Modal'
import FetchAllProducts from 'components/FetchAllProducts'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import { buildPageUTMValues } from 'lib/buildPageUTMValues'
import * as analytics from 'lib/analytics'
import ConvertSDK from '@convertcom/js-sdk'
import { JsSdkContext } from '../context/sdkContext'
import useLocalStorageState from 'use-local-storage-state'
import { PromoConfigProvider } from 'hooks/usePromoConfig'
import { ConfigContextProvider } from 'hooks/ConfigContext'
import NavigationABTest from '../components/Navigation/NavigationABTest'
import useABVariation from '../hooks/useABVariation'
import { useRecartCookie } from '../hooks/useRecartCookie'

const autoDiscountFields = [
  'enableAutoDiscount',
  'promoCode',
  'thresholdAmount',
  'giftProduct',
  'promoBannerText',
]

let hasElevarInitialised = false

const MyApp = ({ Component, pageProps }) => {
  const [noShippingPopup, setNoShippingPopup] = useState(false)

  pageProps = buildPageUTMValues(pageProps)
  const {
    cookieNotice,
    promoBar,
    globals,
    navigation,
    cart,
    page,
    countryCode,
    collection,
    footer,
    promoConfig,
    searchConfig,
    allProducts
  } = pageProps
  // const { variation: variationNavigation } = useABVariation('new-navigation')
  pageProps.navigation.isNewNavigation = false

  const { variation: variationCollectionPage } = useABVariation('subscription-page')
  const isGetSubscriptionPage = variationCollectionPage === 'b'
  pageProps.navigation.isGetSubscriptionPage = isGetSubscriptionPage
  footer.isGetSubscriptionPage = isGetSubscriptionPage

  cart.upsells.products = cart.upsells?.products?.filter(item => {
    return item?.product?.isEnabled && !item?.product?.soldOutSettings?.isSoldOut
  })

  const router = useRouter()
  const pathnameRef = useRef(null)
  let [discountConfig, setDiscountConfig] = useState({})
  const pageAutoDiscount = page
    ? autoDiscountFields.reduce((config, key) => {
      config[key] = page[key] ?? null
      return config
    }, {})
    : null
  const { tieredPromotion } = globals
  const hasPageLevelPromoSet = pageAutoDiscount && pageAutoDiscount.enableAutoDiscount
  const hasGlobalLevelPromoSet = promoConfig?.promoPresets && promoConfig?.promoPresets?.enableAutoDiscount;
  let promoPresetDiscountConfig;
  let activePromo;
  if (promoConfig?.promoPresets?.activePromosArray?.length) {
    for (const promo of promoConfig?.promoPresets?.activePromosArray ?? []) {
      if (promo.enablePromoOnPageLevel) {
        for (const page of promo.pagesToApplyPromo) {
          const pageToApplyPromo = promo.pagesToApplyPromo.some(page =>
            (page.slug.current === 'home' && router.asPath === "/") || router.asPath.includes(page.slug.current)
          );
          if (pageToApplyPromo) {
            promoPresetDiscountConfig = { ...promo, activePromosArray: promoConfig?.promoPresets?.activePromosArray, enableAutoDiscount: promoConfig?.promoPresets?.enableAutoDiscount }
            activePromo = { ...promo };
            if (typeof window !== "undefined") {
              localStorage.setItem('cartPromo', JSON.stringify(promoPresetDiscountConfig));
            }
            break;
          } else if (promo.enablePromoForCustomerFromActivePage) {
            let promoFromLocalStorage = {};
            if (typeof window !== 'undefined' && localStorage) {
              promoFromLocalStorage = JSON.parse(localStorage.getItem('cartPromo') || '{}');
            }
            activePromo = promoFromLocalStorage;
            break;
          }
        }
      }
    }
  } else {
    promoPresetDiscountConfig = { ...promoConfig?.promoPresets?.activePromo, enableAutoDiscount: promoConfig?.promoPresets?.enableAutoDiscount }
    activePromo = { ...promoConfig?.promoPresets?.activePromo }
    if (typeof window !== "undefined") {
      localStorage.setItem('cartPromo', JSON.stringify({}));
    }
  }
  useEffect(() => {
    setDiscountConfig(!hasPageLevelPromoSet || (hasGlobalLevelPromoSet && promoConfig?.promoPresets?.activePromo?.allowOverride)
    ? promoPresetDiscountConfig
    : pageAutoDiscount)
  }, [router]);

  const { modals } = globals
  const hawaiiAlaskaModalDetails = modals ? modals.hawaiiAlaskaModal : {}
  const [banner, setBanner] = useState(promoBar)
  const [cartPromo, setCartPromo] = useState(promoConfig?.promoPresets?.activePromo?.cartPromo)

  useEffect(() => {
    const cartPromoFromStorage = JSON.parse(localStorage?.getItem('cartPromo'));
    const banners = activePromo?.promoBar?.banners || cartPromoFromStorage?.promoBar?.banners;
    const enablePromoOnPageLevel = activePromo?.enablePromoOnPageLevel;
    const pagesToApplyPromo = activePromo?.pagesToApplyPromo;
    const isPromoBannerActive = banners?.some(banner => banner.isEnabled && activePromo?.enablePromoForCustomerFromActivePage || banner.isEnabled && (!enablePromoOnPageLevel || pagesToApplyPromo?.some(page => (page.slug.current === 'home' && router.asPath === "/") || router.asPath.includes(page.slug.current))));
    const promoToApply = isPromoBannerActive ? activePromo?.promoBar || cartPromoFromStorage?.promoBar : promoBar;
    setBanner(promoToApply);
    setCartPromo(activePromo?.cartPromo || cartPromoFromStorage?.cartPromo || promoConfig?.promoPresets?.activePromo?.cartPromo)
  }, [router.asPath]);

  const modalCallback = () => {
    if (hawaiiAlaskaModalDetails && hawaiiAlaskaModalDetails.ctaLink) {
      window.open(hawaiiAlaskaModalDetails.ctaLink[0].url)
    }
  }

  if (typeof window !== 'undefined' && !hasElevarInitialised) {
    hasElevarInitialised = true
    window.ElevarDataLayer = window.ElevarDataLayer ?? []
  }

  const getCookie = (name) => {
    var cookieArr = document.cookie.split(';')
    for (var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split('=')
      if (name == cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1])
      }
    }
    return null
  }

  useEffect(() => {
    fetch('http://ip-api.com/json')
      .then((res) => {
        return res.json()
      })
      .then((data) => {
        if (
          data.country === 'United States' &&
          (data.regionName === 'Hawaii' || data.regionName === 'Alaska')
        ) {
          setNoShippingPopup(true)
        }
      })
      .catch((e) => {
        console.log(e)
      })
    const getUserCookie = getCookie('userData')
    const userInfo = getUserCookie ? JSON.parse(getUserCookie) : undefined
    if (userInfo && userInfo.user_id && userInfo.user_email) {
      ire('identify', { customerId: userInfo.user_id, customerEmail: userInfo.user_email })
    }
  }, [])

  const [sdkContext, setSdkContext] = useState()

  const initialized = useRef(false)
  const [userId, setUserId] = useLocalStorageState('user-id', {
    defaultValue: `${new Date().getTime()}-${performance.now()}`,
  })

  const initSdk = async () => {
    try {
      if (!initialized.current && typeof window !== 'undefined') {
        const convertSDK = new ConvertSDK({
          sdkKey: '10007772/10007519',
          dataRefreshInterval: 30000,
        })

        await convertSDK.onReady()
        const context = convertSDK.createContext(userId)
        // context.runExperiences();
        setSdkContext(context)
        initialized.current = true
      }
    } catch (error) {
      console.error('SDK Error:', error)
    }
  }

  useEffect(() => {
    initSdk()
  }, [])

  useEffect(() => {
    // fix Sentry Instagram issue – Can't find variable: _AutofillCallbackHandler
    // https://developers.facebook.com/community/threads/320013549791141/
    window._AutofillCallbackHandler = window._AutofillCallbackHandler || function(){}
  }, [])

  const TrackPageViews = () => {
    const { total } = useCartTotals()
    const { lineItems } = useCartItems()

    const pageChangeTrackingCallback = useCallback(() => {
      if (pathnameRef.current !== window.location.pathname) {
        try {
          pathnameRef.current = window.location.pathname
          let cartTotal = (total ?? '').replace('$', '')
          let enrichedLineItems = lineItems.map((item, index) => ({
            id: item.variant.sku,
            name: item.title,
            brand: 'Blueland',
            category: '',
            variant: '',
            price: item.variant.price,
            quantity: item.quantity.toString(),
            position: index + 1,
            list: location.pathname,
            product_id: item.variant.product.id.substring(
              item.variant.product.id.lastIndexOf('/') + 1
            ),
            variant_id: item.variant.id.substring(item.variant.id.lastIndexOf('/') + 1),
            compare_at_price: '0.0',
            image: item.variant.image.src,
            inventory: '99999',
          }))

          analytics.trackUserData(cartTotal, enrichedLineItems)
          window?.ElevarInvalidateContext()
        } catch {
          console.log('Error tracking url change ')
        }
      }
    }, [total, lineItems])

    // Track on initial page load
    useEffect(pageChangeTrackingCallback, [pageChangeTrackingCallback])

    // Track on subsequent client side navigation
    useEffect(() => {
      router.events.on('routeChangeComplete', pageChangeTrackingCallback)

      return () => {
        router.events.off('routeChangeComplete', pageChangeTrackingCallback)
      }
    }, [pageChangeTrackingCallback])

    return <></>
  }

  useRecartCookie()

  return (
    <ErrorBoundary globals={globals} footer={footer} navigation={navigation} promoBar={promoBar}>
      <StoreContextProvider
        autoDiscount={discountConfig}
        countryCode={countryCode}
        convertId={userId}
      >
        <JsSdkContext.Provider
          value={{
            sdkContext,
          }}
        >
          {noShippingPopup && (
            <ModalComponent
              headerText={hawaiiAlaskaModalDetails ? hawaiiAlaskaModalDetails.header : ''}
              content={hawaiiAlaskaModalDetails ? hawaiiAlaskaModalDetails.content : ''}
              ctaText={hawaiiAlaskaModalDetails ? hawaiiAlaskaModalDetails.ctaText : ''}
              modalClose={() => setNoShippingPopup(false)}
              ctaCallback={() => modalCallback()}
            />
          )}
          <FetchAllProducts tieredPromo={tieredPromotion} allProducts={allProducts} />
          <CookieNotice {...cookieNotice} />
          <SkipToContent />
          <ConfigContextProvider>
            <PromoConfigProvider config={promoConfig?.promoPresets}>
              <div className="col-span-1 col-span-2 col-span-3 col-span-4 col-span-5 col-span-6 col-span-7 col-span-8 col-span-9 col-span-10 col-span-11 col-span-12 2xs:col-span-1 2xs:col-span-2 2xs:col-span-3 2xs:col-span-4 2xs:col-span-5 2xs:col-span-6 2xs:col-span-7 2xs:col-span-8 2xs:col-span-9 2xs:col-span-10 2xs:col-span-11 2xs:col-span-12 xs:col-span-1 xs:col-span-2 xs:col-span-3 xs:col-span-4 xs:col-span-5 xs:col-span-6 xs:col-span-7 xs:col-span-8 xs:col-span-9 xs:col-span-10 xs:col-span-11 xs:col-span-12 s:col-span-1 s:col-span-2 s:col-span-3 s:col-span-4 s:col-span-5 s:col-span-6 s:col-span-7 s:col-span-8 s:col-span-9 s:col-span-10 s:col-span-11 s:col-span-12 m:col-span-1 m:col-span-2 m:col-span-3 m:col-span-4 m:col-span-5 m:col-span-6 m:col-span-7 m:col-span-8 m:col-span-9 m:col-span-10 m:col-span-11 m:col-span-12 l:col-span-1 l:col-span-2 l:col-span-3 l:col-span-4 l:col-span-5 l:col-span-6 l:col-span-7 l:col-span-8 l:col-span-9 l:col-span-10 l:col-span-11 l:col-span-12 xl:col-span-1 xl:col-span-2 xl:col-span-3 xl:col-span-4 l:-ml-70 xl:ml-0 xxl:ml-0 xl:col-span-5 xl:col-span-6 xl:col-span-7 xl:col-span-8 xl:col-span-9 xl:col-span-10 xl:col-span-11 xl:col-span-12 xxl:col-span-1 xxl:col-span-2 xxl:col-span-3 xxl:col-span-4 xxl:col-span-5 xxl:col-span-6 xxl:col-span-7 xxl:col-span-8 xxl:col-span-9 xxl:col-span-10 xxl:col-span-11 xxl:col-span-12 2xl:col-span-1 2xl:col-span-2 2xl:col-span-3 2xl:col-span-4"></div>
              {
                !page?.isNoBanner &&
                <PromoBar {...banner}/>
              }
              <NavigationABTest
                homepageCta={globals.homepageCta}
                globals={globals}
                {...navigation}
                promoBar={promoBar}
                searchConfig={searchConfig}
                isBannerHidden={page?.isNoBanner}
                isNoNavigation={page?.isNoNavigation}
              />

              <ErrorBoundary footer={footer}>
                <Component key={router.asPath.split('?')[0]} {...pageProps} />
              </ErrorBoundary>
              <Cart cart={cart} globals={globals} collection={collection} cartPromo={cartPromo} />
            </PromoConfigProvider>
          </ConfigContextProvider>
          <TrackPageViews />
        </JsSdkContext.Provider>
      </StoreContextProvider>
    </ErrorBoundary>
  )
}

export default MyApp
