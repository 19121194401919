import linkResolver from 'lib/linkResolver'
import NextLink from 'next/link'
import React from 'react'
import { SanityLink } from 'types/sanity-links'

type Props = React.PropsWithChildren<{
  link: SanityLink
  className?: string
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  style?: React.CSSProperties
  tabIndex?: number
  href?: string
  bgColor?: string
  disabled?: boolean
}>

const Link = (props: Props) => {
  if (!props.link) {
    console.error('invalid link', props)

    return <></>
  }
  const { link, children, className, disabled, bgColor, onClick, ...rest } = props
  const _link = link?._type === 'linkWithIcon' ? link?.links?.[0] : link

  if (!_link && link._type === 'linkWithIcon') {
    console.error('invalid link', link)
    return <></>
  }

  const href = linkResolver(_link)

  if (disabled) {
    return (
      <span className={className} {...rest}>
        {children}
      </span>
    )
  }

  if (!href) {
    return (
      <span className={className} {...rest}>
        {children}
      </span>
    )
  }

  const { _type, openInNewTab } = _link

  switch (_type) {
    case 'externalLink':
      return (
        <a
          className={className}
          href={href}
          target={openInNewTab ? '_blank' : '_self'}
          rel={openInNewTab ? 'noopener noreferrer' : ''}
          {...rest}
          style={{ ...(rest?.style ?? {}), backgroundColor: bgColor }}
        >
          {children}
        </a>
      )
    case 'internalLink':
      return (
        <NextLink href={href} passHref {...rest}>
          <a
            className={className}
            target={openInNewTab ? '_blank' : undefined}
            rel={openInNewTab ? 'noopener noreferrer' : undefined}
            style={{ ...rest?.style, backgroundColor: bgColor }}
          >
            {children}
          </a>
        </NextLink>
      );

    default:
      return null;
  }
}

export default Link
