import Badge from "components/ProductBadge";
import NextSanityImage from "components/NextSanityImage";
import cx from "classnames";
import * as analytics from "lib/analytics";
import Link from "components/Link";
import ArrowIcon from 'public/icons/arrow.svg'
import useMedia from "use-media";
import { screens } from "lib/constants";
import Image from 'components/Image'
import { updateSubscriptionLink } from '../../lib/abTestUtils'

export default function Gallery({ items, handleClose = () => {}, isGetSubscriptionPage }) {
  const isScreenLarge = useMedia( { minWidth: screens.l } )
  const closeMenu = () => {
    if ( isScreenLarge ) handleClose()
  }

  return (
    <ul
      className="grid py-24 l:py-0 grid-cols-2 s:grid-cols-4 gap-14 l:gap-16 border-b l:border-0">
      {items?.map( ({ link, image, badge, label }, index) => {
        link = updateSubscriptionLink(link, isGetSubscriptionPage);
        return (
          <li key={`${index}`}
              className={'image l:w-full group relative'}
              onClick={() => {
                closeMenu()
              }}
          >
            <Link
              link={link}
              onClick={() => analytics.trackNavClick( label )}
            >
              {badge && badge.label ? (
                <span className="hidden l:block l:absolute l:top-10 left-10 z-2">
                  <Badge background={badge.background} size="medium">
                    {badge.label}
                  </Badge>
                </span>
              ) : null}
              <div className="rounded-4 overflow-hidden">
                <Image image={image}/>
              </div>
              <div
                className={'l:flex w-full l:items-center absolute l:static l:mt-16 z-10 top-12 text-16  font-medium truncate ...'}>
                <p className={'text-center l:text-left l:w-auto xl:group-hover:text-bright-blue transition'}>
                  {link?.title}
                </p>
                <ArrowIcon className={'pl-4 hidden l:inline xl:group-hover:text-bright-blue transition'}/>
              </div>
            </Link>
          </li>
        )
      } )}
    </ul>
  )
}