export const getPriceObject = (original, currency = 'USD') => {
  if (!original) return {formatted: '', original: 0};
  const formatted = original.toLocaleString('en', {
    style: 'currency',
    currency: currency,
  }).replace('.00', '')

  return { formatted, original }
}

export default function getPrice(variants, quantity = 1, priceModifier = (price) => price) {
  if (!variants) return getPriceObject(0)
  variants = [].concat(variants)
  if (variants.length === 0) return getPriceObject(0)

  const currency = variants[0]?.price?.currencyCode

  let number = variants.reduce((sum, variant) => {
    return sum + parseFloat(variant.price.amount || +variant?.price) * quantity
  }, 0)

  return getPriceObject(priceModifier(number), currency)
}
