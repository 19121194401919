import { screens } from 'lib/constants'
import cx from 'classnames'

const colCount = {
  '2xs': 2,
  xs: 2,
  s: 6,
  m: 6,
  l: 12,
  xl: 12,
  xxl: 12,
  '2xl': 12,
}
const ports = Object.keys(screens)

const incCol = (port, excount, inc) => {
  if (excount + inc > colCount[port]) return colCount[port]
  else return excount + inc
}

const buildObject = (val) => {
  if (typeof val !== 'object') return val ? { all: val } : val
  else return val
}
export default function Row({ children, fixed = false, MCL = 1, PL = 1 }) {
  let classes = ''
  const fixedGridCol = {}
  if (fixed) {
    const cols = children.flat()
    const colspan = cols.map((c) => buildObject(c.props.colspan))
    const colPorts = Object.keys(
      colspan.reduce((cp, col) => {
        cp = {
          ...cp,
          ...col,
        }
        return cp
      }, {}),
    )
    const usedPorts = ports.reduce((up, port) => {
      if (colPorts.includes(port)) up.push(port)
      return up
    }, [])
    const counter = usedPorts.reduce((c, port) => {
      c[port] = 0
      return c
    }, fixedGridCol)

    usedPorts.forEach((port, mainPos) => {
      colspan.forEach((c) => {
        if (c[port]) counter[port] = incCol(port, counter[port], c[port])
        else {
          const _ports = Object.keys(c)
          for (let index = 0; index < _ports.length; index++) {
            const pos = ports.indexOf(_ports[index])
            if (pos > mainPos) {
              if (!index) {
                // counter[port] += 0;
                break
              } else {
                counter[port] = incCol(port, counter[port], c[_ports[index - 1]])
                break
              }
            } else if (pos < mainPos && (!index || index === _ports.length - 1)) {
              counter[port] = incCol(port, counter[port], c[_ports[index]])
              break
            }
          }
        }
      })
    })
    if (PL > 0 || MCL > 0) {
      classes = 'fixed-grid'
    } else {
      classes = 'fixed-grid'
    }
  } else classes = 'l:grid-cols-12 xxl:grid-cols-12'

  return (
    <div
      style={Object.keys(fixedGridCol).reduce((fgc, port) => {
        fgc[`--${port}-col-count`] = fixedGridCol[port]
        return fgc
      }, {})}
      className={cx('grid gap:24 l:gap-30', classes)}
    >
      {children}
    </div>
  )
}
