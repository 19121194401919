import { useEffect } from 'react'
import getUUID from '../lib/generateUID'

export const useRecartCookie = () => {
  useEffect(() => {
    const indentity = getCookie('_shopify_y')
    if (!indentity) {
      setCookie({ name: '_shopify_y', value: getUUID() })
    }

  }, [])
}

export function getCookie(name) {
  const match = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')
  return match ? match.pop() : ''
}

export function setCookie(cookieObj) {
  let cookie = `${cookieObj.name}=${encodeURIComponent(cookieObj.value ?? '')};`

  // we set cookies for 1 year by default
  const exp = cookieObj.expires ?? 365
  const expires = new Date(new Date().getTime() + exp * 1000 * 60 * 60 * 24)

  cookie += `expires=${expires.toUTCString()};`
  cookie += 'path=/;'

  if (cookieObj.domain) {
    cookie += `domain=${cookieObj.domain};`
  }

  cookie += 'SameSite=None;Secure;'

  document.cookie = cookie
}