import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'

const withErrorBoundary = (Component) => {
  return function WrappedComponentWithErrorBoundry(props) {
    return (
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    )
  }
}
export default withErrorBoundary
