import pluralize from 'lib/pluralize'
import getLineItemImageAssetId from 'lib/getLineItemImageAssetId'
import getLineItemDescription from 'lib/getLineItemDescription'
import { shopify } from 'lib/shopify'
import { formatNumberAsPrice } from './formatPrice'

const get3MonthSellingPlan = (productOrVariant) => {
  let planId = productOrVariant?.sellingPlans?.find(x => x.name.toLowerCase().includes('3 month'))?.id
  if (!planId) return null
  return planId.includes('gid://shopify/SellingPlan') ? planId : `gid://shopify/SellingPlan/${shopify.legacyDecode(planId)}`
}

export default function lineItems(product, payload) {
  const queuedLineItems = []
  const timestamp = Date.now()

  if (!!product.subscriptionType) {
    const { planProducts, isSubscriptionActive, frequency, unit, quantity, subPrice, variant: payloadVariant, attributes = [] } = payload
    planProducts.forEach((_product) => {
      const { variants, subscription } = _product
      let variant
      let variantId

      const customAttributes = [
        ...attributes,
        {
          key: '__timestamp',
          value: timestamp.toString(),
        },
      ]

      if (!isSubscriptionActive) {
        variant = payloadVariant
        variantId = shopify.encode(variant.variantId, 'ProductVariant')
        customAttributes.push(
          // {
          //   key: '__line_item_image_asset_id',
          //   value: getLineItemImageAssetId(product, variant),
          // },
          {
            key: '__line_item_title',
            value: product.title,
          },
        )
      }

      if (isSubscriptionActive) {
        variant = payloadVariant
        variantId = shopify.encode(variant.variantId, 'ProductVariant')


        const pluralizedUnit = frequency > 1 ? pluralize(unit) : unit
        const description = `Refills every ${frequency} ${pluralizedUnit} for ${formatNumberAsPrice(variant?.recurringPrice || product?.subscriptionBreakdown?.recurringCost) || subPrice}`

        customAttributes.push(
          {
            key: 'subscription_id',
            value: (subscription._id || subscription._ref).toString(),
          },
          {
            key: 'selling_plan',
            value: get3MonthSellingPlan(variant?.sellingPlans?.length ? variant : product),
          },
          {
            key: 'shipping_interval_frequency',
            value: frequency.toString(),
          },
          {
            key: 'shipping_interval_unit_type',
            value: unit,
          },
          {
            key: '__line_item_title',
            value: product.title,
          },
          {
            key: '__line_item_description',
            value: description,
          },
          )
      }

      queuedLineItems.push({
        variantId,
        quantity,
        customAttributes,
      })
    })
  }
  else {
    const { variant, quantity, attributes = [] } = payload
    const variantId = shopify.encode(variant.variantId, 'ProductVariant')

    const customAttributes = [
      ...attributes,
      // {
      //   key: '__line_item_image_asset_id',
      //   value: getLineItemImageAssetId(product, variant),
      // },
      {
        key: '__timestamp',
        value: timestamp.toString(),
      },
    ]

    queuedLineItems.push({
      variantId,
      quantity,
      customAttributes,
    })
  }

  return queuedLineItems
}
