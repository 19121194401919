import { round } from 'martha'
import cx from 'classnames'

export default function StarRating({
  score = 0,
  fill = 'text-navy-blue',
  size = 'large',
  input = false,
  onChange = () => {},
  style={}
}) {
  return (
    <div className={cx('flex space-x-2', {
      'text-navy-blue': fill === 'text-navy-blue',
      'text-royal-blue': fill === 'text-royal-blue',
      'text-malibu-blue': fill === 'text-malibu-blue'
    })} style={style}>
      {new Array(5).fill(1).map((_, i) => {
        const remaining = score - i
        let value = 1
        if (remaining <= 0) {
          value = 0
        } else if (remaining < 1) {
          value = remaining
        }

        return (
          <Star key={i} value={value} size={size} index={i} input={input} onChange={onChange} />
        )
      })}
    </div>
  )
}

function Star({ value, size, index, input, onChange }) {
  const Component = input ? 'button' : 'div'
  const rounded = round5(value)
  const isFilled = rounded === 1
  const isEmpty = rounded === 0

  return (
    <Component
      type={input ? 'button' : null}
      className={cx(typeof size === 'string' ? (size === 'small' ? `w-12` : `w-16`) : null, {
        'rounded focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-1 focus-visible:ring-royal-blue group': input,
      })}
      style={{
        width: typeof size === 'number' ? size : null,
      }}
      onClick={input ? () => onChange(index + 1) : null}
    >
      <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 1.26414L9.70382 5.22058L9.82125 5.49326L10.1169 5.52068L14.4062 5.9185L11.1699 8.76154L10.9468 8.95749L11.0121 9.24712L11.9592 13.4494L8.25528 11.2501L8 11.0985L7.74472 11.2501L4.04076 13.4494L4.98788 9.24712L5.05315 8.95749L4.83011 8.76154L1.59381 5.9185L5.88313 5.52068L6.17875 5.49326L6.29618 5.22058L8 1.26414Z"
          className={cx('stroke-current', { 'fill-current': isFilled })}
        />
        <path
          d="M5.88318 5.52068L6.1788 5.49326L6.29623 5.22058L7.50005 2.42518V11.3954L4.04081 13.4494L4.98793 9.24712L5.05321 8.95749L4.83016 8.76154L1.59387 5.9185L5.88318 5.52068Z"
          className={cx({ 'stroke-current fill-current': !isEmpty })}
        />
      </svg>
    </Component>
  )
}

function round5(x) {
  const result = [x]
    .map((x) => round(x, 10))
    .map((x) => x * 10)
    .map((x) => Math.round(x / 5) * 5)
    .map((x) => x / 10)

  return result[0]
}
