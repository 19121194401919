export default {
  get(key) {
    if(!this.cookieSet) this.init();
    return this.cookieSet[key] || ''
  },
  init() {
    const cookie = document?.cookie || ''
    this.cookieSet = cookie.split('; ').reduce((set, item) => {
      const parts = item.split('=')
      set[parts[0]] = parts[1] || ''
      return set
    }, {})
  }
}