/* eslint-disable react/display-name */
import React, { forwardRef } from 'react'
import CartBanner from './CartBanner'

interface CartHeaderProps {
  text: string
  count: number
  handleClose: () => void
}

const CartHeader = forwardRef<HTMLDivElement, CartHeaderProps>(
  ({ text, count, handleClose }, ref) => {
    return (
      <div className="absolute w-full top-0 bg-white shadow-top-bar z-30" ref={ref}>
        <div className="relative w-full h-45 s:h-50">
          <button
            className="absolute left-0 h-full px-15 s:px-25 flex items-center focus:outline-none group transform rotate-180"
            onClick={handleClose}
          >
            <div className="w-32 rounded group-focus-visible:ring-2 group-focus-visible:ring-royal-blue group-focus-visible:ring-offset-2">
              <Arrow />
            </div>
          </button>
          <div className="absolute-center text-24 s:text-26 font-medium leading-100 mt-1">
            {text}
          </div>
          <div className="absolute right-0 h-full px-15 s:px-25 flex items-center text-center">
            <div className="min-w-28 s:min-w-32 h-28 s:h-32 rounded-pill border border-navy-blue relative">
              <div className="absolute-center text-16 s:text-18 font-medium leading-100 mt-1">
                {count}
              </div>
            </div>
          </div>
        </div>
        {CartBanner()}
      </div>
    )
  }
)

export default CartHeader

function Arrow() {
  return (
    <svg viewBox="0 0 32 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.90332 12.9333C8.22083 13.2508 8.22083 13.7656 7.90332 14.0831C7.58582 14.4006 7.07104 14.4006 6.75354 14.0831L0 7.32955L6.75353 0.576019C7.07104 0.258514 7.58582 0.258514 7.90332 0.576019C8.22083 0.893524 8.22083 1.4083 7.90332 1.72581L2.29957 7.32955L7.90332 12.9333Z"
        className="fill-current"
      />
      <path
        d="M31.9996 7.32943C31.9996 6.87931 31.6347 6.51443 31.1846 6.51443H0.999629V8.14443H31.1846C31.6347 8.14443 31.9996 7.77954 31.9996 7.32943Z"
        className="fill-current"
      />
    </svg>
  )
}
