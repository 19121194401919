import Chevron from 'components/Icons/Chevron'
import cx from 'classnames'
import { twMerge } from 'tailwind-merge'

export default function ChevronButton({ label, direction, onClick = () => { }, disabled, className = 'shadow-overlay', chevronIconClassName = '' }) {
  return (
    <button
      className={cx('relative w-45 h-45 group focus:outline-none', {
        'opacity-40': disabled,
      })}
      onClick={onClick}
      disabled={disabled || undefined}
      aria-label={label}
    >
      <div className={twMerge(`w-30 h-30 absolute-center bg-white border border-navy-blue rounded-50 group-focus-visible:ring-2
        group-focus-visible:ring-offset-1 group-focus-visible:ring-royal-blue ${className}`)}>
        <div className={twMerge(`absolute-center w-8 ${chevronIconClassName}`)}>
          <Chevron direction={direction}/>
        </div>
      </div>
    </button>
  )
}
