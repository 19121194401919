import Image from '../../Image'
import QuantitySelector from 'components/QuantitySelector'
import {
  useUpdateCartItems,
  useRemoveCartItems,
  useAutoDiscount,
  useStore,
  useAddCartItems,
  useSubscriptionState,
} from 'context/globalStore'
import * as analytics from 'lib/analytics'
import { useCallback, useEffect, useMemo, useState } from 'react'
import formatPrice from 'lib/formatPrice'
import getPrice from 'lib/getPrice'
import { shopify } from 'lib/shopify'
import cx from 'classnames'
import usePLPAutoDiscount from 'hooks/usePLPAutoDiscount'
import { usePromoConfig } from 'hooks/usePromoConfig'
import { getSubDiscountedPrice } from 'lib/getDiscountedPrice'
import blocksToHtml from '@sanity/block-content-to-html'
import { getCartSubscriptionTitle } from 'components/SubscriptionKitForm/helper'
import { formatNumberAsPrice } from 'lib/formatPrice'
import updateCartItemsAsync from '../helpers/updateGiftLineItem'
import { useRouter } from 'next/router'
import { FREE_GIFT_PROMO_TYPE } from '../constants'
import CartSubscriptionSubText from './CartSubscriptionSubText'
import CartLineExtraItem from './CartLineExtraItem'
import CartRemoveIconItem from './CartRemoveIconItem'
import { extraFreeProductsUtils } from '../helpers/extraFreeProductUtils'

const h = blocksToHtml.h

const serializers = {
  marks: {
    color: (props) => h('span', { style: `color: ${props.mark.hex}` }, props.children),
  },
}

const isLineItemConvertibleToSubscription = (product, lineItem) => {
  // Check if Line Item is alredy a subscription
  if (lineItem.customAttributes.find((att) => att.key == 'selling_plan')) return false

  // Check if product has a subscription type
  if (!product?.subscriptionType) return false

  // Check if added variant is subscribable
  const addedVariant = product?.variants?.find(
    (variant) => `gid://shopify/ProductVariant/${variant.variantId}` === lineItem?.variant?.id
  )
  if (
    (!!addedVariant?.sellingPlans &&
      !addedVariant?.sellingPlans?.find((plan) => plan.type == 'subscription')) ||
    addedVariant?.disableForSubscription
  )
    return false

  return true
}

export default function CartLineItem({
  item,
  globals,
  showLoader,
  bgClasses = 'bg-white',
  product,
  activeDiscount,
  setLineItemPrices = () => { },
  removingItem,
  filteredLineItems,
  setRemovingItem,
  totalPrice,
  excludedProductsPrice,
  allLineItems,
  giftJustAdded,
  setGiftJustAdded,
  isTriggerProductInTheCart
}) {
  const { store, setStore } = useStore()
  const updateCartItems = useUpdateCartItems()
  const removeCartItems = useRemoveCartItems()
  const addCartItems = useAddCartItems()

  const { setAutoDiscount, autoDiscount } = useAutoDiscount()
  const [showSubscriptionToggle, setShowSubscriptionToggle] = useState(false)

  const [quantity, setQuantity] = useState(item.quantity)
  const { isSubscriptionAllowed } = useSubscriptionState()
  const variantId = shopify.decode(item.variant.id)
  const promoConfig = usePromoConfig({})
  const [isGwpAdded, setIsGwpAdded] = useState(false)

  const { autoDiscountSubscriptionAmount } = usePLPAutoDiscount(product || {})

  const enableAutoDiscount = promoConfig?.enableAutoDiscount
  const cartSubTitle = getCartSubscriptionTitle(globals, product)
  const router = useRouter()
  const cartPromoFromArray = JSON.parse(localStorage?.getItem('cartPromo'));
  const primaryColorForPromo = promoConfig?.promoTheme?.primaryColor?.hex || cartPromoFromArray?.promoTheme?.primaryColor?.hex
  const primaryColorForSubscriptionItem = cartPromoFromArray?.promoTheme?.primaryColor?.hex || globals?.subSettings?.subscriptionNotationColor?.hex

  const variant = product?.variants?.find((variant) => variant.sku == item.variant.sku)
  const sellingPlan =
    variant?.sellingPlans?.find((sp) => sp?.type === 'subscription') ??
    variant?.sellingPlans?.[0] ??
    null

  const appliedLineItemDiscount = item.discountAllocations.find(
    ({ discountApplication: discount }) => {
      return (
        discount &&
        (discount.targetSelection === 'LINE_ITEM' || discount.targetType === 'LINE_ITEM')
      )
    }
  )

  const cartSubscriptionToggleText = useMemo(() => {
    const isDiscountApplied = item?.discountAllocations?.length > 0

    const baseTitle =
      enableAutoDiscount && isDiscountApplied
        ? promoConfig?.subscribeAndSaveDiscountText || cartSubTitle?.cartSubscriptionTitle
        : cartSubTitle.cartSubscriptionTitle
    const serializeTitle =
      (isDiscountApplied && !!promoConfig && !!promoConfig?.subscribeAndSaveDiscountText?.length) ||
      cartSubTitle.serializeOutput

    const title = serializeTitle
      ? blocksToHtml({
        blocks: baseTitle,
        serializers: serializers,
      })
      : baseTitle

    const discountAmount = sellingPlan?.discountAmount

    const appliedDiscountText =
      !enableAutoDiscount || autoDiscountSubscriptionAmount === 0 || !appliedLineItemDiscount
        ? discountAmount + '%'
        : `<span class="font-medium"><span class="line-through">${discountAmount}%</span> <span class="font-medium text-bright-blue">${autoDiscountSubscriptionAmount}%</span></span>`

    return title?.replace('$discountAmount%', appliedDiscountText)
  }, [
    autoDiscountSubscriptionAmount,
    cartSubTitle.cartSubscriptionTitle,
    cartSubTitle.serializeOutput,
    enableAutoDiscount,
    item?.discountAllocations?.length,
    promoConfig,
    sellingPlan?.discountAmount,
  ])

  useEffect(() => {
    setQuantity(item.quantity)
  }, [item.quantity])

  useEffect(() => {
    if (isLineItemConvertibleToSubscription(product, item)) {
      setShowSubscriptionToggle(true)
    } else {
      setShowSubscriptionToggle(false)
    }
  }, [item, product, variantId])

  const isTieredPromotion = store.tieredPromotions.find(
    (promo) => promo.couponCode === activeDiscount?.couponCode
  )
  const promoExcludeTypes = ['Donation', 'Gift Cards']
  const isPromoExcludeItem = promoExcludeTypes.includes(item?.variant?.product?.productType)

  const isGiftItem = promoConfig?.promoType === FREE_GIFT_PROMO_TYPE && item.discountAllocations.length > 0 || cartPromoFromArray?.promoType === FREE_GIFT_PROMO_TYPE && item.discountAllocations.length > 0

  const {
    checkout: { discountApplications },
  } = store
  const [appliedPromo] = discountApplications
  const { targetType } = appliedPromo || {}
  const nonDiscountableItem =
    targetType === 'LINE_ITEM' && !item.discountAllocations.length && !isPromoExcludeItem

  const discountGiftProduct =
    autoDiscount && autoDiscount.giftProduct
      ? (({ giftProduct }) => {
        const sku = giftProduct.variants[0].sku
        return item.variant.sku === sku
      })(autoDiscount)
      : false

  const imageAssetId = item.customAttributes.find(
    (attr) => attr.key === '__line_item_image_asset_id'
  )

  const title = item.customAttributes.find((attr) => attr.key === '__line_item_title')

  const description = item.customAttributes.find((attr) => attr.key === '__line_item_description')
  const secondaryDescription = item.customAttributes.find(
    (attr) => attr.key === '__line_item_secondary_description'
  )

  const isSubscriptionItem =
    item?.customAttributes?.find((attr) => attr?.key === 'subscription_id')?.value !== undefined

  const getSubTitle = () => {
    return item.customAttributes.find((attr) => attr.key === '__line_item_description')?.value
  }

  const variantInfo = item.variant.selectedOptions
    .map(attr => attr.value === "Default Title" ? "" : attr.value)
    .join(' / ');

  const productPrice = useCallback(() => {
    return getPrice(item.variant, item.quantity, (price) => {
      return isSubscriptionItem && appliedLineItemDiscount
        ? getSubDiscountedPrice(price, product)
        : price
    })
  }, [appliedLineItemDiscount, isSubscriptionItem, item.quantity, item.variant, product])

  const subscriptionDiscountApplied =
    isSubscriptionAllowed && isSubscriptionItem && !showSubscriptionToggle

  const productPriceBefore = useMemo(() => {
    const { formatted } = productPrice()
    return formatted
  }, [productPrice])

  const productPriceOriginal = useMemo(() => {
    const { formatted } = getPrice(item.variant, item.quantity, (price) => price)
    return formatted
  }, [productPrice])

  const afterDiscountAmount = useMemo(() => {
    const { original } = productPrice()
    const discountAllocations = item.discountAllocations

    const amount = discountAllocations.reduce((acc, curr) => {
      if (curr.discountApplication.targetType === 'LINE_ITEM') {
        return acc + parseFloat(curr.allocatedAmount.amount)
      }
    }, 0)

    const finalAmount = isGiftItem && isSubscriptionItem ? getSubDiscountedPrice(amount, product) : amount

    const afterDiscountAmount =
      subscriptionDiscountApplied && enableAutoDiscount && promoConfig.promoType !== 'FREE_GIFT'
        ? promoConfig?.subscriptionDiscountPct ?? 0
        : (parseFloat(finalAmount) / original) * 100
    return Math.round(afterDiscountAmount)
  }, [
    enableAutoDiscount,
    item.discountAllocations,
    productPrice,
    promoConfig?.subscriptionDiscountPct,
    subscriptionDiscountApplied,
  ])

  const productPriceAfter = useMemo(() => {
    const afterDiscount = true
    const { formatted } = productPrice()
    const { original } = getPrice(item.variant, item.quantity, (price) => price)
    let discountedPrice = ''
    if (afterDiscount && appliedLineItemDiscount) {
      const discountAllocations = item.discountAllocations
      const amount = discountAllocations.reduce((acc, curr) => {
        if (curr.discountApplication.targetType === 'LINE_ITEM') {
          return acc + parseFloat(curr.allocatedAmount.amount)
        }
      }, 0)

      const { currencyCode } = appliedLineItemDiscount.allocatedAmount
      const afterDiscountAmount =
        original -
        (subscriptionDiscountApplied && enableAutoDiscount && promoConfig.promoType !== 'FREE_GIFT'
          ? ((promoConfig?.subscriptionDiscountPct ?? 0) / 100) * original
          : parseFloat(amount))
      discountedPrice =
        afterDiscountAmount === 0
          ? 'FREE'
          : formatPrice({
            price: {
              amount: afterDiscountAmount,
              currencyCode,
            },
          })
    } else {
      if (isSubscriptionItem) {
        discountedPrice = formatNumberAsPrice(getSubDiscountedPrice(original, product))
      }
    }
    return discountedPrice || formatted
  }, [
    productPrice,
    appliedLineItemDiscount,
    showSubscriptionToggle,
    item.discountAllocations,
    product,
    isSubscriptionItem,
  ])

  useEffect(() => {
    setLineItemPrices({ before: productPriceBefore, after: productPriceAfter })
  }, [productPriceAfter, productPriceBefore])

  const getDiscountedTotal = (price) => {
    const productTotal = Number(price.replace('$', ''))
    let discountedTotal = null
    if (activeDiscount?.type === 'percentage') {
      discountedTotal =
        productTotal - (parseFloat(productTotal) / 100) * Number(activeDiscount?.discountAmount)
    } else {
      discountedTotal = ''
    }
    return discountedTotal ? `$${discountedTotal.toFixed(2)}` : ``
  }

  const getProductPriceNew = () => {
    const { formatted } = productPrice()
    const { type, discountAmount } = activeDiscount
    return (
      <div>
        <span
          className={cx(
            'text-13 s:text-16 leading-100 font-normal ml-auto text-navy-blue-muted l:mr-4',
            {
              'mr-5 line-through': discountAmount && type === 'percentage',
            }
          )}
        >
          {formatted}
        </span>
        {type === 'percentage' && (
          <span className="text-13 s:text-16 leading-100 font-medium ml-auto l:mr-4">
            {getDiscountedTotal(formatted)}
          </span>
        )}
      </div>
    )
  }

  const isTriggerQuantity = promoConfig?.gwpIncludedProducts?.some(gwpProduct =>
    gwpProduct.variants.some(variant =>
      filteredLineItems?.some(item => shopify.decode(item.variant.id) === variant.variantId && item.quantity >= promoConfig?.quantityForTriggerGiftProduct)
    )
  )

  const isEligibleForDiscount = globals.cartSubscriptionToggle &&
    globals.cartSubscriptionToggle.cartSubscriptionSubText &&
    enableAutoDiscount &&
    item?.discountAllocations?.length > 0 &&
    promoConfig?.cartSubscriptionToggleNotation &&
    afterDiscountAmount > 0;

  useEffect(() => {
    setIsGwpAdded(filteredLineItems?.some(item =>
      item.discountAllocations.some(discountAllocation =>
        discountAllocation.discountApplication.code === promoConfig?.promoCode
      )
    ))

    if (promoConfig?.promoType === FREE_GIFT_PROMO_TYPE && excludedProductsPrice < promoConfig.thresholdAmountForGift) {
      localStorage.setItem('isGwpRemoved', false)
    }

    if (promoConfig?.promoType === FREE_GIFT_PROMO_TYPE && !isTriggerProductInTheCart && promoConfig.thresholdAmountForGift <= 0) {
      localStorage.setItem('isGwpRemoved', false)
    }

    updateCartItemsAsync(filteredLineItems, promoConfig, excludedProductsPrice, item, isTriggerQuantity, updateCartItems, giftJustAdded);

    if (promoConfig?.promoType === "FREE_GIFT") {
      setAutoDiscount({ promoCode: promoConfig.promoCode })
    }
  }, [totalPrice, excludedProductsPrice, item.discountAllocations.length, router, giftJustAdded]);

  const { createParentAttributeObj, checkIsAllParentsRemoved } = extraFreeProductsUtils()

  async function handleRemoveClick(autoRemoveForSubscription = true, item, parent) {
    setGiftJustAdded(false)
    setRemovingItem(true)
    const items = [item.id]
    let newCheckout;
    const giftProduct = promoConfig?.giftWithPurchase && promoConfig?.giftWithPurchase[0]?.variant?.variantId === shopify.decode(item.variant.id) && item.customAttributes.some(attr => attr.key === '__gift-item')

    if (promoConfig?.promoType === FREE_GIFT_PROMO_TYPE && giftProduct) {
      localStorage.setItem('isGwpRemoved', true)
    }

    if (promoConfig?.promoType === FREE_GIFT_PROMO_TYPE && !isTriggerQuantity && promoConfig.thresholdAmountForGift <= 0) {
      localStorage.setItem('isGwpRemoved', false)
    }

    if (promoConfig?.promoType === FREE_GIFT_PROMO_TYPE && excludedProductsPrice < promoConfig.thresholdAmountForGift) {
      localStorage.setItem('isGwpRemoved', false)
    }

    if (item.customAttributes.some(attr => attr.key === 'extra_free_product_gift_for_list')) {
      const parentAttributeObject = createParentAttributeObj(parent)
      const removedExtraFreeProducts = JSON.parse(localStorage.getItem('removedExtraFreeProducts')) || [];
      removedExtraFreeProducts.push({ parent: parentAttributeObject, giftProductId: item.variant.id });

      const isAllParentsRemoved = checkIsAllParentsRemoved(item, removedExtraFreeProducts);
      localStorage.setItem('removedExtraFreeProducts', JSON.stringify(removedExtraFreeProducts));

      if (!isAllParentsRemoved) {
        setTimeout(() => {
          setRemovingItem(false)
        }, 500)
        return;
      }
    }

    if (appliedLineItemDiscount || discountGiftProduct) {
      const productId = shopify.decode(item.variant.product.id)
      const updateAutoDiscount =
        productId === autoDiscount?.giftProduct?.productId
          ? { productOptOut: true, giftProductDND: false }
          : { productOptOut: true }
      setAutoDiscount(updateAutoDiscount)
    }

    if (appliedLineItemDiscount && discountGiftProduct || allLineItems.length <= 1 && autoRemoveForSubscription) {
      const { checkout, countryCode } = store
      await shopify.removeDiscount(checkout.id, countryCode)
    }

    newCheckout = await removeCartItems(items, false)
    const productsToTrack = [
      {
        product: item,
        variant: item.variant,
        quantity: item.quantity,
        positionIndex: '1',
      },
    ]

    analytics.trackRemoveFromCart(productsToTrack)

    // to aviod memory leak issue, using setTimeout()
    setTimeout(() => {
      setStore((prevState) => ({
        ...prevState,
        checkout: newCheckout,
        isUpdating: false,
      }))
      setRemovingItem(false)
    }, 500)
  }

  async function handleQuantityChange(value) {
    analytics.trackCartClick('QtySelector')

    setGiftJustAdded(false)
    setQuantity(value)

    const action = value > item.quantity ? 'add' : 'remove'

    if (promoConfig?.promoType === FREE_GIFT_PROMO_TYPE && !isTriggerQuantity && promoConfig.thresholdAmountForGift <= 0) {
      localStorage.setItem('isGwpRemoved', false)
    }

    if (promoConfig?.promoType === FREE_GIFT_PROMO_TYPE && excludedProductsPrice < promoConfig.thresholdAmountForGift) {
      localStorage.setItem('isGwpRemoved', false)
    }

    const items = [
      {
        id: item.id,
        quantity: value,
      },
    ]

    if (allLineItems.length <= 1) {
      const { checkout, countryCode } = store
      await shopify.removeDiscount(checkout.id, countryCode)
    }

    await updateCartItems(items)

    const productsToTrack = [
      {
        product: item,
        variant: item.variant,
        quantity: 1,
        positionIndex: '1',
      },
    ]

    if (action === 'add') {
      analytics.trackAddToCart(productsToTrack)
    } else {
      analytics.trackRemoveFromCart(productsToTrack)
    }
  }

  async function convertToSubscription() {
    // return
    const variant = product?.variants?.find((variant) => variant.sku == item.variant.sku)
    const _product = { ...product }
    await addCartItems(
      {
        product: _product,
        shouldSetIsAdding: false,
        payload: {
          planProducts: [_product],
          variant,
          quantity: item.quantity,
          unit: 'month',
          isSubscriptionActive: true,
          frequency: 3,
          subPrice: getPrice(variant, item.quantity, (price) =>
            getSubDiscountedPrice(price, _product)
          )?.formatted,
        },
      },
      false,
      false,
      true
    )

    // to fix Shopify api throttler issues
    const isContainExtraFreeProducts = variant.freeExtraProduct?.length > 0
    if (isContainExtraFreeProducts) {
      const timeoutLength = variant.freeExtraProduct.length * 1000
      setTimeout(() => {
        handleRemoveClick(false, item).then()
      }, timeoutLength)
    } else {
      handleRemoveClick(false, item).then()
    }
  }

  async function toggleSubscription(e) {
    if (e.target.checked) {
      typeof showLoader === 'function' && showLoader(true)
      await convertToSubscription()
      typeof showLoader === 'function' && showLoader(false)
    }
  }

  const showDiscountText = enableAutoDiscount && item?.discountAllocations?.length > 0 && promoConfig?.autoDiscountCartItemText && afterDiscountAmount > 0 ||
    cartPromoFromArray?.enableAutoDiscount && afterDiscountAmount > 0 && item?.discountAllocations?.length > 0 && cartPromoFromArray?.autoDiscountCartItemText

  const isExtraFreeProduct = item?.customAttributes?.some(attr => attr.key === 'extra_free_product_gift_for_list')
  if (isExtraFreeProduct) {
    return null
  }

  const { getParentsFromExtraFreeProduct } = extraFreeProductsUtils()
  const extraProducts = filteredLineItems.filter(item => item?.customAttributes?.some(attr => attr.key === 'extra_free_product_gift_for'))
  const filteredExtraProducts = extraProducts.filter(extraProductItem => {
    const isSubscription = item.customAttributes.some(attr => attr.key === 'subscription_id')
    const parents = getParentsFromExtraFreeProduct(extraProductItem)
    return parents.find((parent) => parent.parentVariantId === item.variant.id && parent.isSubscription === isSubscription)
  })

  return (
    <li
      className={cx('cart-line-item relative')}
    >
      <div className={cx('rounded py-10 s:py-16 pl-8 s:pl-25 pr-15 s:pr-20', bgClasses)}>
        <div className="flex items-start space-x-8 s:space-x-20">
          <div className="w-64 xs:w-70 s:w-90 h-64 xs:h-70 s:h-90 flex-shrink-0 rounded overflow-hidden">
            {imageAssetId?.value ? (
              <Image
                image={imageAssetId.value}
                width={70}
                height={70}
                fill={true}
                fit="contain"
                placeholder={false}
                sizes="70px"
                alt=""
              />
            ) : (
              <img
                src={item?.variant?.image?.src}
                alt={item?.variant?.image?.altText || 'Blueland: Eco-Friendly Cleaning Products'}
                width={90}
                height={90}
                placeholder={false}
                sizes="70px"
                className="w-full h-full temp-class-name"
              />
            )}
          </div>
          <div className="w-full">
            <div className="space-y-6 s:space-y-6 mb-6 s:w-70p">
              <div className="text-13 leading-13 s:text-16 s:leading-23 font-medium">
                {title?.value ?? item.title}
              </div>
            </div>
            {/* {secondaryDescription?.value ? (
          <div className="text-11 leading-13 s:text-13 mb-5 text-navy-blue-muted s:w-70p">
            {secondaryDescription?.value}
          </div>
        ) : null} */}
            {variantInfo && getSubTitle() !== variantInfo ? (
              <div className="text-11 leading-13 s:text-13 mb-5 text-navy-blue-muted s:w-70p">
                {variantInfo}
              </div>
            ) : null}
            {description?.value ? (
              <div className="text-11 leading-13 s:text-13 mb-5 text-navy-blue-muted s:w-70p">
                {getSubTitle()}
              </div>
            ) : null}
            <div className={cx('flex',
              {
                'justify-between': !isGiftItem,
                'justify-end': isGiftItem,
                'items-center': !item?.discountAllocations?.length && afterDiscountAmount <= 0,
                'items-start s:items-end s:-mt-22': enableAutoDiscount && !isGiftItem && item?.discountAllocations?.length && promoConfig?.autoDiscountCartItemText && afterDiscountAmount > 0 && isSubscriptionAllowed && isSubscriptionItem && !showSubscriptionToggle && promoConfig?.autoDiscountSubscriptionText,
                'items-start s:items-center': enableAutoDiscount && item?.discountAllocations?.length && promoConfig?.autoDiscountCartItemText && afterDiscountAmount > 0 && showSubscriptionToggle
              }
            )}>
              {promoConfig?.promoType === FREE_GIFT_PROMO_TYPE || cartPromoFromArray?.promoType === FREE_GIFT_PROMO_TYPE ? (!isGiftItem ? <QuantitySelector value={quantity} size="small" onChange={handleQuantityChange} /> : '') :
                <QuantitySelector value={quantity} size="small" onChange={handleQuantityChange} />}
              <div className='mt-6 s:mt-0'>
                {(!activeDiscount?.discountAmount) && (
                  <div className="text-13 leading-13 s:text-16 s:leading-23 font-medium ml-auto l:mr-4 text-right">
                    {appliedLineItemDiscount && afterDiscountAmount > 0 ? (
                      <span className="mr-5 line-through" style={{ color: '#A1B1BE' }}>
                        {productPriceOriginal}
                      </span>
                    ) : (
                      ''
                    )}{' '}
                    <span
                      className={cx('font-medium')}
                      style={{
                        color:
                          enableAutoDiscount && afterDiscountAmount > 0 && appliedLineItemDiscount || cartPromoFromArray?.enableAutoDiscount && afterDiscountAmount > 0 && appliedLineItemDiscount
                            ? primaryColorForPromo ?? '#3369FF'
                            : undefined,
                      }}
                    >
                      {productPriceAfter}
                    </span>
                  </div>
                )}
                {activeDiscount?.discountAmount ? (
                  <div className='text-13 leading-13 s:text-16 s:leading-23 font-medium ml-auto l:mr-4 text-right'>
                    {isPromoExcludeItem || nonDiscountableItem ? (
                      <>
                        <div className="text-13 s:text-16 leading-100 font-medium ml-auto l:mr-4">
                          {productPriceAfter}
                        </div>
                      </>
                    ) : activeDiscount?.discountAmount ? (
                      <>
                        <div className="text-13 s:text-16 leading-100 font-medium ml-auto l:mr-4">
                          {productPriceAfter}
                        </div>
                      </>
                    ) : (
                      <>
                        <span
                          className={cx('text-13 s:text-16 leading-100 font-medium ml-auto l:mr-4')}
                          style={{
                            color:
                              !enableAutoDiscount &&
                                afterDiscountAmount > 0 &&
                                appliedLineItemDiscount
                                ? primaryColorForPromo ?? '#3369FF'
                                : undefined,
                          }}
                        >
                          {productPriceAfter}
                        </span>
                      </>
                    )}
                  </div>
                ) : null}
                {showDiscountText && (
                  <div
                    className="flex space-x-4 pt-4 s:pt-0 items-center justify-end text-11 leading-13 s:text-13 l:mr-4"
                    style={{ color: primaryColorForPromo ?? '#3369FF' }}
                  >
                    <span>{promoConfig?.autoDiscountCartItemText?.replace('$discountAmount', afterDiscountAmount) || cartPromoFromArray?.autoDiscountCartItemText?.replace('$discountAmount', afterDiscountAmount)}</span>
                    <svg
                      width={14}
                      height={14}
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7Z"
                        fill={primaryColorForPromo ?? '#3369FF'}
                      />
                      <path
                        d="M3 7.69231L5.52632 10L11 5"
                        stroke="white"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                )}
                {enableAutoDiscount &&
                  item?.discountAllocations?.length > 0 &&
                  isSubscriptionAllowed &&
                  isSubscriptionItem &&
                  promoConfig?.autoDiscountSubscriptionText &&
                  !isGiftItem &&
                  !showSubscriptionToggle && (
                    <div
                      className="flex space-x-4 pt-4 s:pt-5 items-center text-11 leading-13 s:text-13 justify-end l:mr-4"
                      style={{ color: primaryColorForPromo ?? '#3369FF' }}
                    >
                      <span>{promoConfig?.autoDiscountSubscriptionText}</span>
                      <svg
                        width={14}
                        height={14}
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7Z"
                          fill={primaryColorForPromo ?? '#3369FF'}
                        />
                        <path
                          d="M3 7.69231L5.52632 10L11 5"
                          stroke="white"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  )}
                {item?.discountAllocations?.length <= 0 &&
                  isSubscriptionAllowed &&
                  isSubscriptionItem &&
                  globals?.subSettings?.subscriptionNotation &&
                  !showSubscriptionToggle && (
                    <div
                      className="flex space-x-4 pt-4 items-center text-11 s:text-13 justify-end l:mr-4"
                      style={{ color: primaryColorForSubscriptionItem ?? '#3369FF' }}
                    >
                      <span>{globals?.subSettings?.subscriptionNotation?.replace('$discountAmount', sellingPlan?.discountAmount)}</span>
                      <svg
                        width={14}
                        height={14}
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7Z"
                          fill={primaryColorForSubscriptionItem ?? '#3369FF'}
                        />
                        <path
                          d="M3 7.69231L5.52632 10L11 5"
                          stroke="white"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        {isSubscriptionAllowed && showSubscriptionToggle && !isGiftItem && (
          <div
            className={
              'border-1 border-t border-pattens-blue mt-12 l:mt-16.5 pt-11 l:pt-16.5 pb-0 l:pb-5'
            }
          >
            <div className="flex items-start justify-start">
              <input
                onChange={(e) => toggleSubscription(e)}
                className="mr-8 l:mr-12 h-14 w-14 s:w-16 s:h-16 s:mt-2 rounded"
                type="checkbox"
              />
              <div>
                {globals.cartSubscriptionToggle && cartSubTitle.cartSubscriptionTitle && (
                  <h2
                    className="font-medium text-13 leading-13 l:leading-18 text-navy-blue mb-4 l:mb-6"
                    dangerouslySetInnerHTML={{ __html: cartSubscriptionToggleText }}
                  />
                )}
                <CartSubscriptionSubText
                  globals={globals}
                  isEligibleForDiscount={isEligibleForDiscount}
                  promoConfig={promoConfig}
                />
              </div>
            </div>
          </div>
        )}
        <div className="absolute top-4 right-0 l:top-16 l:right-16">
          <button
            className={`block rounded p-4 text-navy-blue-muted focus:outline-none focus-visible:ring-2 focus-visible:ring-royal-blue ${removingItem ? 'pointer-events-none' : 'pointer-events-auto'
              }`}
            onClick={() => handleRemoveClick(true, item)}
          >
            <div className="w-10 m-4">
              <CartRemoveIconItem />
            </div>
          </button>
        </div>
      </div>
      {filteredExtraProducts?.map((lineItem) => (
        <CartLineExtraItem
          key={lineItem.title}
          lineItem={lineItem}
          item={item}
          removingItem={removingItem}
          enableAutoDiscount={enableAutoDiscount}
          afterDiscountAmount={afterDiscountAmount}
          appliedLineItemDiscount={appliedLineItemDiscount}
          cartPromoFromArray={cartPromoFromArray}
          primaryColorForPromo={primaryColorForPromo}
          handleRemoveClick={handleRemoveClick}
        />
      ))}
    </li>
  )
}
