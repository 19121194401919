import React, { useState, useEffect, useCallback, useRef } from 'react'
import SearchResultProduct from 'components/SearchResultProduct'
import Skeleton from './Skeleton'
import Fuse from 'fuse.js'
import Button from 'components/Button'
import * as analytics from 'lib/analytics'
import cx from 'classnames'
import { useAllProducts, useCountryCode } from 'context/globalStore'
import debounce from 'lodash.debounce'

const SearchResultComponent = ({ closeSearchBar, globals, searchConfig }) => {
  const { countryCode } = useCountryCode();
  const [products, setProducts] = useState([])
  const [showProduct, setShowProduct] = useState(6)
  const { allProducts } = useAllProducts()
  // const [filteredPopularProducts, setFilteredPopularProducts] = useState([])
  const [popularProducts, setPopularProducts] = useState([])
  const [loader, setLoader] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState('')
  const bestSellers = searchConfig?.products?.map((item) => item.slug.current)
  const fuse = new Fuse(products, {
    keys: ['title', 'searchTags'],
    includeScore: true,
  })
  let results = fuse.search(searchKeyword)

  const isUS = countryCode === 'US'
  const resultsFilterCb = (result) => {
    if (isUS && result?.item?.disableProductForSearchUS) return false
    if (!isUS && result?.item?.disableProductForSearchInternational) return false

    return !result?.item?.disableProductForSearch;
  }

  let characterResults = results
    .filter(resultsFilterCb)
    .map((result, index) => ({
      ...result.item,
      positionIndex: index + 1,
    }));
  const searchRef = useRef()

  useEffect(() => {
    if (products.length > 0) {
      const temp = []
      for (let i = 0; i < products.length; i++) {
        if (bestSellers?.indexOf(products[i].slug) > -1) {
          temp.push(products[i])
        }
      }
      setPopularProducts(temp)
    }
  }, [products])

  useEffect(() => {
    setProducts(allProducts.filter((x) => x.isEnabled))
  }, [allProducts])

  function CloseIcon() {
    return (
      <svg viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          width="1"
          height="9"
          rx="0.5"
          transform="matrix(-0.707109 -0.707104 0.707109 -0.707105 0.707092 7.07111)"
          fill="#4D6E89"
        />
        <rect
          width="1"
          height="9"
          rx="0.5"
          transform="matrix(0.707109 -0.707104 0.707109 0.707105 6.10352e-05 0.707092)"
          fill="#4D6E89"
        />
      </svg>
    )
  }

  const debouncedTrack = useCallback(
    debounce((trackInput) => {
      const val = searchRef.current && searchRef.current.value ? searchRef.current.value : ''
      analytics.trackSearchTerm(val)
      analytics.trackSearchResultsView(trackInput)
    }, 1500),
    []
  )

  const trackSearch = useCallback(() => {
    debouncedTrack(characterResults)
  }, [characterResults, debouncedTrack])

  function searchIconUpdated() {
    return (
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Mobile"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            id="Home/Search-v2"
            transform="translate(-62.000000, -50.000000)"
            stroke="#003057"
            strokeWidth="2.7"
          >
            <g id="Mobile-Nav" transform="translate(0.000000, 35.000000)">
              <g id="search" transform="translate(64.000000, 17.000000)">
                <circle id="Oval" cx="8.88888889" cy="8.88888889" r="8.88888889"></circle>
                <line x1="20" y1="20" x2="15.1666667" y2="15.1666667" id="Path"></line>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }

  function searchIcon() {
    return (
      <svg viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g
          id="Desktop"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            id="BLU---Search-v2---Field"
            transform="translate(-211.000000, -200.000000)"
            stroke="#003057"
            strokeWidth="2"
          >
            <g id="search-copy" transform="translate(212.000000, 201.000000)">
              <circle id="Oval" cx="10.6666667" cy="10.6666667" r="10.6666667"></circle>
              <line x1="24" y1="24" x2="18.2" y2="18.2" id="Path"></line>
            </g>
          </g>
        </g>
      </svg>
    )
  }

  return (
    <>
      <div className={'w-full relative'}>
        <div className={'relative hidden l:block'}>
          <div className={'absolute left-0 top-12 w-26 h-26 z-10'}>{searchIcon()}</div>
          <input
            className={'w-full l:block hidden searchInput text-32 leading-35 mb-50'}
            type="text"
            placeholder="Search..."
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            onKeyUp={trackSearch()}
            ref={searchRef}
            style={{ height: '45px' }}
          />
          {searchKeyword.length > 0 && (
            <div
              className={
                'absolute test right-10 clearText top-24 cursor-pointer w-26 h-26  text-11 leading-12 font-normal'
              }
              onClick={() => setSearchKeyword('')}
            >
              CLEAR
            </div>
          )}
        </div>
        <div className={'relative block l:hidden'}>
          <div className={'absolute left-0 top-6 w-16 h-16 z-10'}>{searchIcon()}</div>
          <input
            className={
              'w-full searchInput text-16 focus:text-16 leading-17 font-normal mb-20 pl-24'
            }
            type="text"
            placeholder="What are you looking for?"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            onKeyUp={trackSearch()}
            style={{ padding: '6.6px 50px 6px 24px' }}
          />
          {searchKeyword.length > 0 && (
            <div
              className={'clear cursor-pointer clearText text-11 leading-12 font-normal'}
              onClick={() => setSearchKeyword('')}
            >
              CLEAR
            </div>
          )}
        </div>
        <p
          className={
            'letterSpacing-0.24 l:letterSpacing-3 text-12 leading-13 font-normal l:text-15 l:leading-15 l:font-medium mb-10 l:mb-25'
          }
        >
          {characterResults.length > 1 || searchKeyword.length > 1
            ? `PRODUCTS (${characterResults.length} RESULTS)`
            : `POPULAR PRODUCTS`}
        </p>
        <div className={'productsContainer pb-150 l:pb-0 l:mb-50 flex items-start justify-start'}>
          <div className={'relative w-full mb-25p l:mb-25'}>
            {loader ? (
              <>
                {Array(2)
                  .fill()
                  .map((_, i) => (
                    <Skeleton className="mb-20" key={i}>
                      {Array(1)
                        .fill()
                        .map((_, i) => (
                          <div
                            key={i}
                            className=" first:border-t border-b border-pattens-blue flex items-baseline justify-between"
                            aria-hidden="true"
                          >
                            {Array(3)
                              .fill()
                              .map((_, j) => (
                                <div
                                  key={j}
                                  className={cx('m:w-2/3 pr-20', {
                                    'hidden l:block': j == 2,
                                  })}
                                >
                                  <div className="flex flex-col l:flex-row items-start justify-start">
                                    <div
                                      className={
                                        'w-130 h-160 l:w-100 l:h-100 mb-10 l:mb-0 mr-0 l:mr-12 rounded bg-navy-blue-muted'
                                      }
                                    ></div>
                                    <div>
                                      <div
                                        className={'w-90 h-15 mb-5 rounded bg-navy-blue-muted'}
                                      ></div>
                                      <div
                                        className={'w-120 h-15 mb-12 rounded bg-navy-blue-muted'}
                                      ></div>
                                      <div className={'w-70 h-15 rounded bg-navy-blue-muted'}></div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        ))}
                    </Skeleton>
                  ))}
              </>
            ) : (
              <>
                <ul className="flex relative flex-wrap my-10 l:w-full">
                  {characterResults.length >= 1 &&
                    characterResults.map((product, i) => {
                      return (
                        <>
                          {i < showProduct && (
                            <SearchResultProduct
                              key={`${product._id}_${i}`}
                              product={product}
                              collection={'all'}
                              index={i}
                              ratingNumberTemplate="($count)"
                              closeSearchBar={() => closeSearchBar()}
                              searchTerm={searchKeyword}
                            />
                          )}
                        </>
                      )
                    })}
                  {characterResults.length === 0 &&
                    searchKeyword.length === 0 &&
                    popularProducts.map((product, i) => {
                      return (
                        <SearchResultProduct
                          key={`${product._id}_${i}`}
                          product={product}
                          collection={'all'}
                          index={i}
                          ratingNumberTemplate="($count)"
                          closeSearchBar={() => closeSearchBar()}
                        />
                      )
                    })}
                  {showProduct === 6 && characterResults.length > 6 && (
                    <div
                      className={'absolute left-0 '}
                      style={{ bottom: '-35px' }}
                      onClick={() => setShowProduct(characterResults.length + 1)}
                    >
                      <Button
                        className="h-45 w-220 text-18 font-medium"
                        variant="contained"
                      >
                        <span>Show More Results</span>
                      </Button>
                    </div>
                  )}
                </ul>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default SearchResultComponent
