import useMedia from 'use-media'
import { screens } from 'lib/constants'

export default function useCollectionImage(subscriptionPLPEnabled = false) {
  const isScreen2k = useMedia({ minWidth: screens['2k'] })
  const isScreenMedium = useMedia({ maxWidth: screens['2k'], minWidth: screens.s })
  const isScreenSmall = useMedia({ maxWidth: screens.s })

  if (isScreen2k) {
    return {
      height: subscriptionPLPEnabled ? 750 : 600,
      width: subscriptionPLPEnabled ? 750 : 600,
    }
  }
  if (isScreenMedium) {
    return {
      height: subscriptionPLPEnabled ? 550 : 400,
      width: subscriptionPLPEnabled ? 550 : 400,
    }
  }

  if (isScreenSmall) {
    return {
      height: subscriptionPLPEnabled ? 400 : 250,
      width: subscriptionPLPEnabled ? 400 : 250,
    }
  }

  return {
    height: 32,
    width: 32
  }
}
