import cx from 'classnames'
import { useEffect } from 'react'

export default function CarouselPagination({
  count = 0,
  index = 0,
  scrollTo = () => {},
  mode = 'normal',
}) {
  if (!count) return null

  return (
    <div
      className={cx('flex justify-center space-x-10', {
        '-ml-15 l:ml-0': mode === 'cartBestSellers',
      })}
    >
      {new Array(count).fill(0).map((_, i) => (
        <>
          {i < 10 && (
            <button
              key={i}
              aria-label={`Go to slide ${i + 1}`}
              onClick={() => scrollTo(i)}
              className={cx(
                'w-10 h-10 rounded-50 border border-navy-blue-muted focus:outline-none focus-visible:ring-1 focus-visible:ring-offset-1 focus-visible:ring-navy-blue-muted',
                {
                  'bg-navy-blue-muted': i === index,
                },
              )}
            />
          )}
        </>
      ))}
    </div>
  )
}
