export function isEmailValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export function isNullOrEmpty(string){
  return !string || string.length === 0
}

export function isNullOrWhitespace(string){
  return !string || string.length === 0 || string.trim().length === 0
}