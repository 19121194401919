import Link from 'components/Link'
import cx from 'classnames'
import * as analytics from 'lib/analytics'

export default function MobileMenuItem({ link, children, menuType = 1, separator = false, handleClose = () => {} }) {
  return (
    <li
      className={cx(
        'first:mt-0 border-pattens-blue pb-18 l:pb-10 last:border-b hover:text-bright-blue'
      )}
      onClick={() => handleClose()}
    >
      <Link
        link={link}
        className={cx('block font-medium outline-none', {
          'text-16 leading-22 font-medium': menuType === 1,
          'text-16': menuType === 2,
        })}
        onClick={() => analytics.trackNavClick(children)}
      >
        {children}
      </Link>
      {separator && <span className="block my-25 -mx-15 border-b border-pattens-blue"></span>}
    </li>
  )
}
