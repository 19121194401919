import useABVariation from '../../hooks/useABVariation'
import Navigation from './Navigation'
import NavigationOld from './NavigationOld/NavigationOld'
import React from 'react'

export default function NavigationABTest(props) {
  const isNewNavigation = props.isNewNavigation

  return isNewNavigation ? (
    <Navigation{...props} />
  ) : (
    <NavigationOld {...props} />
  )
}