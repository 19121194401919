import CartIcon from 'components/CartIcon'
import cx from 'classnames'
import { useCartItems, useCartTotals, useIsAdding, useIsCartOpen, useMobileMenu, useStore } from 'context/globalStore'
import * as analytics from 'lib/analytics'

const CartToggle = () => {
  const [isAdding] = useIsAdding()
  const { filteredCartCount, paidItemsCartCount } = useCartItems()
  const { _, setIsCartOpen } = useIsCartOpen()
  const { isMobileMenuOpen, toggleMobileMenu } = useMobileMenu()
  const { store: { checkout, allProducts } } = useStore()
  const { total } = useCartTotals()

  function handleClick() {
    if (isMobileMenuOpen) {
      toggleMobileMenu()
    }
    analytics.trackNavClick('Cart')
    const { lineItems } = checkout

    let cartTotal = (total ?? '').replace('$', '')
    analytics.trackCartView(
      lineItems.map((item, index) => ({ ...item, positionIndex: index + 1 })),
      cartTotal
    )
    setIsCartOpen(true)
  }

  return (
    <div className={cx('cartPosition right-0', { 'opacity-65': isAdding })}>
      <button
        id={'cart-icon-ga'}
        className="p-10 focus:outline-none rounded transition focus-visible:bg-pattens-blue"
        onClick={handleClick}
      >
        <div className="w-28">
          {(
            <div
              className="absolute left-20 top-4 w-12 h-18 bg-peach-orange rounded-pill transition ease-in-out-cubic duration-300"
              style={{
                opacity: filteredCartCount ? 1 : 0,
                transform: filteredCartCount ? `rotate(-20deg)` : `translateY(-50%)`,
              }}
            />
          )}
          <div className="relative z-1">
            <CartIcon />
          </div>
          {(
            <div
              className="absolute top-3 right-7 z-2 min-w-17 h-17 bg-white border border-navy-blue rounded-pill transition ease-in-out-cubic duration-300"
              style={{
                opacity: filteredCartCount ? 1 : 0,
                transform: filteredCartCount ? null : `translateY(-500%)`,
              }}
            >
              <div className="relative w-full h-full">
                <div className="absolute-center text-11 leading-100 -mt-1">{paidItemsCartCount}</div>
              </div>
            </div>
          )}
        </div>
      </button>
    </div>
  )
}
export default CartToggle
