import useEmblaCarousel from 'embla-carousel-react'
import { useState, useCallback, useEffect } from 'react'

export default function useCarousel({
  callback = () => {},
  onInit = () => {},
  ...emblaOptions
} = {}) {
  const [ref, embla] = useEmblaCarousel({
    ...emblaOptions,
  })

  const [prevEnabled, setPrevEnabled] = useState(true)
  const [nextEnabled, setNextEnabled] = useState(true)
  const [index, setIndex] = useState(0)
  const [init, setInit] = useState(false)

  const scrollPrev = useCallback(() => embla?.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla?.scrollNext(), [embla])
  const scrollTo = useCallback(embla?.scrollTo, [embla])
  const [scrollSnaps, setScrollSnaps] = useState([])

  const onSelect = useCallback(() => {
    if (!embla) return
    setPrevEnabled(embla.canScrollPrev())
    setNextEnabled(embla.canScrollNext())
    setIndex(embla.selectedScrollSnap())
  }, [embla])

  useEffect(() => {
    if (!embla) return
    onSelect()
    embla.on('select', () => {
      callback()
      onSelect()
    })
    setScrollSnaps(embla.scrollSnapList())
    embla.on('init', () => setInit(true))
  }, [embla, onSelect])

  return {
    ref,
    init,
    prevProps: {
      onClick: scrollPrev,
      disabled: !prevEnabled,
    },
    nextProps: {
      onClick: scrollNext,
      disabled: !nextEnabled,
    },
    pagiProps: {
      count: scrollSnaps.length,
      scrollTo,
      index,
    },
  }
}
