import Submenu from './DesktopSubmenu'
import Image from 'components/Image'
import GridRow from 'components/Row'
import GridCol from 'components/Col'
import Link from 'components/Link'
import Badge from 'components/ProductBadge'
import cx from 'classnames'
import * as analytics from 'lib/analytics'
import { useStore } from 'context/globalStore'
import { updateSubscriptionLink } from '../../../../lib/abTestUtils'

export default function DesktopVisualSubmenu(props) {
  const modules = props.modules
  const items = modules.filter((module) => module.enable)
  const { store } = useStore()
  const { countryCode } = store

  const MCL = items.filter((module) => module._type === 'MultiCategoryList').length
  const SCL = items.filter((module) => module._type === 'SingleCategoryList').length

  const PL = items.find((module) => module._type === 'ImageItem')?.items?.length
  return (
    <Submenu {...props} data-test-id={props.title + '-submeny-link'}>
      <div className={'px-44 xxl:px-60'}>
        <GridRow fixed={true} PL={PL} MCL={MCL}>
          {items.map((module, index) => {
            switch (module._type) {
              case 'SingleCategoryList': {
                const isSecondCol = index === 1
                return (
                  <GridCol
                    className="relative negative-margin15"
                    colspan={{
                      l: isSecondCol && (PL > 0 || MCL > 0) ? 5 : 5,
                      xl: isSecondCol ? 3 : 3,
                      xxl: isSecondCol ? 3 : 3,
                    }}
                    key={module._key + index}
                  >
                    {isSecondCol && (
                      <span
                        className={cx('dynebro absolute w-2 top-40 bg-pattens-blue', {
                          'line-placement-single-category-no-promo dynamic-line-no-promo':
                            PL === 0 && MCL === 0,
                          'line-placement-single-category dynamic-line ':
                            PL > 0 || PL === undefined || MCL > 0,
                        })}
                      ></span>
                    )}
                    <ul
                      className={`pt-40 l:pb-40 xl:pb-60 space-y-28 ${
                        isSecondCol ? 'margin-left-singleCategoryList' : ''
                      }`}
                    >
                      {module.items
                        .filter(
                          (item) =>
                            !(countryCode !== 'US' && item.link?.reference?.slug === 'subscriptions')
                        )
                        .map((item) => {
                          const link = updateSubscriptionLink(item.link, props.isGetSubscriptionPage);
                          return (
                            <li key={item._key}>
                              <Link
                                link={link}
                                className="flex items-center"
                                onClick={() => analytics.trackNavClick(link.title)}
                              >
                                <div className="whitespace-nowrap hover:text-bright-blue text-20 xxl:text-22 leading-18 font-medium flex items-end transition group-hover:text-royal-blue">
                                  {link.title}
                                  {item.badge && item.badge.label ? (
                                    <div className="ml-10 items-end dynamic-badge">
                                      <Badge background={item.badge.background} size="small">
                                        {item.badge.label}
                                      </Badge>
                                    </div>
                                  ) : null}
                                </div>
                              </Link>
                            </li>
                        )})}
                    </ul>
                  </GridCol>
                )
                // return <SingleCategoryList index={index} key={module._key} module={module} />
              }
              case 'MultiCategoryList': {
                return (
                  <GridCol
                    className={cx('relative pt-40 l:pb-40 xl:pb-60', {
                      'negative-margin80': index == 2,
                    })}
                    colspan={{ l: index === 1 ? 3 : 2, xl: 2, xxl: 2 }}
                    key={module._key + index}
                  >
                    {index === 1 && (
                      <span className="absolute line-placement w-2 top-40 bg-pattens-blue dynamic-line"></span>
                    )}
                    <GridRow>
                      {module.items.map((item, _index) => (
                        <GridCol colspan={{ l: 6, xl: 6, xxl: 8 }} key={item._key}>
                          <div
                            className={index === 1 && _index === 0 ? 'xxl:ml-37' : ''}
                            onClick={() => analytics.trackNavClick(item.title)}
                          >
                            <div>
                              <span className="text-20 xxl:text-22 leading-18 font-medium">
                                {item.title}
                              </span>
                              <ul className="space-y-20 mt-20">
                                {item.menus.map((menu) => (
                                  <li
                                    key={menu._key}
                                    className="flex hover:text-bright-blue leading-16"
                                  >
                                    <Link
                                      link={menu.link}
                                      onClick={() => analytics.trackNavClick(menu.link.title)}
                                      className="text-16"
                                    >
                                      {menu.link.title}
                                    </Link>
                                    {menu.badge && menu.badge.label ? (
                                      <div className="ml-8">
                                        <Badge background={menu.badge.background} size="small">
                                          {menu.badge.label}
                                        </Badge>
                                      </div>
                                    ) : null}
                                  </li>
                                ))}
                              </ul>
                              {item?.bottomLink && (
                                <Link
                                  className="block mt-44 underline text-16 font-medium hover:text-bright-blue"
                                  link={item.bottomLink}
                                >
                                  {item.bottomLink.title}
                                </Link>
                              )}
                            </div>
                          </div>
                        </GridCol>
                      ))}
                    </GridRow>
                  </GridCol>
                )
                // return <MultiCategoryList index={index} key={module._key} module={module} />
              }
              case 'ImageItem': {
                const hideNextImage = MCL === 1

                return module.items.map((item, index) => (
                  <GridCol
                    key={item._key}
                    className={`pt-40 l:pb-60 xl:pb-60 negative-margin20 ${
                      index === 1 ? 'hidden xl:block' : ''
                    }`}
                    colspan={{ l: MCL === 1 ? 4 : 4, xl: 3, xxl: 3 }}
                  >
                    <div className="hover:text-bright-blue text-navy-blue">
                      <Link
                        link={{ _type: 'internalLink', reference: item.link }}
                        onClick={() => analytics.trackNavClick(item.title)}
                      >
                        <div className="overflow-hidden rounded-4 l:mb-5 xl:mb-16">
                          <Image image={item.image} />
                        </div>
                        <span className="text-14 leading-14 xl:text-18 xl:leading-20 regular">
                          {item.title}
                        </span>
                      </Link>
                    </div>
                  </GridCol>
                ))
                // return <ImageItem style={{MCL: MCL.length, SCL: SCL.length}} index={index} key={module._key} module={module} />
              }
            }
          })}
        </GridRow>
      </div>
    </Submenu>
  )
}
