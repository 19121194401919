import { forwardRef } from 'react'
import Burger from 'components/Navigation/Burger'
import LogoLink from 'components/LogoLink'
import CartToggle from 'components/MobileCartToggle'
import UserIcon from 'public/icons/user.svg'
import Link from 'components/Link'

const MenuHeader = forwardRef( (_, forwardedRef) => (
  <header
    id="mobile-nav"
    ref={forwardedRef}
    className="w-full h-55 shadow-top-bar relative z-1"
    style={_.bgColorStyle}
  >
    <div className="relative w-full h-full flex items-center justify-between px-30">
      <Burger/>
      <LogoLink/>
      <Link link={_.accountLink} className={'absolute right-50'}>
        <UserIcon className={'w-26 h-26'}/>
      </Link>
      <CartToggle/>
    </div>
  </header>
) )

MenuHeader.displayName = 'MenuHeader'

export default MenuHeader
