export default function CartRemoveIconItem() {
  return (
    <svg className="w-8 h-8 l:w-7 l:h-7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.06059 6.71773C0.865325 6.913 0.548741 6.913 0.353478 6.71773C0.158216 6.52247 0.158216 6.20589 0.353478 6.01063L6.01035 0.353794C6.20561 0.158533 6.5222 0.158532 6.71746 0.353794C6.91272 0.549055 6.91272 0.865637 6.71746 1.0609L1.06059 6.71773Z"
        className="fill-current"
      />
      <path
        d="M0.353556 1.06058C0.158293 0.86532 0.158293 0.548739 0.353556 0.353477C0.548819 0.158216 0.865402 0.158216 1.06067 0.353477L6.71754 6.01031C6.9128 6.20558 6.9128 6.52216 6.71754 6.71742C6.52227 6.91268 6.20569 6.91268 6.01043 6.71742L0.353556 1.06058Z"
        className="fill-current"
      />
    </svg>
  )
}