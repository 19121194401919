function CartIcon() {
  return (
    <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 1H5L6 4.5M6 4.5L8 13H21.5L23.5 4.5H6Z"
        stroke="#003057"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="11" cy="18" r="2" fill="white" stroke="#003057" strokeWidth="2" />
      <circle cx="19" cy="18" r="2" fill="white" stroke="#003057" strokeWidth="2" />
    </svg>
  )
}

export default CartIcon
