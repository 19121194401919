import { SubscriptionTypes } from "lib/constants"

export const getCookie = (name) => {
  // Split cookie string and get all individual name=value pairs in an array
  var cookieArr = document.cookie.split(';')

  // Loop through the array elements
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split('=')

    /* Removing whitespace at the beginning of the cookie name
      and compare it with the given string */
    if (name == cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1])
    }
  }
  // Return null if not found
  return null
}

export const getProductDefaultVariant = (product) => {
  const defaultVariantTitle = product.defaultVariant?.variantTitle
    
  return (
    product?.variants?.filter((v) => !!v).find((v) => v?.variantTitle === defaultVariantTitle) ??
    product.variants?.[0]
  )
}

export const isSubscriptionProduct = (product) => {
  return product.subscriptionType === SubscriptionTypes.Kit || product.subscriptionType === SubscriptionTypes.Refills
}

export const getSwatchItemsWithForOption = (product, swatchItemsKey, optionsKey) => {
  return product?.plpSwatch?.[swatchItemsKey]?.map(item => ({
    ...item,
    forOption: `${product?.plpSwatch?.[optionsKey]}:${item.option}`
  })) || [];
}

export const getOptionSettingsData = (product) => {
  const plpSwatchKeys = [
    { component: 'component', forOption: 'options', plpSwatch: 'PLP', showDescription: 'showDescription' },
    { component: 'component2', forOption: 'options2', plpSwatch: 'PLP2', showDescription: 'showDescription2' },
  ];

  return plpSwatchKeys.map((key, index) => ({
    component: product?.plpSwatch?.[key.component] || product?.optionSettings?.[index]?.component,
    forOption: product?.plpSwatch?.[key.forOption] || product?.optionSettings?.[index]?.forOption,
    plpSwatch: key.plpSwatch,
    showDescription: product?.plpSwatch?.[key.showDescription] || product?.optionSettings?.[index]?.showDescription,
  }));
}
