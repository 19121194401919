import { useAutoDiscount, useCartTotals, useStore, useSubscriptionState } from 'context/globalStore'
import { useState, useEffect } from 'react'
import useShopifyProductPlus from 'hooks/useShopifyProductPlus'
import formatPrice, { formatNumberAsPrice, getPriceAsNumber } from 'lib/formatPrice'
import cx from 'classnames'
import { usePromoConfig } from './usePromoConfig'
import { getProductLowestPrice } from 'modules/ProductGrid'
import { getSubDiscountedPrice } from 'lib/getDiscountedPrice'
import { isSubscriptionProduct } from 'components/Product/Product.helper'
import useCartPrices from './useCartPrices'
import { shopify } from 'lib/shopify'


export const findLowestPriceVariant = (variants) => {
  let lowestPrice = Infinity;
  let lowestPriceVariant = null;
  let allUnavailable = true;

  if (variants?.length === 1) {
    return variants[0]
  }

  variants?.forEach(variant => {
    if (+variant.price < lowestPrice && !variant?.disableForOneTime && variant?.available !== 'false') {
      lowestPrice = +variant.price;
      lowestPriceVariant = variant;
      allUnavailable = false;
    } else if (+variant.price === lowestPrice && !variant.disableForSubscription) {
      const hasSubscription = variant.sellingPlans?.some((vp) => vp.type === 'subscription')
      if (hasSubscription) {
        lowestPriceVariant = variant;
      } else {
        lowestPriceVariant = variant;
      }
      allUnavailable = false;
    }
  });

  // If all variants are unavailable, find the variant with the lowest price
  if (allUnavailable) {
    variants?.forEach(variant => {
      if (+variant.price < lowestPrice) {
        lowestPrice = +variant.price;
        lowestPriceVariant = variant;
      }
    });
  }

  return lowestPriceVariant;
}

export const findLowestPriceVariantNotUsStore = (shopifyVariants, product, sanityVariants) => {
  let lowestPrice = Infinity;
  let lowestPriceVariant = null;

  shopifyVariants?.forEach(variant => {
    const disableForOneTime = sanityVariants.find((v) => v.variantId === shopify.decode(variant.id))?.disableForOneTime;
    if (+variant.price.amount < lowestPrice && !disableForOneTime) {
      lowestPrice = +variant.price.amount;
      lowestPriceVariant = sanityVariants.find((v) => v.variantId === shopify.decode(variant.id));
      if (lowestPriceVariant) {
        lowestPriceVariant = sanityVariants.find((v) => v.variantId === shopify.decode(variant.id));
        lowestPriceVariant.price = variant.price.amount;
        lowestPriceVariant.disableForSubscription = true;
      }
    }
  });

  return lowestPriceVariant;
}

const globalPriceNotation = {
  subscription: {
    usca: [
      { _type: 'onetime', crossout: true },
      { _type: 'subscription', crossout: false },
    ],
    others: [{ _type: 'onetime', crossout: false }],
    nocrossout: [{ _type: 'subscription', crossout: false }],
  },
  nonSubscription: [{ _type: 'onetime', crossout: false }],
}

const preparePriceNotation = (
  notations,
  prices,
  priceTemp,
  discountThemeColor = '',
  autoDiscount,
  config,
  isTypeVariant,
  product,
  countryCode,
  oneTimeVariantCompareAtPrice
) => {
  const {
    autoDiscountAmount = 0,
    enableAutoDiscount = false,
    hideStrikeoutPrice = false,
  } = autoDiscount || {}
  const subPrice = Number(prices?.onetime?.match(/[\d.]+/g)?.[0])
  const discountedPrice = getDiscountedPrice(`${subPrice}`, isTypeVariant ? { autoDiscountAmount: config.oneTimeDiscountPct } : autoDiscount)
  const showingOneTimePrice = notations?.[0]?._type === 'onetime';
  if (enableAutoDiscount && autoDiscountAmount > 0 && product.title !== 'Gift Card') {
    return (
      <>
        {!hideStrikeoutPrice && (
          <span className="line-through opacity-50">{showingOneTimePrice || !product?.sellingPlans?.length ? oneTimeVariantCompareAtPrice : prices.subscription}</span>
        )}
        <span
          className="discounted-price font-medium"
          style={{
            color: discountThemeColor && enableAutoDiscount && !hideStrikeoutPrice
              ? discountThemeColor
              : 'inherit',
          }}
        >
          {priceTemp.replace('$value', discountedPrice)}
        </span>
      </>
    )
  }

  return (
    <>
      {oneTimeVariantCompareAtPrice && (
        <span
          className={'line-through opacity-50'}
          style={{
            color: 'inherit'
          }}
        >
          {oneTimeVariantCompareAtPrice}
        </span>
      )}
      <span
        style={{
          color: 'inherit'
        }}
      >
        {prices.subscription && countryCode === 'US' ? prices.subscription : prices.onetime}
      </span>
    </>
  )
}

function getDiscountedPrice(oneTimePrice, autoDiscount) {
  const otp = Number(oneTimePrice?.match(/[\d.]+/g)?.[0])
  const { autoDiscountAmount = 0 } = autoDiscount || {}
  const discountedPrice = formatNumberAsPrice(
    (otp * (1 - autoDiscountAmount / 100)).toFixed(2).replace(/\.00$/, '')
  )
  return discountedPrice
}

export default function usePriceNotation(
  product,
  priceNotation,
  showUpwardsPrice = true,
  variant = {}
) {
  let FP, BP
  const {
    store: { countryCode, isSubscriptionAllowed },
  } = useStore()
  const config = usePromoConfig({ product, location: 'PDP' })
  const { isPromoActivated } = useCartPrices()
  const typeVariant = product?.type === 'productVariant'
  const oneTimeTypeVariant = typeVariant && product.disableForSubscription;

  const [formattedPrice, setFormattedPrice] = useState('')
  const [buttonPrice, setButtonPrice] = useState('')
  const { products, variants } = useShopifyProductPlus({
    ...product,
    slug: product?.slug?.current || product?.slug,
  })
  const discountThemeColor = config?.promoTheme?.primaryColor?.hex
  const lowestPriceVariant = countryCode === 'US' ? findLowestPriceVariant(product.variants || variants) : findLowestPriceVariantNotUsStore(variants, product, product.variants)
  const enableAutoDiscount =
    config?.enableAutoDiscount && !config?.hideStrikeoutPrice && isPromoActivated
  const autoDiscount = {
    autoDiscountAmount: (isSubscriptionProduct(product) && !lowestPriceVariant?.disableForSubscription)
      ? config?.subscriptionDiscountPct
      : config?.oneTimeDiscountPct || 0,
    enableAutoDiscount,
    hideStrikeoutPrice: config?.hideStrikeoutPrice,
  }

  useEffect(() => {
    if (!countryCode) return

    const PN = priceNotation?.notations?.length
      ? priceNotation.notations
      : product?.priceNotation?.notations?.length
        ? product.priceNotation.notations
        : []

    const notation = PN.filter((n) => n.enable).reduce((byCountry, notation) => {
      const cc = notation.countries.map((c) => c.countryCode)
      cc.forEach((c) => (byCountry[c] = notation))
      return byCountry
    }, {})

    const [oneTimeVariant, ...subscriptionVariants] = variants

    const productLowestPrice = getProductLowestPrice({
      variants: [...(product?.variants || []), ...variants],
    })
    const hasDiff = lowestPriceVariant?.price !== productLowestPrice

    const lowestPrice = hasDiff ? lowestPriceVariant?.price : productLowestPrice

    const { autoDiscountAmount = 0, enableAutoDiscount = false } = autoDiscount || {}

    const oneTimeVariantCompareAtPrice = isSubscriptionAllowed && product.title !== 'Gift Card' ? oneTimeVariant?.compareAtPrice?.amount : null
    const oneTimeCompareAtPriceWithoutSubscription = isPromoActivated && product.title !== 'Gift Card' ? oneTimeVariant?.price?.amount : null

    const currentPriceNotation =
      Object.keys(notation || {}).length && countryCode
        ? notation[countryCode] || notation['OT']
        : null
    if (variants?.length && oneTimeVariantCompareAtPrice) {
      const op = oneTimeVariant.price
      const arePricesDifferent = variants.some((item, index, arr) => {
        if (index === 0) return false
        return parseFloat(item.price.amount) !== parseFloat(arr[0].price.amount)
      })
      const priceTemp =
        (variants.find((v) => v.price !== op) && showUpwardsPrice && arePricesDifferent) ||
          (showUpwardsPrice && product?.sellingPlans?.length && product?.subscriptionType)
          ? '$value+'
          : '$value'
      const otp =
        variants.length > 1
          ? formatPrice({ price: products[0].priceRange.minVariantPrice })
          : formatNumberAsPrice(lowestPrice, products[0]?.priceRange?.minVariantPrice?.currencyCode)

      const subscribePrice = Math.ceil(getSubDiscountedPrice(+lowestPrice, product))

      // eslint-disable-next-line react-hooks/exhaustive-deps
      BP =
        // eslint-disable-next-line react-hooks/exhaustive-deps
        enableAutoDiscount && autoDiscountAmount > 0
          ? getDiscountedPrice(lowestPrice, autoDiscount)
          : currentPriceNotation?.atcButtonPrice === 'onetime'
            ? otp
            : subscribePrice

      // eslint-disable-next-line react-hooks/exhaustive-deps
      FP = preparePriceNotation(
        currentPriceNotation?.createNotation,
        {
          onetime: priceTemp.replace('$value', formatNumberAsPrice(lowestPrice, products[0]?.priceRange?.minVariantPrice?.currencyCode)),
          subscription: priceTemp.replace('$value', formatNumberAsPrice(subscribePrice, products[0]?.priceRange?.minVariantPrice?.currencyCode)),
        },
        priceTemp,
        discountThemeColor,
        autoDiscount,
        config,
        oneTimeTypeVariant,
        product,
        countryCode,
        formatNumberAsPrice(oneTimeVariantCompareAtPrice, products[0]?.priceRange?.minVariantPrice?.currencyCode)
      )
    } else {
      if (variants.length > 1) {
        const op = variant?.price ?? oneTimeVariant.price
        const arePricesDifferent = variants.some((item, index, arr) => {
          if (index === 0) return false
          return parseFloat(item.price.amount) !== parseFloat(arr[0].price.amount)
        })
        const priceTemp =
          (variants.find((v) => v.price !== op) && showUpwardsPrice && arePricesDifferent) ||
            (showUpwardsPrice && product?.sellingPlans?.length && product?.subscriptionType)
            ? '$value+'
            : '$value'
        // const variantPrice = getProductLowestPrice(product)
        const lowestVariantProduct = product.title === lowestPriceVariant?.title
        BP =
          // enableAutoDiscount && autoDiscountAmount > 0
          //   ? getDiscountedPrice(variantPrice, autoDiscount)
          formatPrice({
            price: typeVariant ? product.price : products[0]?.priceRange?.minVariantPrice,
          })
        FP = preparePriceNotation(
          (typeVariant) ? product.disableForSubscription ? globalPriceNotation.nonSubscription : globalPriceNotation.subscription.nocrossout
            : lowestVariantProduct && lowestPriceVariant?.disableForSubscription
              ? globalPriceNotation.nonSubscription
              : isSubscriptionProduct(product)
                ? globalPriceNotation.subscription.nocrossout
                : globalPriceNotation.nonSubscription,
          {
            onetime: priceTemp.replace(
              '$value',
              formatNumberAsPrice(typeVariant ? +product.price : +lowestPrice, products[0]?.priceRange?.minVariantPrice?.currencyCode)
            ),
            subscription: priceTemp.replace(
              '$value',
              formatNumberAsPrice(
                Math.ceil(
                  getSubDiscountedPrice(typeVariant ? +product.price : +lowestPrice, product)
                ),
                products[0]?.priceRange?.minVariantPrice?.currencyCode
              )
            ),
          },
          priceTemp,
          discountThemeColor,
          autoDiscount,
          config,
          oneTimeTypeVariant,
          product,
          countryCode,
          formatNumberAsPrice(oneTimeCompareAtPriceWithoutSubscription, products[0]?.priceRange?.minVariantPrice?.currencyCode)
        )
        setFormattedPrice(FP)
      } else {
        BP =
          enableAutoDiscount && autoDiscountAmount > 0
            ? getDiscountedPrice(lowestPrice, autoDiscount)
            : formatNumberAsPrice(lowestPrice, products[0]?.priceRange?.minVariantPrice?.currencyCode)
        FP = preparePriceNotation(
          isSubscriptionProduct(product)
            ? globalPriceNotation.subscription.nocrossout
            : globalPriceNotation.nonSubscription,
          {
            onetime: formatNumberAsPrice(lowestPrice, products[0]?.priceRange?.minVariantPrice?.currencyCode),
            subscription: formatNumberAsPrice(
              Math.ceil(getSubDiscountedPrice(+lowestPrice, product)),
              products[0]?.priceRange?.minVariantPrice?.currencyCode
            ),
          },
          '$value',
          discountThemeColor,
          autoDiscount,
          config,
          oneTimeTypeVariant,
          product,
          countryCode,
          formatNumberAsPrice(oneTimeCompareAtPriceWithoutSubscription, products[0]?.priceRange?.minVariantPrice?.currencyCode)
        )
      }
    }
    setButtonPrice(BP)
    setFormattedPrice(FP)
  }, [countryCode, products, variants.length, enableAutoDiscount])
  return { formattedPrice, buttonPrice }
}
