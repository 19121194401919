import { createContext, useContext, useMemo } from 'react'
import { useEdgeConfigContext } from './ConfigContext'
import { useRouter } from 'next/router'

interface PromoInterface {
  enablePromoForCustomerFromActivePage: any
  promoCode: string
  autoDiscountText: string
  thresholdAmount: number
  enablePromoOnPageLevel: boolean
  pagesToApplyPromo: Array<{
    slug: {
      current: string
    }
  }>
  includedProducts: Array<{
    slug: {
      current: string
    }
  }>
  pdpPromoBox: {
    promoBoxBackgroundColor: {
      hex: string
      alpha: number
    }
    promoBoxForegroundColor: {
      hex: string
      alpha: number
    }
    promoBoxTitle: Array<{
      _type: string
      style: string
      _key: string
      markDefs: Array<any>
      children: Array<{
        _type: string
        marks: Array<string>
        text: string
        _key: string
      }>
    }>
    promoBoxBody: Array<{
      children: Array<{
        _type: string
        marks: Array<any>
        text: string
        _key: string
      }>
      level: number
      _type: string
      style: string
      _key: string
      listItem: string
      markDefs: Array<any>
    }>
  }
  promoType: string
  hideStrikeoutPrice: boolean
  excludedCollections: Array<{
    slug: {
      current: string
    }
  }>
  excludedProducts: Array<{
    slug: {
      current: string
    }
  }>
  PLPTiles: Array<{
    _type: string
    collection: Array<{
      title: string
      slug: {
        current: string
      }
    }>
    subCollection: Array<{
      title: string
      slug: {
        current: string
      }
    }>
    plpCalloutFooter: Array<{
      children: Array<{
        _type: string
        marks: Array<any>
        text: string
        _key: string
      }>
      _type: string
      style: string
      _key: string
      markDefs: Array<any>
    }>
    linkColor: any
    backgroundColor: {
      hex: string
    }
    layout: string
    plpCalloutBody: Array<{
      children: Array<{
        _type: string
        marks: Array<any>
        text: string
        _key: string
      }>
      _type: string
      style: string
      _key: string
      markDefs: Array<any>
    }>
    startIndex: number
    tabletStartIndex: number
    headline: Array<{
      style: string
      _key: string
      markDefs: Array<any>
      children: Array<{
        _key: string
        _type: string
        marks: Array<any>
        text: string
      }>
      _type: string
    }>
    link: any
    subText: any
    showOnDesktop: boolean
    showOnMobile: boolean
  }>
  PLPBanners: Array<{
    thickBannerImage?: {
      _type: string
      asset: {
        uploadId: string
        metadata: {
          blurHash: string
          _type: string
          palette: {
            _type: string
            darkMuted: {
              foreground: string
              title: string
              population: number
              background: string
              _type: string
            }
            muted: {
              title: string
              population: number
              background: string
              _type: string
              foreground: string
            }
            lightVibrant: {
              population: number
              background: string
              _type: string
              foreground: string
              title: string
            }
            darkVibrant: {
              background: string
              _type: string
              foreground: string
              title: string
              population: number
            }
            lightMuted: {
              foreground: string
              title: string
              population: number
              background: string
              _type: string
            }
            vibrant: {
              _type: string
              foreground: string
              title: string
              population: number
              background: string
            }
            dominant: {
              _type: string
              foreground: string
              title: string
              population: number
              background: string
            }
          }
          hasAlpha: boolean
          lqip: string
          dimensions: {
            _type: string
            width: number
            aspectRatio: number
            height: number
          }
          isOpaque: boolean
        }
        _rev: string
        assetId: string
        path: string
        _id: string
        originalFilename: string
        _type: string
        mimeType: string
        sha1hash: string
        url: string
        extension: string
        size: number
        _createdAt: string
        _updatedAt: string
      }
    }
    thickBannerLink?: {
      rawLinkOverride: any
      title: string
      reference: {
        _type: string
        slug: string
      }
      _type: string
      _key: string
    }
    showThinBanner?: boolean
    thinBannerHeadlineColor?: {
      hex: string
    }
    thinBannerImage?: {
      _type: string
      asset: {
        _rev: string
        _type: string
        url: string
        size: number
        assetId: string
        extension: string
        _createdAt: string
        _updatedAt: string
        originalFilename: string
        uploadId: string
        mimeType: string
        sha1hash: string
        metadata: {
          dimensions: {
            aspectRatio: number
            height: number
            _type: string
            width: number
          }
          isOpaque: boolean
          blurHash: string
          _type: string
          palette: {
            muted: {
              title: string
              population: number
              background: string
              _type: string
              foreground: string
            }
            lightVibrant: {
              background: string
              _type: string
              foreground: string
              title: string
              population: number
            }
            darkVibrant: {
              _type: string
              foreground: string
              title: string
              population: number
              background: string
            }
            lightMuted: {
              _type: string
              foreground: string
              title: string
              population: number
              background: string
            }
            vibrant: {
              background: string
              _type: string
              foreground: string
              title: string
              population: number
            }
            dominant: {
              _type: string
              foreground: string
              title: string
              population: number
              background: string
            }
            _type: string
            darkMuted: {
              foreground: string
              title: string
              population: number
              background: string
              _type: string
            }
          }
          hasAlpha: boolean
          lqip: string
        }
        path: string
        _id: string
      }
    }
    collection: {
      title: string
      slug: {
        current: string
      }
    }
    showThickBanner?: boolean
    thickBannerBackgroundColor?: {
      hex: string
    }
    thickBannerText?: string
    thickBannerHeadline?: Array<{
      _key: string
      markDefs: Array<any>
      children: Array<{
        _type: string
        marks: Array<any>
        text: string
        _key: string
      }>
      _type: string
      style: string
    }>
    thinBannerHeadline?: string
    _type: string
  }>
  enableAutoDiscount: boolean
  oneTimeDiscountPct: number
  subscriptionDiscountPct: number
  subscribeAndSaveDiscountText: Array<{
    style: string
    _key: string
    markDefs: Array<any>
    children: Array<{
      _type: string
      marks: Array<any>
      text: string
      _key: string
    }>
    _type: string
  }>
  promoTheme: {
    accentColor: {
      hex: string
      alpha: number
    }
    primaryColor: {
      hex: string
      alpha: number
    },
    cartBannerColor: {
      hex: string
      alpha: number
    }
  }
  includedCollections: Array<{
    slug: {
      current: string
    }
  }>
  shippingMeterSuccesMessage: string
  cartBanner: Array<{
    _type: string
    style: string
    _key: string
    markDefs: Array<any>
    children: Array<{
      _type: string
      marks: Array<string>
      text: string
      _key: string
    }>
  }>
  cartSubscriptionToggle: Array<{
    _type: string
    style: string
    _key: string
    markDefs: Array<any>
    children: Array<{
      _type: string
      marks: Array<string>
      text: string
      _key: string
    }>
  }>
}

export type PromoDiscountConfig = {
  enableAutoDiscount: boolean
  activePromo: PromoInterface
  activePromosArray: PromoInterface[]
}
interface PromoDiscountContext {
  config: PromoDiscountConfig
  //   applyPromoDiscount: (code: string) => void
}

type ContextType = PromoInterface & {
  enableAutoDiscount: boolean
  activePromosArray: PromoInterface[] | null;
}

const PromoDiscountContext = createContext<PromoDiscountConfig | null>(null)

export const PromoConfigProvider: React.FC<PromoDiscountContext> = ({ children, config }) => {
  return <PromoDiscountContext.Provider value={config}>{children}</PromoDiscountContext.Provider>
}

type PromoConfigResolverType = {
  product?: {
    slug: string
    collections?: {
      slug: string
    }[]
  }
  collection?: {
    slug: string
  }
  location?: 'PDP' | 'PLP' | 'Cart'
}

function createPromoConfig(configFromContext, edgeConfig, product, collection, location) {
  const config =
    configFromContext && edgeConfig?.promoTheme
      ? {
        ...configFromContext,
        activePromo: {
          ...(configFromContext?.activePromo ?? {}),
          promoTheme: {
            ...configFromContext?.activePromo?.promoTheme,
            primaryColor: {
              ...configFromContext?.activePromo?.promoTheme?.primaryColor,
              hex: edgeConfig?.promoTheme,
            },
          },
        },
        activePromosArray: {
          ...(configFromContext?.activePromosArray ?? []),
          promoTheme: {
            ...configFromContext?.activePromo?.promoTheme,
            primaryColor: {
              ...configFromContext?.activePromo?.promoTheme?.primaryColor,
              hex: edgeConfig?.promoTheme,
            },
          },
        },
      }
      : configFromContext;

  if ((!product && (location === 'PDP' || location === 'Cart')) || (!collection && location === 'PLP')) {
    return null;
  }

  if ((location === 'PDP' || location === 'Cart') && product) {
    const productSlug = product.slug;
    const includedProducts = config?.activePromo?.includedProducts || [];
    const excludedProducts = config?.activePromo?.excludedProducts || [];
    const includedCollections = config?.activePromo?.includedCollections || [];
    const excludedCollections = config?.activePromo?.excludedCollections || [];
    const productIncluded = includedProducts.some((p) => p.slug.current === productSlug);
    const productExcluded = excludedProducts.some((p) => p.slug.current === productSlug);
    const collectionIncluded = includedCollections.some((c) => product.collections?.some((pc) => pc?.slug === c?.slug?.current));
    const collectionExcluded = excludedCollections.some((c) => product.collections?.some((pc) => pc?.slug === c?.slug?.current));

    if ((!productExcluded && !collectionExcluded && collectionIncluded) || productIncluded) {
      return {
        ...config.activePromo,
        activePromosArray: config.activePromosArray,
        enableAutoDiscount: config?.enableAutoDiscount,
        ...config?.activePromo?.PLPBanners,
        ...config?.activePromo?.PLPTiles,
      };
    }
    return null;
  }

  if (location === 'PLP' && collection) {
    const collectionSlug = collection.slug;
    const includedCollections = config?.activePromo?.includedCollections || [];
    const excludedCollections = config?.activePromo?.excludedCollections || [];
    const collectionIncluded = includedCollections.some((c) => c.slug.current === collectionSlug);
    const collectionExcluded = excludedCollections.some((c) => c.slug.current === collectionSlug);

    if (!collectionExcluded && collectionIncluded) {
      return {
        ...config.activePromo,
        activePromosArray: config.activePromosArray,
        enableAutoDiscount: config?.enableAutoDiscount,
        ...config?.activePromo?.PLPBanners,
        ...config?.activePromo?.PLPTiles,
      };
    }
    return null;
  }

  return { ...config?.activePromo, activePromosArray: config?.activePromosArray, enableAutoDiscount: config?.enableAutoDiscount };
}

export const usePromoConfig = ({
  product,
  collection,
  location,
}: PromoConfigResolverType = {}): ContextType | null => {
  const edgeConfig = useEdgeConfigContext();
  const configFromContext = useContext(PromoDiscountContext);
  const router = useRouter();

  const promoFromLocalStorage = useMemo(() => {
    if (typeof window !== 'undefined' && localStorage) {
      return JSON.parse(localStorage.getItem('cartPromo') || '{}');
    }
    return {};
  }, [router.asPath]);

  return useMemo(() => {
    if (!configFromContext?.activePromosArray?.length) {
      return createPromoConfig(configFromContext, edgeConfig, product, collection, location);
    }

    for (const promo of configFromContext.activePromosArray) {
      if (!promo.enablePromoOnPageLevel) continue;

      const pageToApplyPromo = promo.pagesToApplyPromo.some(page =>
        (page.slug.current === 'home' && router.asPath === "/") || router.asPath.includes(page.slug.current)
      );

      if (pageToApplyPromo) {
        return createPromoConfig({
          activePromo: promo,
          activePromosArray: configFromContext.activePromosArray,
          enableAutoDiscount: configFromContext.enableAutoDiscount
        }, edgeConfig, product, collection, location);
      }

      if (!pageToApplyPromo && promo.enablePromoForCustomerFromActivePage) {
        return createPromoConfig({
          activePromo: promoFromLocalStorage,
          activePromosArray: configFromContext.activePromosArray,
          enableAutoDiscount: configFromContext.enableAutoDiscount
        }, edgeConfig, product, collection, location);
      }
    }

    return null;
  }, [configFromContext, edgeConfig, product, collection, location, router.asPath, promoFromLocalStorage]);
};
