/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { shopify, fetchCustomerSegmentMembership } from 'lib/shopify'
import { createRechargeCheckout } from 'lib/recharge'
import loadFonts from 'lib/loadFonts'
import pluralize from 'lib/pluralize'
import getLineItemDescription from 'lib/getLineItemDescription'
import router, { useRouter } from 'next/router'
import sortBy from 'lodash.sortby'
import reverse from 'lib/reverse'
import * as analytics from 'lib/analytics'
import formatPrice from 'lib/formatPrice'

import { subscriptionCountries } from 'lib/locale'
import {
  SHOPIFY_CHECKOUT_STORAGE_KEY,
  AUTO_DISCOUNT_STORAGE_KEY,
  BLU_UTMPARAMS_STORAGE_KEY,
  SELECTED_COUNTRY_CODE,
} from 'lib/constants'
import lineItems from 'lib/lineItems'
import { getSubDiscountedPrice } from 'lib/getDiscountedPrice'

const initialState = {
  isInit: false,
  isAdding: false,
  isUpdating: false,
  isCartOpen: false,
  isMobileMenuOpen: false,
  isFreeShippingUnlocked: false,
  isStickyProductFormActive: false,
  isMobileStickyProductFormActive: false,
  isNavigatingToCheckout: false,
  isFontsLoaded: false,
  countryCode: null,
  autoDiscount: null,
  isSubscriptionAllowed: false,
  checkout: {
    lineItems: [],
  },
  discountFromURL: null,
  allProducts: [],
  tieredPromotions: [],
  totalReviewCount: 0,
  isStickyPromo: false,
  hasLargerPromoBar: false,
  convertId: null,
  lastVisitedPDP: { id: null, slug: null },
}

const StoreContext = createContext({
  store: initialState,
  setStore: () => null,
})

function StoreContextProvider({ children, autoDiscount, convertId }) {
  const router = useRouter()
  const [store, setStore] = useState(initialState)

  const hasPageDiscountUpdated = (autoDiscount, autoDiscountSavedConfig) => {
    return [
      'promoCode',
      'thresholdAmount',
      'giftProduct',
      'enableAutoDiscount',
      'promoBannerText',
      'hasPromoPopup',
      'popupContent',
      'popupToggles',
    ].some((key) => {
      return key === 'giftProduct'
        ? autoDiscount[key]?.productId !== autoDiscountSavedConfig[key]?.productId
        : autoDiscount[key] !== autoDiscountSavedConfig[key]
    })
  }

  useEffect(async () => {
    if (!store.isInit) return

    const autoDiscountSavedConfig = JSON.parse(localStorage.getItem(AUTO_DISCOUNT_STORAGE_KEY))
    const { checkout, countryCode } = store
    if (convertId) {
      setStore((prevState) => ({
        ...prevState,
        convertId: convertId,
      }))
    }

    let products = await fetch("/api/get-all-products").catch((e) => console.error('/api/get-all-products', e))
    if (products) {
      products = await products.json()

      setStore((prevState) => ({
        ...prevState,
        allProducts: products
      }))
    }

    if (autoDiscountSavedConfig) {
      const {
        promoCode: _promoCode,
        discountApplied: _discountApplied,
        giftProduct: _giftProduct,
      } = autoDiscountSavedConfig
      const isDisountUpdated = hasPageDiscountUpdated(autoDiscount || {}, autoDiscountSavedConfig)

      if (!isDisountUpdated) {
        return setStore((prevState) => ({
          ...prevState,
          autoDiscount: autoDiscount || {},
        }))
      } else if (_promoCode && (_discountApplied || _giftProduct)) {
        // remove applied discount
        if (_discountApplied) {
          const newCheckout = await shopify.removeDiscount(checkout.id, countryCode)
          if (newCheckout) {
            setStore((prevState) => ({
              ...prevState,
              checkout: newCheckout,
            }))
          }
        }

        // remove if gift product is in cart
        const sku = _giftProduct && _giftProduct.variants[0].sku
        const addedGiftItem = checkout.lineItems.find((item) => item.variant.sku === sku)
        if (addedGiftItem) {
          const newCheckout = await shopify.removeLineItems(
            checkout.id,
            addedGiftItem.id,
            countryCode
          )

          setStore((prevState) => ({
            ...prevState,
            checkout: newCheckout,
          }))
        }
      }
    }

    const {
      promoCode,
      enableAutoDiscount,
      promoBannerText,
      thresholdAmount = 0,
      giftProduct,
      hasPromoPopup,
      popupContent,
      popupToggles,
      PLPAutoDiscountConfig = {},
    } = autoDiscount || {}
    // check validaty of auto discount config
    if (!promoCode || !enableAutoDiscount) return

    const newCheckout =
      thresholdAmount === 0 ? await shopify.addDiscount(checkout.id, promoCode, countryCode) : null
    const discountApplied = newCheckout ? newCheckout.discountApplications.length > 0 : false

    const _autoDiscount = {
      promoBannerText,
      enableAutoDiscount,
      PLPAutoDiscountConfig,
      discountApplied,
      promoCode,
      thresholdAmount,
      giftProduct,
      productOptOut: false,
      hasPromoPopup,
      popupContent,
      popupToggles,
    }
    localStorage.setItem(AUTO_DISCOUNT_STORAGE_KEY, JSON.stringify(_autoDiscount))

    const updateStore = { autoDiscount: _autoDiscount }
    if (newCheckout) updateStore.checkout = newCheckout

    setStore((prevState) => ({
      ...prevState,
      ...updateStore,
    }))
  }, [store.isInit])

  useEffect(() => {
    if (!store.isInit) return

    if (!store.isCartOpen && router?.query?.cart) {
      window?.Solvvy?.hide()
      setStore((prevState) => ({
        ...prevState,
        isCartOpen: true,
      }))
    }
  }, [router.query])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const query = router?.query || {}

      let bluUtmParams = JSON.parse(localStorage.getItem(BLU_UTMPARAMS_STORAGE_KEY)) || {}
      const utmParams = Object.keys(query).filter((utm) =>
        /^[utm_|fbclid|gclid|irclickid]/.test(utm)
      )
      const oldParams = Object.keys(bluUtmParams)

      // check to reset utm parameters if value changes
      const resetParam =
        oldParams.length &&
        oldParams.find((param) => query[param] && bluUtmParams[param] !== query[param])
      if (resetParam) bluUtmParams = {}

      utmParams.forEach((utm) => {
        bluUtmParams[utm] = query[utm]
      })
      localStorage.setItem(BLU_UTMPARAMS_STORAGE_KEY, JSON.stringify(bluUtmParams))
    }

    if (!store.isInit) {
      if (typeof window !== 'undefined') {
        const history = (window.appHistory = [router.asPath])
        history.resolve = () => { }
        history.onResolve = () => {
          return new Promise((resolve) => {
            history.resolve = resolve
          })
        }
      }
      return
    }

    if (typeof window !== 'undefined' && window.appHistory) {
      const history = window.appHistory
      if (history.length == 2) history.shift()
      history.push(router.asPath)
      history.resolve()
    }
  }, [router])

  useEffect(async () => {
    if (store.isInit) return

    const countryCode = await detectCountryCode()
    const isSubscriptionAllowed = subscriptionCountries.includes(countryCode)

    setStore((prevState) => ({
      ...prevState,
      countryCode,
      isSubscriptionAllowed,
    }))

    await Promise.all([initCheckout(countryCode, setStore), initPolyfills(), initFonts(setStore)]).catch((e) => console.log(e));

    setStore((prevState) => ({
      ...prevState,
      isInit: true,
    }))

    const handleChange = () => {
      window?.Solvvy?.show()
      setStore((prevState) => ({
        ...prevState,
        isCartOpen: false,
        isMobileMenuOpen: false,
      }))
    }

    router.events.on('routeChangeStart', handleChange)
    router.events.on('hashChangeStart', handleChange)
  }, [])

  return <StoreContext.Provider value={{ store, setStore }}>{children}</StoreContext.Provider>
}

async function detectCountryCode() {
  let selectedCountryCode = sessionStorage.getItem(SELECTED_COUNTRY_CODE)
  if (!!selectedCountryCode) {
    return selectedCountryCode
  }

  const response = await fetch('/api/location')
    .then((res) => res.json())
    .catch((err) => console.log('/api/location', err))
  const code = response?.countryCode ?? 'US'
  sessionStorage.setItem(SELECTED_COUNTRY_CODE, code)

  return code
}

async function initCheckout(countryCode, setStore) {
  const isBrowser = typeof window !== 'undefined'
  const existingCheckoutId = isBrowser ? localStorage.getItem(SHOPIFY_CHECKOUT_STORAGE_KEY) : null

  if (existingCheckoutId) {
    try {
      const checkout = await shopify.fetchCheckout(existingCheckoutId, countryCode)

      if (checkout.buyerIdentity.countryCode !== countryCode) {
        throw new Error('Customer country changed')
      }

      // Make sure none of the items in this cart have been deleted from Shopify
      if (checkout.lineItems.some((lineItem) => !lineItem.variant)) {
        throw new Error(
          'Invalid line item in checkout. This variant was probably deleted from Shopify.'
        )
      }

      // Make sure this cart was not already purchased
      if (
        checkout.completedAt ||
        checkout.customAttributes.find((attr) => attr.key === '__completedRechargeOrder')
      ) {
        throw new Error('Checkout was already completed.')
      }

      setCheckoutInState(checkout, setStore)
      return
    } catch (error) {
      console.error(error)
      localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, null)
    }
  }
  const cart = await shopify.createCartV2({});
  localStorage.setItem('customerCartId', cart.id);
  const newCheckout = await shopify.createCheckout(countryCode)
  setCheckoutInState(newCheckout, setStore)
}

function initPolyfills() {
  return import('wicg-inert')
}

function initFonts(setStore) {
  return loadFonts([
    {
      family: 'Sailec',
      options: {
        weight: 400,
      },
    },
    {
      family: 'Sailec',
      options: {
        weight: 500,
      },
    },
    {
      family: 'Hermann Semibold',
    },
    {
      family: 'Hermann Semibold',
      options: {
        style: 'italic',
      },
    },
    {
      family: 'Self Modern',
    },
    {
      family: 'Self Modern',
      options: {
        style: 'italic',
      },
    },
    {
      family: 'Self Modern',
      options: {
        weight: 100,
      },
    },
    {
      family: 'Self Modern',
      options: {
        weight: 500,
      },
    },
    {
      family: 'Self Modern',
      options: {
        weight: 700,
      },
    },
  ]).then(() => {
    setStore((prevState) => ({
      ...prevState,
      isFontsLoaded: true,
    }))
  })
}

function setCheckoutInState(checkout, setStore) {
  const isBrowser = typeof window !== 'undefined'
  if (isBrowser) {
    window.localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, checkout.id)
  }

  setStore((prevState) => ({
    ...prevState,
    checkout,
  }))
}

function useStore() {
  const { store, setStore } = useContext(StoreContext)
  return { store, setStore }
}

function useAutoDiscount() {
  const {
    store: { autoDiscount: options, checkout },
    setStore,
  } = useStore()

  function setAutoDiscount(value) {
    const autoDiscount = {
      ...options,
      ...value,
    }
    setStore((prevState) => {
      return {
        ...prevState,
        autoDiscount,
      }
    })

    try {
      localStorage.setItem(AUTO_DISCOUNT_STORAGE_KEY, JSON.stringify(autoDiscount))
    } catch (e) {
      console.error("Error on set discount storage:" + e)
    }
  }

  function isAutoDiscountGiftAdded() {
    const sku = options.giftProduct && options.giftProduct.variants[0].sku
    return checkout.lineItems.some((item) => item.variant.sku === sku)
  }

  function getDiscountedGiftItemFromCart() {
    const sku = options.giftProduct && options.giftProduct.variants[0].sku
    return checkout.lineItems.find((item) => item.variant.sku === sku)
  }

  return {
    autoDiscount: options,
    setAutoDiscount,
    isAutoDiscountGiftAdded,
    getDiscountedGiftItemFromCart,
  }
}

function useDiscountFromURL() {
  const {
    store: { discountFromURL },
    setStore,
  } = useStore()
  function setDiscountFromURL(value) {
    setStore((prevState) => {
      return { ...prevState, discountFromURL: value }
    })
  }
  return {
    discountFromURL,
    setDiscountFromURL,
  }
}

function useMobileMenu() {
  const {
    store: { isMobileMenuOpen },
    setStore,
  } = useStore()

  function toggleMobileMenu() {
    if (isMobileMenuOpen) {
      window?.Solvvy?.show()
    } else {
      window?.Solvvy?.hide()
    }
    setStore((prevState) => {
      return { ...prevState, isMobileMenuOpen: !isMobileMenuOpen }
    })
  }

  return {
    isMobileMenuOpen,
    toggleMobileMenu,
  }
}

function useTieredPromotions() {
  const {
    store: { tieredPromotions },
    setStore,
  } = useStore()

  function setTieredPromotions(promo) {
    setStore((prevState) => {
      return { ...prevState, tieredPromotions: promo }
    })
  }
  return {
    tieredPromotions,
    setTieredPromotions,
  }
}

function useAllProducts() {
  const {
    store: { allProducts }
  } = useStore()
  return {
    allProducts
  }
}

function useTotalReviews() {
  const {
    store: { totalReviewCount },
    setStore,
  } = useStore()

  function setTotalReviewCount(count) {
    setStore((prevState) => {
      return { ...prevState, totalReviewCount: count }
    })
  }
  return {
    totalReviewCount,
    setTotalReviewCount,
  }
}

function useStickyProductForm() {
  const {
    store: { isStickyProductFormActive },
    setStore,
  } = useStore()

  function setIsStickyProductFormActive(value) {
    setStore((prevState) => {
      return { ...prevState, isStickyProductFormActive: value }
    })
  }

  return {
    isStickyProductFormActive,
    setIsStickyProductFormActive,
  }
}

function useIsStickyPromo() {
  const {
    store: { isStickyPromo, hasLargerPromoBar },
    setStore,
  } = useStore()
  function setIsStickyPromo(flag) {
    setStore((prevState) => {
      return { ...prevState, isStickyPromo: flag }
    })
  }
  function sethasLargerPromoBar(flag) {
    setStore((prevState) => {
      return { ...prevState, hasLargerPromoBar: flag }
    })
  }
  return {
    isStickyPromo,
    hasLargerPromoBar,
    setIsStickyPromo,
    sethasLargerPromoBar,
  }
}

function useSubscriptionState() {
  const {
    store: { isSubscriptionAllowed },
    setStore,
  } = useStore()

  function setSubscriptionState(value) {
    setStore((prevState) => {
      return { ...prevState, isSubscriptionAllowed: value }
    })
  }

  return { isSubscriptionAllowed, setSubscriptionState }
}

function useCountryCode() {
  const router = useRouter()
  const {
    store: { countryCode },
  } = useStore()

  function setCountryCode(value) {
    sessionStorage.setItem(SELECTED_COUNTRY_CODE, value)
    router.reload()
  }

  return { countryCode, setCountryCode }
}

function useIsCartOpen() {
  const {
    store: { isCartOpen },
    setStore,
  } = useStore()

  function setIsCartOpen(value) {
    if (value) {
      window?.Solvvy?.hide()
    } else {
      window?.Solvvy?.show()
    }
    setStore((prevState) => {
      return { ...prevState, isCartOpen: value }
    })
  }

  return { isCartOpen, setIsCartOpen }
}

function useIsAdding() {
  const {
    store: { isAdding },
    setStore,
  } = useStore()

  function setIsAdding(value = !isAdding) {
    setStore((prevState) => {
      return { ...prevState, isAdding: value }
    })
  }

  return [isAdding, setIsAdding]
}

function useIsUpdating() {
  const {
    store: { isUpdating },
    setStore,
  } = useStore()

  function setIsUpdating(value = !isUpdating) {
    setStore((prevState) => {
      return { ...prevState, isUpdating: value }
    })
  }

  return { isUpdating, setIsUpdating }
}

function useIsFreeShippingUnlocked() {
  const {
    store: { isFreeShippingUnlocked },
    setStore,
  } = useStore()

  function setIsFreeShippingUnlocked(value = !isFreeShippingUnlocked) {
    setStore((prevState) => {
      return { ...prevState, isFreeShippingUnlocked: value }
    })
  }

  return { isFreeShippingUnlocked, setIsFreeShippingUnlocked }
}

function useCartTotals() {
  const {
    store: { checkout, autoDiscount },
  } = useStore()

  const { subtotalPrice, discountApplications, lineItems, lineItemsSubtotalPrice } = checkout
  const minusPrice = () => {
    if (autoDiscount?.enableAutoDiscount) {
      const discountMissMatch =
        discountApplications && lineItems.every((item) => !item.discountAllocations.length)
      if (!discountMissMatch) return 0

      const PID = autoDiscount?.giftProduct?.productId
      const giftProduct = lineItems.find((item) => PID === shopify.decode(item.variant.product.id))
      if (giftProduct) {
        const value = discountApplications[0]?.value || { percentage: 0 }
        const price = giftProduct.variant.price
        const discount = (parseFloat(price) / 100) * value.percentage
        return discount
      }

      return 0
    }
    return 0
  }

  const discountedTotal = parseFloat(lineItemsSubtotalPrice?.amount) - minusPrice()
  const total = formatPrice({
    price: {
      ...subtotalPrice,
      amount: discountedTotal,
    },
  })

  const subtotalPriceAmount = parseFloat(subtotalPrice?.amount)

  const shippableTotal = calculateShippableTotal(checkout)

  const nonDiscountTotal = getNonDiscountAmount(checkout)
  const rawTotal = formatPrice(
    {
      price: {
        ...subtotalPrice,
        amount: discountedTotal,
      },
    },
    1,
    true
  )

  const hasDiscountedItems = lineItems.some((item) => item.discountAllocations.length > 0)

  return {
    total,
    discountedTotal,
    shippableTotal,
    nonDiscountTotal,
    hasDiscountedItems,
    subtotalPriceAmount,
    rawTotal
  }
}

function useCartItems() {
  const {
    store: { checkout },
  } = useStore()

  const { lineItems = [] } = checkout
  const cartCount = lineItems.reduce((total, item) => total + item.quantity, 0)

  const sortedLineItems = reverse(
    sortBy(lineItems, [
      (item) =>
        parseInt(item.customAttributes.find((attr) => attr.key === '__timestamp').value, 10),
    ])
  )

  const filteredLineItems = sortedLineItems.filter((item) => !!item)

  const filteredCartCount = filteredLineItems.reduce((total, item) => total + item.quantity, 0)
  const filteredLineItemsCount = filteredLineItems.length

  const lineItemsWithoutExtraFree = filteredLineItems.filter(item => {
    return !item.customAttributes.find(item => item.key === "extra_free_product")
  })
  const paidItemsCartCount = lineItemsWithoutExtraFree.reduce((total, item) => total + item.quantity, 0)

  return {
    lineItems,
    cartCount,
    sortedLineItems,
    filteredLineItems,
    filteredCartCount,
    filteredLineItemsCount,
    paidItemsCartCount,
  }
}

const getProductDetails = (item, allProducts) => {
  let productId = shopify.decode(item.variant.product.id)
  return allProducts.find((x) => x.productId === productId)
}

function getSubtotalConsideringSubscriptions(lineItems, allProducts) {
  let total = 0
  for (let index = 0; index < lineItems.length; index++) {
    const item = lineItems[index]
    const isSubscriptionItem =
      item?.customAttributes?.find((attr) => attr?.key === 'subscription_id')?.value !== undefined
    const product = getProductDetails(item, allProducts)
    const original = parseFloat(item.variant.price.amount)
    const discountedPrice = isSubscriptionItem ? getSubDiscountedPrice(original, product) : original
    total += discountedPrice * item.quantity
  }
  return total
}

function useAddCartItems() {
  const { store, setStore } = useStore()
  const { total } = useCartTotals()

  const addToCart = useCallback(
    async function (
      { product, payload, shouldSetIsAdding = true },
      autoAction = false,
      toggleSubscription = false
    ) {
      try {
        const {
          checkout,
          countryCode,
          autoDiscount,
          discountFromURL,
          tieredPromotions,
          allProducts,
        } = store
        const queuedLineItems = lineItems(product, payload)
        const lineItemsToAdd = []
        const lineItemsToUpdate = []
        const cartLineItemsToUpdate = []

        queuedLineItems.forEach((queuedItem) => {
          let customAttributes = []

          const existingItem = checkout.lineItems.find((item) => {
            const isEqualVariant =
              shopify.decode(item.variant.id) === shopify.decode(queuedItem.variantId)
            if (isEqualVariant) {
              const isEqualCustomAttribute = isEqual(
                without(toObject(item.customAttributes), '__timestamp', '__line_item_title'),
                without(toObject(queuedItem.customAttributes), '__timestamp', '__line_item_title')
              )
              if (!isEqualCustomAttribute) {
                customAttributes = Object.assign([], queuedItem.customAttributes)
              } else {
                customAttributes = Object.assign([], item.customAttributes)
              }
            }
            const isEqualCustomAttributes = isEqual(
              without(
                toObject(item.customAttributes),
                '__timestamp',
                '__line_item_description',
                'shipping_interval_unit_type',
                '__line_item_title',
                '__line_item_image_asset_id'
              ),
              without(
                toObject(queuedItem.customAttributes),
                '__timestamp',
                '__line_item_description',
                'shipping_interval_unit_type',
                '__line_item_title',
                '__line_item_image_asset_id'
              )
            )

            return isEqualVariant && isEqualCustomAttributes
          })

          if (existingItem) {
            lineItemsToUpdate.push({
              id: existingItem.id,
              quantity: existingItem.quantity + queuedItem.quantity,
              customAttributes: customAttributes,
            })
            cartLineItemsToUpdate.push(queuedItem)
          } else {
            if (toggleSubscription) {
              queuedItem.customAttributes.push({
                key: 'shipping_interval_unit_type',
                value: 'eligibleForSubscriptionToggle',
              })
            }

            lineItemsToAdd.push(queuedItem)
          }
        })

        const giftProductDND =
          !autoAction && product.productId === autoDiscount?.giftProduct?.productId
        const cartState = { isAdding: shouldSetIsAdding }

        if (!autoDiscount?.giftProductDND) {
          cartState.autoDiscount = {
            ...autoDiscount,
            giftProductDND,
          }
        }
        setStore((prevState) => ({
          ...prevState,
          ...cartState,
        }))

        let newCheckout = null
        const existingCartId = localStorage.getItem('customerCartId');
        if (lineItemsToAdd.length) {
          newCheckout = await shopify.addLineItems(checkout.id, lineItemsToAdd, countryCode)
          const lines = lineItemsToAdd.map(item => {
            const lineItem = {
              merchandiseId: item.variantId,
              quantity: item.quantity,
            };

            const sellingPlanAttribute = item.customAttributes.find(attr => attr.key === 'selling_plan');
            if (sellingPlanAttribute) {
              lineItem.sellingPlanId = sellingPlanAttribute.value;
            }

            return lineItem;
          });
          if (existingCartId) {
            const cart = await shopify.addCartLines(existingCartId, lines);
          }
          if (!autoDiscount?.promoCode || !autoDiscount?.thresholdAmount) {
            if (!newCheckout) return
          }
        } else {
          newCheckout = await shopify.updateLineItems(checkout.id, lineItemsToUpdate, countryCode)

          const cart = await shopify.fetchCart(existingCartId);
          const productToUpdate = cart.lines.find((line) => {
            return line.merchandise.id === cartLineItemsToUpdate[0].variantId;
          });
          const lines = [
            {
              id: productToUpdate.id,
              quantity: lineItemsToUpdate[0].quantity,
            }
          ]
          if (existingCartId) {
            await shopify.updateCartLines(existingCartId, lines);
          }
        }

        const subtotalConsideringSubscriptions = getSubtotalConsideringSubscriptions(
          newCheckout.lineItems,
          allProducts
        )
        const isPromoDiscountApplied =
          newCheckout?.discountApplications?.filter(({ code }) => code === autoDiscount?.promoCode)
            .length > 0
        const threshold = autoDiscount?.thresholdAmount
        let excludedTotal = excludedAmount(newCheckout)

        if (autoDiscount?.promoCode && countryCode === 'US') {
          if (
            subtotalConsideringSubscriptions - excludedTotal > threshold &&
            !isPromoDiscountApplied
          ) {
            newCheckout = await shopify.addDiscount(
              checkout.id,
              autoDiscount.promoCode,
              countryCode
            )
          } else if (
            subtotalConsideringSubscriptions - excludedTotal < threshold &&
            isPromoDiscountApplied
          ) {
            const newCheckout = shopify.removeDiscount(checkout.id, countryCode)
            setStore((prevState) => ({
              ...prevState,
              checkout: newCheckout,
            }))
          }
        }
        let shouldCartOpen = !router.asPath.includes('/cart')

        if (discountFromURL && tieredPromotions.length == 0 && countryCode === 'US') {
          newCheckout.webUrl = `${newCheckout.webUrl}&discount=${discountFromURL}`
        }
        if (shouldCartOpen) {
          window?.Solvvy?.hide()
        } else {
          window?.Solvvy?.show()
        }
        setStore((prevState) => ({
          ...prevState,
          checkout: newCheckout,
          isCartOpen: shouldCartOpen,
          isAdding: false,
        }))

        if (shouldCartOpen) {
          const { lineItems } = checkout
          let cartTotal = (total ?? '').replace('$', '')
          analytics.trackCartView(
            lineItems.map((item, index) => ({ ...item, positionIndex: index + 1 })),
            cartTotal
          )
        }
      } catch (e) {
        console.log(e)
      }
    },
    [store, store.checkout, setStore, total]
  )

  return addToCart
}

function useRemoveCartItems() {
  const { store, setStore } = useStore()
  return removeCartItems(store, setStore)
}

function removeCartItems(store, setStore) {
  return async function (items = [], update = true) {
    setStore((prevState) => ({
      ...prevState,
      isUpdating: true,
    }))

    items = [].concat(items)

    const { checkout, countryCode, autoDiscount } = store
    const existingCartId = localStorage.getItem('customerCartId');
    const cart = await shopify.fetchCart(existingCartId);
    const linesToUpdate = items.map(item => {
      const checkoutLineItem = checkout.lineItems.find(lineItem => lineItem.id === item);
      if (!checkoutLineItem) return null;
      const cartLine = cart.lines.find(line => line.merchandise.id === checkoutLineItem.variant.id);
      if (!cartLine) return null;
      return {
        id: cartLine.id
      };
    }).filter(line => line !== null);

    if (linesToUpdate.length > 0) {
      await shopify.removeCartItems(existingCartId, linesToUpdate[0].id);
    }

    let newCheckout = await shopify.removeLineItems(checkout.id, items, countryCode)

    const subtotalConsideringSubscriptions = getSubtotalConsideringSubscriptions(
      newCheckout.lineItems,
      store.allProducts
    )

    const isPromoDiscountApplied =
      newCheckout?.discountApplications?.filter(({ code }) => code === autoDiscount?.promoCode)
        .length > 0
    const threshold = autoDiscount?.thresholdAmount

    let excludedTotal = excludedAmount(newCheckout)

    if (
      autoDiscount?.promoCode &&
      subtotalConsideringSubscriptions - excludedTotal < threshold &&
      isPromoDiscountApplied
    ) {
      newCheckout = shopify.removeDiscount(checkout.id, countryCode)
    }

    update &&
      setStore((prevState) => ({
        ...prevState,
        checkout: newCheckout,
        isUpdating: false,
      }))

    return newCheckout
  }
}

function useApplyTieredPromotion() {
  const {
    store: { checkout, countryCode, tieredPromotions },
    setStore,
  } = useStore()

  async function applyTiredPromotion() {
    const { discountApplications, lineItemsSubtotalPrice } = checkout
    const [appliedPromo] = discountApplications

    const sortedPromos = tieredPromotions.sort((one, two) =>
      one.threshold > two.threshold ? -1 : 0
    )

    // Exclude Gift card and Donation items total from calculation
    const lineItemsTotal = Number(lineItemsSubtotalPrice.amount)
    let excludableTotal = 0

    const items = checkout.lineItems
    for (let i = 0; i < items.length; i++) {
      if (items[i]?.title.indexOf('Gift Card') > -1 || items[i]?.title.indexOf('Donate') > -1) {
        excludableTotal = Number(excludableTotal) + Number(items[i].variant.price)
      }
    }
    const promo =
      sortedPromos.find((promo) => lineItemsTotal - excludableTotal > Number(promo.threshold)) || {}
    const { couponCode } = promo

    if (couponCode && couponCode !== appliedPromo?.code) {
      const newCheckout = await shopify.addDiscount(checkout.id, couponCode, countryCode)

      setStore((prevState) => ({
        ...prevState,
        checkout: newCheckout,
      }))
    }

    return promo
  }
  return applyTiredPromotion
}

function useUpdateCartItems() {
  const {
    store: { checkout, countryCode, autoDiscount, allProducts },
    setStore,
  } = useStore()

  async function updateCartItems(items) {
    setStore((prevState) => ({
      ...prevState,
      isUpdating: true,
    }))

    items = [].concat(items)

    let newCheckout = await shopify.updateLineItems(checkout.id, items, countryCode)

    const subtotalConsideringSubscriptions = getSubtotalConsideringSubscriptions(
      newCheckout?.lineItems ?? [],
      allProducts
    )

    const isPromoDiscountApplied =
      newCheckout?.discountApplications?.filter(({ code }) => code === autoDiscount?.promoCode)
        .length > 0
    const threshold = autoDiscount?.thresholdAmount
    let excludedTotal = excludedAmount(newCheckout)

    if (autoDiscount?.promoCode && countryCode === 'US') {
      if (subtotalConsideringSubscriptions - excludedTotal < threshold) {
        if (isPromoDiscountApplied) {
          newCheckout = await shopify.removeDiscount(checkout.id, countryCode)
        }
      } else {
        if (!isPromoDiscountApplied) {
          newCheckout = await shopify.addDiscount(checkout.id, autoDiscount?.promoCode, countryCode)
        }
      }
    }

    const existingCartId = localStorage.getItem('customerCartId');
    const cart = await shopify.fetchCart(existingCartId);
    const linesToUpdate = items.map(item => {
      const checkoutLineItem = checkout.lineItems.find(lineItem => lineItem.id === item.id);
      if (!checkoutLineItem) return null;
      const cartLine = cart.lines.find(line => line.merchandise.id === checkoutLineItem.variant.id);
      if (!cartLine) return null;
      return {
        id: cartLine.id,
        quantity: item.quantity,
      };
    }).filter(line => line !== null);

    if (linesToUpdate.length > 0) {
      await shopify.updateCartLines(existingCartId, linesToUpdate);
    }

    setStore((prevState) => ({
      ...prevState,
      checkout: newCheckout,
      isUpdating: false,
    }))
  }

  return updateCartItems
}

function useNavigateToCheckout() {
  const {
    store: {
      checkout,
      isNavigatingToCheckout,
      discountFromURL,
      countryCode,
      autoDiscount,
      convertId,
    },
    setStore,
  } = useStore()

  async function navigateToCheckout(globals) {
    setStore((prevState) => ({
      ...prevState,
      isNavigatingToCheckout: true,
    }))

    const lines = checkout.lineItems.map((item) => {
      return {
        quantity: item.quantity,
        merchandiseId: item.variant.id,
        sellingPlanId: item.customAttributes.find((x) => x.key === 'selling_plan')?.value,
        attributes: item.customAttributes.filter((x) => x.key === '__gift-item'),
      }
    }).reverse();

    const checkoutDiscountCodes = checkout.discountApplications.map(({ code }) => code) ?? []
    const input = {
      attributes: [...checkout.customAttributes.filter((x) => x.key && x.value)],
      lines,
      buyerIdentity: {
        countryCode,
      },
      discountCodes: [discountFromURL, ...checkoutDiscountCodes].filter((d) => !!d),
    }

    const getCookie = (name) => {
      var cookieArr = document.cookie.split(';')
    
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split('=')
        if (name == cookiePair[0].trim()) {
          return decodeURIComponent(cookiePair[1])
        }
      }
      return null
    }
    let getUserId
    if (typeof window !== "undefined") {
      const getUserData = getCookie('userData');
      getUserId = getUserData ? JSON.parse(getUserData) : undefined;
    }

    const customerId = getUserId ? `gid://shopify/Customer/${getUserId.user_id}` : undefined;
    const customerSegmentIds = globals?.customerSegmentIds?.map((id) => `gid://shopify/Segment/${id}`)

    let cart
    try {
      const existingCartId = localStorage.getItem('customerCartId');
      if (existingCartId) {
        cart = await shopify.fetchCart(existingCartId);
      }

      if (!cart) {
        cart = await shopify.createCartV2(input);
        localStorage.setItem('customerCartId', cart.id);
      }

      await shopify.updateCartBuyerIdentity(cart.id, input.buyerIdentity);
    
      const isCustomerInSegment = customerSegmentIds.length && customerId ? await fetchCustomerSegmentMembership(customerId, customerSegmentIds) : {};
      const isAtLeastOneMember = isCustomerInSegment?.data?.customerSegmentMembership?.memberships?.some(membership => membership.isMember === true);
    
      if (isAtLeastOneMember) {
        await shopify.updateCartAttributes(
          cart.id,
          [{ key: 'discount_for_customer_segment', value: 'true' }]
        );
      } else {
        await shopify.updateCartAttributes(
          cart.id,
          [{ key: 'discount_for_customer_segment', value: 'false' }]
        )
      }
    
      if (input.discountCodes && countryCode === 'US') {
        await shopify.addCartDiscount(cart.id, input.discountCodes)
      }
    } catch (error) {
      cart = await shopify.createCartV2(input);
      localStorage.setItem('customerCartId', cart.id)
    }
    const checkoutUrl = (await shopify.fetchCheckoutUrlV2(cart.id)).cart.checkoutUrl
    let linkerParam = analytics.getLinkerParam(process.env.GA_ID)
    const newLocation = checkoutUrl + (linkerParam ? `?${linkerParam}` : ``)

    let urlObj = new URL(newLocation)
    if (convertId) {
      urlObj?.searchParams?.append('userId', convertId)
    }

    let excludedTotal = excludedAmount(checkout)
    let discountsForUrl = input.discountCodes.join(',')
    if (
      (Number(cart?.estimatedCost?.totalAmount?.amount - excludedTotal ?? 0) >=
        autoDiscount?.thresholdAmount ||
        checkoutDiscountCodes?.includes(autoDiscount?.promoCode)) &&
      autoDiscount?.enableAutoDiscount &&
      autoDiscount?.promoCode &&
      countryCode === 'US'
    ) {
      urlObj.searchParams.append('discount', autoDiscount?.promoCode)
    }

    const newCheckoutURL = urlObj.toString()

    setStore((prevState) => ({
      ...prevState,
      isNavigatingToCheckout: false,
    }))

    global.location = newCheckoutURL
  }
  return {
    navigateToCheckout,
    isNavigatingToCheckout,
  }
}

function useGiftMessage() {
  const {
    store: { checkout, countryCode },
    setStore,
  } = useStore()

  const giftMessageAttribute = checkout.customAttributes.find((attr) => attr.key === 'gift_message')

  async function setGiftMessage(value) {
    const newCheckout = await shopify.updateCheckoutAttributes(
      checkout.id,
      {
        customAttributes: [{ key: 'gift_message', value }],
      },
      countryCode
    )

    setStore((prevState) => ({
      ...prevState,
      checkout: newCheckout,
    }))
  }

  return {
    giftMessage: giftMessageAttribute?.value ?? '',
    setGiftMessage,
  }
}

function useIsFontsLoaded() {
  const {
    store: { isFontsLoaded },
    setStore,
  } = useStore()

  function setIsFontsLoaded(value) {
    setStore((prevState) => ({
      ...prevState,
      isFontsLoaded: value,
    }))
  }

  return {
    isFontsLoaded,
    setIsFontsLoaded,
  }
}
function useTopPosition(isSticky, showLarger) {
  if (typeof window !== 'undefined') {
    const bannerHeight = document.querySelector('#dynamic-promo')?.offsetHeight
    if (bannerHeight && isSticky) {
      return bannerHeight + 'px'
    }
  }

  if (isSticky && showLarger) {
    return '50px'
  }
  if (isSticky) {
    return '35px'
  }
  return '0px'
}

function useLastVisitedPDP() {
  const {
    store: { lastVisitedPDP },
    setStore,
  } = useStore()

  function setLastVisitedPDP(value) {
    setStore((prevState) => ({
      ...prevState,
      lastVisitedPDP: value,
    }))
  }

  return {
    lastVisitedPDP,
    setLastVisitedPDP,
  }
}

// TODO: Add "Exclude from free shipping calculation" boolean field at product level in Sanity.
// Then use that property to set a customAttribute when items are added to the cart.
function calculateShippableTotal(checkout) {
  return `${checkout.lineItems
    .filter((item) => {
      const handle = item?.variant?.product?.handle
      return handle !== 'gift-card' && !handle.includes('donate')
    })
    .reduce((total, item) => total + item.quantity * parseFloat(item.variant.price.amount, 10), 0)
    .toFixed(2)}`
}

function getNonDiscountAmount({ lineItemsSubtotalPrice }) {
  return formatPrice({ price: lineItemsSubtotalPrice })
}

function toObject(array) {
  return array.reduce((obj, attr) => {
    obj[attr.key] = attr.value
    return obj
  }, {})
}

function without(obj, key, key2 = undefined, key3 = undefined, key4 = undefined, key5 = undefined) {
  const copy = Object.assign({}, obj)
  delete copy[key]
  if (key2) {
    delete copy[key2]
  }
  if (key3) {
    delete copy[key3]
  }
  if (key4) {
    delete copy[key4]
  }
  if (key5) {
    delete copy[key5]
  }
  return copy
}

function isEqual(obj1, obj2) {
  return areObjectsEqual(obj1, obj2)
}

function areObjectsEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false
    }
  }

  return true
}

const excludedAmount = (checkout) => {
  let excludedTotal = 0
  const items = checkout?.lineItems
  for (let i = 0; i < items?.length; i++) {
    if (items[i]?.variant?.product?.productType === 'Donation' || items[i]?.variant?.product?.productType === 'Gift Cards') {
      excludedTotal =
        Number(excludedTotal) + Number(items[i]?.variant?.price?.amount) * items[i]?.quantity
    }
  }
  return excludedTotal
}

export {
  useStore,
  useIsAdding,
  useCartItems,
  useCartTotals,
  useIsUpdating,
  useIsCartOpen,
  useMobileMenu,
  useDiscountFromURL,
  useAllProducts,
  useTieredPromotions,
  useTotalReviews,
  useCountryCode,
  useGiftMessage,
  useAutoDiscount,
  useAddCartItems,
  useIsFontsLoaded,
  useLastVisitedPDP,
  useRemoveCartItems,
  useUpdateCartItems,
  useApplyTieredPromotion,
  StoreContextProvider,
  useSubscriptionState,
  useStickyProductForm,
  useIsStickyPromo,
  useNavigateToCheckout,
  useIsFreeShippingUnlocked,
  useTopPosition
}
