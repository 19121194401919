import { createContext, useContext, useState } from 'react'
import flattenChildren from 'react-keyed-flatten-children'
import Chevron from 'components/Icons/Chevron'
import cx from 'classnames'
import Image from 'components/Image'
import getColorStyle, { getLastColorInGradient } from 'lib/getColorStyle'

// Create contexts
const DropdownContext = createContext()

// Export context hooks
export const useDropdownContext = () => useContext(DropdownContext)
export const useDropdownOption = () => useContext(DropdownOption)

export default function Dropdown({
  children,
  name,
  value,
  label = '',
  onChange = (v) => {},
  className = '',
  showName = false,
  bgColorHex = '',
  showFocusRing = true,
  ...props
}) {
  const [isFocused, setIsFocused] = useState(false)

  function handleChange(ev) {
    onChange(ev.target.value)
  }

  function handleFocus() {
    setIsFocused(true)
  }

  function handleBlur() {
    setIsFocused(false)
  }

  const options = flattenChildren(children)
  const activeOption = options.find((option) => option.props.value === value)?.props

  const parsedIcon = () => {
    
    return (
      typeof activeOption?.icon === 'string' ? (
        <div className="mr-10">{activeOption.icon}</div>
      ) : activeOption?.icon?.asset ? (
        <div className="w-14 h-14 mr-10">
          <Image
            image={activeOption.icon}
            fit="contain"
            fill={true}
            placeholder={false}
            sizes="16px"
            overrides={(x) => x.fit('clip')}
          />
        </div>
      ) : activeOption?.color ? <span className="w-12 h-12 mr-7 border border-white box-content rounded-pill" style={{ backgroundColor: activeOption.color.hex }}></span> : null
    )
  }
    
  const style = bgColorHex ? {backgroundColor: bgColorHex} : {}
  return (
    <DropdownContext.Provider value={{ value, options, onChange }}>
      <div
        className={cx(
          className,
          'relative pt-10 pb-10 px-15 border border-navy-blue-muted rounded-4',
          { 'ring-2 ring-offset-1 ring-royal-blue': isFocused && showFocusRing },
        )}
        style={style}
      >
        <select
          {...props}
          name={name}
          className="text-14 appearance-none absolute inset-0 w-full opacity-0"
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          {options}
        </select>
        <div
          className="flex justify-between items-center pointer-events-none text-15 leading-100 font-medium text-navy-blue"
          aria-hidden="true"
        >
          <div className="flex w-full ">
            {parsedIcon()}
            <div className={cx("items-baseline mr-20", {
              'flex': !showName
            })}>
              {showName && <>{name}: <br className='l:hidden'/></>} {activeOption?.label}
            </div>
          </div>
          <div className="w-10">
            <Chevron direction={showName ? "up" : "down"} />
          </div>
        </div>
      </div>
    </DropdownContext.Provider>
  )
}
