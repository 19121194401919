import { AnchorReference, ExternalLink, InternalLink } from 'types/sanity-links'

export default function linkResolver(link: InternalLink | ExternalLink) {
  if (!link) return
  const { _type, url } = link
  switch (_type) {
    case 'externalLink':
      return url
    case 'internalLink':
      const reference = link?.reference
      if (reference?._type === 'anchor') {
        return `${referenceToPath(reference.parent)}#${reference.hash}`
      } else {
        return referenceToPath(reference)
      }
  }
}

function referenceToPath(reference: AnchorReference['parent'] | AnchorReference) {
  switch (reference?._type) {
    case 'page':
      return reference?.isHomepage ? '/' : `/${reference?.slug}`
    case 'product':
      return `/products/${reference?.slug}`
    case 'productLander':
      return `/products/${reference?.productSlug}/${reference?.slug}`
    case 'collection':
      return `/collections/${reference?.slug}`
    case 'blog':
      return `/blog`
    case 'articleTag':
      return `/blog?slug=${reference?.slug}`
    case 'article':
      return `/articles/${reference?.slug}`
  }
}
