import { useStore } from 'context/globalStore'
import { usePromoConfig } from './usePromoConfig'
import { Checkout, SellingPlan } from 'types/checkout'
import { useMemo } from 'react'

function getPriceWithDiscount(cents: number, sellingPlan: SellingPlan | null) {
  if (sellingPlan?.discountType === 'percentage') {
    return (cents * (100 - sellingPlan.discountAmount)) / 100 / 100
  }
  return cents / 100
}

export default function useCartPrices() {
  const {
    store: { checkout, allProducts },
  } = useStore()

  const config = usePromoConfig()

  const promoDiscountForSubscription = config?.subscriptionDiscountPct ?? 0
  const promoDiscountForOneTime = config?.oneTimeDiscountPct ?? 0

  const productsMap = useMemo(() => {
    const productMap = new Map()
    if (allProducts.length) {
      allProducts.map((product) => {
        productMap.set(`gid://shopify/Product/${(product as any).productId}`, product)
      })
    }

    return productMap
  }, [allProducts])

  const excludedProductsSlugsForThresholdCalculation =
    config?.excludedProducts?.map((p) => p?.slug?.current)?.filter((p) => p) ?? []

  const lineItems = (checkout as unknown as Checkout)?.lineItems.map((lineItem) => {
    const product = productsMap?.get(lineItem?.variant?.product?.id)
    const variant = product?.variants?.find(
      (variant) => `gid://shopify/ProductVariant/${variant.variantId}` === lineItem.variant.id
    )
    const appliedSellingPlan = lineItem?.customAttributes?.find(({ key }) => key === 'selling_plan')
    const isSubscription = !!appliedSellingPlan

    const sellingPlan =
      (variant?.sellingPlans as SellingPlan[])?.find((sp) => sp.id === appliedSellingPlan?.value) ??
      null

    const unitPriceForOnetime =
      (variant?.cents ?? parseFloat(lineItem.variant.price.amount ?? 0) * 100) / 100
    const unitPrice = isSubscription
      ? getPriceWithDiscount(unitPriceForOnetime * 100, sellingPlan)
      : unitPriceForOnetime
    const totalLineItemPrice = unitPrice * lineItem.quantity
    const priceAfterPromo =
      (totalLineItemPrice *
        (100 - (isSubscription ? promoDiscountForSubscription : promoDiscountForOneTime))) /
      100

    return {
      isSubscription,
      product,
      variant,
      sellingPlan,
      unitPrice,
      totalLineItemPrice,
      priceAfterPromo,
      isExcludedForThresholdCalculation:
        lineItem.variant.product.productType === 'Donation' ||
        lineItem.variant.product.productType === 'Gift Cards' ||
        lineItem.customAttributes.some(attr => attr.key === '__gift-item') ||
        excludedProductsSlugsForThresholdCalculation.includes(product?.slug?.current),
    }
  })

  const cartSubtotalIncludingSubscriptionDiscountsWithoutPromo = lineItems
    .reduce((prev, current) => prev + current.totalLineItemPrice, 0)
    .toFixed(2)
  const promoCode = config?.promoCode
  const thresholdAmount = config?.thresholdAmount ?? 0
  const isPromoActivated =
    !!(checkout as unknown as Checkout)?.discountApplications?.find(
      (da) => da.code === promoCode
    ) && parseFloat(cartSubtotalIncludingSubscriptionDiscountsWithoutPromo ?? 0) > thresholdAmount

  const cartSubtotalIncludingSubscriptionDiscountsWithPromo = isPromoActivated
    ? lineItems.reduce((prev, current) => prev + current.priceAfterPromo, 0).toFixed(2)
    : 0

  const effectiveCartSubtotalForPromoTriggerThresholdCalculation = lineItems
    .reduce(
      (prev, current) =>
        prev + (current.isExcludedForThresholdCalculation ? 0 : current.totalLineItemPrice),
      0
    )
    .toFixed(2)

  return {
    lineItems,
    cartSubtotalIncludingSubscriptionDiscountsWithPromo,
    cartSubtotalIncludingSubscriptionDiscountsWithoutPromo,
    effectiveCartSubtotalForPromoTriggerThresholdCalculation,
    isPromoActivated,
  }
}
