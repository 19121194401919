import { getOptionSettingsData } from 'components/Product/Product.helper'
import ChoiceChipGroup from 'components/ChoiceChipGroup'
import ChoiceChipItem from 'components/ChoiceChipItem'
import cx from 'classnames'
import Swatch from './Swatch'
import PillSwatch from './PillSwatch'
import Dropdown from 'components/Dropdown'
import DropdownOption from 'components/DropdownOption'
import { PACK_SIZE, COUNT, CHOICE_CHIPS, DROPDOWN_MENU, SWATCH_SELECTOR } from '../helpers/constants'
import GetSwatch from './GetSwatch'

export default function getOptions(product, activeVariant, handleOptionChange, isOptionAvailable, activeOptionValueSettings, selectedOption, selectedOption2, handleSwatchSelect, options, swatchInfo) {
  const quantityLabel = options.find((x) => x.name == PACK_SIZE)?.name ?? options.find((x) => x.name == COUNT)?.name

  return options?.length ? (
    <div className={cx('space-y-10 l:space-y-16')}>
      {options
        .filter((option) => option.name !== quantityLabel)
        .map(({ _key, name, values, position }) => {
          const groupValue = activeVariant[`option${position}`]
          const optionSettingsData = getOptionSettingsData(product);
          const optionSettings = optionSettingsData?.find((s) => s.forOption === name)
          const component = optionSettings?.component ?? 'Choice Chips'

          const isSecondSwatchSelector = optionSettings?.plpSwatch === 'PLP2'
          const plpSwatch2 = product?.plpSwatch?.swatchItems2 ? {
            enable: product?.plpSwatch?.enable,
            multiLineArrangement: product?.plpSwatch?.multiLineArrangement2,
            options: product?.plpSwatch?.options2,
            swatchItems: product?.plpSwatch?.swatchItems2
          } : product?.plpSwatch2
          const plpSwatch = isSecondSwatchSelector ? plpSwatch2 : product?.plpSwatch

          const swatch = GetSwatch({
            component,
            _key,
            groupValue,
            handleOptionChange,
            values,
            name,
            activeOptionValueSettings,
            product,
            position,
            plpSwatch,
            swatchInfo,
            selectedOption,
            isOptionAvailable,
            activeVariant,
            isSecondSwatchSelector,
            selectedOption2,
            handleSwatchSelect
          });

          return (
            <div key={_key} className='l:flex l:items-center'>
              <span
                className={cx(
                  'l:mt-0 block text-13 text-navy-blue font-medium leading-13 l:mr-13',
                  {
                    'pb-8 l:pb-0': component === CHOICE_CHIPS,
                    'pb-15': component === DROPDOWN_MENU,
                  }
                )}
                style={{ display: 'inline-block' }}
                htmlFor={_key}
              >
                {component === SWATCH_SELECTOR ? (
                  <>
                    Select {name}
                    <span className="ml-5 mt-8 l:mt-4 text-13 leading-13 italic text-navy-blue-muted">
                      {isSecondSwatchSelector ? selectedOption2 : selectedOption}
                    </span>
                  </>
                ) : (
                  <>
                    {name}
                  </>
                )}
              </span>
              {swatch}
            </div>
          )
        })}
    </div>
  ) : null
}