import { shopify } from 'lib/shopify'
import { FREE_GIFT_PROMO_TYPE } from '../constants'

const updateCartItemsAsync = async (filteredLineItems, promoConfig, excludedProductsPrice, item, isTriggerQuantity, updateCartItems, giftJustAdded) => {
  setTimeout(() => {
    const giftProduct = filteredLineItems?.find(item =>
      promoConfig?.giftWithPurchase && promoConfig?.giftWithPurchase[0]?.variant?.variantId === shopify.decode(item.variant.id) && item.customAttributes.some(attr => attr.key === '__gift-item')
    );
    const isGiftProductAdded = promoConfig?.giftWithPurchase?.some(gwpProduct =>
      filteredLineItems?.some(item => shopify.decode(item.variant.id) === gwpProduct.variantId && item.customAttributes.some(attr => attr.key === '__gift-item'))
    )
    const isFreeGiftPromo = promoConfig?.promoType === FREE_GIFT_PROMO_TYPE;
    const isBelowThreshold = excludedProductsPrice < promoConfig?.thresholdAmountForGift;
    const hasGiftItemAttribute = item.customAttributes.some(attr => attr.key === '__gift-item');
    const isPromoCodeMismatch = promoConfig && promoConfig?.promoCode !== item.discountAllocations[0]?.discountApplication?.code;
    const isTriggerProductLogic = isFreeGiftPromo && giftProduct && !isTriggerQuantity && promoConfig?.thresholdAmountForGift <= 0;

    // Threshold logic
    if (isFreeGiftPromo && isBelowThreshold && giftProduct && isGiftProductAdded && hasGiftItemAttribute && !giftJustAdded || hasGiftItemAttribute && isPromoCodeMismatch && !giftJustAdded) {
      const items = [
        {
          id: item.id,
          quantity: item.quantity - 1 || 0,
        },
      ]
      updateCartItems(items)
    }

    // Trigger product logic
    if (isTriggerProductLogic && hasGiftItemAttribute && !giftJustAdded || hasGiftItemAttribute && isPromoCodeMismatch && !giftJustAdded) {
      const items = [
        {
          id: item.id,
          quantity: item.quantity - 1 || 0,
        },
      ]
      updateCartItems(items)
      localStorage.setItem('isGwpRemoved', false)
    }

    if (hasGiftItemAttribute && item.discountAllocations.length === 0 && !giftJustAdded) {
      const items = [
        {
          id: item.id,
          quantity: item.quantity - 1 || 0,
        },
      ]
      updateCartItems(items)
    }
  }, 10);
}

export default updateCartItemsAsync