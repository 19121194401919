import React, { FC } from 'react'
import Image from 'components/Image'
import cx from 'classnames'
import { screens } from 'lib/constants'
import getColorStyle, { getLastColorInGradient } from 'lib/getColorStyle'

interface IColorSwatchCategoriesA {
  index: number
  multiLineArrangement: boolean
  category: string
  items: any[]
  position: string | number
  handleSwatchSelect: Function
  isOptionAvailable: (...args) => boolean
  selectedOption: string
  activeVariant: any,
  settings: any,
  plpSwatch?: boolean
}

const ColorSwatchCategoriesA: FC<IColorSwatchCategoriesA> = ({
  index,
  multiLineArrangement,
  category,
  items,
  position,
  handleSwatchSelect,
  isOptionAvailable,
  selectedOption,
  activeVariant,
  settings,
  plpSwatch = false
}) => {
  return (
    <div
      className={cx({
        'l:border-l l:pl-18': index !== 0 && !multiLineArrangement,
        'l:border-none l:pl-0': index !== 0 && multiLineArrangement,
        'l:inline-block l:mt-0': !multiLineArrangement,
        'l:mt-0 l:block': multiLineArrangement,
        'mr-18': !plpSwatch,
      })}
    >
      {category && <p className="mb-4 text-11 leading-14 mt-0 l:mt-10"> {category} </p>}
      <div className="relative flex flex-col flex-wrap s:flex-row s:items-center z-1 max-w-full l:max-w-500">
        <ul className={cx('product-option-swatch flex flex-wrap', {
          'mt-6 l:mt-0': plpSwatch,
        })}>
          {items.map(({ _key, color, colorStack, option, swatchIcon }) =>
            isOptionAvailable(null, option, position) ? (
              <>
                {swatchIcon && swatchIcon.asset ? (
                  <>
                    <li
                      onClick={() => handleSwatchSelect(option, position)}
                      className="relative mt-5 h-36 w-36 mr-10 last:mr-0 cursor-pointer"
                      key={_key}
                    >
                      <span
                        style={{
                          backgroundColor: 'transparent',
                          borderColor: option === selectedOption ? '#003057' : '#000000',
                          border: '1px solid',
                        }}
                        className={cx(
                          'absolute left-0 top-0 right-0 bottom-0 -m-4 rounded-pill opacity-0 bg-navy-blue',
                          {
                            'opacity-100': option === selectedOption,
                          }
                        )}
                      ></span>
                      <Image
                        image={swatchIcon.asset}
                        width={50}
                        height={50}
                        className={'cursor-pointer'}
                        sizes={`100vw, (min-width: ${screens.xs}) 430px, (min-width: ${screens.m}) 50vw, (min-width: ${screens.l}) 430px`}
                        placeholder={false}
                      />
                      {option === selectedOption && settings?.showDescription && activeVariant.swatchColorDescription && (
                        <div className="absolute left-1/2 -bottom-24 transform -translate-x-1/2 whitespace-nowrap text-13 text-navy-blue font-medium leading-13">
                          {activeVariant.swatchColorDescription}
                        </div>
                      )}
                    </li>
                  </>
                ) : (
                  <>
                    <li
                      onClick={() => handleSwatchSelect(option, position)}
                      className={cx('relative last:mr-0 rounded-pill cursor-pointer', {
                        'w-36 h-36 mt-5 mr-10': !plpSwatch,
                        'w-32 l:w-22 h-32 l:h-22 mr-7 l:mr-5': plpSwatch
                      })}
                      key={_key}
                      style={{
                        backgroundColor: colorStack
                          ? getColorStyle(colorStack)
                          : getColorStyle({ color }),
                      }}
                    >
                      <div>
                        <div
                          className={cx('relative  rounded-pill cursor-pointer border border-solid flex flex-shrink-0 flex-1', {
                            'w-36 h-36': !plpSwatch,
                            'w-32 l:w-22 h-32 l:h-22': plpSwatch
                          })}
                          style={{
                            background: colorStack ? getColorStyle(colorStack) : '',
                            borderColor:
                              option === selectedOption
                                ? '#003057'
                                : getLastColorInGradient(colorStack),
                          }}
                        >
                          <div
                            className="absolute inset-0 border-white border  rounded-pill"
                            style={{
                              borderWidth: option === selectedOption && !plpSwatch ? '3px' : option === selectedOption && plpSwatch ? '2px' : '0px',
                            }}
                          ></div>
                        </div>
                      </div>
                      {option === selectedOption && settings?.showDescription && activeVariant.swatchColorDescription && (
                        <div className="absolute left-1/2 -bottom-24 transform -translate-x-1/2 whitespace-nowrap text-13 text-navy-blue font-medium leading-13">
                          {activeVariant.swatchColorDescription}
                        </div>
                      )}
                    </li>
                  </>
                )}
              </>
            ) : null
          )}
        </ul>
      </div>
    </div>
  )
}

export default ColorSwatchCategoriesA
