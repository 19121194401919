import Link from 'components/Link'
import * as analytics from 'lib/analytics'

export default function ColoredLink({ link, color }) {
  return (
    <li className="relative h-full flex items-center">
      <Link
        link={link}
        style={{ color: color?.color?.hex || 'inherit' }}
        className="block text-16 xl:text-18 leading-100 get-my-starter-kit tracking-1 font-medium pt-11 pb-10 px-15 rounded focus:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-royal-blue hover:bg-alice-blue hover:text-royal-blue transition"
        onClick={() => analytics.trackNavClick(link.title)}
      >
        {link.title}
      </Link>
    </li>
  )
}
