import { useRef, useEffect, useState } from 'react'
import { useRect } from '@reach/rect'
import { add, remove } from 'martha'
import SearchDropDownComponent from 'components/SearchDropDown'
import FocusTrap from 'focus-trap-react'
import Header from 'components/MobileHeader'
import Menu from './MobileMenu'
import PrimaryMenuV2 from './MobilePrimaryMenuV2'
import SecondaryMenuV2 from './MobileSecondaryMenuV2'
import MobileFixedMenu from 'components/MobileFixedMenu'
import { useRouter } from 'next/router'
import { useIsStickyPromo, useTopPosition, useDiscountFromURL, useMobileMenu } from 'context/globalStore'
import useGorgiasChatPositioning from 'hooks/useGorgiasChatPositioning'
import usePageScrollLock from 'hooks/usePageScrollLock'

export default function MobileNavigation({
                                           mobilePrimaryMenuV2,
                                           mobileSecondaryMenuV2,
                                           mobileFixedMenu,
                                           bgColorStyle,
                                           globals,
                                           searchConfig,
                                           isBannerHidden,
                                           isNoNavigation,
                                           isGetSubscriptionPage
                                         }) {
  const { updateChatPosition } = useGorgiasChatPositioning()
  const { isMobileMenuOpen } = useMobileMenu()
  const headerRef = useRef()
  const headerRect = useRect(headerRef)
  const [searchDropDown, setSearchDropDown] = useState(false)
  const router = useRouter()
  const { setDiscountFromURL } = useDiscountFromURL()

  const { isStickyPromo, hasLargerPromoBar } = useIsStickyPromo()
  const toggleSearchDropDown = () => {
    if (searchDropDown) {
      window?.Solvvy?.show()
    } else {
      window?.Solvvy?.hide()
    }
    setSearchDropDown(!searchDropDown)
  }

  usePageScrollLock(searchDropDown, 600)

  useEffect(() => {
    if (typeof window === 'undefined') return
    if (isMobileMenuOpen) {
      add(document.body, 'overflow-hidden')
    } else {
      remove(document.body, 'overflow-hidden')
    }
    searchDropDown || isMobileMenuOpen
      ? updateChatPosition({
        display: 'none',
      })
      : updateChatPosition({
        display: 'block',
      })
  }, [isMobileMenuOpen, searchDropDown])

  useEffect(() => {
    const url = router.asPath
    if (url.indexOf('?') > -1) {
      let query = url.split('?')
      if (query.length > 0) {
        for (let i = 0; i < query.length; i++) {
          if (query[i].indexOf('=') > -1) {
            var keys = query[i].split('=')
            if (keys[0] === 'discount') {
              setDiscountFromURL(keys[1])
              break
            }
          }
        }
      }
    }
    if (url.indexOf('&') > -1) {
      let query = url.split('&')
      if (query.length > 0) {
        for (let i = 0; i < query.length; i++) {
          if (query[i].indexOf('=') > -1) {
            var keys = query[i].split('=')
            if (keys[0] === 'discount') {
              setDiscountFromURL(keys[1])
              break
            }
          }
        }
      }
    }
  }, [])

  function searchIcon() {
    return (
      <svg viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g
          id="Desktop"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            id="BLU---Search-v2---Field"
            transform="translate(-211.000000, -200.000000)"
            stroke="#003057"
            strokeWidth="2"
          >
            <g id="searchConfig-copy" transform="translate(212.000000, 201.000000)">
              <circle id="Oval" cx="10.6666667" cy="10.6666667" r="10.6666667"></circle>
              <line x1="24" y1="24" x2="18.2" y2="18.2" id="Path"></line>
            </g>
          </g>
        </g>
      </svg>
    )
  }

  function searchIconUpdated() {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="8.11111"
          cy="8.11111"
          r="7.11111"
          stroke="#003057"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 17.0001L13.1333 13.1334"
          stroke="#003057"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }

  const getTopPosition = () => {
    if (isStickyPromo && isBannerHidden !== false) {
      let promo = document.getElementById('dynamic-promo')
      if (promo) {
        return promo.clientHeight
      } else {
        return 50
      }
    } else {
      return 0
    }
  }

  return (
    <FocusTrap
      active={isMobileMenuOpen}
      focusTrapOptions={{ initialFocus: '#mobile-menu button, #mobile-menu a' }}
    >
      <div
        className={`l:hidden sticky z-10`}
        style={{ top: useTopPosition(isStickyPromo, hasLargerPromoBar) }}
      >
        <span
          className={'absolute mobileSearchOld z-10 h-20 w-20'}
          style={{ top: '50%' }}
          onClick={() => toggleSearchDropDown()}
        >
          {searchIconUpdated()}
        </span>
        <Header
          ref={headerRef}
          bgColorStyle={{ ...bgColorStyle }}
          toggle={() => toggleSearchDropDown()}
          isNoNavigation={isNoNavigation}
        />

        <Menu isMobileMenuOpen={isMobileMenuOpen} headerRect={headerRect}>
          <>
            <PrimaryMenuV2 items={mobilePrimaryMenuV2} isGetSubscriptionPage={isGetSubscriptionPage} />
            <SecondaryMenuV2 items={mobileSecondaryMenuV2} />
            <MobileFixedMenu items={mobileFixedMenu} />
          </>
        </Menu>
        {searchDropDown && (
          <SearchDropDownComponent
            closeSearchBar={() => {
              setSearchDropDown(false)
              window?.Solvvy?.show()
            }}
            toggle={() => toggleSearchDropDown()}
            globals={globals}
            searchConfig={searchConfig}
            searchDropDown={searchDropDown}
          />
        )}
      </div>
    </FocusTrap>
  )
}
