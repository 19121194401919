import React from 'react';

const CartSubscriptionSubText = ({ globals, isEligibleForDiscount, promoConfig }) => {
  return (
    <>
      {globals.cartSubscriptionToggle &&
        globals.cartSubscriptionToggle.cartSubscriptionSubText && (
          isEligibleForDiscount ? (
            <p className="text-navy-blue-muted text-11 leading-13 l:text-13 font-normal">
              {promoConfig?.cartSubscriptionToggleNotation}
            </p>
          ) : (
            <p className="text-navy-blue-muted text-11 leading-13 l:text-13 font-normal">
              {globals.cartSubscriptionToggle.cartSubscriptionSubText}
            </p>
          )
      )}
    </>
  );
};

export default CartSubscriptionSubText;