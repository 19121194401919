import useCartPrices from './useCartPrices'

interface Config {
  promoType?: string;
}

export default function useGetAmountForCartProgress(config: Config = {}) {
  const {
    effectiveCartSubtotalForPromoTriggerThresholdCalculation,
    isPromoActivated,
    cartSubtotalIncludingSubscriptionDiscountsWithoutPromo,
  } = useCartPrices() || {}

  return isPromoActivated && config?.promoType !== "FREE_GIFT"
    ? cartSubtotalIncludingSubscriptionDiscountsWithoutPromo
    : effectiveCartSubtotalForPromoTriggerThresholdCalculation
}
