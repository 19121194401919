export const getSubDiscountedPrice = (
  originalPrice: number,
  product: any,
  discountObject: any,
  applyDiscount = false
) => {
  const typeVariant = product?.type === 'productVariant';
  const isOneTimeTypeVariant = product?.sellingPlans?.[0]?.type == 'onetime'
  const useSubDiscount = (product?.sellingPlans?.[0]?.discountAmount || product?.subscription?.discountAmount) && (product?.sellingPlans?.[0]?.discountType == 'percentage' || product?.subscription?.discountType == 'percentage') && product?.sellingPlans?.length;
  const subDiscountPercentage = (useSubDiscount && (product?.sellingPlans?.[0]?.discountAmount || product?.subscription?.discountAmount)) || 0
  const discountedPrice = (typeVariant && isOneTimeTypeVariant) ? Number(product.price) :
    +originalPrice - ((applyDiscount ? (+discountObject?.subscriptionDiscountPct || subDiscountPercentage) : subDiscountPercentage) * +originalPrice) / 100
  return +discountedPrice.toFixed(2)
}

export const getOnetimeDiscountedPrice = (
  originalPrice: number,
  product: any,
  discountObject: any,
  applyDiscount = false,
  countryCode = ''
) => {

  if (!discountObject || !applyDiscount) return originalPrice
  if (!discountObject?.oneTimeDiscountPct) return originalPrice

  let discountedPrice: number

  if (countryCode && countryCode === 'US') {
    discountedPrice =
      +originalPrice - (+discountObject?.oneTimeDiscountPct * +originalPrice) / 100
  } else {
    discountedPrice = +originalPrice
  }
  return +discountedPrice.toFixed(2)
}

