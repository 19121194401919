import React, { useEffect } from 'react'
import { useAllProducts, useTieredPromotions } from 'context/globalStore'

const FetchAllProducts = ({ tieredPromo }) => {
  const { allProducts } = useAllProducts()
  const { setTieredPromotions } = useTieredPromotions()

  useEffect(() => {
    if (tieredPromo && tieredPromo.tieredPromotionEnable) {
      setTieredPromotions(tieredPromo.promotionList)
    }
  }, [tieredPromo])

  return <></>
}

export default FetchAllProducts
