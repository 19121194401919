import centsToPrice from 'lib/centsToPrice'
import { useStore } from 'context/globalStore'
import { useEffect, useState } from 'react'
import ProgressBar from 'components/ProgressBar'

export default function TieredShippingMeter({ countryGroups, subTotalAmount }) {
  const { store } = useStore()
  const totalCents = parseFloat(subTotalAmount) * 100
  const [thresholdIndex, setThresholdIndex] = useState(-1)
  const tiers = countryGroups?.find((x) => x.countryCode === store.countryCode)?.tiers

  useEffect(() => {
    for (let i = tiers?.length - 1; i >= 0; i--) {
      let thresholdPriceCents = tiers[i].thresholdPrice * 100

      if (totalCents >= thresholdPriceCents) {
        setThresholdIndex(i)
        break
      }

      setThresholdIndex(-1)
    }
  }, [subTotalAmount, tiers, totalCents])

  if (!tiers) return <div className="pb-20 s:pb-25"></div>

  const maxThreshold = tiers[tiers?.length - 1]?.thresholdPrice * 100
  const previousThresholdCents = tiers?.[thresholdIndex]?.thresholdPrice * 100 || 0
  const thresholdCents = tiers?.[thresholdIndex + 1]?.thresholdPrice * 100 || maxThreshold
  const rangeCents = thresholdCents - previousThresholdCents
  const remainderCents = thresholdCents - totalCents
  const slotSize = 1 / tiers?.length
  const prevSlot = slotSize * (thresholdIndex + 1)
  const percentage = rangeCents > 0
	  ? prevSlot + ((totalCents - previousThresholdCents) / rangeCents) * slotSize
	  : prevSlot + ((totalCents - previousThresholdCents) ) * slotSize;
  const progressBarTiers = tiers.map((x, index, _) => ({
    title: x.title,
    price: `$${x.thresholdPrice}`,
    percentage: slotSize * (index + 1),
  }))

  const remainderPrice = centsToPrice(remainderCents)
  const message = tiers?.[thresholdIndex + 1]?.messageTemplate?.split('{remainder}')
  const successMessage = tiers?.[thresholdIndex]?.successMessage
	
  if (percentage !== 0) {
    return (
      <div className="pt-20 pb-40 text-center">
        <div className="mb-25 s:mb-32 px-16">
          {thresholdIndex !== -1 && <div className="text-13 leading-18 s:leading-13">{successMessage}</div>}
          {message && thresholdIndex !== tiers.length - 1 && (
            <div className="text-13">
              {message[0]} <span className="font-bold">{remainderPrice}</span> {message[1]}
            </div>
          )}
        </div>
        <ProgressBar
          percentage={percentage > 1 ? 1 : percentage}
          thresholdIndex={thresholdIndex}
          tiers={progressBarTiers}
          className="h-4 s:h-7"
        />
      </div>
    )
  } else {
    return <div className="pb-20 s:pb-25"></div>
  }
}
