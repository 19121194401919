import centsToPrice from 'lib/centsToPrice'
import { useCartTotals, useIsFreeShippingUnlocked, useStore } from 'context/globalStore'
import { useEffect, useMemo, useState } from 'react'
import ProgressBar from 'components/ProgressBar'
import { usePromoConfig } from 'hooks/usePromoConfig'

function processMessageTemplate(messageTemplate, amount) {
  if (!messageTemplate) return ''

  if (messageTemplate.includes('{remainder}')) return messageTemplate.replace('{remainder}', amount)

  let extraAmount = messageTemplate.match(/\d+/)[0]
  let totalAmount = (parseFloat(extraAmount) + parseFloat(amount.replace('$', '')))
    .toFixed(2)
    .toString()
    .replace('.00', '')

  messageTemplate = messageTemplate.replace(`{remainder + ${extraAmount}}`, `$${totalAmount}`)

  return messageTemplate
}

export default function CartFreeShippingMeter({ freeShippingMeters, subTotalAmount }) {
  const { store } = useStore()
  const { discountedTotal, hasDiscountedItems } = useCartTotals()
  const sortedShippingMeters = useMemo(() => {
    return freeShippingMeters.sort((a, b) => {
      return a.thresholdPrice - b.thresholdPrice
    })
  }, [freeShippingMeters])

  const thresholdCount = freeShippingMeters.length
  const price =
    !hasDiscountedItems && subTotalAmount < discountedTotal ? subTotalAmount : discountedTotal

  const [currentShippingMeterIndex, setCurrentShippingMeterIndex] = useState(0)

  const thresholdCents = useMemo(
    () => (sortedShippingMeters[currentShippingMeterIndex]?.thresholdPrice ?? 0) * 100,
    [currentShippingMeterIndex, sortedShippingMeters]
  )
  const messageTemplate = useMemo(
    () => sortedShippingMeters[currentShippingMeterIndex]?.messageTemplate,
    [currentShippingMeterIndex, sortedShippingMeters]
  )
  const successMessage = useMemo(
    () => sortedShippingMeters[currentShippingMeterIndex]?.successMessage,
    [currentShippingMeterIndex, sortedShippingMeters]
  )

  const totalCents = parseInt(price, 10) * 100
  const percentage = totalCents / thresholdCents
  const remainderCents = thresholdCents - totalCents
  const remainderPrice = centsToPrice(remainderCents)
  const message = processMessageTemplate(messageTemplate, remainderPrice)
  const promoConfig = usePromoConfig()
  const freeShippingUnlockedMessage = successMessage

  useEffect(() => {
    const newIndex = sortedShippingMeters.findIndex((meter) => {
      return meter.thresholdPrice * 100 > totalCents
    })
    if (newIndex === -1) {
      return setCurrentShippingMeterIndex(thresholdCount - 1)
    } else {
      setCurrentShippingMeterIndex(newIndex)
    }
  }, [sortedShippingMeters, thresholdCount, totalCents])

  const { setIsFreeShippingUnlocked } = useIsFreeShippingUnlocked()

  const isFreeShippingUnlocked = useMemo(() => {
    return remainderCents <= 0
  }, [remainderCents])

  useEffect(() => {
    setIsFreeShippingUnlocked(isFreeShippingUnlocked)
  }, [isFreeShippingUnlocked])

  if (store.countryCode === 'US' && percentage !== 0) {
    return (
      <div className="pt-15 s:pt-20 pb-20 s:pb-25 flex flex-col items-center text-center">
        <div className="text-13 mb-10">
          {isFreeShippingUnlocked ? freeShippingUnlockedMessage : message}
        </div>
        <ProgressBar
          percentage={percentage <= 1 ? percentage : 1}
          className="h-7 s:h-10 w-2/3"
          color={promoConfig?.promoTheme?.primaryColor?.hex}
        />
      </div>
    )
  } else {
    // Render spacer div when free shipping meter is hidden for users outside US
    return <div className="pb-20 s:pb-25"></div>
  }
}
