export default function CheckmarkIcon() {
  return (
    <svg width="10px" height="10px" viewBox="0 0 10 10">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g transform="translate(-1467.000000, -50.000000)" stroke="#003057" strokeWidth="2">
          <g transform="translate(1296.000000, 32.000000)">
            <polyline points="172 23.920964 175.119559 26.2035217 180 19"></polyline>
          </g>
        </g>
      </g>
    </svg>
  )
}