import generalContentSerializer from '../../serializers/generalContentSerializer'
import blocksToHtml from '@sanity/block-content-to-html'

export function getStrikeoutPrice(price, extraClasses = 'mr-2 s:mr-4') {
  return <span className={`line-through opacity-50 font-normal ${extraClasses}`} style={{color:"#4D6E89"}}>{price}</span>
}
const h = blocksToHtml.h

const serializers = {
  marks: {
    color: (props) => h('span', { style: `color: ${props.mark.hex}` }, props.children),
  },
}

export const getOptionTitles = (subSettings, product) => {
  let subscriptionOptionTitle = subSettings?.subscriptionOptionTitle
  if (typeof subscriptionOptionTitle != "string") {
    subscriptionOptionTitle = subSettings?.subscriptionOptionTitle?.filter((item) => item?._key);
  }
  if (!subscriptionOptionTitle) {
    return {
      subscriptionOptionTitle: "",
      oneTimeOptionTitle: subSettings?.oneTimeOptionTitle
    }
  }


  let oneTimeOptionTitle = subSettings?.oneTimeOptionTitle

  const collectionWithSubOptionTitle = product?.collections?.find(
    (collection) => !!collection.subscriptionOptionTitle
  )
  const collectionWithOneTimeOptionTitle = product?.collections?.find(
    (collection) => !!collection.oneTimeOptionTitle
  )
  if (collectionWithSubOptionTitle) {
    subscriptionOptionTitle = collectionWithSubOptionTitle?.subscriptionOptionTitle
  }

  if (collectionWithOneTimeOptionTitle) {
    oneTimeOptionTitle = collectionWithOneTimeOptionTitle.oneTimeOptionTitle
  }

  if (product.subscriptionOptionTitle) {
    subscriptionOptionTitle = product.subscriptionOptionTitle
  }

  if (product.oneTimeOptionTitle) {
    oneTimeOptionTitle = product.oneTimeOptionTitle
  }

  return {
    subscriptionOptionTitle: (typeof subscriptionOptionTitle == 'string') ? subscriptionOptionTitle : (
      blocksToHtml({
            blocks: subSettings?.subscriptionOptionTitle,
            serializers: serializers,
      })
    ),
    oneTimeOptionTitle,
  }
  // refactor this to return early
}

export const getCartSubscriptionTitle = (globals, product) => {
  if (product?.cartSubscriptionTitle) {
    return {
      cartSubscriptionTitle: product.cartSubscriptionTitle,
      serializeOutput: typeof product.cartSubscriptionTitle !== 'string',
    }
  }

  const collectionWithCartSubscriptionTitle = product?.collections?.find(
    (collection) => !!collection.cartSubscriptionTitle
  )

  if (collectionWithCartSubscriptionTitle) {
    return {
      cartSubscriptionTitle: collectionWithCartSubscriptionTitle.cartSubscriptionTitle,
      serializeOutput: typeof collectionWithCartSubscriptionTitle.cartSubscriptionTitle !== 'string',
    }
  }

  return {
    cartSubscriptionTitle: globals?.cartSubscriptionToggle?.cartSubscriptionTitle,
    serializeOutput: true,
  }
}

export const removeOption = (title, optionPosition) => {
  return title
    ?.split(' / ')
    .map((option, i) => (i + 1 === optionPosition ? undefined : option))
    .filter((x) => !!x)
    .join(' / ')
}

export const getWhatsIncludedItems = (product, activeVariant) => {
  const quantityOption = product.options.find(option => ['pack size', 'count', 'recurring quantity', 'refill quantity', 'quantity'].includes(option?.name?.toLowerCase()))
  const qtyVariantWithWhatsIncluded = product.variants.find(variant => (variant?.[`option${quantityOption?.position}`] == activeVariant?.[`option${quantityOption?.position}`] && variant?.whatsIncluded?.items?.length));
  
  return activeVariant?.whatsIncluded?.items || qtyVariantWithWhatsIncluded?.whatsIncluded?.items || product?.drawers?.items?.find((item) => item._type == 'whatsIncluded')?.items || []
}
