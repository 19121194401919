import React, { createContext, useContext, useState, useEffect } from 'react'

const ConfigContext = createContext({
  data: {},
  setData: () => {},
})

export const useData = () => {
  const [data, setData] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`/api/edge-config`).catch((e) => console.error('/api/edge-config', e))
      if (response) {
        const data = await response.json()
        setData(data)
      }
    }

    fetchData()
  }, [])

  return { data, setData }
}

export const ConfigContextProvider = ({ children }) => {
  const dataContextValue = useData()

  return <ConfigContext.Provider value={dataContextValue}>{children}</ConfigContext.Provider>
}

export const useEdgeConfigContext = () => {
  const value = useContext(ConfigContext)

  if (!value) {
    throw new Error('useEdgeConfigContext must be used within a ConfigContextProvider')
  }

  return value.data
}
