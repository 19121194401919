import React from 'react'
import styles from './Loader.module.css'

const Loader = ({
  size = '',
  header = 'Loading',
  info = "Sit tight, We're loading your content",
}) => {
  return (
    <div
      className={`${styles.container} absolute z-10 flex flex-col items-center justify-center w-full h-full`}
    >
      <div className={styles.roller}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <h2 className={'z-20 text-20 mb-8 l:text-32 leading-26 l:leading-41.6 font-medium mt-24'}>
        {header}
      </h2>
      <p
        className={
          'z-20 text-center text-15 l:text-20 leading-21.3 l:leading-28.4 font-normal w-200 l:w-100p'
        }
      >
        {info}
      </p>
    </div>
  )
}

export default Loader
