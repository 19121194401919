import cx from 'classnames'

export default function Skeleton({ children, className }) {
  return (
    <div className={cx('relative overflow-hidden', className)}>
      <div className="opacity-25">{children}</div>
      <div className="gradient-anim absolute inset-0">
        <div className="w-1/2 h-full bg-gradient-to-r from-transparent-white via-white to-transparent-white opacity-50"></div>
      </div>
    </div>
  )
}
