import cx from 'classnames'
import getColorStyle from 'lib/getColorStyle'
import withErrorBoundary from 'components/ErrorBoundary/withErrorBoundary'

function ProductBadge({ background, textColor, children, size = 'large', disabled = false }) {
  if (disabled) {
    return null
  }
  const getTextColor = textColor ? getColorStyle(textColor) : '#003057';

  return (
    <div
      className={cx('inline-block align-top rounded-pill leading-100 font-medium', {
        'py-4 px-10 text-16': size === 'large',
        'pt-4 pb-3 px-3 s:px-7 text-13 mobileBadgeSize': size === 'medium',
        'pt-4 pb-3 px-4 text-10': size === 'small',
        'py-4 px-10 text-11 m:text-16': size === 'mobileAdapt'
      })}
      style={{ background: getColorStyle(background), color: getTextColor }}
    >
      {children}
    </div>
  )
}

export default withErrorBoundary(ProductBadge)

