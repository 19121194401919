import cx from 'classnames'
import Offset from 'components/Offset'

const buildObject = (val) => {
  if (typeof val !== 'object') return val ? { all: val } : val
  else return val
}

const buildClasses = (obj, str) => {
  const _obj = buildObject(obj)
  let strClass = ''
  let config = Object.keys(_obj).reduce((config, port) => {
    const value = _obj[port]
    if (port === 'all') config[`${str}-${value}`] = true
    else config[`${port}:${str}-${value}`] = true
    // return port === 'all' ? `${str}-${value}` : `${port}:${str}-${value}`
    return config
  }, {})
  for (let val in config) {
    strClass = `${strClass} ${val}`
  }
  return strClass
}

export default function Col({ children, colspan = 1, offset = 0, ...props }) {
  const _colspan = buildClasses(colspan, 'col-span')

  return (
    <>
      <Offset offset={offset} />
      <div className={`col ${props.className} ${_colspan}`}>{children}</div>
    </>
  )
}
