import Link from 'components/Link'
import UserIcon from 'public/icons/user.svg'
import * as analytics from 'lib/analytics'
import { useEffect, useState } from 'react'

export default function MenuFooter({ item }) {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  useEffect(() => {
    const getUserData = getCookie('userData')
    setIsUserLoggedIn(getUserData ? true : false)
  }, [])
  return (
    <div className="fixed inset-x-0 bottom-0 h-50 flex items-center bg-alice-blue shadow-bottom-bar z-1"
         style={{ 'box-shadow': '0px 0px 6px 0px #00000033' }}>
      <nav className="w-full" aria-labelledby="mobile-fixed-menu" role="navigation">
        <span id="mobile-fixed-menu" className="sr-only">
          Secondary navigation menu
        </span>
        <ul className="h-full flex justify-evenly">
          <li key={item?._key}>
            <Link
              className="text-16 flex items-center font-medium leading-100 rounded px-5 focus:outline-none focus-visible:bg-pattens-blue transition"
              link={{ ...item, url: isUserLoggedIn ? '/account' : item.url }}
              onClick={() => analytics.trackNavClick( item?.title )}
            >
              <UserIcon className={'w-26, h-26 mr-8'}/>
              <span className="">{item?.title}</span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}
