import React, { useState, useEffect, FC } from 'react'

export const CUSTOM_EVENT_ERROR_ALERT = 'customEventErrorAlert'

const ErrorAlert: FC = () => {
  const [message, setMessage] = useState('')

  const handleCustomEvent = (e) => {
    const errMsg = e?.detail?.message
    if (typeof errMsg === 'string') {
      setMessage(errMsg)
    }
  }

  useEffect(() => {
    window.addEventListener(CUSTOM_EVENT_ERROR_ALERT, handleCustomEvent)

    return () => {
      window.removeEventListener(CUSTOM_EVENT_ERROR_ALERT, handleCustomEvent)
    }
  }, [])

  if (!message) {
    return null
  }

  return (
    <div className="fixed bottom-24 left-24 z-30">
      <button
        onClick={() => setMessage('')}
        type="button"
        style={{ borderRadius: '50%', top: '12px', left: '-5px' }}
        className="relative bg-red text-white rounded-full p-6 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
      >
        <span className="sr-only">Close menu</span>
        <svg
          className="h-8 w-8"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <div
        className="w-400 flex p-24 text-16 shadow-overlay border-b border-red bg-white rounded items-center"
        style={{ width: '450px', borderBottomWidth: '4px' }}
      >
        <div>{message}</div>
        <a href='' className="btn text-red whitespace-nowrap">Try again</a>
      </div>
    </div>
  )
}

export default ErrorAlert
