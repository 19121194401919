import { imageBuilder } from 'lib/sanity'
import cx from 'classnames'
import React, { useRef, useState } from 'react'
import { useIntersection } from 'use-intersection'
import Head from 'next/head'
import { SanityImage } from 'types/sanity-image'
import { twMerge } from 'tailwind-merge'

type Props = {
  image: SanityImage
  width?: number
  height?: number
  fill?: boolean
  fit?: 'cover' | 'contain'
  position?: 'top' | 'bottom' | 'left' | 'right' | 'center' | string
  placeholder?: boolean
  thumbnail?: boolean
  sizes?: string
  overrides?: (x: any) => any
  preload?: boolean
  className?: string
  lazyLoadEffect?: boolean
  noTranforms?: boolean
  alt?: string
}

const Image = ({
  image,
  alt,
  width,
  height,
  fill = false,
  fit = 'cover',
  position = 'center',
  placeholder = true,
  thumbnail = false,
  sizes = '100vw',
  overrides = (x) => x,
  preload = false,
  className = '',
  lazyLoadEffect = false,
  noTranforms = false,
}: Props) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)
  const isIntersecting = useIntersection(ref, {
    once: true,
    threshold: 0.1,
    rootMargin: '50% 50%',
  })

  if (!image) return null

  const quality = thumbnail ? 25 : 80
  const aspect =
    typeof width === 'number' && typeof height === 'number'
      ? (height / width) * 100
      : 100 / image.asset.metadata.dimensions.aspectRatio

  const src = buildSrc(image, { width, height, quality, overrides, noTranforms }).url()
  const srcSet = buildSrcSet(image, {
    sizes: [400, 650, 768, 1024, 1280, 1536],
    aspect,
    quality,
    overrides,
    noTranforms,
  })

  const getSrc = () => (lazyLoadEffect ? (isIntersecting ? src : null) : src)
  const getSrcSet = () => (lazyLoadEffect ? (isIntersecting ? srcSet : null) : srcSet)
  const getFilter = () => (lazyLoadEffect ? { filter: 'blur(4px)' } : {})
  const getImageClassName = () =>
    lazyLoadEffect
      ? cx(baseImageClasses, 'opacity-0 transition', { 'opacity-100': isLoaded })
      : 'w-full h-full object-cover object-center'

  function handleLoad() {
    requestAnimationFrame(() => {
      setIsLoaded(true)
    })
  }

  const baseImageClasses = cx('w-full h-full', {
    'object-cover': fit === 'cover',
    'object-contain': fit === 'contain',
    'object-top': position === 'top',
    'object-bottom': position === 'bottom',
    'object-left': position === 'left',
    'object-right': position === 'right',
    'object-center': position === 'center',
  })

  return (
    <>
      {preload && src ? (
        <Head key={`image-${src}`}>
          <link rel="preload" as="image" href={src} imageSrcSet={srcSet} />
        </Head>
      ) : null}
      <div
        ref={ref}
        className={twMerge(
          cx(
            'relative z-1 overflow-hidden',
            {
              'w-full h-0': !fill,
              'w-full h-full': fill,
            },
            className
          )
        )}
        style={
          !fill
            ? {
                paddingTop: `${aspect}%`,
              }
            : {}
        }
      >
        {placeholder ? (
          <div className="absolute inset-0 transform scale-105" style={getFilter()}>
            <img
              width={image?.asset?.metadata?.dimensions?.width}
              height={image?.asset?.metadata?.dimensions?.height}
              className={baseImageClasses}
              src={image?.asset?.metadata?.lqip}
              alt={image?.asset?.altText || 'Blueland: Eco-Friendly Cleaning Products'}
            />
          </div>
        ) : null}
        <div className="absolute inset-0">
          <img
            alt={image?.asset?.altText || 'Blueland: Eco-Friendly Cleaning Products'}
            width={image?.asset?.metadata?.dimensions?.width}
            height={image?.asset?.metadata?.dimensions?.height}
            src={getSrc()!}
            srcSet={getSrcSet()}
            sizes={sizes}
            onLoad={handleLoad}
            className={getImageClassName()}
            style={
              position.includes('%')
                ? {
                    objectPosition: position,
                  }
                : {}
            }
          />
        </div>
      </div>
    </>
  )
}

export default Image

function buildSrc(image, { width, height, quality = 75, overrides, noTranforms }) {
  if (noTranforms) return imageBuilder.image(image)

  let tmp = imageBuilder.image(image).width(width).auto('format').fit('max').quality(quality)

  if (height) {
    tmp = tmp.height(height)
  }

  tmp = overrides(tmp)

  return tmp
}

export function buildSrcSet(image, { sizes, aspect, quality, overrides, noTranforms }) {
  const srcSetparts = sizes.map((width) => {
    let tmp = buildSrc(image, {
      width,
      height: Math.round((width * aspect) / 100),
      quality,
      overrides,
      noTranforms,
    })
    return `${tmp.url()} ${width}w`
  })

  return srcSetparts.join(',')
}
