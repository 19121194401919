import { Keys, Sizes } from 'lib/constants'
import { useStore } from 'context/globalStore'
import cx from 'classnames'

export default function QuantitySelector({ value, onChange, size = Sizes.LARGE }) {
  const { store, setStore } = useStore()

  function handleDecrement() {
    setStore((prevState) => ({
      ...prevState,
      isUpdating: true,
    }))
    const parsedValue = parseInt(value, 10)
    onChange(parsedValue - 1)
  }

  function handleIncrement() {
    setStore((prevState) => ({
      ...prevState,
      isUpdating: true,
    }))
    onChange(parseInt(value, 10) + 1)
  }

  function handleKeyDown(ev) {
    let flag = false

    switch (ev.keyCode) {
      case Keys.UP: {
        handleIncrement()
        flag = true
        break
      }

      case Keys.DOWN: {
        handleDecrement()
        flag = true
        break
      }

      default:
        break
    }

    if (flag) {
      ev.stopPropagation()
      ev.preventDefault()
    }
  }

  return (
    <div
      className={cx(
        'relative flex items-center justify-center flex-shrink-0 border border-navy-blue-muted rounded-pill h-28',
        { 'w-67': size === Sizes.SMALL, 'w-90 2xs:w-100': size === Sizes.LARGE },
      )}
    >
      <span id="qty-label" className="sr-only"></span>
      <button
        aria-labelledby="qty-dec-label"
        type="button"
        className={cx(
          'absolute left-0 h-full pr-5 rounded-pill focus:outline-none focus-visible:ring-2 focus-visible:ring-royal-blue',
          {
            'pl-15': size === Sizes.LARGE,
            'pl-10': size === Sizes.SMALL,
            'pointer-events-none': store.isUpdating,
          },
        )}
        onClick={handleDecrement}
      >
        <span id="qty-dec-label" className="sr-only">
          Decrease quantity
        </span>
        <div
          className={cx({
            'w-12': size === Sizes.LARGE,
            'w-9': size === Sizes.SMALL,
          })}
        >
          <MinusIcon size={size} />
        </div>
      </button>
      <div
        role="spinbutton"
        tabIndex="0"
        aria-live="polite"
        onKeyDown={handleKeyDown}
        aria-labelledby="qty-label"
        className={cx(
          'text-center font-medium leading-100 h-full flex items-center rounded focus:outline-none focus-visible:ring-2 focus-visible:ring-royal-blue',
          {
            'text-16 l:text-18 pt-1 s:pt-2 px-10': size === Sizes.LARGE,
            'text-12 s:text-13 pt-5 pb-4 px-5': size === Sizes.SMALL,
          },
        )}
      >
        {value}
      </div>
      <button
        aria-labelledby="qty-inc-label"
        type="button"
        className={cx(
          'absolute right-0 h-full pl-5 rounded-pill focus:outline-none focus-visible:ring-2 focus-visible:ring-royal-blue',
          {
            'pr-15': size === Sizes.LARGE,
            'pr-10': size === Sizes.SMALL,
            'pointer-events-none': store.isUpdating,
          },
        )}
        onClick={handleIncrement}
      >
        <span id="qty-inc-label" className="sr-only">
          Increase quantity
        </span>
        <div
          className={cx({
            'w-12': size === Sizes.LARGE,
            'w-9': size === Sizes.SMALL,
          })}
        >
          <PlusIcon size={size} />
        </div>
      </button>
    </div>
  )
}

function MinusIcon({ size }) {
  return size === Sizes.LARGE ? (
    <svg viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 2C0.447715 2 2.41416e-08 1.55228 0 1C-2.41407e-08 0.447716 0.447715 4.61255e-07 1 4.37114e-07L11 0C11.5523 -2.41412e-08 12 0.447715 12 1C12 1.55228 11.5523 2 11 2L1 2Z"
        className="fill-current"
      />
    </svg>
  ) : Sizes.SMALL ? (
    <svg viewBox="0 0 9 1" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 1C0.223858 1 1.20715e-08 0.776143 0 0.5C-1.20697e-08 0.223858 0.223858 3.6176e-07 0.5 3.4969e-07L8.5 0C8.77614 -1.20705e-08 9 0.223858 9 0.5C9 0.776142 8.77614 1 8.5 1L0.5 1Z"
        className="fill-current"
      />
    </svg>
  ) : null
}

function PlusIcon({ size }) {
  return size === Sizes.LARGE ? (
    <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 7C0.447715 7 2.41416e-08 6.55228 0 6C-2.41406e-08 5.44772 0.447715 5 1 5L11 5C11.5523 5 12 5.44771 12 6C12 6.55228 11.5523 7 11 7L1 7Z"
        className="fill-current"
      />
      <path
        d="M5 1C5 0.447716 5.44772 -9.65625e-08 6 0C6.55229 9.65634e-08 7 0.447716 7 1L7 11C7 11.5523 6.55229 12 6 12C5.44772 12 5 11.5523 5 11L5 1Z"
        className="fill-current"
      />
    </svg>
  ) : size === Sizes.SMALL ? (
    <svg viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 4.99995C0.223858 4.99995 1.20705e-08 4.7761 0 4.49995C-1.20705e-08 4.22381 0.223858 3.99995 0.5 3.99995L8.5 3.99995C8.77614 3.99995 9 4.22381 9 4.49995C9 4.7761 8.77614 4.99995 8.5 4.99995L0.5 4.99995Z"
        className="fill-current"
      />
      <path
        d="M3.99951 0.5C3.99951 0.223858 4.22337 -4.82829e-08 4.49951 0C4.77566 4.82829e-08 4.99951 0.223858 4.99951 0.5L4.99951 8.5C4.99951 8.77614 4.77565 9 4.49951 9C4.22337 9 3.99951 8.77614 3.99951 8.5L3.99951 0.5Z"
        className="fill-current"
      />
    </svg>
  ) : null
}
