export const buildSwatchCategories = (swatchItems: any[], classicOnly = false) => {
  const categories = {}

  swatchItems?.forEach((item) => {
    const category = item.swatchCategory || 'Classic'
    if (classicOnly && category !== 'Classic') return

    if (!categories[category]) {
      categories[category] = []
    }

    categories[category].push(item)
  })

  return categories
}
