import { useState, useEffect } from 'react'
import { productTestRuleset } from "lib/constants"
import useConvert from './useConvert'

export default function useABVariation(campaign) {
  return useConvert(campaign)
}

export const useProductTestingFilter = (campaignVariationPair, products, dependencies = []) => {
  const [hiddenProducts, setHiddenProducts] = useState([])
  const [shownProducts, setShownProducts] = useState([])

  useEffect(() => {
    let hide = []
    let show = []

    for (let [key, value] of Object.entries(campaignVariationPair)) {
      // Default to variation 'a' if null
      hide = [...hide, ...(productTestRuleset?.[key]?.[value ?? 'a']?.[0] ?? [])]
      show = [...show, ...(productTestRuleset?.[key]?.[value ?? 'a']?.[1] ?? [])]
    }
    
    setHiddenProducts(hide)
    setShownProducts(show)

  }, [...dependencies])

  // Select Non-test products and test products that are included in the ``show`` list
  let filteredProducts = products?.filter(x => !x.isTestProduct || (x.isTestProduct && shownProducts.includes(x._id)))
  // Select products that do not appear in the ``hide`` list
  filteredProducts = filteredProducts?.filter(x => !hiddenProducts.includes(x._id)) ?? []

  return { filteredProducts }
}