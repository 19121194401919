import Link from 'components/Link'
import cx from 'classnames'
import * as analytics from 'lib/analytics'

export default function MobileMenuItemV2({ link, children, menuType = 1, seperator }) {
  return (
    <li
      className={cx(
        'first:mt-0 border-pattens-blue mt-16 last:border-b'
      )}
    >
      <Link
        link={link}
        className={cx('block text-16 leading-22 font-medium outline-none', {
          'text-16 leading-22 font-medium': menuType === 1,
          'text-16 s:text-18 leading-22': menuType === 2,
        })}
        onClick={() => analytics.trackNavClick(children)}
      >
        {children}
      </Link>
      {seperator && <span className="block my-25 -mx-15 border-b border-pattens-blue"></span>}
    </li>
  )
}
