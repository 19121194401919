import getColorStyle from 'lib/getColorStyle'
import cx from 'classnames'
import { formatNumberAsPrice } from 'lib/formatPrice'
import CollectionProduct from 'components/CollectionProduct'

export const getLowestPriceVariant = (variants) => {
  const variant = variants?.find((v) => {
    const isSubSellingPlan = v?.sellingPlans?.find((vp) => vp?.type === 'subscription')
    if (isSubSellingPlan) {
      return !v.disableForSubscription
    } else {
      return !v.disableForOneTime
    }
  })
  return variant?.price?.amount || variant?.price
}

export const getProductLowestPrice = (product) => {
  let lowestPrice = getLowestPriceVariant(product?.variants)
  let hasMultiplePrices = false
  product?.variants?.forEach((variant) => {
    const isSubSellingPlan = variant?.sellingPlans?.find((vp) => vp?.type === 'subscription')
    const variantPrice = variant?.price?.amount || variant.price
    if (variantPrice !== lowestPrice) {
      hasMultiplePrices = true
    }
    if (
      (+variantPrice < +lowestPrice &&
        (isSubSellingPlan ? !variant?.disableForSubscription : !variant.disableForOneTime)) ||
      !lowestPrice
    ) {
      lowestPrice = variantPrice
    }
  })

  const price = lowestPrice ?? product.price
  // return `${formatNumberAsPrice(+price)}${hasMultiplePrices ? '+': ''}`
  return price
}

export default function ProductGrid({ module, globals, mode }) {
  const { headline, products, background } = module

  return (
    <div
      className="product-grid-upsell m:flex items-center py-24 px-16 l:px-40 l:py-32"
      style={{ background: getColorStyle(background) }}
    >
      <div className="mb-20">
        <div className="">
          {headline ? (
            <h2 className="text-22 m:text-16 l:text-22 w-fit mx-auto font-medium whitespace-nowrap leading-130">
              {headline}
              <span className="hidden m:inline">:</span>
            </h2>
          ) : null}
        </div>
      </div>
      <div className="relative w-full">
        <div className="">
          <div className="flex gap-y-24 flex-wrap m:flex-nowrap">
            {products?.map((product, i) => {
              return (
                <div
                  key={product._id}
                  className={cx('relative w-1/2 m:w-full', {
                    'pr-7 m:pl-9 l:pl-12 l:pr-12': i % 2 == 0,
                    'pl-7 m:pr-9 l:pl-12 l:pr-12': i % 2 != 0,
                  })}
                >
                  <CollectionProduct
                    key={product.id}
                    bottomPrice={false}
                    starRatingStyle={0}
                    starRatingColor={undefined}
                    inlineAddingState={true}
                    withClass={false}
                    imageHeight={undefined}
                    imageWidth={undefined}
                    as="div"
                    product={product}
                    collection={{ title: 'my collection' }}
                    index={i}
                    enableQuickAdd={true}
                    buttonClass=""
                    mobileButton={{
                      variant: 'contained',
                      size: 'large',
                    }}
                    mode={mode || 'normal'}
                    // showTitleAndPriceRow={false}
                    showProductBadges={false}
                    showQuickAddPrice={false}
                    // showUpwardsPrice={false}
                    // enableProductLink={true}
                    priceClass={'text-navy-blue-muted font-light block space-x-6'}
                    titleClass={
                      'text-13 leading-16 l:text-16 xl:flex xl:justify-between xl:text-left text-center font-medium transition group-hover:text-royal-blue'
                    }
                    atcOptionClass="relative group-btn font-medium text-14 l:text-18 inline-block px-10 s:px-10 l:px-15 py-8 first:border-l-0 border border-l-1 border-r-0 border-t-0 border-b-0 border-navy-blue pointer-events-auto z-10"
                    globals={globals}
                    showQuantitySelector={false}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
{
  /* <div
                      key={product._id}
                      className={cx("relative w-1/2 m:w-full", {
                        'pr-7 m:pl-9 l:pl-12 l:pr-12': i%2 == 0,
                        's:pl-7 m:pr-9 l:pl-12 l:pr-12': i%2 != 0,
                      })}
                    >
                      <Link
                        className="group"
                        link={product.link || {_type: "internalLink", reference: {_type: "product", slug: product.slug}} }
                        onClick={() => analytics.trackPdpClick('CrossSell')}
                      >
                        <div className="rounded overflow-hidden">
                          <Image
                            className="transition duration-1000 group-hover:transform-gpu group-hover:scale-105"
                            image={product?.thumbnailImage ?? product?.shopifyImage ?? null}
                            width={300}
                            height={300}
                            sizes={`50vw, (min-width: ${screens.s}) 33vw, (min-width: ${screens.l}) 20vw`}
                            alt='cross-sell'
                          />
                        </div>
                        <h3 className="text-13 l:text-16 xl:flex xl:justify-between xl:text-left items-center text-center font-medium mt-10 transition group-hover:text-royal-blue">
                          <div className='leading-16'>{product.title}</div> 
                          <div className='text-navy-blue-muted font-light'>{getProductPrice(product)}</div> 
                        </h3>
                      </Link>
                    </div> */
}
