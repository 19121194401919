import useCarousel from 'hooks/useCarousel'
import ChevronButton from 'components/ChevronButton'
import getColorStyle from 'lib/getColorStyle'
import * as analytics from 'lib/analytics'
import cx from 'classnames'
import CollectionProduct from 'components/CollectionProduct'
import CarouselPagination from 'components/CarouselPagination'
import { screens } from 'lib/constants'
import useMedia from 'use-media'
import ProductGrid from './ProductGrid'
import ProductCarouselGridScroll from './ProductCarouselGridScroll'

export default function AdvProductCarousel({ module, pageContext = {} }) {
  const isScreenSmall = useMedia({ maxWidth: screens.s })
  const {
    headline,
    headlinePosition,
    headlineSizeClasses = 'text-28 l:text-40',
    products,
    background,
    imageHeight,
    imageWidth,
    mode = 'normal',
    titleClass,
    enableQuickAdd = true,
    bottomPrice = false,
    priceClass,
    scrollable,
    useScrollGrid,
    showQuickAddPrice,
  } = module
  const r = background?.color?.rgb?.r ?? 255
  const g = background?.color?.rgb?.g ?? 255
  const b = background?.color?.rgb?.b ?? 255
  const YOU_MAY_ALSO_LIKE = 'youMayAlsoLike';

  const { globals } = pageContext

  const {
    ref,
    prevProps,
    nextProps,
    init: emblaInit,
    pagiProps,
  } = useCarousel({
    callback: () => analytics.trackPdpClick('CrossSell'),
    containScroll: 'trimSnaps',
  })

  const isNotCarousel = () => {
    if (emblaInit && products) return isScreenSmall ? products.length < 2 : products.length < 4
  }

  return scrollable ? (
    !useScrollGrid ? (
      <ProductCarouselGridScroll module={module} globals={globals} mode={mode !== 'cartBestSellers' ? YOU_MAY_ALSO_LIKE : mode} />
    ) : (
      <div
        className="adv-product-carousel m:flex items-center pt-40 pb-40 flex-wrap"
        style={{ background: getColorStyle(background) }}
      >
        <div
          className={`px-10 ${
            mode === 'cartBestSellers' ? 'mb-20' : 'mb-40'
          }  flex-shrink-0 w-full`}
        >
          {headline ? (
            <h2
              className={`text-${headlinePosition ?? 'center'} ${headlineSizeClasses} font-medium`}
            >
              {headline}
            </h2>
          ) : null}
        </div>
        <div className={cx('relative w-full pl-10 l:px-32 overflow-hidden')}>
          <div className={cx('overflow-hidden')} ref={ref}>
            <div
              className={cx(`flex ${mode === 'cartBestSellers' ? '' : ''}`, {
                'justify-center': isNotCarousel(),
              })}
            >
              {products?.length
                ? products.map((product, i) => {
                    return (
                      <div
                        key={product._id}
                        className={`relative  ${
                          mode === 'cartBestSellers'
                            ? 'px-6 min-w-176 l:min-w-33p'
                            : 'px-12 min-w-70p s:min-w-33p m:min-w-55p l:min-w-33p'
                        }`}
                      >
                        <CollectionProduct
                          bottomPrice={bottomPrice ? bottomPrice : false}
                          starRatingStyle={0}
                          bestSellerClass={'w-full'}
                          starRatingColor="text-navy-blue"
                          inlineAddingState={true}
                          withClass={false}
                          ratingNumberTemplate="($count)"
                          imageHeight={imageHeight}
                          imageWidth={imageWidth}
                          as="div"
                          product={product}
                          collection={{ title: 'my collection' }}
                          index={i}
                          enableQuickAdd={enableQuickAdd ? enableQuickAdd : true}
                          showQuickAddPrice={showQuickAddPrice}
                          buttonClass="w-90p"
                          priceClass={
                            priceClass
                              ? priceClass
                              : 'text-18 l:text-20 l:font-medium text-navy-blue-muted space-x-10'
                          }
                          mobileButton={{
                            variant: 'contained',
                            size: 'large',
                          }}
                          mode={mode !== 'cartBestSellers' ? YOU_MAY_ALSO_LIKE : mode}
                          titleClass={
                            titleClass
                              ? titleClass
                              : 'flex justify-between text-18 l:text-20 leading-130 font-medium mb-7'
                          }
                          atcOptionClass="relative group-btn font-medium text-14 l:text-18 inline-block px-10 s:px-10 l:px-15 py-8 first:border-l-0 border border-l-1 border-r-0 border-t-0 border-b-0 border-navy-blue pointer-events-auto z-1"
                          globals={globals}
                          showQuantitySelector={false}
                        />
                      </div>
                    )
                  })
                : null}
            </div>
          </div>
          <div className="block l:hidden h-full w-24 s:w-40 l:w-60 xl:w-80 absolute top-0 right-0 z-1 bg-gradient-to-r from-transparent-white to-white transition pointer-events-none" />

          <div className={cx({ hidden: isNotCarousel() })}>
            <div
              className={cx(
                `h-full w-40 l:w-60 xl:w-80 absolute top-0 z-1 ${
                  mode !== 'cartBestSellers' ? 'left-0' : '-left-15'
                }`,
                {
                  hidden: prevProps.disabled,
                }
              )}
              style={{
                background: `linear-gradient(to right, rgba(${r}, ${g}, ${b}, 1), rgba(${r}, ${g}, ${b}, 0))`,
              }}
            ></div>
            <div
              className={cx(
                `absolute ${
                  mode !== 'cartBestSellers' ? 'left-0 s:left-10 xs:left-10' : 'left-0'
                } top-1/2 transform -translate-y-1/2 z-1 hidden l:block`
              )}
            >
              <ChevronButton
                direction="left"
                label="Previous slide"
                {...prevProps}
                className="bg-transparent border-none"
                chevronIconClassName="w-15"
              />
            </div>
            <div
              className={cx(
                `absolute top-1/2 transform -translate-y-1/2 z-1  hidden l:block ${
                  mode !== 'cartBestSellers' ? 'right-0 xs:right-10 s:right-10' : 'right-0'
                }`
              )}
            >
              <ChevronButton
                direction="right"
                label="Next slide"
                {...nextProps}
                className="bg-transparent border-none"
                chevronIconClassName="w-15"
              />
            </div>
          </div>
          {mode !== 'cartBestSellers' && (
            <div
              className={cx('s:hidden mt-50', {
                hidden: isNotCarousel(),
              })}
            >
              <CarouselPagination mode={mode} count={products?.length} {...pagiProps} />
            </div>
          )}
        </div>
      </div>
    )
  ) : (
    <ProductGrid module={module} globals={globals} mode={mode !== 'cartBestSellers' ? YOU_MAY_ALSO_LIKE : mode} />
  )
}
