import { forwardRef } from 'react'
import Burger from 'components/Navigation/NavigationOld/MobileOld/Burger'
import LogoLink from 'components/LogoLink'
import CartToggle from 'components/MobileCartToggle'

const MobileHeader = forwardRef((_, forwardedRef) => (
  <header
    id="mobile-nav"
    ref={forwardedRef}
    className="w-full h-55 shadow-top-bar relative z-1"
    style={_.bgColorStyle}
  >
    <div className="relative w-full h-full flex items-center justify-between">
      {!_?.isNoNavigation && <Burger />}
      <LogoLink />
      <CartToggle />
    </div>
  </header>
))

MobileHeader.displayName = 'MobileHeader'

export default MobileHeader
