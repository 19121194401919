import { useState, useEffect } from 'react'

export const convertAddToCartGoalId = 100030438;
export const convertCustomSelectorForATCClick = 'convert-custom-selector-for-atc-click'

export default function useConvert(campaign) {
  const [variation, setVariation] = useState(null)

  useEffect(() => {
    try {
      if (window.convert) {
        _conv_q = _conv_q || []
        _conv_q.push(['run', 'true'])
      }
    } catch (err) {
      console.log('Convert Crash Notice')
    }
  }, [])

  useEffect(() => {
    const convertCB = (window.convertCB = window.convertCB || {})
    const cb = convertCB[campaign]

    // if convert variation script execute before React
    const params = new URLSearchParams(window.location.search)
    const previewCampaignName = params.get('preview_convert_campaign_name')
    const previewCampaignVariation = params.get('preview_convert_variation')
    if (
      previewCampaignName &&
      previewCampaignVariation &&
      campaign == previewCampaignName &&
      variation !== previewCampaignVariation
    ) {
      return setVariation(previewCampaignVariation)
    }

    if (cb) return setVariation(cb())

    // if React execute before convert variation script
    convertCB[campaign] = (_var) => {
      setVariation(_var)
    }
  }, [campaign])

  if (variation) convertCB[campaign] = null
  return { variation }
}

export const triggerConvertEvent = (goalId) => {
  if (_conv_q) {
    _conv_q?.push(["triggerConversion", `${goalId}`]);
  }
}
