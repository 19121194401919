export default function SkipToContent() {
  return (
    <a
      name="maincontent"
      className="font-medium fixed bottom-full left-0 py-5 px-10 bg-white shadow-overlay rounded-br z-10 opacity-0 focus:opacity-100 focus:transform focus:translate-y-full"
      href="#maincontent"
    >
      Skip to Main Content
    </a>
  )
}
