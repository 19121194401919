import { buildSwatchCategories } from 'components/SubscriptionRefillsForm/helper'
import { getOptionSettingsData } from 'components/Product/Product.helper'
import ColorSwatchCategoriesA from '../../SubscriptionKitForm/ColorSwatchCategories/ColorSwatchCategories-A'
import cx from 'classnames'

export default function Swatch(product, position, swatchParam, activeOptionValueSettings, selectedOption, handleSwatchSelect, isOptionAvailable, activeVariant) {
  const swatch = swatchParam || product.plpSwatch || {}
  const { swatchItems, options: selectedOptionTitle } = swatch
  const multiLineArrangement = swatch.multiLineArrangement

  const swatchCategories = buildSwatchCategories(swatchItems)
  const containsMultipleCategories = Object.keys(swatchCategories).length > 1

  const valueSettings = activeOptionValueSettings?.find(
    (s) => s.forOption === `${swatch.options}:${selectedOption}`
  )

  const optionSettingsData = getOptionSettingsData(product);
  const optionSettings = optionSettingsData
  const settings = optionSettings?.find((s) => s.forOption === swatch.options)

  return (
    <div
      className={cx('gap-25 flex', {
        'l:whitespace-nowrap l:overflow-x-scroll no-scrollbar': !multiLineArrangement,
      })}
    >
      {Object.entries(swatchCategories).map(([category, items], i) => (
        <ColorSwatchCategoriesA
          key={category}
          index={i}
          multiLineArrangement={multiLineArrangement}
          category={containsMultipleCategories && category}
          items={items}
          position={position}
          handleSwatchSelect={handleSwatchSelect}
          isOptionAvailable={isOptionAvailable}
          selectedOption={selectedOption}
          activeVariant={activeVariant}
          settings={settings}
          plpSwatch={true}
        />
      ))}
      {(valueSettings?.description || valueSettings?.text) && (
        <div className="text-13 font-normal text-navy-blue-muted">{valueSettings?.description || valueSettings.text}</div>
      )}
    </div>
  )
}