import { useRef, useState, useEffect } from 'react';
import Link from 'components/Link';
import Badge from 'components/ProductBadge';
import * as analytics from 'lib/analytics';
import Gallery from 'components/Navigation/Gallery';
import MenuItem from 'components/Navigation/Mobile/MobileMenuItem';
import cx from 'classnames';
import Chevron from 'components/Icons/Chevron';
import { useRect } from '@reach/rect';
import ArrowIcon from 'public/icons/arrow.svg'

export default function VisualSubmenu(props) {
  const {
    gallery,
    primaryMenu,
    menuButton,
    item,
    closeSearchBar,
    isExpand = false,
    setIsExpand = () => {},
    isTablet,
  } = props;
  const [elementHeight, setElementHeight] = useState( 0 );
  const colSpan9Ref = useRef();
  const colSpan3Ref = useRef();
  const submenuRef = useRef();
  const colSpan9Rect = useRect( colSpan9Ref );
  const colSpan3Rect = useRect( colSpan3Ref );
  const closeTimeoutRef = useRef(null);
  const desktopPadding = 80;

  useEffect( () => {
    if ( colSpan9Rect && colSpan3Rect ) {
      const colSpan9Height = colSpan9Rect.height;
      const colSpan3Height = colSpan3Rect.height;
      colSpan9Height > colSpan3Height
        ? setElementHeight( colSpan9Height + desktopPadding )
        : setElementHeight( colSpan3Height + desktopPadding );
    }
  }, [colSpan9Rect, colSpan3Rect] );

  useEffect( () => {
    const handleClickOutside = (event) => {
      if ( submenuRef.current && !submenuRef.current.contains( event.target ) ) {
        setIsExpand( false );
      }
    };

    document.addEventListener( 'mousedown', handleClickOutside );
    return () => {
      document.removeEventListener( 'mousedown', handleClickOutside );
    };
  }, [submenuRef, setIsExpand] );

  const subMenuClasses = {
    logic: 'content',
    position: 'absolute l:-left-25 xxl:-left-45 top-full',
    animation: 'transition-all ease-in-out-cubic duration-500 overflow-y-hidden',
    size: 'w-screen',
    general: 'bg-white border-t border-pattens-blue rounded-b shadow-overlay',
  };
  const menuButtonClasses = {
    content: 'flex items-center',
    animation: 'transition-all',
    text: 'text-16 hover:text-bright-blue font-bold',
    size: 'pb-10 h-full w-full',
    general: '',
  };

  const getStyles = (styles) => Object.values( styles ).join( ' ' );

  const openMenu = () => {
    closeSearchBar();
    setIsExpand(true);
  };

  const toggleMenu = () => {
    closeSearchBar();
    setIsExpand(!isExpand);
  };

  const handleMouseEnter = () => {
    if (!isTablet) {
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current);
      }
      openMenu();
    }
  };

  const handleMouseLeave = () => {
    if (!isTablet) {
      closeTimeoutRef.current = setTimeout(() => {
        setIsExpand(false);
      }, 500);
    }
  };

  useEffect(() => {
    return () => {
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current);
      }
    };
  }, []);

  return (
    <li
      ref={submenuRef}
      className="title relative h-full flex items-center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={isTablet ? toggleMenu : null}
    >
      <div className="flex items-center relative mx-15 pt-11 pb-10">
        <div className="block text-16 xl:text-18 leading-100 tracking-1 font-medium transition group">
          {item.parentTitle}
        </div>
        <div className={cx( 'w-9 mt-2 ml-5 transition-transform' )}>
          <Chevron direction={isExpand ? 'up' : 'down'}/>
        </div>
        <span
          className={cx(
            'absolute h-3 -mb-14 xl:-mb-13 bottom-0 bg-navy-blue z-2 opacity-0 transition-opacity duration-300 l:-left-40 l:-right-30 w-full l:w-auto',
            { 'opacity-100': isExpand }
          )}
        ></span>
      </div>
      <div
        className={cx( getStyles( subMenuClasses ), { 'hidden opacity-0': !isExpand } )}
        style={{ maxHeight: isExpand ? elementHeight : 0 }}
        // style={{maxHeight: '430px'}}
      >
        <div className="grid grid-cols-12 gap-20 xl:gap-40 py-24 l:py-40 l:px-50 xl:px-110 overflow-hidden">
        <div className="col-span-3 2xl:col-span-2" ref={colSpan3Ref}>
            <div>
              <ul>
                <li className="block font-medium outline-none" onClick={() => setIsExpand( false )}>
                  <Link
                    className={getStyles( menuButtonClasses )}
                    style={{ maxWidth: '224px' }}
                    link={menuButton}
                    onClick={() => analytics.trackNavClick( menuButton.title )}
                  >
                    <span className="truncate">{menuButton.title}</span>
                    <ArrowIcon className={'pl-4 hidden l:inline l:group-hover:text-bright-blue transition'}/>
                  </Link>
                </li>
                {primaryMenu?.map( ({ link, badge }) => (
                  <MenuItem key={link._key} link={link} handleClose={() => setIsExpand( false )}>
                    {link.title}
                    {badge && badge.label ? (
                      <span className="inline-block ml-10">
                        <Badge background={badge.background} size="medium">
                          {badge.label}
                        </Badge>
                      </span>
                    ) : null}
                  </MenuItem>
                ) )}
              </ul>
            </div>
          </div>
          <div className="col-span-9 2xl:col-span-10" ref={colSpan9Ref}>
            <Gallery items={gallery} handleClose={() => setIsExpand( false )}/>
          </div>
        </div>
      </div>
    </li>
  );
}
