import BlockContent from '@sanity/block-content-to-react'
import Link from 'components/Link'

const classes =
  'underline rounded hover:text-royal-blue hover:no-underline focus:outline-none focus-visible:ring-2 focus-visible:ring-navy-blue focus-visible:ring-offset-4 transition'

const promoBoxSerializer = {
  types: {
    block: (props) => {
      const { style = 'normal' } = props.node

      if (style === 'h1') {
        return (
          <h2 className="text-24 m:text-28 l:text-32 font-medium mb-15 m:mb-20 l:mb-25 mt-40 l:mt-60 leading-130">
            {props.children}
          </h2>
        )
      }

      if (style === 'h2') {
        return (
          <h3 className="text-18 m:text-22 l:text-26 font-medium mb-15 m:mb-20 l:mb-25 mt-40 l:mt-60 leading-130">
            {props.children}
          </h3>
        )
      }

      if (style === 'normal') {
        return <span>{props.children}</span>
      }

      return BlockContent.defaultSerializers.types.block(props)
    },
  },
  list: ({ type, children }) => {
    if (type === 'number') {
      return <ol className="list-decimal">{children}</ol>
    }
    if (type === 'bullet') {
      return <ul className="list-disc ml-20 py-1">{children}</ul>
    }
  },
  listItem: ({ children }) => {
    return <li className="">{children}</li>
  },
  marks: {
    externalLink: ({ children, mark }) => {
      return (
        <Link className={classes} link={mark}>
          {children}
        </Link>
      )
    },
    internalLink: ({ children, mark }) => {
      return (
        <Link className={classes} link={mark}>
          {children}
        </Link>
      )
    },
    strikethrough: ({ children, mark }) => {
      return <s>{children}</s>
    },
    strong: ({ children, mark }) => {
      return <strong className="font-bold">{children}</strong>
    },
    italic: ({ children, mark }) => {
      return <i>{children}</i>
    },
  },
}

export default promoBoxSerializer
