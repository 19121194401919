import ChoiceChipGroup from 'components/ChoiceChipGroup'
import ChoiceChipItem from 'components/ChoiceChipItem'
import Swatch from './Swatch'
import PillSwatch from './PillSwatch'
import Dropdown from 'components/Dropdown'
import DropdownOption from 'components/DropdownOption'

export default function GetSwatch({
  component,
  _key,
  groupValue,
  handleOptionChange,
  values,
  name,
  activeOptionValueSettings,
  product,
  position,
  plpSwatch,
  swatchInfo,
  selectedOption,
  isOptionAvailable,
  activeVariant,
  isSecondSwatchSelector,
  selectedOption2,
  handleSwatchSelect
}) {
  switch (component) {
    case 'Choice Chips':
      return (
        <ChoiceChipGroup
          id={_key}
          key={_key}
          value={groupValue}
          onChange={(value) => {
            handleOptionChange({ value, position });
          }}
          plpSwatch={true}
        >
          {values.map((value) => {
            const label = name === 'Pack Size' ? `${value} Pack` : value;
            const valueSettings = activeOptionValueSettings?.find(
              (s) => s.forOption === `${name}:${value}`
            );
            const iconType = valueSettings?.color ? 'color' : 'image';
            return (
              <ChoiceChipItem
                key={`${label}-${value}`}
                label={label}
                subtitle={valueSettings?.description || valueSettings?.text}
                icon={valueSettings?.emoji ?? valueSettings?.image}
                value={value}
                iconType={iconType}
                showColorOnlyOnPlp={product?.plpSwatch?.showColorOnlyOnPlp}
                color={valueSettings?.color?.hex}
                isOptionAvailable={isOptionAvailable(groupValue, value, position)}
                variation={null}
                product={product}
                plpSwatch={true}
              />
            );
          })}
        </ChoiceChipGroup>
      );
    case 'Dropdown Menu':
      return (
        <Dropdown
          id={_key}
          key={_key}
          name={name}
          value={groupValue}
          onChange={(value) => {
            handleOptionChange({ value, position });
          }}
        >
          {values.map((value) => {
            const label = name === 'Pack Size' ? `${value} Pack` : value;
            const valueSettings = activeOptionValueSettings?.find(
              (s) => s.forOption === `${name}:${value}`
            );
            return (
              <DropdownOption
                key={`${label}-${value}`}
                label={label}
                subtitle={valueSettings?.description || valueSettings?.text}
                icon={valueSettings?.emoji ?? valueSettings?.image}
                value={value}
                isOptionAvailable={isOptionAvailable(groupValue, value, position)}
              />
            );
          })}
        </Dropdown>
      );
    case 'Swatch Selector':
      return (
        swatchInfo && (
          <>
            {Swatch(
              product,
              position,
              plpSwatch,
              activeOptionValueSettings,
              selectedOption,
              handleSwatchSelect,
              isOptionAvailable,
              activeVariant
            )}
          </>
        )
      );
    case 'Pill Swatch Selector':
      return (
        swatchInfo && (
          <>
            {PillSwatch(
              position,
              plpSwatch,
              isSecondSwatchSelector ? selectedOption2 : selectedOption,
              activeOptionValueSettings,
              product,
              activeVariant,
              handleSwatchSelect,
              isOptionAvailable
            )}
          </>
        )
      );
    default:
      return null;
  }
}