import { useMobileMenu } from 'context/globalStore'
import cx from 'classnames'
import { useEffect } from 'react'
import { on } from 'martha'
import * as analytics from 'lib/analytics'

function Burger() {
  const { isMobileMenuOpen, toggleMobileMenu } = useMobileMenu()

  // Close mobile nav with Escape key
  useEffect(() => {
    if (typeof window === 'undefined') return

    let offKeyup = () => {}

    if (isMobileMenuOpen) {
      offKeyup = on(window, 'keyup', ({ key }) => {
        if (key === 'Escape') {
          toggleMobileMenu()
        }
      })
    }

    return offKeyup
  }, [isMobileMenuOpen, toggleMobileMenu])

  function handleClick() {
    analytics.trackNavClick(isMobileMenuOpen ? 'BurgerClose' : 'BurgerOpen')
    toggleMobileMenu()
  }

  const burgerLayerClasses = 'h-3 w-20 rounded bg-navy-blue transition'

  return (
    <div
      className={cx('  flex items-center absolute left-0')}
    >
      <button
        className="rounded transition focus:outline-none focus-visible:bg-pattens-blue"
        onClick={handleClick}
        aria-haspopup={true}
        aria-controls="mobile-menu"
        aria-expanded={isMobileMenuOpen}
      >
        <span className="sr-only">Toggle navigation menu</span>
        <div className="p-10">
          <div className="w-20 h-14 flex flex-col justify-between">
            <div
              className={cx(burgerLayerClasses, {
                'transform rotate-45 translate-y-6': isMobileMenuOpen,
              })}
            ></div>
            <div
              className={cx(burgerLayerClasses, {
                'opacity-0': isMobileMenuOpen,
              })}
            ></div>
            <div
              className={cx(burgerLayerClasses, {
                'transform -rotate-45 -translate-y-5': isMobileMenuOpen,
              })}
            ></div>
          </div>
        </div>
      </button>
    </div>
  )
}

export default Burger
