import Link from 'next/link'
import linkResolver from '../lib/linkResolver'
import getColorStyle from 'lib/getColorStyle'
import { useState } from 'react'

const classes =
  'underline rounded hover:text-royal-blue hover:no-underline focus:outline-none focus-visible:ring-2 focus-visible:ring-navy-blue focus-visible:ring-offset-4 transition'

const textBlockSerializer = (blockClasses = '', linkHoverColor = '') => {
  return {
    marks: {
      externalLink: ({ children, mark }) => (
        <a
          className={classes}
          style={{ textDecorationLine: mark.underline === true ? 'underline' : 'none' }}
          href={mark.url}
          target={mark.openInNewTab ? '_blank' : null}
          rel={mark.openInNewTab ? 'noopener noreferrer' : null}
          data={JSON.stringify(mark)}
        >
          {children}
        </a>
      ),
      internalLink: ({ children, mark }) => {
        const href = linkResolver(mark)
        if (mark.openInNewTab) {
          return (
            <a
              className={classes}
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecorationLine: mark.underline === true ? 'underline' : 'none' }}
            >
              {children}
            </a>
          )
        } else {
          const promoColorClass = children && children.length > 0 ? 'hover:text-light-green' : ''
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [isHover, setIsHover] = useState(false)

          const handleMouseEnter = () => {
            setIsHover(true)
          }
          const handleMouseLeave = () => {
            setIsHover(false)
          }
          return (
            <Link href={href}>
              <a
                className={classes + ' ' + promoColorClass}
                style={{
                  textDecorationLine: mark.underline === true ? 'underline' : 'none',
                  color: isHover && linkHoverColor ? linkHoverColor : null,
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {children}
              </a>
            </Link>
          )
        }
      },
      textColor: ({ children, mark }) => {
        const decodedColor = getColorStyle({ color: mark })
        return <span style={{ color: decodedColor }}>{children}</span>
      },
      extraBold: ({ children }) => {
        return <span style={{ fontWeight: '1000' }}>{children}</span>
      },
      strong: ({ children, mark }) => {
        return <strong className="font-bold">{children}</strong>
      },
    },
    types: {
      block: ({ children }) => {
        return <div className={blockClasses}>{children}</div>
      },
      lineBreak: (props) => {
        const linesCount = props?.node?.linesCount || 1
        let newLines = new Array(linesCount).fill(0)
        const newLinesRender = () =>
          newLines.map((i, _) => {
            return <br key={i}></br>
          })

        return <>{newLinesRender()}</>
      },
    },
  }
}

export default textBlockSerializer
