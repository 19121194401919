import React, { useRef } from 'react'
import cx from 'classnames'
import Link from 'components/Link'
import BlockContent from '@sanity/block-content-to-react'
import bannerHeadingSerializers from 'serializers/bannerHeading'
import { screens } from 'lib/constants'
import useMedia from 'use-media'
import { usePromoConfig } from 'hooks/usePromoConfig'

type Props = {
  plpTile: {
    startIndex: number
    tabletStartIndex: number
    subText: string
    link: any
    backgroundImage: any
    _type: string
    backgroundColor: {
      hex: string
    }
    textColor: {
      hex: string
    }
    layout: string
    subCollection: {
      title: string
      slug: {
        current: string
      }
    }
    headline: any
    plpCalloutBody: any
    plpCalloutFooter: any
    linkColor: {
      hex: string
    }
    showOnDesktop: boolean
    showOnMobile: boolean
  }
}

const PLPCalloutTile = ({ plpTile }: Props) => {
  const isScreenLarge = useMedia({ minWidth: screens.l })
  const {
    layout = '1X1',
    headline,
    link,
    linkColor,
    plpCalloutBody,
    plpCalloutFooter
  } = plpTile
  const ref = useRef<HTMLDivElement>(null)

  const promoConfig = usePromoConfig()
  // extract rows and cols from AXB string
  const [cols, rows ] = layout?.split('X') || ['1', '1']
  const has2rows = rows === '2'
  const has2cols = cols === '2'

  const rootClasses = cx(
    'mx-7 l:mx-10 xl:mx-12 rounded flex items-center justify-center text-navy-blue text-center mb-20 l:mb-50',
    {
      'l:row-span-2': has2rows,
      'l:col-span-2': has2cols,
      'px-10 l:px-55': has2cols,
      'px-10 2xl:px-20': !has2cols
    }
  )

  const headingClassess = cx('max-w-full leading-120 font-medium', {
    'text-20 s:text-40 m:text-26 xl:text-32 xxl:text-40': !has2rows && !has2cols, // 1x1 tile
    'px-10': !has2cols,
  })

  const plpCalloutBodyClasses = cx('text-13 font-medium mt-16 xl:mt-24', {
    'm:text-16 l:text-20 l:leading-28': has2rows || has2cols, // 2x2, 2x1 or 1x2 tile
    's:text-22 m:text-16 xl:text-20 xxl:text-22': !has2rows && !has2cols, // 1x1 tile
  })

  const plpCalloutFooterClasses = cx('mt-16 xl:mt-24 text-11 font-medium s:text-13', {})
  const linkClassess = cx(
    'mt-16 xl:mt-24 inline-block underline underline-offset-4 leading-16.9 rounded transition focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-1 group-btn',
    {
      'text-11 m:text-16': has2rows || has2cols || (!has2rows && !has2cols),
    }
  )
 
  if (isScreenLarge && !plpTile.showOnDesktop) {
    return null
  }

  if (!isScreenLarge && !plpTile.showOnMobile) {
    return null
  }

  return (
    <div
      className={rootClasses}
      style={{
        backgroundColor:
          plpTile?.backgroundColor?.hex ?? promoConfig?.promoTheme?.primaryColor?.hex ?? '#003057',
        backgroundImage: plpTile?.backgroundImage?.asset?.url ? `url(${plpTile?.backgroundImage?.asset?.url})` : '',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
      ref={ref}
    >
      <div className="h-full flex flex-col justify-center pt-8 pb-12 l:py-16"
        style={{
          color: plpTile?.textColor?.hex,
        }}
      >
        <div className={headingClassess}>
          <BlockContent
            blocks={headline}
            serializers={bannerHeadingSerializers}
            renderContainerOnSingleChild
          />
        </div>
        {plpCalloutBody && (
          <div className={plpCalloutBodyClasses}>
                  <BlockContent
                    blocks={plpCalloutBody}
                    serializers={bannerHeadingSerializers}
                  />
          </div>
        )}
        {link && (
          <Link
            className={linkClassess}
            link={link}
            style={{ textDecorationColor: linkColor?.hex ?? 'inherit' }}
          >
            <div
              className="flex items-center"
              style={{ color: linkColor?.hex ?? 'inherit' }}>
              <div>{link.title} →</div>
            </div>
          </Link>
        )}
        {plpCalloutFooter && (
          <div className={plpCalloutFooterClasses}>
            <BlockContent
              blocks={plpCalloutFooter}
              serializers={bannerHeadingSerializers}
              renderContainerOnSingleChild
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default PLPCalloutTile
