export const getCurrencyCode = (countryCode) => {
  switch (countryCode) {
    case 'US':
      return 'USD';
    case 'NZ':
      return 'NZD';
    case 'GB':
      return 'GBP';
    case 'AU':
      return 'AUD';
    case 'CA':
    default:
      return 'USD';
  }
};