import MenuItem from 'components/Navigation/Mobile/MobileMenuItem'
import Badge from 'components/ProductBadge'
import * as analytics from "lib/analytics";
import Link from "components/Link";

export default function PrimaryMenu({ items, menuButton }) {
  return (
    <div className="pt-18 border-b">
      <h5 className={'text-16 font-bold pb-18'}>Shop by Category</h5>
      <ul>
        {items?.map( ({ link, badge }) => {
          return (
            <MenuItem key={link._key} link={link}>
              {link.title}
              {badge && badge.label ? (
                <span className="inline-block ml-10">
                <Badge background={badge.background} size="small">
                  {badge.label}
                </Badge>
              </span>
              ) : null}
            </MenuItem>
          )
        } )}
      </ul>
    </div>
  )
}
