import { useEffect, useState } from 'react'
import Image from 'components/Image'
import getLineItemImageAssetId from 'lib/getLineItemImageAssetId'
import formatPrice from 'lib/formatPrice'
import * as analytics from 'lib/analytics'
import ActionButton from 'components/ActionButton'
import { useAddCartItems, useStore } from 'context/globalStore'
import { shopify } from 'lib/shopify'

export default function ExtraSellItem({
  product,
  variant,
  charLen,
}) {
  const States = ActionButton.States
  const {
    store: { countryCode },
  } = useStore()
  const [status, setStatus] = useState(States.IDLE)
  const [price, setPrice] = useState(0)
  const addToCart = useAddCartItems()

  useEffect(async () => {
    if (!countryCode) return;

    const data = await shopify.fetchProduct(product.productId, countryCode)
    const shopifyVariant = data?.variants?.find((v) => shopify.decode(v.id) === variant.variantId)
    const price = formatPrice(shopifyVariant)
    setPrice(price)
  }, [countryCode])

  const image = getLineItemImageAssetId(product, variant)
  const Styles = {
    item: 'flex-grow-1 flex-shrink-0 mr-15 w-5/6 last:mr-0 l:mb-12',
    image: 'mx-15 min-w-60 l:mx-20 l:min-w-75'
  }

  const handleClick = async function () {
    setStatus(States.LOADING)
    analytics.trackButtonClick('AddCartCrossSell')

    const data = {
      product,
      shouldSetIsAdding: false,
      payload: {
        variant,
        planProducts: [product],
        quantity: 1,
      },
    }

    await addToCart(data)
    setStatus(States.SUCCESS)
    analytics.trackAddToCart({
      product,
      variant,
      quantity: 1
    })
  }

  const getDesc = (desc, len) => {
    return desc && desc.length > len ? desc.trim().substr(0, len) + '..' : desc
  }
  return (
    <div className={`flex cart-crossells bg-alice-blue border border-pattens-blue rounded items-center pt-10 pb-15 pr-10 ${Styles.item}`}>
      <div className={`rounded overflow-hidden ${Styles.image}`}>
        <Image image={image} height={150} width={150} placeholder={false} />
      </div>
      <div className="flex flex-col items-start">
        <span className="text-16 font-medium">{product.title}</span>
        <p className="text-12 text-navy-blue-muted mt-5 mb-10">
          {getDesc(product?.metaDescription, charLen)}
        </p>
        <ActionButton status={status} onChange={setStatus} onClick={handleClick}>
          <span className="hidden s:block">
            Add{' '}
            <span className="mx-8" aria-hidden="true">
              |
            </span>{' '}
            {price}
          </span>
          <span className="s:hidden">+ {price}</span>
        </ActionButton>
      </div>
    </div>
  )
}