export default function getColorStyle(background) {
  return background?.cssGradient ?? background?.color?.hex ?? null
}

export function getLastColorInGradient(background) {

  // linear-gradient(180deg, rgba(255,255,255,1) 50%, #EDEDED 50%, rgba(250,250,250,1) 50%)
  const gradient = background?.cssGradient
  if (!gradient) return null

  let value = null
  let lastIndexOfRGBA = gradient.lastIndexOf('rgba')
  let lastIndexOfHex = gradient.lastIndexOf('#')

  if (lastIndexOfRGBA > lastIndexOfHex) {
    value = gradient.substring(lastIndexOfRGBA)
    let firstIndexOfCloseParenthesis = value.indexOf(')')
    value = value.substring(0, firstIndexOfCloseParenthesis + 1)
  }
  else {
    value = gradient.substring(lastIndexOfHex)
    value = value.substring(0, 7)
  }

  return value
}