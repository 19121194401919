import { useEffect } from 'react'

const usePageScrollLock = (isEnabled: boolean, delay = 300, breakpoint?: string): void => {
  useEffect(() => {
    let timeoutId: NodeJS.Timeout

    const handleScrollLock = () => {
      if (isEnabled) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'visible'
      }
    }

    const handleResize = () => {
      if (breakpoint && window.innerWidth <= parseInt(breakpoint)) {
        handleScrollLock()
      }
    }

    if (breakpoint) {
      window.addEventListener('resize', handleResize, { passive: true })
      handleResize()
    } else {
      handleScrollLock()
    }

    // if (isEnabled) {
    //   timeoutId = setTimeout(() => {
    //     handleScrollLock();
    //   }, delay);
    // }

    return () => {
      clearTimeout(timeoutId)
      document.body.style.overflow = 'visible'

      if (breakpoint) {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [isEnabled, delay, breakpoint])
}

export default usePageScrollLock
