import BlockContent from '@sanity/block-content-to-react'
import cx from 'classnames'
import useSWR from 'swr'
import formatNumber from 'lib/formatNumber'

export default function CartDonationPopover({ donation, active }) {
  const { data } = useSWR('/api/impact')

  return (
    <>
      <div
        className={cx(
          'absolute left-1/2 z-20 transform -translate-x-3/4 -translate-y-full pb-20 cursor-default transition',
          {
            'opacity-0 pointer-events-none': !active,
          }
        )}
      >
        <div className="bg-pale-orange rounded p-20 l:mr-15 leading-142 text-left w-245 shadow-overlay">
          <div className="text-14 leading-130 font-medium mb-10">{donation.headline}</div>
          <BlockContent
            blocks={donation.description}
            serializers={{
              marks: {
                strong: ({ children }) => <span className="font-medium">{children}</span>,
              },
            }}
          />
          {data?.amount && !donation.isImpicitDonationEnabled ? (
            <div className="text-11 text-navy-blue-muted mt-10">
              ${formatNumber(data?.amount)} raised so far this month!
            </div>
          ) : null}
        </div>
      </div>
      <div
        className={cx(
          'absolute w-15 h-15 rounded bg-pale-orange bottom-full left-1/2 transform -translate-x-1/2 -translate-y-3 rotate-45 transition cursor-default',
          {
            'opacity-0 pointer-events-none': !active,
          }
        )}
      ></div>
    </>
  )
}
