import { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import getOptionsForSwatches from "../helpers/getOptionsForSwatches"
import { useRouter } from 'next/router'
import useClickOutside from 'hooks/useClickOutside'
import { getSwatchItemsWithForOption } from 'components/Product/Product.helper'
import { DEFAULT_FREQUENCY } from 'lib/constants'
import { getSubDiscountedPrice } from 'lib/getDiscountedPrice'
import { useAddCartItems } from 'context/globalStore'
import useMedia from 'use-media'
import { screens } from 'lib/constants'
import { formatNumberAsPrice } from 'lib/formatPrice'
import Button from '../../Button'
import cx from 'classnames'
import DropdownQuantitySelector from '../../SubscriptionKitForm/QuantitySelector/DropdownQuantitySelector'
import getOptions from './GetOptions'
import useShopifyProductPlus from "../../../hooks/useShopifyProductPlus";

function GetSubscriptionPLPDetails(product, oneTimeProductsSwitch, activeVariant, setActiveVariant, quantityItems, packSizeOptions, subscriptionPLPEnabled, showQuantitySelector, selectImageOption) {
  const [frequency, setFrequency] = useState(DEFAULT_FREQUENCY)
  const [isAdding, setIsAdding] = useState(false)
  const { variants: shopifyVariants } = useShopifyProductPlus(product)

  let defaultSwatchOption = product.plpSwatch?.swatchItems?.[0]?.option
  let defaultSwatchOption2 = product.plpSwatch?.swatchItems2?.[0]?.option || product.plpSwatch2?.swatchItems?.[0]?.option
  const [selectedOption, setSelectedOption] = useState(defaultSwatchOption)
  const [selectedOption2, setSelectedOption2] = useState(defaultSwatchOption2)
  const router = useRouter()
  const isScreenLarge = useMedia({ minWidth: screens.l })
  const dropdownRef = useRef(null)
  const [swatchMenuVisible, setSwatchMenuVisible] = useState(false)
  useClickOutside(dropdownRef, () => setSwatchMenuVisible(false))
  const [swatchInfo, setSwatchInfo] = useState({})

  const unit = oneTimeProductsSwitch ? undefined : 'month'
  const subPrice = oneTimeProductsSwitch
    ? undefined
    : activeVariant?.subscriptionBreakdown?.recurringCost ??
    getSubDiscountedPrice(activeVariant?.price, product)
  const addToCart = useAddCartItems()
  const swatchItemsWithForOption = getSwatchItemsWithForOption(product, 'swatchItems', 'options');
  const swatchItemsWithForOption2 = getSwatchItemsWithForOption(product, 'swatchItems2', 'options2');

  const allSwatchItems = [...swatchItemsWithForOption, ...swatchItemsWithForOption2]

  const activeOptionValueSettings = product?.optionValueSettings || allSwatchItems

  const options = getOptionsForSwatches(product)

  const recurringPrice = formatNumberAsPrice(
    +(activeVariant?.recurringPrice || product?.recurringCost)
  ) || `$${subPrice}`

  useEffect(() => {
    if (product.plpSwatch && product.plpSwatch?.swatchItems?.length > 0) {
      let swatchOption = product.plpSwatch.options
      let swatchOption2 = product.plpSwatch?.options2 || product.plpSwatch2?.options
      let swatchIndex = product.options.findIndex((x) => x.name === swatchOption)
      let swatchIndex2 = product.options.findIndex((x) => x.name === swatchOption2)
      let option = activeVariant.variantTitle.split(' / ')[swatchIndex]
      let option2 = activeVariant.variantTitle.split(' / ')[swatchIndex2]

      setSwatchInfo(product.plpSwatch)
      setSelectedOption(option)
      setSelectedOption2(option2)
    }
  }, [activeVariant, product.options, product.plpSwatch])

  const subscribeToProduct = async () => {
    setIsAdding(true)
    await addToCart({
      product: { ...product },
      shouldSetIsAdding: true,
      payload: {
        planProducts: oneTimeProductsSwitch ? [product] : [{ ...product, ...activeVariant }],
        variant: activeVariant,
        quantity: 1,
        unit: oneTimeProductsSwitch ? undefined : unit,
        isSubscriptionActive: !oneTimeProductsSwitch,
        frequency: oneTimeProductsSwitch ? undefined : frequency,
        subPrice,
      },
    })
    setIsAdding(false)
  }

  const isAvailable = shopifyVariants?.find(variant => variant.id.includes(activeVariant.variantId))?.available
  const ctaText = !isAvailable ? 'Out Of Stock' : oneTimeProductsSwitch ? 'Add To Cart' : 'Subscribe'

  const currentSwatch = useMemo(() => {
    return product?.plpSwatch?.swatchItems?.find((x) => x.option === selectedOption)
  }, [product?.plpSwatch?.swatchItems, selectedOption])

  const isOptionAvailable = useCallback(
    (groupValue, value, position) => {
      if (value === groupValue) return true

      let title = activeVariant.variantTitle
        .split(' / ')
        .map((option, i) => (i + 1 === position ? value : option))
        .join(' / ')
      let variant = product.variants.find((v) => v.variantTitle === title)

      return !!variant
    },
    [activeVariant, product.variants]
  )

  const handleOptionChange = useCallback(
    ({ value, position }) => {
      let firstAvailableVariant = product.variants.find((variant) => {
        if (variant.disableForOneTime === true && plan === 'one-time') {
          return false;
        }
        if (variant.disableForSubscription === true && plan === 'subscription') {
          return false;
        }
        if (variant[`option${position}`] !== value) {
          return false;
        }
        for (let i = 1; i <= 3; i++) {
          if (
            variant.hasOwnProperty(`option${i}`) &&
            activeVariant.hasOwnProperty(`option${i}`) &&
            i !== position &&
            variant[`option${i}`] !== activeVariant[`option${i}`]
          ) {
            return false;
          }
        }
        return true;
      })
      let title = firstAvailableVariant?.variantTitle
        .split(' / ')
        .map((option, i) => (i + 1 === position ? value : option))
        .join(' / ')

      router.replace(
        { to: window.location.pathname, query: { ...router.query, variant: title } },
        undefined,
        { shallow: true }
      )

      let variant = product.variants.find((v) => v.variantTitle === title)
      if (variant) {
        setActiveVariant(variant)
      }
    },
    [activeVariant.variantTitle, product.variants, router, setActiveVariant]
  )

  const handleSwatchSelect = useCallback(
    (option, index) => {
      const colorOptions = options.find(item => item.name?.toLowerCase() === 'color')
      if (colorOptions?.values?.includes(option)) {
        selectImageOption(option)
      }
      setSelectedOption(option)
      handleOptionChange({ value: option, position: index })
    },
    [handleOptionChange]
  )

  if (
    !oneTimeProductsSwitch &&
    !product.variants?.find(
      (v) =>
        (!v?.sellingPlans || !!v?.sellingPlans?.find((plan) => plan.type == 'subscription')) &&
        !v.disableForSubscription
    )
  )
    return <></>

  const optionsForRended = getOptions(product, activeVariant, handleOptionChange, isOptionAvailable, activeOptionValueSettings, selectedOption, selectedOption2, handleSwatchSelect, options, swatchInfo)
  return (
    <div className={cx('flex flex-col justify-between relative', {
      'l:h-full': (optionsForRended || showQuantitySelector) && !product?.plpSwatch?.disableSwatchesOnSubsPlp,
    })}>
      {packSizeOptions && quantityItems.length > 1 && subscriptionPLPEnabled && !product?.plpSwatch?.disableSwatchesOnSubsPlp && (optionsForRended || showQuantitySelector) && (
        <>
        <div className='absolute top-0 left-0 w-full border-t border-solid h-1'></div>
        <div className='pt-10 m:pt-12'>
          {getOptions(product, activeVariant, handleOptionChange, isOptionAvailable, activeOptionValueSettings, selectedOption, selectedOption2, handleSwatchSelect, options, swatchInfo)}
          {showQuantitySelector && (
            <div className='pt-10'>
              <div className='text-13 leading-17'>
                <span className='font-medium'>Recurring refills</span> (Every 3 months)
              </div>
              <DropdownQuantitySelector
                product={product}
                activeVariant={activeVariant}
                setActiveVariant={setActiveVariant}
                isSubscriptionOption={true}
                setShowStickyAtcDrawer={() => false}
                isProductCard={true}
              />
            </div>
          )}
        </div>
        </>
      )}

      <div>
        <Button
          variant="contained"
          size="large"
          className={cx('block mb-12 mt-14 w-full pointer-events-auto h-45')}
          tabIndex={-1}
          onClick={subscribeToProduct}
          disabled={!isAvailable}
          loading={isAdding}
        >
          <span className="text-18 leading-16"> {ctaText}</span>
        </Button>
        {!oneTimeProductsSwitch && (
          <div>
            <p className='text-center text-11 leading-17 m:text-13 -mt-2 -mx-6 s:mx-0'>
              Recurring price - {recurringPrice} every 3 months | Delay or cancel anytime
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default GetSubscriptionPLPDetails