import { useEffect, useState } from 'react'
import {useStore, useCartItems, useAllProducts, useLastVisitedPDP, useCartTotals} from 'context/globalStore'
import { shopify } from 'lib/shopify'
import rebuyRecommendations from 'lib/rebuyRecommendations'
import { getProductById } from "../lib/api";

let recommendationsLoaded = false

export default function useCartUpsellItems(items, variation) {
  const [cartUpsellItems, setCartUpsellItems] = useState(items)
  const { rawTotal: cartTotal } = useCartTotals()
  const [rebuyRecommendationIds, setRebuyRecommendationIds] = useState([])

  const { filteredLineItemsCount, filteredLineItems } = useCartItems()
  const { store } = useStore()
  const { allProducts } = useAllProducts()

  useEffect(() => {
    let ignore = false;
    async function fetchRebuyRecommendations() {
      try {
        let rebuyRecommendationIds = await rebuyRecommendations.fetchCustom('148076');
        setRebuyRecommendationIds(rebuyRecommendationIds);
        recommendationsLoaded = false;
      } catch (error) {
        console.error("Error fetching rebuy recommendations:", error);
      }
    }

    if (!ignore) fetchRebuyRecommendations();

    return () => { ignore = true; }
  }, []);

  useEffect(() => {
    if (!store.isInit) return;

    const length = filteredLineItemsCount || 0;
    if (!length) return;

    if (!allProducts?.length) return;

    const processUpsells = async () => {
      try {
        let rebuyProductRecommendations = await formatUpsellProducts(allProducts, rebuyRecommendationIds, filteredLineItems);
        if (variation === 'b' && cartTotal >= 40 && cartTotal <= 50) {
          setCartUpsellItems(rebuyProductRecommendations);
        } else {
          setCartUpsellItems(items);
        }
      } catch (error) {
        setCartUpsellItems(items);
      }

      recommendationsLoaded = true;
    }
    processUpsells();
  }, [filteredLineItemsCount, store.isInit, allProducts?.length, variation, items, cartTotal]);


  return { cartUpsellItems }
}


async function formatUpsellProducts(allProducts, upsellProductIds, lineItems) {
   let items = []

  for (let i = 0; i < upsellProductIds.length; i++) {
    let product = allProducts.find((x) => parseInt(x._id) === upsellProductIds[i].id)
    if (!product) {
      product = (await getProductById(upsellProductIds[i].id))?.product
    }

    if (!product)
      continue;

    if (product.tags?.includes('exclude_rebuy'))
      continue;

    if (lineItems.find((x) => shopify.decode(x.variant.product.id).toString() === product._id))
      continue;

    const variant = product.variants.find(
      variant => parseInt(variant.variantId
    ) === upsellProductIds[i].variants[0].id) ?? product.variants[0]

    let upsell = {
      product,
      variants: [variant],
    }

    items.push(upsell)
  }

  return items;
}