import CartFreeShippingMeter from 'components/CartFreeShippingMeter'
import BlockContent from '@sanity/block-content-to-react'
import serializer from 'serializers/justLinks'
import TieredShippingMeter from './TieredShippingMeter'

export default function CartPromo({ promo, subTotalAmount }) {
  return promo.component._type === 'freeShippingMeter' ? (
    <CartFreeShippingMeter
      freeShippingMeters={promo.freeShippingMeters}
      subTotalAmount={subTotalAmount}
    />
  ) : promo.component._type === 'basicMessage' ? (
    <div className="py-20 flex flex-col items-center text-center">
      <div className="text-13 mb-10">
        <BlockContent blocks={promo.component.content} serializers={serializer} />
      </div>
    </div>
  ) : (
    (promo.component._type = 'tieredPromo' ? (
      <TieredShippingMeter subTotalAmount={subTotalAmount} countryGroups={promo.component.countryGroups} />
    ) : null)
  )
}
