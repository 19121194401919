import cx from 'classnames'

export default function DividerLine({
  className,
  fontClasses = '',
  as: Component = 'div',
  children,
}) {
  const Line = ({ className }) => (
    <div className={cx('w-full h-1 bg-pattens-blue', className)}></div>
  )

  return children ? (
    <Component className={cx('flex items-center w-full', className)}>
      <div
        className={cx(
          'text-16 l:text-18 font-serif italic leading-100 mr-10 flex-shrink-0',
          fontClasses,
        )}
      >
        {children}
      </div>
      <Line className="l:mt-4" />
    </Component>
  ) : (
    <Line className={className} />
  )
}
