import { isNullOrWhitespace } from "./validate"

export default function getLineItemDescription(options, variant) {
  return options
    .filter((o) =>
      [
        'Pack Size',
        'Scent',
        'Character',
        'Size',
        'Count',
        'Sink Count',
        'Bathroom Count',
        'Tablet Count',
        'Amount',
        'Color',
        'Type',
        'Cleaner Type',
        'Recurring Quantity',
        'Refill Quantity',
        'Quantity'
      ].includes(o.name),
    )
    .map((o) => {
      const value = variant[`option${o.position}`]
      return o.name === 'Pack Size' ? `${value} Pack` : value
    })
    .filter(x => !isNullOrWhitespace(x))
    .join(' / ')
}
