import React, { useState } from 'react';
import cx from 'classnames';
import { useMedia } from 'use-media';
import { screens } from 'lib/constants';

const Tooltip = ({ text, children, className = '', position = 'default', size = 'default' }) => {
    const [showTip, setShowTip] = useState(false)
    const isScreenLarge = useMedia({minWidth: screens.l})
    let minWidth;
    switch (size) {
      case 'default':
        minWidth = '150px';
        break;
      case 'small':
        minWidth = '135px';
        break;
      default:
        minWidth = '135px';
        break;
    }

    const handleMouseOver = () => {
      if (isScreenLarge) setShowTip(true)
    }

    const handleMouseOut = () => {
        if (isScreenLarge) setShowTip(false)
    }

    const handleMouseClick = () => {
      setShowTip(true)
    }

    return (
    <div className='flex'>
      <div className={cx("relative inline-block", className)} onClick={(e) => {e.stopPropagation(); handleMouseClick()}} 
      onMouseOver={(e) => {e.stopPropagation(); handleMouseOver()}} 
      onMouseOut={(e) => { e.stopPropagation(); handleMouseOut()}}
      >
        {children}
        {
          showTip && 
          <div style={{minWidth: minWidth}} className={cx('block  absolute bottom-30 text-navy-blue bg-pale-orange text-sm p-6 leading-115 font-normal rounded shadow', {
            'transform -translate-x-50p left-50p': position === 'default',
            '-left-47 transform -translate-x-50p l:left-50p': position === 'left' && size === 'default',
            '-left-47 transform -translate-x-50p': position === 'leftAllDevices' && size === 'default',
            '-right-47 transform -translate-x-50p': position === 'rightAllDevices' && size === 'default'
          })}>
              <span className='pb-5 inline-block'>{text}</span> 
              <div
                  style={{
                      borderLeftWidth: '10px',
                      borderTopWidth: '15px',
                      borderRightWidth: '10px',
                      borderLeftColor: 'transparent',
                      borderRightColor: 'transparent'
                  }} 
                  className={cx('w-0 h-0 block mx-auto absolute border-pale-orange', {
                    'left-50p transform -translate-x-50p': position === 'default',
                    'right-0 l:left-50p l:right-auto transform -translate-x-50p': position === 'left' && size === 'default',
                    'right-0 transform -translate-x-50p': position === 'leftAllDevices' && size === 'default',
                    'left-0 transform -translate-x-50p': position === 'rightAllDevices' && size === 'default'
                  })}
              >
              </div>
          </div>
        }

      </div>            
       {
          showTip && !isScreenLarge && <div style={{ top: '-500px', height: '2000px', left: '-5%', width: '107%' }} className='fixed w-full h-screen top-0 left-0' onClick={(e) => {e.stopPropagation(); setShowTip(false)}}>

          </div>
        } 
    </div>

  );
};

export default React.memo(Tooltip);
