import cx from 'classnames'

const buildObject = (val) => {
  const value = typeof val !== 'object' ? { all: val } : val
  return {
    all: 0,
    ...value,
  }
}

const buildValue = (value, str, port) => {
  let _value = ''

  if (port === 'all') _value = value ? `${str}-${value}` : `hidden`
  else _value = value ? `${port}:block ${port}:${str}-${value}` : `${port}:hidden`

  return _value
}

const buildClasses = (obj, str) => {
  const _obj = buildObject(obj)
  return Object.keys(_obj).map((port) => {
    const value = _obj[port]
    return buildValue(value, str, port)
  })
}

/**
 * style={{
        'background-color': '#e5e5f7',
        'opacity': '0.3',
        'background': 'repeating-linear-gradient( -45deg, #444cf7, #444cf7 2px, #e5e5f7 2px, #e5e5f7 10px )'
      }}
 */

export default function Offset({ offset = 0 }) {
  const _offset = buildClasses(offset, 'col-span')
  return <div className={cx([..._offset])}>Offset</div>
}
