import { useState, useCallback, useEffect } from 'react'
import Autoplay from 'embla-carousel-autoplay'
import useEmblaCarousel from 'embla-carousel-react'
import textBlockSerializer from 'serializers/textBlockSerializer'
import Chevron from 'components/Icons/Chevron'
import PromoModal from 'components/PromoModal'
import cx from 'classnames'
import getColorStyle from 'lib/getColorStyle'
import { remove } from 'martha'
import { useCountryCode, useAutoDiscount, useIsStickyPromo } from 'context/globalStore'
import { screens } from 'lib/constants'
import useMedia from 'use-media'
import { InfoIcon } from 'components/Icons/InfoIcon'
import textInlineSerializer from '../../serializers/textInlineSerializer'
import NextButton from 'components/PromoBar/NextButton'
import PrevButton from 'components/PromoBar/PrevButton'
import CheckmarkIcon from './CheckmarkIcon'
import GetCountdownInfo from './GetCountdownInfo'
import PromoMessage from './PromoMessage'
import { handlePopupClick, handleNavClick, closeActiveModalId, getPromoBanners, handleCountrySelection, getSpacing } from './helpers'
import { useRouter } from 'next/router'

const autoplay = Autoplay({ delay: 4000, stopOnMouseEnter: true, stopOnInteraction: false })

export default function PromoBar({ autoplayDuration, banners, allCountries }) {
  const { isStickyPromo, setIsStickyPromo, sethasLargerPromoBar, hasLargerPromoBar } =
    useIsStickyPromo()
  const { countryCode, setCountryCode } = useCountryCode()
  const [spacing, setSpacing] = useState(30)
  const [mobileSpacing, setMobileSpacing] = useState(10)
  const { autoDiscount } = useAutoDiscount()
  const banner = getPromoBanners(banners, countryCode)
  const { isEnabled, background, textColor, messages, linkHoverColor } = banner
  const [promoMessages, setPromoMessages] = useState([])
  const isScreenLarge = useMedia({ minWidth: screens.l })
  const [flagExpanded, setFlagExpanded] = useState(false)
  const [flagVisible, setFlagVisible] = useState(false)
  const [selectedFlagIndex, setSelectedFlagIndex] = useState(0)
  const [mouseEnterFlagIndex, setMouseEnterFlagIndex] = useState(0)
  const [currentTime, setCurrentTime] = useState(new Date().getTime())
  let modalPopup = 0
  const router = useRouter()
  const [activeModalId, setActiveModalId] = useState(null)
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      loop: true,
    },
    [autoplay]
  )
  const scrollPrev = useCallback(() => emblaApi?.scrollPrev(), [emblaApi])
  const scrollNext = useCallback(() => emblaApi?.scrollNext(), [emblaApi])

  useEffect(() => {
    banner?.padding && setSpacing(banner.padding);
    banner?.paddingMobile && setMobileSpacing(banner.paddingMobile);
    banner?.isSticky && setIsStickyPromo(true);
    banner?.showLargerPromoBar && sethasLargerPromoBar(true);
  }, [banner])

  useEffect(() => {
    let index = allCountries.findIndex((x) => x.countryCode === countryCode)
    if (index === -1) {
      index = allCountries.findIndex((x) => x.countryCode === 'US')
    }
    setFlagVisible(countryCode !== 'US')
    setSelectedFlagIndex(index)
    setMouseEnterFlagIndex(index)
  }, [countryCode, allCountries])

  useEffect(() => {
    setPromoMessages(messages || [])
    if (emblaApi) {
      setTimeout(() => {
        emblaApi.reInit()
      }, 100);
    }
  }, [router.asPath, messages])

  useEffect(() => {
    if (countryCode) {
      setPromoMessages(messages || [])
    }
  }, [countryCode])

  useEffect(() => {
    const { promoBannerText, hasPromoPopup, popupContent, popupToggles } = autoDiscount || {}

    if (promoBannerText) {
      setTimeout(() => {
        setPromoMessages([
          {
            type: 'discount',
            _key: Date.now(),
            content: promoBannerText,
            hasPopup: hasPromoPopup,
            popupContent,
            popupToggles,
          },
        ])
      }, 1000);
    }
  }, [autoDiscount, router.asPath])

  useEffect(() => {
    if (promoMessages.find((x) => x.hasCountdown)) {
      const code = setInterval(function () {
        let now = new Date().getTime()
        setCurrentTime(now)
      }, 1000)

      return () => clearInterval(code)
    }
  }, [promoMessages])

  function closeActiveModalId(modelId) {
    setActiveModalId(modelId)
    remove(document.body, 'overflow-hidden')
  }

  if (!banner || !isEnabled) {
    return null
  }

  return (
    <>
      <div
        className="promo-bar relative flex items-center min-h-35 z-20"
        style={{
          background: getColorStyle(background),
          position: isStickyPromo ? 'sticky' : 'static',
          top: '0',
          left: '0',
          transition: 'background-color 0.3s ease-in-out',
          color: textColor && getColorStyle(textColor),
        }}
        id="dynamic-promo"
      >
        <div
          className={cx('overflow-hidden w-full', {
            'l:pl-60': flagVisible,
            'l:pl-70': promoMessages.length > 1 && flagVisible,
          })}
          ref={promoMessages.length > 1 ? emblaRef : null}
        >
          <div className={cx('flex w-full py-7 items-center', { 'min-h-52': hasLargerPromoBar })}>
            {promoMessages.map((message, i) => {
              const hasMultipleLines =
                (isScreenLarge && message.desktopContentLine2) ||
                (!isScreenLarge && message.mobileContentLine2)
              const thickerPromoBar = hasMultipleLines || hasLargerPromoBar
              return (
                <PromoMessage
                  key={i}
                  message={message}
                  handlePopupClick={handlePopupClick}
                  modalPopup={modalPopup}
                  setActiveModalId={setActiveModalId}
                  getSpacing={getSpacing}
                  spacing={spacing}
                  mobileSpacing={mobileSpacing}
                  isScreenLarge={isScreenLarge}
                  getColorStyle={getColorStyle}
                  linkHoverColor={linkHoverColor}
                  textColor={textColor}
                  hasMultipleLines={hasMultipleLines}
                  textBlockSerializer={textBlockSerializer}
                  currentTime={currentTime}
                  flagVisible={flagVisible}
                  GetCountdownInfo={GetCountdownInfo}
                  thickerPromoBar={thickerPromoBar}
                />
              )
            })}
          </div>
        </div>
        <div
          className={cx('mr-20 l:mr-40 cursor-pointer z-10', {
            hidden: !flagVisible,
            'mr-30 l:mr-50': promoMessages.length > 1,
          })}
          onClick={() => setFlagExpanded((x) => !x)}
        >
          <div className="flex items-center">
            <img
              className="inline mr-7"
              src={allCountries[selectedFlagIndex]?.countryFlag?.asset?.url}
              aria-hidden="true"
              alt=""
              width={20}
              height={15}
            />
            <div
              className={cx('w-12 min-w-9 transition-transform', {
                'transform rotate-180': flagExpanded,
              })}
            >
              <Chevron direction="down" />
            </div>
          </div>
        </div>
        <div
          className={cx(
            'absolute flex-col overflow-y-auto transition top-36 right-0 mr-20 l:mr-40 bg-white w-200 rounded shadow-drop-down z-30',
            {
              'mr-30 l:mr-50': promoMessages.length > 1,
              'transform scale-95 opacity-0 pointer-events-none': !flagExpanded,
            }
          )}
          style={{ maxHeight: flagExpanded ? '270px' : '0' }}
          onMouseLeave={(_) => setMouseEnterFlagIndex(selectedFlagIndex)}
        >
          {allCountries?.map((item, index) => {
            return (
              <div
                key={item._id}
                onMouseEnter={(_) => setMouseEnterFlagIndex(index)}
                className="px-20 border-b border-pattens-blue cursor-pointer hover:bg-alice-blue text-navy-blue-muted hover:text-navy-blue"
                onClick={() => handleCountrySelection(item.countryCode, setFlagExpanded, countryCode, setCountryCode)}
              >
                <div className="py-12 inline-block text-15 leading-18 flex items-centre">
                  <img
                    className="inline"
                    src={item?.countryFlag?.asset?.url}
                    aria-hidden="true"
                    alt=""
                    width={20}
                    height={15}
                  />
                  <span
                    className={cx('px-8 font-medium', {
                      'text-navy-blue':
                        selectedFlagIndex === index && mouseEnterFlagIndex === index,
                    })}
                  >
                    {`${item.currencySymbol} ${item.countryCode}`}
                  </span>
                  <div
                    className={cx('w-16 mt-2 ml-auto text-navy-blue', {
                      hidden: selectedFlagIndex !== index,
                    })}
                  >
                    <CheckmarkIcon />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        {promoMessages.length > 1 && (
          <>
            <PrevButton onClick={() => handleNavClick(scrollPrev)} />
            <NextButton onClick={() => handleNavClick(scrollNext)} />
          </>
        )}
      </div>
      {promoMessages
        .filter((item) => item.popupContent)
        .map((item, index) => (
          <PromoModal
            key={index}
            content={item.popupContent}
            toggles={item.popupToggles}
            modalId={index}
            activeModalId={activeModalId}
            setActiveModalId={closeActiveModalId}
          />
        ))}
    </>
  )
}
