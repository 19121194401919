import Link from 'next/link'
import linkResolver from '../lib/linkResolver'

const classes =
  'underline rounded hover:text-royal-blue hover:no-underline focus:outline-none focus-visible:ring-2 focus-visible:ring-navy-blue focus-visible:ring-offset-4 transition'

export default {
  marks: {
    externalLink: ({ children, mark }) => (
      <a
        className={classes}
        style={{ textDecorationLine: mark.underline === true ? 'underline' : 'none' }}
        href={mark.url}
        target={mark.openInNewTab ? '_blank' : null}
        rel={mark.openInNewTab ? 'noopener noreferrer' : null}
        data={JSON.stringify(mark)}
      >
        {children}
      </a>
    ),
    internalLink: ({ children, mark }) => {
      const href = linkResolver(mark)
      if (mark.openInNewTab) {
        return (
          <a
            className={classes}
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecorationLine: mark.underline === true ? 'underline' : 'none' }}
          >
            {children}
          </a>
        )
      } else {
        return (
          <Link href={href}>
            <a
              className={classes}
              style={{ textDecorationLine: mark.underline === true ? 'underline' : 'none' }}
            >
              {children}
            </a>
          </Link>
        )
      }
    },
    textColor: ({ children, mark }) => {
      return (
        <span style={{ color: mark.colorCode }}>
          {children}
        </span>
      )
    },
    extraBold: ({ children }) => {
      return <span style={{ fontWeight: '1000' }}>{children}</span>
    },
    newLines: ({ children, mark }) => {
      let newLines = new Array(mark?.count).fill(0)
      const newLinesRender = () => newLines.map((i, _) => {
        return <br></br>
      })

      return (
        <>
          <span>{children}</span>
          {newLinesRender()}
        </>
      )
    },
    popupTrigger: ({ children }) => {
      return (
        <span id='popup-trigger-text' className='cursor-pointer'>{children}</span>
      )
    }
  },
}