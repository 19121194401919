import { useState, useRef } from 'react'
import Image from 'components/Image'
import CartDonationPopover from 'components/CartDonationPopover'
import { useAddCartItems } from 'context/globalStore'
import useClickOutside from 'hooks/useClickOutside'
import ActionButton from 'components/ActionButton'
import useShopifyProductPlus from 'hooks/useShopifyProductPlus'
import formatPrice from 'lib/formatPrice'
import cx from 'classnames'

const States = ActionButton.States

export default function CartDonation({ donation }) {
  const [status, setStatus] = useState(States.IDLE)
  const addCartItems = useAddCartItems()
  const { variants } = useShopifyProductPlus(donation.product)
  const variant = variants?.[0]

  const ref = useRef()
  const [isPopoverActive, setIsPopoverActive] = useState(false)
  useClickOutside(ref, () => setIsPopoverActive(false))

  async function handleClick() {
    setStatus(States.LOADING)

    await addCartItems({
      product: donation.product,
      shouldSetIsAdding: false,
      payload: {
        variant: donation.product.variants[0],
        planProducts: [donation.product],
        quantity: 1,
      },
    })

    setStatus(States.SUCCESS)
  }

  const price = formatPrice(variant)
  const iconImageDimensions = getImageDimensions(donation)
  const iconWidth = iconImageDimensions.width
  const iconHeight = iconImageDimensions.height

  return (
    <div className="donation bg-pattens-blue flex items-center justify-between py-10 pl-20 pr-15">
      <div className="flex items-center space-x-10 mr-10">
        <div className={`w-${iconWidth} h-${iconHeight} flex-shrink-0`}>
          <Image
            image={donation.iconImage}
            fill={true}
            fit="contain"
            width={iconWidth}
            height={iconHeight}
            sizes={`${iconWidth}px`}
            placeholder={false}
            alt="donation image icon"
          />
        </div>
        <div className="">
          <div className={cx("text-11 s:text-13 leading-130 s:font-normal flex items-center",
            {
              "font-medium": !donation?.isImpicitDonationEnabled
            })}>
            {donation?.isImpicitDonationEnabled ? donation?.implicitDonationTitle : donation?.product?.title}
            <button
              ref={ref}
              className="relative w-22 h-22 focus:outline-none focus-visible:ring-2 focus-visible:ring-royal-blue rounded"
              onClick={() => setIsPopoverActive(!isPopoverActive)}
            >
              <div className="absolute-center w-11 s:w-12">
                <InfoIcon />
              </div>
              <CartDonationPopover donation={donation} active={isPopoverActive} />
            </button>
          </div>
        </div>
      </div>
      {!donation?.isImpicitDonationEnabled && <ActionButton status={status} onChange={setStatus} onClick={handleClick}>
        <span className="hidden s:block">
          Add{' '}
          <span className="mx-8" aria-hidden="true">
            |
          </span>
          {'+ '}
          {price}
        </span>
        <span className="s:hidden">+ {price}</span>
      </ActionButton>}
    </div>
  )
}

function getImageDimensions(donation) {
  if (donation?.iconImageAspectRatio === 'square') {
    return {
      width: 30,
      height: 30,
    }
  }

  if (donation?.iconImageAspectRatio === 'landscape') {
    return {
      width: 60,
      height: 24,
    }
  }

  return {
    width: 30,
    height: 30,
  }
}

function InfoIcon() {
  return (
    <svg viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.92097 5.18591C9.92097 7.77164 7.81425 9.87182 5.21049 9.87182C2.60673 9.87182 0.5 7.77164 0.5 5.18591C0.5 2.60018 2.60673 0.5 5.21049 0.5C7.81425 0.5 9.92097 2.60018 9.92097 5.18591Z"
        className="stroke-current"
      />
      <path
        d="M5.206 2.20117C4.90651 2.20117 4.6543 2.44435 4.6543 2.75028C4.6543 3.05621 4.89862 3.29939 5.206 3.29939C5.51339 3.29939 5.76559 3.05621 5.76559 2.75028C5.76559 2.44435 5.51339 2.20117 5.206 2.20117ZM5.64737 8.17078V4.14659H4.76464V8.17078H5.64737Z"
        className="fill-current"
      />
    </svg>
  )
}
