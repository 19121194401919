import MenuItem from 'components/Navigation/Mobile/MobileMenuItem'

export default function AddLinks({ items }) {
  return (
    <>
      <div className="w-full pt-18 border-b">
        <ul className="">
          {items?.map( (item) => {
            return (
              <MenuItem menuType={2} key={item._key} link={item}>
                {item.title}
              </MenuItem>
            )
          } )}
        </ul>
      </div>
      <div className={'pb-40'}></div>
    </>
  )
}
