import Link from 'next/link'
import linkResolver from '../lib/linkResolver'

const classes =
  'rounded hover:text-royal-blue hover:underline focus:outline-none focus-visible:ring-2 focus-visible:ring-navy-blue focus-visible:ring-offset-4 transition'

const bannerHeadingSerializers = {
  marks: {
    color: ({ children, mark }) => <span style={{ color: mark.hex }}>{children}</span>,
    externalLink: ({ children, mark }) => (
      <a
        className={classes}
        href={mark.url}
        target={mark.openInNewTab ? '_blank' : null}
        rel={mark.openInNewTab ? 'noopener noreferrer' : null}
      >
        {children}
      </a>
    ),
    internalLink: ({ children, mark }) => {
      const href = linkResolver(mark)
      if (mark.openInNewTab) {
        return (
          <a className={classes} href={href} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        )
      } else {
        return (
          <Link href={href} className={classes}>
            {children}
          </Link>
        )
      }
    },
  },
}

export default bannerHeadingSerializers
