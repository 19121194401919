import { useEffect, useState } from 'react'
import { useStore } from 'context/globalStore'
import { shopify } from 'lib/shopify'
const fetchedProductsCache = {}
const requestsQueueCache = {};
const promisesCache = {}
const fetchArg = (arg) => new Promise((res) => res(arg))
const getRequest = ({ slug, productId }, cc) => {
  const cached = fetchedProductsCache[productId]
  requestsQueueCache[productId] = true

  return cached ? fetchArg(cached) : shopify.fetchProduct(productId, cc)
}

export default function useShopifyProductPlus(sanityProduct) {
  const {
    store: { countryCode },
  } = useStore()
  const [isLoading, setIsLoading] = useState(true)
  const [products, setProducts] = useState([])
  const [variants, setVariants] = useState([])

  useEffect(() => {
    if (!countryCode || !sanityProduct || !isLoading) return;
    const variantQueue = []

    async function fetchProducts(requestPromise) {
      let product
      if (!requestPromise) {
        promisesCache[sanityProduct.productId] = getRequest(sanityProduct, countryCode)
        product = await promisesCache[sanityProduct.productId]
        fetchedProductsCache[sanityProduct.productId] = product
      } else if (fetchedProductsCache[sanityProduct.productId]) {
        product = fetchedProductsCache[sanityProduct.productId]
      } else {
        product = await requestPromise
        fetchedProductsCache[sanityProduct.productId] = product
      }
      if (!product) {
        setProducts([])
        setVariants([])
        setIsLoading(false)
        delete fetchedProductsCache[sanityProduct.productId]
        return;
      }
      product.variants?.forEach((variant) => {
        variantQueue.push({
          ...variant,
          cleanId: shopify.decode(variant.id),
        })
      })
      setProducts([product])
      setVariants(variantQueue)
      setIsLoading(false)
    }

    fetchProducts(promisesCache[sanityProduct.productId])

  }, [variants, products, countryCode, sanityProduct, isLoading])

  return { products, variants, setIsLoading }
}
