export const buildPageUTMValues = (pageProps) => {
    try {
        const params = new URLSearchParams(window.location.search)

        let pagePropsString = JSON.stringify(pageProps)
        for (const [key, value] of params.entries()) {
            if (key.indexOf('utm_') === 0) {
                const utmTarget = `#_${key.toUpperCase()}_#`
                pagePropsString = pagePropsString.replaceAll(utmTarget, value)
            }
        }
        return JSON.parse(pagePropsString)
    } catch (error) {
    }
    return pageProps
}