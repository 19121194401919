import React, { FC, useRef } from 'react'
import Image from 'components/Image'
import cx from 'classnames'
import getColorStyle, { getLastColorInGradient } from 'lib/getColorStyle'
import classNames from 'classnames'

interface Props {
  index: number
  multiLineArrangement: boolean
  category: string
  items: any[]
  position: string | number
  handleSwatchSelect: Function
  isOptionAvailable: (...args) => boolean
  selectedOption: string
  options: string
  optionSettings: any
  optionValueSettings: any
  showColorOnlyOnPlp: boolean
  plpSwatch?: boolean
}

interface PillSwatchoptionProps {
  option: string
  selectedOption: string
  position: string | number
  handleSwatchSelect: Function
  swatchIcon: any
  color: string
  colorStack: any
  options: string
  totalOptions: number
  containerRef: any
  settings: any
  optionSettings: any
  showColorOnlyOnPlp: boolean,
  description: string,
  plpSwatch?: boolean
  isLongList: boolean
}

const PillSwatchoption: FC<PillSwatchoptionProps> = ({
  option,
  color,
  position,
  handleSwatchSelect,
  swatchIcon,
  selectedOption,
  colorStack,
  options,
  totalOptions,
  containerRef,
  settings,
  optionSettings,
  showColorOnlyOnPlp,
  description,
  plpSwatch = false,
  isLongList,
}) => {
  if (!option) {
      return null;
  }
  const isSelected = selectedOption === option
  const background = colorStack ? getColorStyle(colorStack) : ''
  const label = options === 'Pack Size' ? `${option} Pack` : option
  const maxWidth = `calc(${(100 / totalOptions).toFixed(2)}%) - ${(totalOptions - 1) * 8}px`

  return (
    <li
      onClick={() => handleSwatchSelect(option, position)}
      className={cx('relative cursor-pointer h-auto my-5')}
      style={{
        maxWidth,
      }}
    >
      <div
        className={cx(
          'relative cursor-pointer flex justify-center flex-1 space-x-6 l:space-x-8 items-center px-9 l:px-12 py-8 border border-solid rounded-8 mr-8 last:mr-0 pill-selector', {
            'border-navy-blue text-navy-blue bg-light-blue': isSelected,
            'border-[#CBD3D9] text-navy-blue-muted': !isSelected,
            'min-h-48 mb-8': !plpSwatch,
            'min-h-48 l:min-w-36': plpSwatch,
            'flex-col gap-2': isLongList
          }
        )}
        style={{ minHeight: isLongList ? '6.8rem' : '4.8rem' }}
      >
        {(swatchIcon?.asset && !showColorOnlyOnPlp) ? (
          <div className="">
            <Image
              image={swatchIcon.asset}
              width={30}
              height={30}
              sizes="30px"
              placeholder={false}
            />
          </div>
        ) : (
          (background && !showColorOnlyOnPlp) && (
            <div>
              <div
                className="relative w-18 h-18 rounded-pill cursor-pointer border border-solid flex flex-shrink-0 flex-1"
                style={{
                  background,
                  borderColor: getLastColorInGradient(colorStack),
                }}
              ></div>
            </div>
          )
        )}
        <div
          className={classNames('text-11 l:text-13 leading-11 l:leading-15', {
            'text-center w-full': !background || showColorOnlyOnPlp || isLongList,
            'ml-5 l:ml-8': (background && !showColorOnlyOnPlp) || (swatchIcon?.asset && !showColorOnlyOnPlp),
            'whitespace-normal': label.length > 13 || totalOptions > 2,
            'whitespace-nowrap': label.length <= 13 && totalOptions < 3,
            'font-normal': !isSelected,
            'font-medium': isSelected,
          })}
          style={{ ...(isLongList ? { marginLeft: 0 } : {}) }}
        >
          {isLongList ? <>{label.split(' ')[0]}<br />{label.split(' ').slice(1).join(' ')}</> : label}
        </div>
      </div>
      {settings?.showDescription && (
        <div className="text-13 whitespace-normal flex-1 flex-shrink-0 font-normal text-navy-blue-muted pr-8 text-center mt-4">
          {optionSettings?.description || optionSettings?.text || description}
        </div>
      )}
    </li>
  )
}

const PillSwatchCategories: FC<Props> = ({
  index,
  multiLineArrangement,
  items,
  position,
  handleSwatchSelect,
  isOptionAvailable,
  selectedOption,
  options,
  optionSettings,
  optionValueSettings,
  showColorOnlyOnPlp,
  plpSwatch = false,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  const settings = optionSettings?.find((s) => s.forOption === options)

  const getOption = (option) => {
    return optionValueSettings?.find((s) => s.forOption === `${options}:${option}`)
  }

  const longList = 4;
  const isLongList = items.length >= longList

  return (
    <div
      className={cx('mr-0 mb-6 s:mb-0 w-full', {
        'm:border-none': index !== 0 && !multiLineArrangement,
        'm:pl-0 s:pl-0 xs:pl-0': index == 0 && !multiLineArrangement,
        'l:pl-0': index == 0 && !multiLineArrangement,
        'l:inline-block l:mt-0': !multiLineArrangement,
        '': multiLineArrangement,
        'l:mt-16 l:block': multiLineArrangement,
      })}
      ref={containerRef}
    >
      <div className="flex flex-col flex-wrap s:flex-row s:items-center z-1 l:max-w-600 w-full">
        <ul
          className={classNames(
            'product-option-swatch max-w-full hide-scrollbar w-full gap-x-8',
            {
              'grid grid-cols-1': items.length === 1,
              'grid grid-cols-2': items.length === 2,
              'grid grid-cols-3': items.length === 3,
              'grid grid-cols-4': isLongList,
            }
          )}
        >
          {items.map(({ _key, color, option, colorStack, swatchIcon, description }) => (
            <PillSwatchoption
              key={_key}
              option={option}
              swatchIcon={swatchIcon}
              description={description}
              color={color}
              position={position}
              handleSwatchSelect={handleSwatchSelect}
              selectedOption={selectedOption}
              colorStack={colorStack}
              options={options}
              totalOptions={items.length}
              containerRef={containerRef}
              settings={settings}
              optionSettings={getOption(option)}
              showColorOnlyOnPlp={showColorOnlyOnPlp}
              isLongList={isLongList}
              plpSwatch
            />
          ))}
        </ul>
      </div>
    </div>
  )
}

export default PillSwatchCategories
