import MenuItem from './MobileMenuItemV2'

export default function MobileSecondaryMenuV2({ items }) {
  return (
    <ul className="pt-32 pb-32 px-30">
      {items?.map((item) => {
        return (
          <MenuItem menuType={2} key={item._key} link={item}>
            {item.title}
          </MenuItem>
        )
      })}
    </ul>
  )
}
