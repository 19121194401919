import priceToCents from './priceToCents'
import getUUID from './generateUID'
import { shopify } from './shopify'
import debounce from 'lodash.debounce'
import cookie from 'lib/cookie'
import { BLU_UTMPARAMS_STORAGE_KEY, SELECTED_COUNTRY_CODE } from './constants'
import { get } from 'http'

const LIST_VALUE = {
  CURRENT_PATH: 1,
  PREVIOUS_PATH: 2,
}

const getUserData = () => {
  let userCookie = cookie.get('userData')
  let userData = userCookie ? JSON.parse(userCookie) : null
  return userData
}

function gtmProduct({ product, variant, quantity, positionIndex = 1 }, listValue = LIST_VALUE.CURRENT_PATH) {
  const _variant = variant || product.variants[0]
  const PID = product.id || product.productId
  const productId = isNaN(Number(PID)) && PID ? shopify.decode(PID) : PID
  const VID = (_variant && (_variant.id || _variant.variantId)) || ''
  const vid = isNaN(Number(VID)) && VID ? shopify.decode(VID) : VID
  const imageFromMedia = product.mediaGallery?.find(item => item?.hideFromPDP !== true && item?.image?._type === "image");
  const image = product?.shopifyImage?.asset?.url || _variant?.image?.src || _variant?.siloImage?.asset?.url || imageFromMedia?.image?.asset?.url || ""
  const category = product?.collections?.filter((item) => item.slug != 'all')[0] || {
    title: 'Shop All',
  }

  const fields = {
    id: _variant?.sku,
    name: product.title,
    brand: 'Blueland',
    category: category.title,
    compare_at_price: "0.0",
    product_id: productId?.toString(),
    variant: (_variant && _variant.title && _variant.title.replace("'", '')) || '',
    price: _variant?.price,
    quantity: quantity?.toString() ?? "1",
    position: positionIndex ?? 1,
    list: listValue === LIST_VALUE.CURRENT_PATH ? location.pathname : window.appHistory[0],
    product_id: productId?.toString(),
    variant_id: vid?.toString(),
    image,
    inventory: "99999"
  }

  const variantTitle = variant?.variantTitle ?? variant?.title

  if (variantTitle && variantTitle !== 'Default Title') {
    fields.variant = variantTitle
  }

  return fields
}

function klaviyoProduct({ product, variant, quantity }) {
  const image = product?.thumbnailImage ?? product?.shopifyImage ?? product?.variant?.image
  const cents =
    variant?.cents ?? product?.variants?.[0]?.cents ?? priceToCents(product?.variant?.price)

  const fields = {
    ProductName: product.title,
    ProductID: product.productId,
    SKU: variant?.sku ?? product.variants[0].sku,
    ImageURL: image?.asset?.url ?? image?.src,
    URL: `https://www.blueland.com/products/${product.slug}`,
    Brand: 'Blueland',
    Price: cents / 100,
  }

  if (quantity) {
    fields.Quantity = quantity
  }

  return fields
}

export function format(label) {
  return label.split(' ').join('').replace("'", '')
}

export function track(payload = {}) {
  setTimeout(() => {
    try {

      window.ElevarDataLayer.push({
        event: 'track',
        ...payload,
      })
    }
    catch (error) {
      console.error("Error occurred in tracking", error)
    }
  }, 3000)
}

export function trackButtonClick(label) {
  if (typeof label === 'string' && label?.length) {
    track({
      eventDetail: {
        category: 'Button',
        action: 'Click',
        label: format(label),
      },
    })
  }
}

export function trackNavClick(label) {
  if (typeof label === 'string' && label?.length) {
    track({
      eventDetail: {
        category: 'Nav',
        action: 'Click',
        label: format(label),
      },
    })
  }
}

export function trackFilterSortClick(label) {
  if (typeof label === 'string' && label?.length) {
    track({
      eventDetail: {
        category: 'FilterSort',
        action: 'Click',
        label: format(label),
      },
    })
  }
}

export function trackScrollClick(label) {
  if (typeof label === 'string' && label?.length) {
    track({
      eventDetail: {
        category: 'Scroll',
        action: 'Click',
        label: format(label),
      },
    })
  }
}

export function trackPdpClick(label, value) {
  if (typeof label === 'string' && label?.length) {
    const eventDetail = {
      category: 'PDP',
      action: 'Click',
      label: format(label),
    }

    if (typeof value !== 'undefined') {
      eventDetail.value = format(value)
    }

    track({ eventDetail })
  }
}

export function trackCartClick(label) {
  if (typeof label === 'string' && label?.length) {
    track({
      eventDetail: {
        category: 'Cart',
        action: 'Click',
        label: format(label),
      },
    })
  }
}

export function trackEmailCapture() {
  track({
    eventDetail: {
      category: 'Field',
      action: 'EmailEntry',
      label: 'EmailCapture',
    },
  })
}

export function trackSearchTerm(searchTerm) {
  track({
    event: 'searchTerm',
    event_id: getUUID(),
    event_time: new Date().toISOString(),
    searchTerm: searchTerm,
  })
}

export function trackSearchResultsView(searchResults = []) {
  track({
    event: 'dl_view_search_results',
    user_properties: getUserProperties(),
    ecommerce: {
      currencyCode: 'USD',
      actionField: {
        list: "search results",
      },
      impressions: getImpressions(searchResults),
    },
  })
}

export function trackCartView(lineItemsToTrack = [], cartTotal = "0") {
  track({
    event: 'dl_view_cart',
    user_properties: getUserProperties(),
    cart_total: cartTotal,
    ecommerce: {
      currencyCode: 'USD',
      actionField: { list: "Shopping Cart" },
      impressions: getImpressions(lineItemsToTrack),
    },
  })
}

export function trackCollectionPageView(products = []) {
  track({
    event: 'dl_view_item_list',
    user_properties: getUserProperties(),
    ecommerce: {
      currencyCode: 'USD',
      impressions: getImpressions(products),
    },
  })
}

export function trackProductClick(product) {
  if (typeof product !== 'object') return

  if (product.hasOwnProperty('id') || product.hasOwnProperty('name')) {
    track({
      event: 'dl_select_item',
      user_properties: getUserProperties(),
      ecommerce: {
        currencyCode: 'USD',
        click: {
          actionField: { list: location.pathname, action: "click", },
          products: [product], // TODO: Unify to use gtmProduct function
        },
      },
    })
  }
}

export function trackProductClickFromSearch(product, searchTerm) {
  if (typeof product !== 'object') return

  if (product.hasOwnProperty('id') || product.hasOwnProperty('name')) {
    const utmParams = JSON.parse(localStorage.getItem(BLU_UTMPARAMS_STORAGE_KEY)) || {}
    track({
      event: 'search_select_item',
      searchTerm: searchTerm,
      event_id: getUUID(),
      event_time: new Date().toISOString(),
      ecommerce: {
        currencyCode: 'USD',
        click: {
          actionField: { list: location.pathname },
          products: [product], // TODO: Unify to use gtmProduct function
        },
      },
      eventDetail: {
        category: 'Search',
        action: 'ProductClick',
      },
    })
  }
}

const getProductDetailViewData = (product, list) => {
  track({
    event: 'dl_view_item',
    user_properties: getUserProperties(),
    ecommerce: {
      currencyCode: 'USD',
      detail: {
        actionField: { list: location.pathname, action: "detail" },
        products: [gtmProduct(product, list)],
      },
    },
    // klaviyo: klaviyoProduct(product),
  })
}

export function trackProductDetailView(product) {
  if (window.appHistory)
    window.appHistory.onResolve().then(() => {
      track(getProductDetailViewData(product, LIST_VALUE.PREVIOUS_PATH))
    })
  else track(getProductDetailViewData(product, LIST_VALUE.CURRENT_PATH))
}

export function trackAddToCart(lineItemsToTrack = []) {
  lineItemsToTrack = [].concat(lineItemsToTrack)
  const utmParams = JSON.parse(localStorage.getItem(BLU_UTMPARAMS_STORAGE_KEY)) || {}
  track({
    event: 'dl_add_to_cart',
    user_properties: getUserProperties(),
    ecommerce: {
      currencyCode: 'USD',
      add: {
        actionField: { list: location.pathname },
        products: lineItemsToTrack.map(({ product, variant, quantity, positionIndex }) =>
          gtmProduct({ product, variant, quantity, positionIndex }, LIST_VALUE.PREVIOUS_PATH),
        ),
      },
    },
    /*klaviyo: {
      add: {
        products: lineItemsToTrack.map(({ product, variant, quantity }) =>
          klaviyoProduct({ product, variant, quantity }),
        ),
      },
    },*/
  })
}

export function trackUserData(cartTotal, lineItems) {
  track({
    "event": "dl_user_data",
    "cart_total": cartTotal,
    user_properties: getUserProperties(),
    ecommerce: {
      currencyCode: 'USD',
      cart_contents: {
        products: [...lineItems]
      },
    },
  })
}

export function trackRemoveFromCart(lineItemsToTrack = []) {
  lineItemsToTrack = [].concat(lineItemsToTrack)
  track({
    event: 'dl_remove_from_cart',
    user_properties: getUserProperties(),
    ecommerce: {
      currencyCode: 'USD',
      remove: {
        actionField: { list: location.pathname },
        products: lineItemsToTrack.map(({ product, variant, quantity, positionIndex }) =>
          gtmProduct({ product, variant, quantity, positionIndex }),
        ),
      },
    },
  })
}

export function getLinkerParam(id) {
  if (global?.ga && global?.ga?.getAll) {
    const trackers = global.ga.getAll()
    for (let i = 0; i < trackers.length; i++) {
      const tracker = trackers[i]
      if (!id || tracker.get('trackingId') === id) {
        return tracker.get('linkerParam')
      }
    }
  }
}


const getImpressions = (products = []) => {
  return products.map(x => {
    let productId
    let variantId
    let price
    let sku

    if (x.customAttributes) {
      productId = x.variant.product.id.substring(x.variant.product.id.lastIndexOf("/") + 1)
      variantId = x.variant.id.substring(x.variant.id.lastIndexOf("/") + 1)
      price = x.variant.price
      sku = x.variant.sku
    }

    return {
      id: sku || x.variants?.[0]?.sku,
      name: x.title,
      brand: 'Blueland',
      category: x.collections?.[0].title || "",
      variant: (x.variants?.[0] && x.variants[0].title && x.variants[0].title.replace("'", '')) || '',
      price: price || (x.cents / 100)?.toString(),
      quantity: "1",
      list: location.pathname,
      product_id: productId || x._id?.toString(),
      variant_id: variantId || x.variants?.[0]?.variantId?.toString(),
      position: x.positionIndex ?? 1,
    }

  }).slice(0, 20)

  // TODO: Consider slice value
}

const getUserProperties = () => {
  let userData = getUserData()

  return {
    customer_id: userData?.user_id,
    customer_email: userData?.email,
    customer_country: sessionStorage.getItem(SELECTED_COUNTRY_CODE) || "US",
    user_consent: "",
    visitor_type: userData?.user_id ? "logged_in" : "guest",
  }
}