import { useRef, useEffect, useState } from 'react'
import cx from 'classnames'
import BlockContent from '@sanity/block-content-to-react'
import serializers from 'serializers/justLinks'
import TextButton from 'components/TextButton'
import Button from 'components/Button'
import { screens } from 'lib/constants'
import useMedia from 'use-media'

export default function CookieNotice({ isEnabled, content }) {
  const [isHidden, setIsHidden] = useState(true)
  const isScreenMedium = useMedia({ minWidth: screens.m })
  const isInit = useRef(false)
  const key = 'cookie_notice_timestamp'

  function handleClick() {
    setIsHidden(true)
    localStorage.setItem(key, Date.now())
  }

  useEffect(() => {
    if (typeof window === 'undefined') return
    if (isInit.current) return
    const timestamp = localStorage.getItem(key)
    const isExpired = (ms) => {
      const THIRTY_DAYS = 30 * 24 * 60 * 60 * 1000
      const elapsed = Date.now() - ms
      return elapsed >= THIRTY_DAYS
    }

    if (timestamp === null || isExpired(timestamp)) {
      setTimeout(() => {
        setIsHidden(false)
      }, 5000)
    }

    isInit.current = true
  }, [])

  if (isEnabled) {
    return (
      <div
        className={cx(
          'fixed z-6 w-full bottom-0 pt-35 pb-30 px-45 l:px-60 xl:px-80 flex flex-col m:flex-row justify-between items-center space-y-20 m:space-y-0 m:space-x-40 bg-alice-blue shadow-bottom-bar transition duration-750 ease-in-out-cubic',
          { 'transform translate-y-full': isHidden },
        )}
        role="dialog"
        aria-modal="false"
        aria-labelledby="cookie-notice-title"
        aria-describedby="cookie-notice-body"
        inert={isHidden ? '' : null}
      >
        <div className="w-full max-w-590 text-14">
          <div id="cookie-notice-title" className="sr-only">
            Cookie Notice
          </div>
          <BlockContent blocks={content} serializers={serializers} />
        </div>
        <div className="flex flex-shrink-0 items-baseline space-x-15 m:space-x-30 pb-5 self-end m:self-auto">
          <TextButton size={isScreenMedium ? 'large' : 'compact'} onClick={handleClick}>
            Dismiss
          </TextButton>
          <Button size={isScreenMedium ? 'large' : 'compact'} onClick={handleClick}>
            {`Accept${isScreenMedium ? ` Cookies` : ``}`}
          </Button>
        </div>
      </div>
    )
  } else {
    return null
  }
}
