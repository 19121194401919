import cx from 'classnames'
import { screens } from 'lib/constants'
import { useMemo } from 'react'
import useMedia from 'use-media'

export default function ProgressBar({
  percentage,
  thresholdIndex = undefined,
  className,
  tiers = [],
  color = '#003057',
}) {
  const isScreenLarge = useMedia({ minWidth: screens.s })

  const progressDisplayed = useMemo(() => {
    if (thresholdIndex === undefined) return percentage

    if (thresholdIndex < 0) {
      if (percentage > 0) return percentage

      return 0
    }

    if (percentage === 1) return 1
    return percentage
  }, [percentage, tiers, thresholdIndex])

  return (
    <div
      className={cx('relative border border-current rounded-pill mx-auto', className)}
      style={{ width: isScreenLarge ? '460px' : '85%', color }}
    >
      {tiers.map(({ title, price, percentage: tierPrecentage }, index) => {
        return (
          <>
            <div
              className="absolute"
              style={{
                top: isScreenLarge ? '-24px' : '-20px',
                left: `calc(${(100 / tiers?.length) * (index + 1)}% - 16px)`,
              }}
            >
              <div className="progressBarText">{price}</div>
            </div>
            <div
              className="absolute z-10"
              style={{
                top: isScreenLarge ? '-5.75px' : '-4.5px',
                left: `calc(${(100 / tiers?.length) * (index + 1)}% - 10px)`,
              }}
            >
              <div
                className={cx('w-14 h-14  bg-navy-blue', {
                  'bg-white': tierPrecentage > percentage,
                  hidden: tierPrecentage <= percentage,
                })}
                style={{
                  borderColor: color,
                  borderWidth: '1px',
                  borderRadius: '50%',
                }}
              />
              <svg
                viewBox="0 0 15 15"
                fill="none"
                style={{
                  borderColor: color,
                  borderWidth: '1px',
                  borderRadius: '50%',
                }}
                xmlns="http://www.w3.org/2000/svg"
                // className='bg-navy-blue rounded-50'
                className={cx('w-14 h-14 z-20 bg-navy-blue', {
                  hidden: tierPrecentage > percentage,
                  'bg-navy-blue': tierPrecentage <= percentage,
                })}
              >
                <path d="M14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7Z" />
                <path
                  d="M3 7.69231L5.52632 10L11 5"
                  stroke="white"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div
                className={cx('font-medium mt-5 l:mt-4', {
                  'prog-bar-m1': index == 0,
                  'min-w-100 -ml-85': index == 1,
                  'prog-bar-m3': index == 2,
                })}
              >
                <div className="progressBarText font-medium">{title}</div>
              </div>
            </div>
          </>
        )
      })}
      <div
        className="absolute inset-0 bg-current origin-left transition duration-500 ease-in-out-sine"
        style={{ transform: `scaleX(${progressDisplayed})` }}
      />
    </div>
  )
}
