import MenuItem from 'components/Navigation/Mobile/MobileMenuItem';
import React, { useState } from 'react';
import Chevron from 'components/Icons/Chevron';

export default function SecondaryMenu({ items }) {
  const [isExpanded, setIsExpanded] = useState( false );
  const rectHeight = items?.length * 46;

  const toggleExpansion = () => {
    setIsExpanded( !isExpanded );
  };

  return (
    <div className="w-full pt-18 border-b">
      <div
        className={'flex justify-between'}
        onClick={toggleExpansion}
      >
        <h5 className={'text-16 font-medium pb-18'}>Mission</h5>
        <div className="w-12 h-12 mt-7 relative flex justify-center">
          <Chevron direction={isExpanded ? 'up' : 'down'} className={'w-14 h-14'}/>
        </div>
      </div>
      <ul className={'text-13 overflow-y-hidden transition-all ease-in-out'}
          style={{
            maxHeight: isExpanded ? rectHeight : 0
          }}>
        {items?.map( (item) => (
          <MenuItem menuType={2} key={item._key} link={item}>
            {item.title}
          </MenuItem>
        ) )}
      </ul>
    </div>
  );
}