import getProductDetails from './getProductDetails'
import getPrice from '../../../lib/getPrice'
import { getSubDiscountedPrice } from '../../../lib/getDiscountedPrice'
import { shopify } from 'lib/shopify'

const addToCartAsync = async (config, filteredLineItems, setExcludedProductsPrice, allProducts, subtotalPriceAmount, removingItem, addToCart, setGiftJustAdded, updateCartItems, store) => {
  const isTriggerProductAdded = config?.gwpIncludedProducts?.some(gwpProduct =>
    gwpProduct.variants.some(variant =>
      filteredLineItems.some(item => shopify.decode(item.variant.id) === variant.variantId)
    )
  )
  const isTriggerQuantity = config?.gwpIncludedProducts?.some(gwpProduct =>
    gwpProduct.variants.some(variant =>
      filteredLineItems.some(item => shopify.decode(item.variant.id) === variant.variantId && item.quantity >= config?.quantityForTriggerGiftProduct)
    )
  )
  const isGiftProductAdded = config?.giftWithPurchase?.some(gwpProduct =>
    filteredLineItems.some(item => shopify.decode(item.variant.id) === gwpProduct.variantId && item.customAttributes.some(attr => attr.key === '__gift-item'))
  )
  const isGwpAdded = filteredLineItems.some(item =>
    item.discountAllocations.some(discountAllocation =>
      discountAllocation.discountApplication.code === config?.promoCode
    )
  );
  const giftItemToUpdate = [
    {
      id: filteredLineItems[0]?.id,
      quantity: filteredLineItems[0]?.quantity,
    },
  ]
  const getTotalWithSubscription = filteredLineItems.reduce((total, item) => {
    if (item.customAttributes.some(attr => attr.key === "subscription_id")) {
      const product = getProductDetails(item, allProducts)
      const { original } = getPrice(item.variant, item.quantity, (price) => price)
      const subscriptionPrice = getSubDiscountedPrice(original, product)
      return total - (original - subscriptionPrice)
    } else {
      return total
    }
  }, parseFloat(subtotalPriceAmount));

  const excludedProductsPrice = filteredLineItems.reduce((total, item) => {
    if (item.variant.product.productType === "Gift Cards" || item.variant.product.productType === "Donation") {
      return total - item.variant.price.amount * item.quantity;
    }
    if (config?.giftWithPurchase && shopify.decode(item.variant.id) === config?.giftWithPurchase[0]?.variant?.variantId && !isGwpAdded && item.customAttributes.some(attr => attr.key === '__gift-item')) {
      total = total - item.variant.price.amount;
    }
    return total;
  }, parseFloat(getTotalWithSubscription));

  setExcludedProductsPrice(excludedProductsPrice);

  if (config?.promoType === "FREE_GIFT" && isTriggerProductAdded && !isGiftProductAdded && !removingItem && config.thresholdAmountForGift <= 0 && isTriggerQuantity && localStorage.getItem('isGwpRemoved') !== 'true' && store.countryCode === 'US') {
    await addToCart({
      product: config.giftWithPurchase[0].product,
      shouldSetIsAdding: true,
      payload: {
        variant: config.giftWithPurchase[0],
        planProducts: [config.giftWithPurchase[0].product],
        quantity: 1,
        attributes: [
          {
            key: '__gift-item',
            value: 'true',
          },
        ],
      },
    })
    await updateCartItems(giftItemToUpdate)
    setGiftJustAdded(true)
  } else if (config?.promoType === "FREE_GIFT" && config.thresholdAmountForGift > 0 && excludedProductsPrice >= config.thresholdAmountForGift && !removingItem && !isGwpAdded && !isGiftProductAdded && localStorage.getItem('isGwpRemoved') !== 'true' && store.countryCode === 'US') {
    await addToCart({
      product: config.giftWithPurchase[0].product,
      shouldSetIsAdding: true,
      payload: {
        variant: config.giftWithPurchase[0],
        planProducts: [config.giftWithPurchase[0].product],
        quantity: 1,
        attributes: [
          {
            key: '__gift-item',
            value: 'true',
          },
        ],
      },
    })
    await updateCartItems(giftItemToUpdate)
    setGiftJustAdded(true)
  }
}

export default addToCartAsync