export default function isBannerAvailableOnPageLevel(config, router) {
  return (
    config?.cartBanner &&
    config?.enablePromoOnPageLevel &&
    config?.pagesToApplyPromo?.some(
      (page) =>
        (page.slug.current === 'home' && router.asPath === '/') ||
        router.asPath.includes(page.slug.current)
    )
  );
}