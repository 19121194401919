import * as gid from 'shopify-gid'
import { BLU_UTMPARAMS_STORAGE_KEY } from 'lib/constants'
import pDebounce from 'p-debounce'
import axios from 'axios'
import { CUSTOM_EVENT_ERROR_ALERT } from '../components/ErrorAlert/ErrorAlert'

export const shopify = createClient({
  domain: process.env.SHOPIFY_DOMAIN,
  token: process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN,
})

function createClient({ domain, token }) {
  return {
    encode,
    decode,
    legacyDecode,
    query,
    fetchCart: (id) =>
      query(FETCH_CART, {id: id}).then((data) => data?.cart),
    removeCartDiscount: (id) =>
      query(FETCH_CART, {id: id}).then((data) => data),
    addCartDiscount: (id, discountCodes) =>
      query(FETCH_CART, {id: id, discountCodes: discountCodes}).then((data) => data),
    removeCartItems: (id, lineIds) =>
      query(REMOVE_CART_ITEMS, {id: id, lineIds: lineIds}).then((data) => data),
    addCartLines: (id, lines) =>
      query(ADD_CART_LINES, {id: id, lines: lines}).then((data) => data),
    updateCartLines: (id, lines) =>
      query(UPDATE_CART_LINES, { cartId: id, lines }).then((data) => data.cartLinesUpdate.cart),
    updateCartBuyerIdentity: (id, buyerIdentity) =>
      query(UPDATE_CART_BUYER_IDENTITY, {id: id, buyerIdentity: buyerIdentity}).then((data) => data),
    fetchProduct: (id, country = 'US') =>
      query(PRODUCT_FETCH, { id: encode(id), country }).then((data) => data?.node),
    createCheckout: (country = 'US') =>
      query(CHECKOUT_CREATE, { country }).then((data) => data.checkoutCreate.checkout),
    createCartV2: (cartInput) =>
      query(CART_CREATE_V2, { input: cartInput }).then((data) => data.cartCreate.cart),
    fetchCheckout: (id, country) =>
      query(CHECKOUT_FETCH, { id, country }).then((data) => data.node),
    fetchCheckoutUrlV2: (id) =>
      query(CHECKOUT_URL_FETCH_V2, { cartId: id }).then((data) => data),
    addLineItems: (checkoutId, lineItems, country) =>
      query(LINE_ITEMS_ADD, { checkoutId, lineItems, country }).then((data) => {
        return data?.checkoutLineItemsAdd?.checkout
      }),
    removeLineItems: (checkoutId, lineItemIds, country) =>
      query(LINE_ITEMS_REMOVE, { checkoutId, lineItemIds, country }).then(
        (data) => data.checkoutLineItemsRemove?.checkout
      ),
    updateLineItems: (checkoutId, lineItems, country) =>
      query(LINE_ITEMS_UPDATE, { checkoutId, lineItems, country }).then(
        (data) => data.checkoutLineItemsUpdate.checkout
      ),
    updateCheckoutAttributes: (checkoutId, input, country) =>
      query(CHECKOUT_ATTRIBUTES_UPDATE, { checkoutId, input, country }).then(
        (data) => data.checkoutAttributesUpdateV2.checkout
      ),
    updateCartAttributes: (cartId, attributes) => 
      query(CART_ATTRIBUTES_UPDATE, { cartId, attributes }).then((data) => data.cartAttributesUpdate.cart),
    addDiscount: pDebounce(
      async (checkoutId, discountCode, country, SOURCE = '') => {
        return query(
          DISCOUNT_ADD,
          { checkoutId, discountCode, country },
          '2024-01',
          `[ADD DISCOUNT] ${SOURCE}`
        ).then((data) => {
          return data?.checkoutDiscountCodeApplyV2?.checkout
        })
      },
      200,
      { before: true }
    ),
    customerRecoverPassword: (email) =>
      query(CUSTOMER_RECOVER_PASSWORD, { email }).then((data) => data),
    removeDiscount: pDebounce(
      (checkoutId, country, SOURCE = '') =>
        query(
          DISCOUNT_REMOVE,
          { checkoutId, country },
          '2024-01',
          `[REMOVE DISCOUNT] ${SOURCE}`
        ).then((data) => {
          return data?.checkoutDiscountCodeRemove?.checkout
        }),
      200,
      { before: true }
    ),
    fetchRecommendations: (productId, intent) =>
      query(RECOMMENDATIONS_FETCH, { productId, intent }).then(
        data => data?.productRecommendations?.map((item) => decode(item.id).toString()) ?? [])
  }

  function encode(id, type = 'Product') {
    return `gid://shopify/${type}/${id}`
  }

  function decode(id) {
    let startIndex = id.lastIndexOf('/') + 1
    return parseInt(id.slice(startIndex), 0)
  }

  function legacyDecode(id) {
    return parseInt(gid.decode(id).id, 0)
  }

  function getUtmQueryParams() {
    let query = ''
    try {
      const utmParams = JSON.parse(localStorage.getItem(BLU_UTMPARAMS_STORAGE_KEY)) || {}
      for (let param in utmParams) {
        const encodeUri = encodeURIComponent(utmParams[param])
        query += `${query ? '&' : ''}${param}=${encodeUri}`
      }
      if (query) query = '?'.concat(query)
    } catch {
      query = ''
    }

    return query
  }

  function query(gql, v = {}, apiVersion = '2024-01', action = '') {
    const utmParamsQuery = getUtmQueryParams()
    return fetch(`https://${domain}/api/${apiVersion}/graphql${utmParamsQuery}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': token,
      },
      body: JSON.stringify({
        query: gql,
        variables: v,
      }),
    })
      .then((res) => {
        return res.json()
      })
      .then(clean)
      .then((json) => {
        if (json.errors) {
          console.error(`[Shopify Error]: ${action}`, json.errors)

          const event = new CustomEvent(CUSTOM_EVENT_ERROR_ALERT, {
            detail: { message: 'Something went wrong. Please refresh the page or go back to try again.' }
          });
          window.dispatchEvent(event);
        }

        return json.data
      })
      .catch((err) => console.error(err))
  }
}

export const fetchCustomerSegmentMembership = async (customerId, segmentIds) => {
  try {
    const response = await axios.get('https://tfon8mrtf7.execute-api.us-east-1.amazonaws.com/default/CheckIfCustomerInSegment', {
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        customerId: customerId,
        segmentIds: JSON.stringify(segmentIds),
      }
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

/**
 * Remove edges, node and __typename from graphql response
 *
 * @param {Object} input - The graphql response
 * @returns {Object} Clean graphql response
 */
function clean(input) {
  if (!input) return null
  const output = {}
  const isObject = (obj) => {
    return obj !== null && typeof obj === 'object' && !Array.isArray(obj)
  }

  Object.keys(input).forEach((key) => {
    if (input[key] && input[key].edges) {
      output[key] = input[key].edges.map((edge) => clean(edge.node))
    } else if (isObject(input[key])) {
      output[key] = clean(input[key])
    } else if (key !== '__typename') {
      output[key] = input[key]
    }
  })
  return output
}

const CART_ATTRIBUTES_UPDATE = `
    mutation cartAttributesUpdate($cartId: ID!, $attributes: [AttributeInput!]!) {
      cartAttributesUpdate(cartId: $cartId, attributes: $attributes) {
        cart {
          id
          attributes {
            key
            value
          }
        }
        userErrors {
          field
          message
        }
      }
    }
  `;

const UPDATE_CART_BUYER_IDENTITY = `
  mutation cartBuyerIdentityUpdate($id: ID!, $buyerIdentity: CartBuyerIdentityInput!) {
    cartBuyerIdentityUpdate(cartId: $id, buyerIdentity: $buyerIdentity) {
      cart {
        id
        buyerIdentity {
          email
          phone
          customer {
            id
          }
        }
      }
      userErrors {
        field
        message
      }
    }
  }
`

const ADD_CART_DISCOUNT_CODES = `
  mutation cartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!]) {
    cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
      cart {
        id
        discountCodes {
          code
        }
      }
      userErrors {
        field
        message
      }
    }
  }
`

const ADD_CART_LINES = `
  mutation cartLinesAdd($id: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $id, lines: $lines) {
      cart {
        id
        lines(first: 5) {
          edges {
            node {
              id
              quantity
              merchandise {
                ... on ProductVariant {
                  id
                  product {
                    title
                  }
                }
              }
            }
          }
        }
      }
      userErrors {
        field
        message
      }
    }
  }
`

const UPDATE_CART_LINES = `
  mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        id
        lines(first: 5) {
          edges {
            node {
              id
              quantity
              merchandise {
                ... on ProductVariant {
                  id
                  product {
                    title
                  }
                }
              }
            }
          }
        }
      }
      userErrors {
        field
        message
      }
    }
  }
`;

const REMOVE_CART_DISCOUNT = `
  mutation cartDiscountCodesRemove($id: ID!) {
    cartDiscountCodesRemove(cartId: $id) {
      cart {
        id
        discountCodes {
          code
        }
      }
      userErrors {
        field
        message
      }
    }
  }
`

const REMOVE_CART_ITEMS = `
  mutation cartLinesRemove($id: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $id, lineIds: $lineIds) {
      cart {
        id
        lines(first: 5) {
          edges {
            node {
              id
              merchandise {
                ... on ProductVariant {
                  product {
                    title
                  }
                }
              }
            }
          }
        }
      }
      userErrors {
        field
        message
      }
    }
  }
`

const FETCH_CART = `
  query fetchCart($id: ID!) {
      cart(id: $id) {
          id
          createdAt
          updatedAt
          lines(first: 30) {
              edges {
                  node {
                      id
                      merchandise {
                        ... on ProductVariant {
                            id
                        }
                    }
                  }
              }
          }
          attributes {
              key
              value
          }
      }
  }
`

const VARIANT_FRAGMENT = `
  fragment VariantFragment on ProductVariant {
    id
    price {
      amount
      currencyCode
    }
    weight
    available: availableForSale
    sku
    compareAtPrice {
      amount
      currencyCode
    }
    image {
      id
      src: originalSrc
      altText
    }
    selectedOptions {
      name
      value
    }
  }
`

const CHECKOUT_FRAGMENT = `
  fragment MailingAddressFragment on MailingAddress {
    id
    address1
    address2
    city
    company
    country
    firstName
    formatted
    lastName
    latitude
    longitude
    phone
    province
    zip
    name
    countryCode: countryCodeV2
    provinceCode
  }
  fragment DiscountApplicationFragment on DiscountApplication {
    targetSelection
    allocationMethod
    targetType
    value {
      ... on MoneyV2 {
        amount
        currencyCode
      }
      ... on PricingPercentageValue {
        percentage
      }
    }
    ... on ManualDiscountApplication {
      title
      description
    }
    ... on DiscountCodeApplication {
      code
      applicable
    }
    ... on AutomaticDiscountApplication {
      title
    }
  }
  fragment AppliedGiftCardFragment on AppliedGiftCard {
    amountUsedV2 {
      amount
      currencyCode
    }
    balanceV2 {
      amount
      currencyCode
    }
    presentmentAmountUsed {
      amount
      currencyCode
    }
    id
    lastCharacters
  }
  ${VARIANT_FRAGMENT}
  fragment VariantWithProductFragment on ProductVariant {
    ...VariantFragment
    product {
      id
      handle
      productType
    }
  }

  fragment CheckoutFragment on Checkout {
    id
    ready
    requiresShipping
    note
    webUrl
    orderStatusUrl
    taxExempt
    taxesIncluded
    currencyCode
    lineItemsSubtotalPrice {
      amount
      currencyCode
    }
    subtotalPrice {
      amount
      currencyCode
    }
    totalPrice {
      amount
      currencyCode
    }
    completedAt
    createdAt
    updatedAt
    email
    discountApplications(first: 10) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...DiscountApplicationFragment
        }
      }
    }
    appliedGiftCards {
      ...AppliedGiftCardFragment
    }
    shippingAddress {
      ...MailingAddressFragment
    }
    shippingLine {
      handle
      price {
        amount
        currencyCode
      }
      title
    }
    customAttributes {
      key
      value
    }
    order {
      id
      processedAt
      orderNumber
      subtotalPrice {
        amount
        currencyCode
      }
      totalShippingPrice {
        amount
        currencyCode
      }
      totalTax {
        amount
        currencyCode
      }
      totalPrice {
        amount
        currencyCode
      }
      currencyCode
      totalRefunded {
        amount
        currencyCode
      }
      customerUrl
      shippingAddress {
        ...MailingAddressFragment
      }
      lineItems (first: 250) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            title
            variant {
              ...VariantWithProductFragment
            }
            quantity
            customAttributes {
              key
              value
            }
          }
        }
      }
    }
    lineItems (first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          title
          variant {
            ...VariantWithProductFragment
          }
          quantity
          customAttributes {
            key
            value
          }
          discountAllocations {
            allocatedAmount {
              amount
              currencyCode
            }
            discountApplication {
              ...DiscountApplicationFragment
            }
          }
        }
      }
    }
    buyerIdentity {
      countryCode
    }
  }
`

const ERROR_FRAGMENTS = `
  fragment UserErrorFragment on UserError {
    field
    message
  }
  fragment CheckoutUserErrorFragment on CheckoutUserError {
    field
    message
    code
  }
`

const CHECKOUT_CREATE = `
  ${ERROR_FRAGMENTS}
  ${CHECKOUT_FRAGMENT}
  mutation ($country: CountryCode!) @inContext(country: $country) {
    checkoutCreate(input: {
      buyerIdentity: {
        countryCode: $country
      }
    }) {
      userErrors {
        ...UserErrorFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
`

const CHECKOUT_FETCH = `
  ${CHECKOUT_FRAGMENT}
  query ($country: CountryCode!, $id: ID!) @inContext(country: $country) {
    node(id: $id) {
      ...CheckoutFragment
    }
  }  
`

const LINE_ITEMS_ADD = `
  ${ERROR_FRAGMENTS}
  ${CHECKOUT_FRAGMENT}
  mutation ($country: CountryCode!, $checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!) @inContext(country: $country) {
    checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
      userErrors {
        ...UserErrorFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
`

const LINE_ITEMS_REMOVE = `
  ${ERROR_FRAGMENTS}
  ${CHECKOUT_FRAGMENT}
  mutation ($country: CountryCode!, $checkoutId: ID!, $lineItemIds: [ID!]!) @inContext(country: $country) {
    checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
      userErrors {
        ...UserErrorFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
`

const LINE_ITEMS_UPDATE = `
  ${ERROR_FRAGMENTS}
  ${CHECKOUT_FRAGMENT}
  mutation ($country: CountryCode!, $checkoutId: ID!, $lineItems: [CheckoutLineItemUpdateInput!]!) @inContext(country: $country) {
    checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
      userErrors {
        ...UserErrorFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
`

const CHECKOUT_ATTRIBUTES_UPDATE = `
  ${ERROR_FRAGMENTS}
  ${CHECKOUT_FRAGMENT}
  mutation checkoutAttributesUpdateV2($country: CountryCode!, $checkoutId: ID!, $input: CheckoutAttributesUpdateV2Input!) @inContext(country: $country) {
    checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
      userErrors {
        ...UserErrorFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
`

const DISCOUNT_ADD = `
  ${ERROR_FRAGMENTS}
  ${CHECKOUT_FRAGMENT}
  mutation checkoutDiscountCodeApplyV2($country: CountryCode!, $discountCode: String!, $checkoutId: ID!) @inContext(country: $country) {
    checkoutDiscountCodeApplyV2(discountCode: $discountCode, checkoutId: $checkoutId) {
      userErrors {
        ...UserErrorFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
`

const DISCOUNT_REMOVE = `
  ${ERROR_FRAGMENTS}
  ${CHECKOUT_FRAGMENT}
  mutation checkoutDiscountCodeRemove($country: CountryCode!, $checkoutId: ID!) @inContext(country: $country) {
    checkoutDiscountCodeRemove(checkoutId: $checkoutId) {
      userErrors {
        ...UserErrorFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
`

const PRODUCT_FRAGMENT = `
  fragment ProductFragment on Product {
    id
    createdAt
    updatedAt
    descriptionHtml
    description
    handle
    productType
    title
    vendor
    publishedAt
    onlineStoreUrl
    requiresSellingPlan
    sellingPlanGroups(first: 1) {
      edges {
        node {
          name
          sellingPlans(first: 10) {
            edges {
              node {
                id
                name
                description
                priceAdjustments {
                  adjustmentValue
                  orderCount
                }
              }
            }
          }
        }
      }
    }
    options {
      name
      values
    }
    images(first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          src
          altText
        }
      }
    }
    priceRange {
      minVariantPrice {
        amount
        currencyCode
      }
      maxVariantPrice {
        amount
        currencyCode
      }
    }
    variants(first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...VariantFragment
        }
      }
    }
  }
`

const CART_CREATE_V2 = `
mutation createCart($input: CartInput) {
  cartCreate(
    input: $input
  ) {
    cart {
      id
      createdAt
      updatedAt
      lines(first: 30) {
        edges {
          node {
            id
            merchandise {
              ... on ProductVariant {
                id
              }
            }
          }
        }
      }
      attributes {
        key
        value
      }
      estimatedCost {
        totalAmount {
          amount
          currencyCode
        }
        subtotalAmount {
          amount
          currencyCode
        }
        totalTaxAmount {
          amount
          currencyCode
        }
        totalDutyAmount {
          amount
          currencyCode
        }
      }
    }
  }
}
`

const CHECKOUT_URL_FETCH_V2 = `
query getCheckoutUrl($cartId: ID!) {
  cart(id: $cartId) {
    checkoutUrl
  }
}
`

const PRODUCT_FETCH = `
  ${VARIANT_FRAGMENT}
  ${PRODUCT_FRAGMENT}
  query($id: ID!, $country: CountryCode!) @inContext(country: $country) {
  	node(id: $id) {
      ...ProductFragment
    }
  }
`

const RECOMMENDATIONS_FETCH = `
query getComplementaryProductRecommendations($productId: ID!, $intent: ProductRecommendationIntent!) {
  productRecommendations(productId: $productId, intent: $intent) {
    id
  }
}
`

const CUSTOMER_RECOVER_PASSWORD= `
  mutation customerRecover($email: String!) {
    customerRecover(email: $email) {
      customerUserErrors {
        code
        field
        message
      }
      userErrors {
        field
        message
      }
    }
  }
`

function snif(x) {
  console.log(x)
  return x
}
