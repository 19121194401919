import { useMobileMenu } from 'context/globalStore'
import cx from 'classnames'
import { useEffect } from 'react'
import { on } from 'martha'
import * as analytics from 'lib/analytics'
import usePageScrollLock from "hooks/usePageScrollLock"

function Burger() {
  const { isMobileMenuOpen, toggleMobileMenu } = useMobileMenu()

  usePageScrollLock( isMobileMenuOpen, 300 )

  // Close mobile nav with Escape key
  useEffect( () => {
    if ( typeof window === 'undefined' ) return

    let offKeyup = () => {}

    if ( isMobileMenuOpen ) {
      offKeyup = on( window, 'keyup', ({ key }) => {
        if ( key === 'Escape' ) {
          toggleMobileMenu()
        }
      } )
    }

    return offKeyup
  }, [isMobileMenuOpen, toggleMobileMenu] )

  function handleClick() {
    analytics.trackNavClick( isMobileMenuOpen ? 'BurgerClose' : 'BurgerOpen' )
    toggleMobileMenu()
  }

  const burgerLayerClasses = 'w-24 rounded bg-navy-blue transition'

  return (
    <div
      className={cx( 'flex items-center absolute left-0' )}
    >
      <button
        className="rounded transition focus:outline-none focus-visible:bg-pattens-blue"
        onClick={handleClick}
        aria-haspopup={true}
        aria-controls="mobile-menu"
        aria-expanded={isMobileMenuOpen}
      >
        <span className="sr-only hidden">Toggle navigation menu</span>
        <div className={cx( 'px-10 py-0', {
          '-mt-2': isMobileMenuOpen,
        } )}>
          <div className="w-29 h-18 flex flex-col justify-between">
            <div
              className={cx( burgerLayerClasses, {
                'h-3': !isMobileMenuOpen,
                'h-2 transform rotate-45 translate-y-10': isMobileMenuOpen,
              } )}
            ></div>
            <div className={cx( burgerLayerClasses, {
              'h-3': !isMobileMenuOpen,
              'opacity-0': isMobileMenuOpen,
            } )}
            ></div>
            <div className={cx( burgerLayerClasses, {
              'h-3': !isMobileMenuOpen,
              'h-2 transform -rotate-45 -translate-y-6': isMobileMenuOpen,
            } )}
            ></div>
          </div>
        </div>
      </button>
    </div>
  )
}

export default Burger
