import { useState } from 'react'
import VisualSubmenu from 'components/Navigation/Desktop/VisualSubmenu'
import MenuItem from 'components/Navigation/Desktop/DesktopMenuItem'
import ColoredMenuItem from 'components/Navigation/Desktop/DesktopColoredMenuItem'
import { screens } from "lib/constants";
import useMedia from "use-media";
import { updateSubscriptionLink } from '../../../lib/abTestUtils'

export default function DesktopLeftMenu({ globalNavigation, toggle, isGetSubscriptionPage }) {
  const [isExpand, setIsExpand] = useState( false );
  const screenL = useMedia( { minWidth: screens.m } );
  const screenXL = useMedia( { maxWidth: screens.l } );
  const isTablet = screenL && screenXL;
  const getSubmenuType = {
    'desktopColumnSubmenu': (item) => { return getVisualSubmenu( item ) },
    'internalLink': (item) => {return (<MenuItem key={item._key} link={item}>{item.title}</MenuItem>)},
    'externalLink': (item) => {return (<MenuItem key={item._key} link={item}>{item.title}</MenuItem>)},
    'coloredLink': (item) => {return (<ColoredMenuItem key={item?._key} {...item} />)}
  }
  const getVisualSubmenu = (item) => {
    const visualSubmenuProps = {
      closeSearchBar: toggle,
      key: item._key,
      isExpand,
      setIsExpand: (x) => setIsExpand( x ),
      item,
      ...globalNavigation,
      isTablet
    };

    return <VisualSubmenu {...visualSubmenuProps} />;
  };

  return (
    <div className="h-full flex xl:space-x-10 -ml-15"
         onMouseLeave={!isTablet ? () => {setIsExpand( false )} : null}
    >
      {globalNavigation?.desktopHeaderMenu?.map( (item) => {
        item = updateSubscriptionLink(item, isGetSubscriptionPage);

        if ( !item._type || !getSubmenuType[item._type] ) {
          return null;
        }
        return getSubmenuType[item._type]( item );
      } )}
    </div>
  )
}
