import { useRef, useEffect, useState } from 'react'
import { useRect } from '@reach/rect'
import { useIsCartOpen } from 'context/globalStore'
import { add, remove } from 'martha'
import SearchDropDownComponent from 'components/SearchDropDown'
import FocusTrap from 'focus-trap-react'
import MenuHeader from 'components/Navigation/Mobile/MenuHeader'
import Menu from 'components/Navigation/Mobile/MobileMenu'
import PrimaryMenu from 'components/Navigation/Mobile/PrimaryMenu'
import SecondaryMenu from 'components/Navigation/Mobile/SecondaryMenu'
import MenuFooter from 'components/Navigation/Mobile/MenuFooter'
import { useRouter } from 'next/router'
import { useIsStickyPromo, useTopPosition, useDiscountFromURL, useMobileMenu } from 'context/globalStore'
import useGorgiasChatPositioning from 'hooks/useGorgiasChatPositioning'
import usePageScrollLock from 'hooks/usePageScrollLock'
import SearchIcon from 'public/icons/search.svg'
import Gallery from "components/Navigation/Gallery";
import AddLinks from "components/Navigation/Mobile/AddLinks";
import Link from "components/Link";
import * as analytics from "lib/analytics";

export default function MobileNavigation({
  bgColorStyle,
  globals,
  searchConfig,
  isBannerHidden,
  isNoNavigation,
	globalNavigation,
  isGetSubscriptionPage
}) {
  const { updateChatPosition } = useGorgiasChatPositioning()
  const { isMobileMenuOpen } = useMobileMenu()
  const headerRef = useRef()
  const headerRect = useRect(headerRef)
  const [searchDropDown, setSearchDropDown] = useState(false)
  const router = useRouter()
  const { setDiscountFromURL } = useDiscountFromURL()
  const { isCartOpen, setIsCartOpen } = useIsCartOpen()

  const { isStickyPromo, hasLargerPromoBar } = useIsStickyPromo()
  const toggleSearchDropDown = () => {
    if (searchDropDown) {
      window?.Solvvy?.show()
    } else {
      window?.Solvvy?.hide()
    }
    setSearchDropDown(!searchDropDown)
  }

  usePageScrollLock(searchDropDown, 600)

  useEffect(() => {
    if (typeof window === 'undefined') return

    searchDropDown || isMobileMenuOpen || isCartOpen
      ? (() => {
        add(document.body, 'overflow-hidden')
        updateChatPosition({
          display: 'none',
        })
      })()
      : (() => {
        remove(document.body, 'overflow-hidden')
        updateChatPosition({
          display: 'block',
        })
      })()
  }, [isMobileMenuOpen, searchDropDown, isCartOpen])

  useEffect(() => {
    const url = router.asPath
    if (url.indexOf('?') > -1) {
      let query = url.split('?')
      if (query.length > 0) {
        for (let i = 0; i < query.length; i++) {
          if (query[i].indexOf('=') > -1) {
            var keys = query[i].split('=')
            if (keys[0] === 'discount') {
              setDiscountFromURL(keys[1])
              break
            }
          }
        }
      }
    }
    if (url.indexOf('&') > -1) {
      let query = url.split('&')
      if (query.length > 0) {
        for (let i = 0; i < query.length; i++) {
          if (query[i].indexOf('=') > -1) {
            var keys = query[i].split('=')
            if (keys[0] === 'discount') {
              setDiscountFromURL(keys[1])
              break
            }
          }
        }
      }
    }
  }, [])

  return (
    <FocusTrap
      active={isMobileMenuOpen}
      focusTrapOptions={{ initialFocus: '#mobile-menu button, #mobile-menu a' }}
    >
      <div
        className={`l:hidden sticky z-10`}
        style={{ top: useTopPosition(isStickyPromo, hasLargerPromoBar) }}
      >
        <span
          className={'absolute mobileSearch z-10 h-21 w-21 left-20'}
          style={{ top: '50%'}}
          onClick={() => toggleSearchDropDown()}
          id={'search-icon-ga'}
        >
          <SearchIcon className={'w-21 h-21 text-navy-blue'}/>
        </span>
        <MenuHeader
	        accountLink={globalNavigation?.accountLink}
          ref={headerRef}
          bgColorStyle={{ ...bgColorStyle }}
          toggle={() => toggleSearchDropDown()}
          isNoNavigation={isNoNavigation}
        />

        <Menu isMobileMenuOpen={isMobileMenuOpen} headerRect={headerRect}>
          <>
            <Link
              className={'mt-24 pt-11 pb-10 flex items-center text-16 border-navy-blue hover:bg-navy-blue hover:text-white transition-all font-medium border rounded-pill justify-center'}
              link={globalNavigation?.menuButton}
              onClick={() => analytics.trackNavClick()}
            >
              <span className={'truncate ...'}>{globalNavigation?.menuButton?.title}</span>
            </Link>
            <Gallery items={globalNavigation?.galleryMobile} isGetSubscriptionPage={isGetSubscriptionPage} />
            <PrimaryMenu items={globalNavigation?.primaryMenu} menuButton={globalNavigation?.menuButton}/>
            <SecondaryMenu items={globalNavigation?.secondaryMenu}/>
            <AddLinks items={globalNavigation?.additionalLinks}/>
            <MenuFooter item={globalNavigation?.accountLink}/>
          </>
        </Menu>
        {searchDropDown && (
          <SearchDropDownComponent
            closeSearchBar={() => {
              setSearchDropDown(false)
              window?.Solvvy?.show()
            }}
            toggle={() => toggleSearchDropDown()}
            globals={globals}
            searchConfig={searchConfig}
            searchDropDown={searchDropDown}
          />
        )}
      </div>
    </FocusTrap>
  )
}
