export default function centsToPrice(
  cents,
  quantity = 1,
  trailingZeros = false,
  currencyCode = 'USD',
) {
  if (typeof cents === 'string') {
    cents = parseInt(cents.replace('.', ''), 10)
  }

  const price = (cents / 100) * quantity

  const currencySymbol = currencyCode
    ? (0)
        .toLocaleString('en', {
          style: 'currency',
          currency: currencyCode,
        })
        .slice(0, 1)
    : null

  if (!trailingZeros && price % 1 === 0) {
    return `${currencySymbol ? currencySymbol : ``}${price
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  } else {
    const parts = price.toFixed(2).split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return `${currencySymbol ? currencySymbol : ``}${parts.join('.')}`
  }
}
