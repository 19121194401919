import React, { useEffect, useState } from 'react'
import cx from 'classnames';
import { renderToString } from 'react-dom/server';
import Chevron from 'components/Icons/Chevron';

const getTextContent = (jsxElement) => {
    if (typeof jsxElement === 'string') {
      return jsxElement;
    }
    if (Array.isArray(jsxElement)) {
      return jsxElement.map(getTextContent).join('');
    }
    if (jsxElement && jsxElement.props && jsxElement.props.children) {
      return getTextContent(jsxElement.props.children);
    }
    return '';
};

const CustomSelect = ({ options, onChange, value, isProductCard }) => {

    const handleSelectChange = (targetValue) => {
      onChange(targetValue)
    }
  
  return (
    <div className={cx('relative border border-[#CBD3D9] p-10', {
      'rounded-4': !isProductCard,
      'rounded-8': isProductCard
    })} >
        <div className='flex w-full justify-between items-center' >
            <span>{options.find(option => option.value == value)?.label}</span>
            <span className='w-9'><Chevron direction='down' /> </span>
        </div>
        <div className={cx('w-full bg-red absolute top-0 left-0 opacity-0  h-full', {
        })}>
            <select className='w-full absolute top-0 left-0 h-full' onChange={(e: any) => handleSelectChange(e.target.value)} value={value} name="" id="">
                {
                    options?.map((option, i) => (
                    <option value={option.value} selected={option.value == value} className="flex justify-between" key={i}>
                        {getTextContent(option.label)}
                    </option>
                  ))
                }
            </select>        
        </div>    
    </div>
  )
}

export default CustomSelect