import isLink from 'lib/isLink'
import Link from 'components/Link'
import cx from 'classnames'
import { Sizes } from 'lib/constants'
import Chevron from './Icons/Chevron'

export default function TextButton({
  children,
  link,
  size = Sizes.SMALL,
  onClick = () => {},
  className,
  chevron = false,
  disabled = false,
  ...rest
}) {
  const isLinkType = isLink(link)
  const Component = isLinkType ? Link : 'button'

  return (
    <Component
      className={cx(
        'inline-block font-medium leading-100 rounded transition hover:text-royal-blue focus:outline-none focus-visible:ring-2 focus-visible:ring-navy-blue focus-visible:ring-offset-1 group-btn',
        {
          'pt-5 pb-4': size === Sizes.COMPACT,
          'text-13': size === Sizes.COMPACT || size === Sizes.SMALL,
          'pt-8 pb-7': size === Sizes.SMALL || size === Sizes.LARGE,
          'text-16': size === Sizes.LARGE,
        },
        className
      )}
      link={isLinkType ? link : null}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      <div className="flex items-center">
        <div>{children}</div>
        {chevron ? (
          <div
            className={cx(
              'mt-2 ml-5 transition-gpu group-btn-hover:transform group-btn-hover:translate-x-3',
              {
                'w-6': size === Sizes.LARGE,
                'w-5': size !== Sizes.LARGE,
              }
            )}
          >
            <Chevron direction="right" />
          </div>
        ) : null}
      </div>
    </Component>
  )
}
