import cx from 'classnames'
import { useState, useEffect } from 'react'

export default function MobileMenu({ isMobileMenuOpen, headerRect, children }) {
  const [isFirstOpen, setIsFirstOpen] = useState(true)

  useEffect(() => {
    if (!isFirstOpen) return
    if (isMobileMenuOpen) {
      setIsFirstOpen(false)
    }
  }, [isMobileMenuOpen])

  return (
    <div
      id="mobile-menu"
      inert={isMobileMenuOpen ? null : ''}
      className={cx('fixed right-0 bottom-50 left-0 transition-opacity', {
        'opacity-0 pointer-events-none': !isMobileMenuOpen,
      })}
      style={{
        top: headerRect ? headerRect.top + headerRect.height : null,
        display: isFirstOpen ? 'none' : null,
      }}
    >
      <div className="relative w-full h-full bg-white overflow-y-auto px-32 m:px-60">{children}</div>
    </div>
  )
}
