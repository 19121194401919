import { useState, useRef, useEffect } from 'react'
import cx from 'classnames'
import Chevron from 'components/Icons/Chevron'
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  useAccordionItemContext,
  useAccordionContext,
} from '@reach/accordion'
import { tabbable } from 'tabbable'
import * as analytics from 'lib/analytics'

export default function DesktopSubmenu({ parentTitle, children, mode = '' }) {
  return (
    <AccordionItem as="li" className="relative h-full flex items-center z-20">
      <AnimatedAccordionButton mode={mode}>{parentTitle}</AnimatedAccordionButton>
      <AnimatedAccordionPanel>{children}</AnimatedAccordionPanel>
    </AccordionItem>
  )
}

const AnimatedAccordionButton = ({ children, mode }) => {
  const ref = useRef()
  const isInitialMount = useRef(true)
  const { openPanels } = useAccordionContext()
  const { isExpanded } = useAccordionItemContext()

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
      return
    }

    // Only return focus to button if collapsed and no other panels open
    if (!isExpanded && openPanels[0] === null) {
      ref?.current?.focus({ preventScroll: true })
    }
  }, [isExpanded])

  return (
    <AccordionButton
      ref={ref}
      className="block text-16 xl:text-18 leading-100 tracking-1 font-medium pt-11 pb-10 px-15 rounded focus:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-royal-blue hover:bg-alice-blue hover:text-royal-blue transition"
      onClick={() => analytics.trackNavClick(children)}
    >
      <div className="flex items-center">
        {children}
        {mode !== 'search' && (
          <div
            className={cx('w-9 mt-2 ml-5 transition-transform', {
              'transform rotate-180': isExpanded,
            })}
          >
            <Chevron direction="down" />
          </div>
        )}
      </div>
    </AccordionButton>
  )
}

const AnimatedAccordionPanel = ({ children }) => {
  const { isExpanded } = useAccordionItemContext()
  const [isFirstExpand, setIsFirstExpand] = useState(true)
  const ref = useRef()

  useEffect(() => {
    if (!isFirstExpand) return
    if (isExpanded) {
      setIsFirstExpand(false)
    }
  }, [isExpanded])

  useEffect(() => {
    if (!ref.current) return

    // When panel expands, focus first tabbable node in panel
    if (isExpanded && !isFirstExpand) {
      const nodes = tabbable(ref.current)
      nodes[0]?.focus({ preventScroll: true })
    }
  }, [isExpanded, isFirstExpand])

  return (
    <AccordionPanel
      ref={ref}
      className={cx(
        'absolute l:-left-25 xxl:-left-45 top-full bg-white border-t border-pattens-blue rounded-b shadow-overlay transition origin-top-left',
        {
          'transform scale-95 opacity-0 pointer-events-none': !isExpanded,
        }
      )}
      // We need to set hidden to false for the exit animations to work
      // but the panel should still be hidden from the accessibility tree
      // when the panel is closed. We'll use aria-hidden instead.
      hidden={false}
      aria-hidden={!isExpanded || undefined}
    >
      <div inert={isExpanded ? null : ''} style={{ display: isFirstExpand ? 'none' : null }}>
        {children}
      </div>
    </AccordionPanel>
  )
}
