import * as analytics from 'lib/analytics'

export default function handleProductClick(product, collection, index, location) {
  const variant = product.variants[0]
  const image =
    product?.shopifyImage?.asset?.url ||
    (variant && variant?.image?.src) ||
    variant?.siloImage?.asset?.url

  analytics.trackProductClick({
    id: (variant && variant.sku) || '',
    name:
      product?.type === 'productVariant' ? product.title ?? product.variantTitle : product.title,
    brand: 'Blueland',
    category: collection.title,
    variant: (variant && variant.variantTitle) || '',
    price: (product.cents / 100).toFixed(2).toString(),
    quantity: '1',
    position: index + 1 ?? 1,
    list: location.pathname,
    product_id: product.productId?.toString(),
    variant_id: (variant && variant.variantId)?.toString() || '',
    image: image,
    inventory: '99999',
  })
}