import isLink from 'lib/isLink'
import Link from 'components/Link'
import cx from 'classnames'
import { Sizes, ButtonVariants } from 'lib/constants'
import Spinner from './Spinner'
import TextButton from './TextButton'
import useMedia from 'use-media'
import { screens } from 'lib/constants'

export default function Button({
  children,
  link = '',
  size = Sizes.SMALL,
  variant = ButtonVariants.OUTLINED,
  onClick = () => { },
  className = '',
  disabled = false,
  loading = false,
  style = {},
  active = false,
  bgColor = undefined,
  ...props
}) {
  const hasLinkProp = isLink(link)
  const Component = hasLinkProp ? Link : 'button'
  const isScreenMedium = useMedia({ minWidth: screens.m })

  return (
    variant === ButtonVariants.TEXT_BTN ? (
      <TextButton
        className={className}
        link={link}
        size={isScreenMedium ? 'large' : 'compact'}
        chevron={true}
        onClick={onClick}
      >
        <span aria-hidden="true">{children}</span>
      </TextButton>
    ) : (
      <Component
        {...props}
        className={cx(
          'button inline-block font-medium text-center rounded-pill transition focus:outline-none focus-visible:ring-2 focus-visible:ring-royal-blue focus-visible:ring-offset-1 group',
          className,
          {
            'text-12 s:text-13 leading-100': size === Sizes.COMPACT || size === Sizes.SMALL,
            'px-15 pt-5 pb-4 leading-100': size === Sizes.COMPACT,
            'px-20 pt-8 pb-7 leading-100': size === Sizes.SMALL,
            'text-16 l:text-18 px-30 pt-13 pb-12 leading-100': size === Sizes.LARGE,
            'border border-navy-blue duration-200 hover:text-white disabled:text-white hover:bg-navy-blue disabled:bg-navy-blue': variant === ButtonVariants.OUTLINED,
            'text-white bg-navy-blue': variant === ButtonVariants.OUTLINED && active,
            'relative text-white bg-bright-blue group-btn': variant === ButtonVariants.CONTAINED,
            'relative text-navy-blue bg-white group-btn': variant === ButtonVariants.WHITE_BTN,
            'cursor-not-allowed': disabled || loading,
            'text-18 m:text-22': size === 'extra-large',
          }
        )}
        link={hasLinkProp ? link : null}
        onClick={onClick}
        disabled={disabled}
        {...(hasLinkProp ? { bgColor: bgColor } : {})}
        style={{ ...style, backgroundColor: hasLinkProp ? undefined : bgColor }}
      >
        <div className="flex justify-center">
          {loading ? (
            <Spinner className="px-8" />
          ) : [ButtonVariants.CONTAINED, ButtonVariants.WHITE_BTN].includes(variant) ? (
            <>
              <div className="relative z-1 transition group-btn-hover:text-navy-blue group-btn-disabled:text-navy-blue">
                {children}
              </div>
              <div
                className={cx(
                  'absolute inset-0 rounded-pill border border-navy-blue bg-gradient-to-r from-pattens-blue via-blush-pink to-pale-orange transition-opacity opacity-0 group-btn-disabled:opacity-100',
                  { 'group-btn-hover:opacity-100': !loading }
                )}
              ></div>
            </>
          ) : (
            children
          )}
        </div>
      </Component>
    )
  )
}