import { useRef, useEffect } from 'react'
import { useChoiceChipGroupContext, useChoiceChipItemContext } from 'components/ChoiceChipGroup'
import Image from 'components/Image'
import cx from 'classnames'
import { wrap } from 'martha'
import { Keys } from 'lib/constants'

export default function ChoiceChipItem({
  label,
  subtitle,
  icon,
  iconType,
  color,
  className = '',
  value,
  smallSized = false,
  isOptionAvailable = true,
  variation,
  product,
  showColorOnlyOnPlp,
  plpSwatch = false,
  ...props
}) {
  const groupContext = useChoiceChipGroupContext()
  const itemContext = useChoiceChipItemContext()
  const isChecked = groupContext.value === value
  const ref = useRef()
  const isInitialMount = useRef(true)
  const hasColorIcon = iconType === 'color'

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
      return
    }

    if (isChecked) {
      ref.current.focus({ preventScroll: true })
    }
  }, [isChecked])

  function handleClick() {
    groupContext.onChange(value)
  }

  function handleKeyDown(ev) {
    const { items, onChange } = groupContext
    const index = itemContext

    let flag = false

    switch (ev.keyCode) {
      case Keys.SPACE:
      case Keys.RETURN: {
        onChange(value)
        flag = true
        break
      }

      case Keys.UP:
      case Keys.LEFT: {
        onChange(items[wrap(index - 1, items.length)].props.value)
        flag = true
        break
      }

      case Keys.DOWN:
      case Keys.RIGHT: {
        onChange(items[wrap(index + 1, items.length)].props.value)
        flag = true
        break
      }

      default:
        break
    }

    if (flag) {
      ev.stopPropagation()
      ev.preventDefault()
    }
  }

  const parsedIcon = () => {
    return typeof icon === 'string' ? (
      <div className="mr-5">{icon}</div>
    ) : icon?.asset ? (
      <div className="w-16 h-16 mr-8">
        <Image
          image={icon}
          fit="contain"
          fill={true}
          placeholder={false}
          sizes={'16px'}
          overrides={(x) => x.fit('clip')}
        />
      </div>
    ) : hasColorIcon && !showColorOnlyOnPlp ? (
      <span
        className="w-12 h-12 mr-7 border border-white box-content rounded-pill"
        style={{ backgroundColor: color }}
      ></span>
    ) : null
  }

  return (
    <>
      <div
        {...props}
        role="radio"
        tabIndex={isChecked ? 0 : -1}
        aria-checked={isChecked}
        className={cx('group focus:outline-none cursor-pointer', className, {
          'opacity-60 cursor-not-allowed hidden': !isOptionAvailable,
          'x-margin-cc': variation !== 'b',
          'border rounded-8 border-light-grey text-navy-blue-muted px-8 xs:px-4 l:px-8 py-11 min-h-52 mx-0': product?.slug === 'toilet-cleaner-starter-set' && variation === 'b' && !isChecked,
          'bg-light-blue overflow-hidden rounded-8 border border-navy-blue px-8 xs:px-4 l:px-8 py-11 min-h-52': product?.slug === 'toilet-cleaner-starter-set' && variation === 'b' && isChecked,
          'mt-10': !plpSwatch,
          'my-5': plpSwatch,
        })}
        onClick={isOptionAvailable ? handleClick : undefined}
        onKeyDown={handleKeyDown}
        ref={ref}
      >
        <div
          className={cx(
            'min-w-75',
            {
              'bg-navy-blue border-navy-blue text-white':  isChecked && variation !== 'b',
              'border-navy-blue-muted s:group-hover:bg-alice-blue s:group-hover:text-royal-blue s:group-hover:border-royal-blue':
                !isChecked && variation !== 'b',
              'py-5 px-8 l:px-15': smallSized && variation !== 'b',
              'py-8 px-15 l:px-20': !smallSized && variation !== 'b',
              'border rounded-pill group-focus-visible:ring-2 group-focus-visible:ring-offset-1 group-focus-visible:ring-royal-blue': variation !== 'b',
              'pb-4': product?.slug === 'toilet-cleaner-starter-set' && variation === 'b',
            }
          )}
        >
          <div
            className={cx('flex justify-center items-center text-center', {
              'font-medium leading-16 text-13': smallSized && variation !== 'b',
              'font-medium leading-16 text-13': !smallSized && variation !== 'b',
              'font-medium leading-13 text-13 s:text-16 s:leading-16' : product?.slug === 'toilet-cleaner-starter-set' && variation === 'b',
            })}
          >
            {parsedIcon()}
            {label}
          </div>
        </div>
        {subtitle && (
          <div
            className={cx('text-center', {
              'text-navy-blue-muted': isChecked,
              'text-navy-blue-muted': !isChecked,
              'mt-6 ml-8 text-14': variation !== 'b',
              'font-normal text-11 leading-13 s:text-13':  product?.slug === 'toilet-cleaner-starter-set' && variation === 'b',
            })}
          >
            {subtitle}
          </div>
        )}
      </div>
    </>
  )
}
