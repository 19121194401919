import { useState, useEffect } from 'react'
import { useStore, useCartItems } from 'context/globalStore'
import AdvProductCarousel from 'modules/AdvProductCarousel'

export default function CartBestSellersCarousel({
  products,
  parentStyle,
  setBestSellerVisibility,
  globals,
}) {
  const { lineItems } = useCartItems()
  const [bestSellerProducts, setBestSellerProducts] = useState(products)
  const { store } = useStore()
  // const isScreenLarge = useMedia({ minWidth: screens.l })

  // const Styles = {
  //   mainParent: parentStyle ? parentStyle : 'pt-1 pb-15 px-15 mt-25 bg-alice-blue l:p-25',
  //   itemWrap: 'flex flex-nowrap overflow-auto hide-scrollbar',
  // }

  useEffect(() => {
    if (!store.isInit) return
    const productIds = []
    const _products = products.filter(({ variant }) => {
      const vid = variant?.variantId
      return !lineItems.find(({ variant }) => {
        const _vid = Number(atob(variant.id).replace('gid://shopify/ProductVariant/', ''))
        return _vid === vid
      })
    }) //.slice(0, 4)

    if (_products.length > 0) {
      _products.map((product) => {
        if (product.variant && product.variant.productId) {
          productIds.push(product.variant.productId)
        }
      })
      fetch(`/api/getStarRatings?productIds=${productIds.join()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          res.json().then((res) => {
            if (res.length > 0) {
              for (let i = 0; i < _products.length; i++) {
                _products[i].product.bottomline = res[i]
                _products[i].product.isBottomlineVisible = true
              }
            }
            setBestSellerProducts(_products)
          })
        })
        .catch((e) => {
          setBestSellerProducts(_products)
        })
    }
  }, [lineItems, products, store.isInit])

  return (
    <AdvProductCarousel
      module={{
        headline: 'Explore Our Bestsellers',
        headlineSizeClasses: 'text-20 leading-24',
        headlinePosition: 'center',
        imageHeight: 232,
        imageWidth: 232,
        products: bestSellerProducts.map((x) => x.product),
        background: '#F5F9FF',
        mode: 'cartBestSellers',
        enableQuickAdd: false,
        bottomPrice: true,
        titleClass: 'flex justify-between text-left text-13 l:text-16 leading-24 font-medium mb-4',
        priceClass: 'text-13 l:text-16 text-left l:font-medium text-navy-blue space-x-10 ml-4',
        scrollable: true,
        useScrollGrid: true
      }}
      pageContext={{
        globals,
      }}
    />
  )
}
