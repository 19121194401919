import Button from 'components/Button'
import cx from 'classnames'
import { useEffect } from 'react'
import useDelayedCallback from 'hooks/useDelayedCallback'
import CheckmarkIcon from 'components/Icons/CheckmarkIcon'

const States = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
}

export default function ActionButton({
  children,
  status,
  onClick,
  onChange,
  afterSuccess = () => {},
  successDelay = 2000,
  size = 'compact',
  as: Component = Button,
  ...props
}) {
  const delayedCallback = useDelayedCallback(() => {
    onChange(States.IDLE)
    afterSuccess()
  }, successDelay)

  useEffect(() => {
    if (status === States.SUCCESS) {
      delayedCallback()
    }
  }, [status])

  return (
    <Component
      type="outlined"
      size={size}
      onClick={onClick}
      disabled={status === States.LOADING || status === States.SUCCESS}
      className="relative flex-shrink-0"
      {...props}
    >
      <div className="relative z-1">
        <div
          className={cx('transition-gpu', {
            'opacity-0': status !== States.IDLE,
            'transform-gpu translate-x-20': status === States.LOADING,
          })}
        >
          {children}
        </div>
      </div>
      <div className="absolute z-1 inset-0 flex justify-center items-center">
        <Spinner status={status} />
      </div>
      <div className="absolute z-1 inset-0 flex justify-center items-center">
        <Checkmark status={status} />
      </div>
    </Component>
  )
}

ActionButton.States = States

function Spinner({ status }) {
  return (
    <div
      className={cx('w-12 transition', {
        'transform-gpu -translate-x-15': status === States.IDLE,
        'opacity-0': status !== States.LOADING,
        'transform-gpu translate-x-15': status === States.SUCCESS,
      })}
    >
      <div className="animate-spin">
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.6518 12.4446C15.3794 11.3556 15.8262 10.1084 15.9585 8.81369C16.0043 8.36587 15.6351 8 15.1849 8C14.7348 8 14.3753 8.3668 14.3184 8.81333C13.919 11.9472 11.2424 14.3699 7.99989 14.3699C4.48183 14.3699 1.62989 11.5179 1.62989 7.99988C1.62989 4.75732 4.05267 2.08062 7.18673 1.6813C7.63317 1.62441 7.99991 1.26506 7.99995 0.815C7.99998 0.364897 7.63415 -0.00429951 7.18639 0.0414753C5.89167 0.173832 4.64438 0.620637 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346631 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446Z"
            className="fill-current"
          />
        </svg>
      </div>
    </div>
  )
}

function Checkmark({ status }) {
  return (
    <div
      className={cx('w-16 transition', {
        'transform-gpu -translate-x-15': status === States.LOADING,
        'opacity-0': status !== States.SUCCESS,
      })}
    >
      <CheckmarkIcon />
    </div>
  )
}
