function getOptionsForSwatches(product) {
  const scentOption = product.options.find(
    ({ name, values }) => name === 'Scent' && values.length > 1
  );

  const laundryScentOption = product.options.find(
    ({ name, values }) => name === 'Laundry Scent' && values.length > 1
  )

  const packSizeOption = product.options.find(
    ({ name, values }) => name === 'Pack Size' && values.length > 1
  );

  const characterOption = product.options.find(
    ({ name, values }) => name === 'Character' && values.length > 1
  );

  const amountOption = product.options.find(
    ({ name, values }) => name === 'Amount' && values.length > 1
  );

  const colorOption = product.options.find(
    ({ name, values }) => name === 'Color' && values.length > 1
  );

  const countOption = product.options.find(
    ({ name, values }) => name === 'Count' && values.length > 1
  );

  const sizeOption = product.options.find(
    ({ name, values }) => name === 'Size' && values.length > 1
  );

  const sinkCountOption = product.options.find(
    ({ name, values }) => name === 'Sink Count' && values.length > 1
  );

  const bathroomCountOption = product.options.find(
    ({ name, values }) => name === 'Bathroom Count' && values.length > 1
  );

  const tabletCountOption = product.options.find(
    ({ name, values }) => name === 'Tablet Count' && values.length > 1
  );

  const typeOption = product.options.find(
    ({ name, values }) => name === 'Type' && values.length > 1
  );

  const cleanerTypeOption = product.options.find(
    ({ name, values }) => name === 'Cleaner Type' && values.length > 1
  );

  const refillOption = product.options.find(
    ({ name, values }) => name === 'Refill Quantity' && values.length > 1
  );

  const options = [
    packSizeOption,
    characterOption,
    amountOption,
    colorOption,
    scentOption,
    countOption,
    laundryScentOption,
    sizeOption,
    sinkCountOption,
    tabletCountOption,
    typeOption,
    cleanerTypeOption,
    bathroomCountOption
  ].filter((x) => !!x);

  return options;
}

export default getOptionsForSwatches