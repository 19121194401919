import Link from 'components/Link'
import * as analytics from 'lib/analytics'
import cx from "classnames";

export default function DesktopMenuItem({ key, link, children, isUnderline = true }) {
  return (
    <li className="relative h-full flex items-center group" key={key}>
      <Link
        link={link}
        className={cx( 'block text-16 xl:text-18 leading-100 tracking-1 font-medium pt-11 pb-10 px-15 transition group',
          {
            'hover:text-bright-blue': !isUnderline
          } )}
        onClick={() => analytics.trackNavClick( children )}
      >
        {children}
      </Link>
      {isUnderline && <span
        className="absolute w-full h-3 bottom-0 bg-navy-blue z-2 opacity-0 transition-opacity duration-300 group-hover:opacity-100"></span>
      }
    </li>
  )
}
