import Link from 'components/Link';
import * as analytics from 'lib/analytics';
import { useEffect, useState } from 'react';
import { getCookie } from './Product/Product.helper';

export default function MobileFixedMenu({ items }) {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  useEffect(() => {
    const getUserData = getCookie('userData');
    setIsUserLoggedIn(getUserData ? true : false);
  }, []);

  return (
    <div className="fixed inset-x-0 bottom-0 h-50 flex items-center bg-alice-blue shadow-bottom-bar z-1">
      <nav className="w-full" aria-labelledby="mobile-fixed-menu" role="navigation">
        <span id="mobile-fixed-menu" className="sr-only">
          Secondary navigation menu
        </span>
        <ul className="h-full flex justify-evenly pt-4">
          {items?.map((item) => (
            item.title.includes('Account') ? (
              <li key={item._key}>
                <Link
                  className="text-18 font-medium leading-100 rounded pt-9 pb-5 px-5 focus:outline-none focus-visible:bg-pattens-blue transition"
                  link={{ ...item, url: isUserLoggedIn ? '/account' : item.url }}
                  onClick={() => analytics.trackNavClick(item.title)}
                >
                  {item.title}
                </Link>
              </li>
            ) : (
              <li key={item._key}>
                <Link
                  className="text-18 font-medium leading-100 rounded pt-9 pb-5 px-5 focus:outline-none focus-visible:bg-pattens-blue transition"
                  link={item}
                  onClick={() => analytics.trackNavClick(item.title)}
                >
                  {item.title}
                </Link>
              </li>
            )
          ))}
        </ul>
      </nav>
    </div>
  );
}