import { createContext, useContext } from 'react'
import flattenChildren from 'react-keyed-flatten-children'
import cx from 'classnames'

// Create contexts
const ChoiceChipGroupContext = createContext()
const ChoiceChipItemContext = createContext()

// Export context hooks
export const useChoiceChipGroupContext = () => useContext(ChoiceChipGroupContext)
export const useChoiceChipItemContext = () => useContext(ChoiceChipItemContext)

export default function ChoiceChipGroup({
  children,
  value,
  onChange = (v) => {},
  className = '',
  plpSwatch,
  ...props
}) {
  const items = flattenChildren(children)
  const classes = !plpSwatch ? 'flex flex-wrap -mt-10 mb-0 l:mb-0' : 'flex flex-wrap mb-0'

  return (
    <ChoiceChipGroupContext.Provider value={{ value, items, onChange }}>
      <div
        {...props}
        role="radiogroup"
        className={cx(classes, className)}
      >
        {items.map((item, i) => (
          <ChoiceChipItemContext.Provider key={i} value={i}>
            {item}
          </ChoiceChipItemContext.Provider>
        ))}
      </div>
    </ChoiceChipGroupContext.Provider>
  )
}
