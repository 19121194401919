const updateSubscriptionLink = (link, isGetSubscriptionPage) => {
  if (link?.reference?.slug?.includes('subscription') && typeof isGetSubscriptionPage === 'boolean') {
    if (isGetSubscriptionPage) {
      link.reference.slug = 'get-subscription'
      link.reference._type = 'page'
    } else {
      link.reference.slug = 'subscriptions'
      link.reference._type = 'collection'
    }
  }

  return link;
}

export { updateSubscriptionLink }