import getColorStyle from 'lib/getColorStyle'

export default function GetCountdownInfo(
  countdownStartDateString,
  countDownDateString,
  currentTime,
  content,
  flagVisible,
  isScreenLarge,
  textColor
) {
  let countdownStartDate = new Date(countdownStartDateString).getTime();

  if (countdownStartDate && currentTime < countdownStartDate) return null;

  let countDownDate = new Date(countDownDateString)
  countDownDate = countDownDate.getTime()
  let distance = countDownDate - currentTime

  // Time calculations for days, hours, minutes and seconds
  let days = Math.floor(distance / (1000 * 60 * 60 * 24))
  let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
  let seconds = Math.floor((distance % (1000 * 60)) / 1000)

  let contentParts = content?.split('{countdown}')

  const largeScreenStyle = {
    position: 'absolute',
    right: '15px',
    top: '50%',
    transform: 'translateY(-50%)',
    textAlign: 'right',
    marginRight: flagVisible ? '5rem' : '2rem',
    color: textColor && getColorStyle( textColor ),
  }

  const mobileScreenStyle = {
    textAlign: 'center',
    color: textColor && getColorStyle(textColor),
  }

  if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) return <></>

  return (
    <div
      className="countdown text-navy-blue leading-12 l:leading-16"
      style={isScreenLarge ? largeScreenStyle : mobileScreenStyle}
    >
      {!isScreenLarge && <br />}
      {contentParts?.[0] && <span>{contentParts[0]}</span>}
      <span>
        {days > 0 && (
          <>
            <span className="font-bold text-12 l:text-13">{days} </span>{' '}
            <span className="text-10 l:text-11">{` ${days === 1 ? 'DAY' : 'DAYS'} `}</span>
          </>
        )}
        {hours > 0 && (
          <>
            <span className="font-bold text-12 l:text-13">{hours} </span>{' '}
            <span className="text-10 l:text-11">{` ${hours === 1 ? 'HR' : 'HRS'} `}</span>
          </>
        )}
        {minutes > 0 && (
          <>
            <span className="font-bold text-12 l:text-13">{minutes} </span>{' '}
            <span className="text-10 l:text-11"> MIN </span>
          </>
        )}
        <span className="font-bold text-12 l:text-13">{seconds} </span>{' '}
        <span className="text-10 l:text-11"> SEC </span>
      </span>
      {contentParts?.[1] && <span>{contentParts[1]}</span>}
    </div>
  )
}
