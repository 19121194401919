import { shopify } from 'lib/shopify'
import { useState } from 'react'
import { useStore } from '../../../context/globalStore'

const extraFreeProductsUtils = () => {
  const getParentsFromExtraFreeProduct = (extraFreeProduct) => {
    try {
      const parentsAttribute = extraFreeProduct?.customAttributes?.find((item) => {
        return item.key === 'extra_free_product_gift_for_list'
      })
      const parentsParsed = JSON.parse(parentsAttribute.value)

      return Array.isArray(parentsParsed) ? parentsParsed : []
    } catch {
      return []
    }
  }

  const createParentAttributeObj = (mainProduct) => {
    if (!mainProduct) {
      return {}
    }
    const isSubscription = mainProduct.customAttributes.some((attr) => attr.key === 'subscription_id')
    return {
      parentVariantId: mainProduct.variant.id,
      isSubscription,
    }
  }

  const checkIsExtraRemoved = (extraProductId, parent) => {
    const removedProducts = JSON.parse(localStorage.getItem('removedExtraFreeProducts')) || []
    let parentObject = parent
    if (!parentObject.parentVariantId) {
      parentObject = createParentAttributeObj(parent)
    }
    return removedProducts.some((item) => {
      const extraProductIdNumber = typeof extraProductId === 'number' ? extraProductId : shopify.decode(extraProductId)
      return (
        shopify.decode(item.giftProductId) === extraProductIdNumber &&
        item.parent.parentVariantId === parentObject.parentVariantId &&
        item.parent.isSubscription === parentObject.isSubscription
      )
    })
  }

  const checkIsAllParentsRemoved = (giftProduct, removedItems) => {
    const parents = getParentsFromExtraFreeProduct(giftProduct)
    return parents.every((parent) => {
      return removedItems.some((removedItem) => {
        return (
          removedItem.parent.parentVariantId === parent.parentVariantId &&
          removedItem.parent.isSubscription === parent.isSubscription
        )
      })
    })
  }

  return {
    createParentAttributeObj,
    getParentsFromExtraFreeProduct,
    checkIsExtraRemoved,
    checkIsAllParentsRemoved,
  }
}

const useUpdateExtraFreeProduct = () => {
  const [removedFromCartList, setRemovedFromCartList] = useState([])
  const { store} = useStore()

  const addExtraFreeProduct = async (filteredLineItems, addToCart, allProducts, router) => {
    const { createParentAttributeObj } = extraFreeProductsUtils()

    let parent = null;
    // matched extra free products
    const matchedEFProducts = {}
    const removedExtraFreeProducts = JSON.parse(localStorage.getItem('removedExtraFreeProducts')) || [];

    const checkExtraFreeProducts = (filteredLineItems, allProducts) => {
      return filteredLineItems.some((item) =>
        allProducts.some((product) =>
          product.variants.some((variant) => {
            const { variantId, freeExtraProduct } = variant;
            if (
              variantId === shopify.decode(item.variant.id) &&
              freeExtraProduct?.length > 0
            ) {
              parent = item
              freeExtraProduct.forEach(freeExtraProductItem => {
                const { variantId } = freeExtraProductItem

                matchedEFProducts[variantId] = matchedEFProducts[variantId] ?
                  { ...matchedEFProducts[variantId], quantity: matchedEFProducts[variantId].quantity + 1 } :
                  { matchedVariant: freeExtraProductItem, quantity: 1 }
              })
              return true
            }

            return false
          })
        )
      )
    };

    checkExtraFreeProducts(filteredLineItems, allProducts);

    const parentAttrObject = createParentAttributeObj(parent)

    for (const { matchedVariant: matchedItem, quantity } of Object.values(matchedEFProducts)) {
      const matchedVariantId = matchedItem.variantId

      const matchedProduct = allProducts.find(item => item.productId === matchedItem.productId)
      const matchedVariant = matchedProduct?.variants?.find(item => item.variantId === matchedVariantId)

      if (!matchedProduct || !matchedVariant) {
        continue;
      }

      const isAlreadyInCart = filteredLineItems.some(item => {
        return shopify.decode(item.variant.id) === matchedVariantId;
      })

      const isRemoved = removedExtraFreeProducts?.find(item => {
        const itemParent = item.parent
        return +shopify.decode(item.giftProductId) === matchedVariantId &&
          itemParent.parentVariantId === parentAttrObject.parentVariantId &&
          itemParent.isSubscription === parentAttrObject.isSubscription
      })

      if (!isAlreadyInCart && !isRemoved && router?.pathname !== '/cart') {
        const attributes = [
          {
            key: 'extra_free_product',
            value: 'true',
          },
          {
            key: 'extra_free_product_gift_for',
            value: parent.variant.id,
          },
          {
            key: 'extra_free_product_gift_for_list',
            value: JSON.stringify([{ ...parentAttrObject, quantityPerParentItem: quantity }]),
          },
        ]

        const isRemovedFromCart = removedFromCartList.some(removedEmptyItem => {
          return +shopify.decode(removedEmptyItem.variant.id) === matchedVariantId
        })
        if (isRemovedFromCart) {
          setRemovedFromCartList((prevState) => {
            return prevState.filter(prevStateItem =>  +shopify.decode(prevStateItem.variant.id) !== matchedVariantId)
          })
        }

        await addToCart({
          product: matchedProduct,
          shouldSetIsAdding: true,
          payload: {
            variant: matchedVariant,
            planProducts: [matchedProduct],
            quantity,
            attributes: attributes
          },
        })
      }
    }
  }

  const updateExtraFreeProductsCart = async (filteredLineItems, updateCartItems, allProducts, setRemovingItem) => {
    const { getParentsFromExtraFreeProduct, createParentAttributeObj, checkIsExtraRemoved } = extraFreeProductsUtils()
    if (filteredLineItems?.length) {
      const extraProducts = filteredLineItems.filter(item => item?.customAttributes?.some(attr => attr.key === 'extra_free_product_gift_for'))
      const mainProducts = filteredLineItems.filter(item => !item?.customAttributes?.some(attr => attr.key === 'extra_free_product_gift_for'))
      let extraProductsShouldBe = []

      mainProducts.forEach(mainProductItem => {
        // find mainProductVariant
        let mainProductVariant = null
        allProducts.some(product =>
          product.variants.some(variant => {
            if (variant.variantId === shopify.decode(mainProductItem.variant.id) && variant.freeExtraProduct && variant.freeExtraProduct.length > 0) {
              mainProductVariant = variant
            }
          })
        )

        if (mainProductVariant) {
          // get extraFreeProducts from mainProductVariant
          const extraFreeProducts = mainProductVariant.freeExtraProduct

          // forEach extraFreeProducts of mainProductVariant to build to extraProductsShouldBe
          extraFreeProducts.forEach(extraFreeProductItem => {
            const extraFreeProductVariantId = extraFreeProductItem.variantId
            const isExtraIncluded = extraProductsShouldBe.find(item => item.variantId === extraFreeProductVariantId)
            const isExtraRemoved = checkIsExtraRemoved(extraFreeProductVariantId, mainProductItem)
            if (isExtraRemoved) {
              return;
            }
            if (isExtraIncluded) {
              extraProductsShouldBe = extraProductsShouldBe.map(item => {
                if (item.variantId === extraFreeProductVariantId) {
                  const parent = createParentAttributeObj(mainProductItem)
                  const isParentExist = item.parents.find(item => item.parentVariantId === parent.parentVariantId && item.isSubscription === parent.isSubscription)
                  const parents = isParentExist ?
                    item.parents.map(item => {
                      if (item.parentVariantId === parent.parentVariantId && item.isSubscription === parent.isSubscription) {
                        return { ...item, quantityPerParentItem: item.quantityPerParentItem + 1 }
                      }
                      return item
                    }) :
                    [...item.parents, { ...parent, quantityPerParentItem: 1 }]
                  return { ...item, quantity: item.quantity + mainProductItem.quantity, parents }
                }
                return item
              })
            } else {
              const parent = createParentAttributeObj(mainProductItem)
              extraProductsShouldBe.push({
                variantId: extraFreeProductVariantId,
                quantity: mainProductItem.quantity,
                parents: [{ ...parent, quantityPerParentItem: 1 }]
              })
            }
          })
        }
      })

      let shouldUpdateExtraProducts = extraProducts.filter(extraProductItem => {
        const extraProductShouldBeItem = extraProductsShouldBe.find(item => item.variantId === shopify.decode(extraProductItem.variant.id))
        const extraProductItemParents = getParentsFromExtraFreeProduct(extraProductItem)

        const isParentsEqual = extraProductShouldBeItem?.parents?.every(epShouldBeParentItem => {
          return extraProductItemParents.find(epParentItem => {
            return epParentItem.parentVariantId === epShouldBeParentItem.parentVariantId &&
              epParentItem.isSubscription === epShouldBeParentItem.isSubscription &&
              epParentItem.quantityPerParentItem === epShouldBeParentItem.quantityPerParentItem
          })
        })

        return extraProductItem.quantity !== extraProductShouldBeItem?.quantity || !isParentsEqual
      }).map(extraProductItem => {
        const extraProductShouldBeItem = extraProductsShouldBe.find(item => item.variantId === shopify.decode(extraProductItem.variant.id))
        return { ...extraProductItem, quantity: extraProductShouldBeItem?.quantity, parents: extraProductShouldBeItem?.parents }
      })

      if (shouldUpdateExtraProducts.length) {
        shouldUpdateExtraProducts = shouldUpdateExtraProducts.filter(shouldUpdateExtraProductItem => {
          return !removedFromCartList.find(removedEmptyItem => {
            return removedEmptyItem.variant.id === shouldUpdateExtraProductItem.variant.id && shouldUpdateExtraProductItem.quantity === removedEmptyItem.quantity
          })
        })

        const shouldUpdateExtraEmptyProducts = shouldUpdateExtraProducts.filter(item => !item.quantity)
        setRemovedFromCartList(prevState => [...prevState, ...shouldUpdateExtraEmptyProducts])
      }

      if (shouldUpdateExtraProducts.length) {
        let itemsToUpdate = shouldUpdateExtraProducts.map(extraProductItem => {
          let isNoParents = false
          const customAttributes = extraProductItem.customAttributes.map(item => {
            if (item.key === 'extra_free_product_gift_for_list') {
              isNoParents = !extraProductItem?.parents?.length
              return {
                ...item,
                value: JSON.stringify(extraProductItem?.parents)
              }
            }
            return item
          })

          return {
            id: extraProductItem.id,
            // to filter null/undefined quantities, set -1 and filter then
            quantity: isNoParents ? 0 : extraProductItem?.quantity ?? -1,
            ...(isNoParents ? {} : { customAttributes }),
          }
        }).filter(extraProductItem => extraProductItem.quantity !== -1)

        const itemsToRemove = itemsToUpdate.filter(item => item.quantity === 0)
        itemsToUpdate = itemsToUpdate.filter(item => item.quantity !== 0)

        if (itemsToUpdate.length) {
          updateCartItems(itemsToUpdate)
        }
        if (itemsToRemove.length) {
          setRemovingItem(true)
          if (filteredLineItems.length === itemsToRemove.length) {
            const appliedLineItemDiscount = filteredLineItems.some(item => item.discountAllocations.find(
              ({ discountApplication: discount }) => {
                return discount?.targetSelection === 'LINE_ITEM' || discount?.targetType === 'LINE_ITEM'
              }
            ))
            if (appliedLineItemDiscount) {
              const { checkout, countryCode } = store
              await shopify.removeDiscount(checkout.id, countryCode)
            }
          }

          updateCartItems(itemsToRemove)
          setRemovingItem(false)
        }
      }

    }
  }

  return { addExtraFreeProduct, updateExtraFreeProductsCart }
}

export { useUpdateExtraFreeProduct, extraFreeProductsUtils }