function Logo() {
  return (
    <svg
      width="112"
      height="22"
      viewBox="0 0 112 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.132 0.0474481C109.149 0.0474481 110.046 0.442473 110.832 1.22463C111.618 2.00678 112.015 2.91534 112 3.95821V17.2469C112 18.2977 111.61 19.2062 110.824 19.9884C110.038 20.7706 109.141 21.1577 108.132 21.1577H100.055L100.063 0.0474481H108.132ZM103.915 17.2627H108.164V3.95031H103.915V17.2627Z"
        fill="#003057"
      />
      <path
        d="M96.6644 21.15L96.6485 21.1262L96.6565 0.0476361L92.8366 0.0397339V11.1163L88.6275 0.0397339H84.5059V21.15H88.3337V10.0892L92.5348 21.15H96.6644Z"
        fill="#003057"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.5569 21.1737L76.6595 16.7494H72.5934L71.7278 21.2211H67.8841L72.1646 0.0239489H77.0804L81.4561 21.1737H77.5569ZM75.9209 12.9097L74.6265 5.92565L73.3241 12.9097H75.9209Z"
        fill="#003057"
      />
      <path
        d="M65.3905 17.2942H59.6964L59.7044 0H55.8289L55.821 21.205L65.3905 21.2129V17.2942Z"
        fill="#003057"
      />
      <path
        d="M52.1679 17.3023H45.9497V12.5779H51.2388V8.65129H45.9576V3.92677H52.0885V0.00810067H42.098L42.0901 21.2131H52.1679V17.3023Z"
        fill="#003057"
      />
      <path
        d="M27.1284 17.2311V0.0158166H30.845V15.2954C30.845 15.9038 30.6465 17.4997 32.076 17.4997H33.6246C35.0143 17.4997 34.9746 16.204 34.9746 15.3745V0.0158166H38.6515V17.2311C38.6515 19.0482 37.8097 20.0515 36.6582 20.6599C35.6814 21.1656 34.7364 21.1656 34.6967 21.1656H31.115C28.9867 21.1656 27.1284 19.6802 27.1284 17.2311Z"
        fill="#003057"
      />
      <path d="M24.7139 17.2942H19.0198V0H15.1444V21.205L24.7139 21.2129V17.2942Z" fill="#003057" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6979 7.41071V3.90286C11.6979 2.86789 11.3088 1.95933 10.5305 1.17718C9.75222 0.395028 8.84686 0 7.83034 0H0.00794171L0 21.205H7.8224C8.83892 21.205 9.74426 20.8179 10.5225 20.0436C11.3008 19.2694 11.6899 18.3529 11.6899 17.3022V13.7627C11.6899 13.0359 11.547 12.4512 11.2611 12.0247C10.9752 11.5901 10.6178 11.1082 10.2049 10.5788C10.6258 10.0653 10.9752 9.59919 11.2611 9.17253C11.555 8.74589 11.6979 8.16126 11.6979 7.41071ZM7.56828 17.2942H3.60546V12.5698H7.56828V17.2942ZM3.61339 8.64318H7.57621V3.91867H3.61339V8.64318Z"
        fill="#003057"
      />
    </svg>
  )
}

export default Logo
