import React, { useEffect } from 'react'
import cx from 'classnames'
import Footer from 'components/Footer'
import Navigation from 'components/Navigation'

const PageFallback = ({ footer = {}, globals = {}, navigation = {}, promoBar = {} }: any) => {

  return (
    <>
      {/* {!!Object.keys(globals).length && !!Object.keys(navigation).length && (
        <Navigation
          homepageCta={globals.homepageCta}
          globals={globals}
          {...navigation}
          promoBar={promoBar}
        />
      )} */}
      <div className={cx('relative l:flex h-screen justify-center items-center z-1')}>
        <div className="w-full">
          <div className="max-w-350 l:mx-auto px-16 py-60 l:px-0 l:py-0 ">
            <div className="justify flex-wrap space-y-15 l:space-y-20">
              <div className="block" style={{ color: 'rgb(0, 48, 87)' }}>
                <h2 className="stylized-headline leading-130 font-medium text-26">
                  Please pardon our mess
                </h2>
              </div>
              <p className="max-w-300 text-16">
                Something went wrong. Please refresh the page or go back to try again.
              </p>
            </div>
            <div className="">
              {/*  eslint-disable-next-line @next/next/no-html-link-for-pages */}
                <a href="/" className="button inline-block font-medium text-center rounded-pill transition bg-navy-blue focus:outline-none focus-visible:ring-2 focus-visible:ring-navy-blue focus-visible:ring-offset-1 mt-14 l:mt-16 btn-hero text-16 l:text-18 px-30 pt-13 pb-12 leading-100 relative text-white group-btn">
                  <div className="flex justify-center">
                    <div className="relative z-1 transition ">Go to homepage</div>
                  </div>
                </a>
            </div>
          </div>
        </div>

        <div className="w-full h-screen hidden l:block" style={{ zIndex: '-1' }}>
          <img src={'/temp-error.png'} className='h-screen' width="100%" height="100%" />
        </div>
      </div>

      {/* {!!Object.keys(footer).length && <Footer {...footer} />} */}
    </>
  )
}

export default PageFallback
