import CustomSelect from 'components/SubscriptionKitForm/CustomSelect/CustomSelect'
import React, { useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import pluralize from 'lib/pluralize'
import { useRouter } from 'next/router'

export const getUnitLabel = (countContent: string, unitPriceLabel = 'tablet', unitPriceLabelPlural) => {
  if (!unitPriceLabel) unitPriceLabel = 'tablet'
  if (countContent?.toLowerCase().indexOf(unitPriceLabel?.toLowerCase()) == -1) {
    return countContent + ' ' + (parseInt(countContent?.replaceAll(/([\D])/g, '')) > 1 ? pluralize(unitPriceLabel, 2, unitPriceLabelPlural) : unitPriceLabel)
  }
  return countContent
}

const DropdownQuantitySelector = ({ product, activeVariant, setActiveVariant, isSubscriptionOption, setShowStickyAtcDrawer, isProductCard = false }: any) => {
  const router = useRouter()
  const packSizeOption = product.options.find((option: { name: string }) => {
    return ["pack size", "count", "recurring quantity", "refill quantity", 'quantity'].includes(option?.name?.toLowerCase())
  })

  const quantityItems = useMemo(() => {
    return product.variants.filter((variant: { [x: string]: any; sellingPlans: any; }) => (isSubscriptionOption ? ((!variant?.sellingPlans || !!variant?.sellingPlans?.find(plan => plan.type == 'subscription')) && !variant.disableForSubscription) : ((!variant?.sellingPlans || !!variant?.sellingPlans?.find(plan => plan.type == 'onetime')) && !variant.disableForOneTime)) && product.options.every((option: { _key: any; position: any }) => (option._key == packSizeOption?._key ? true : activeVariant[`option${option.position}`] == variant[`option${option.position}`])))
  }, [activeVariant, isSubscriptionOption, packSizeOption?._key, product.options, product.variants])

  useEffect(() => {
    if (quantityItems.length && !quantityItems.find(item => item.variantId == activeVariant.variantId)) {
      setActiveVariant(quantityItems[0])
    }
  }, [isSubscriptionOption])

  if (!packSizeOption || quantityItems.length <= 1) return null;

  return (
    <div className={cx("relative transition-all text-navy-blue-muted space-y-4 l:space-y-10", {
      'pl-16 l:pl-24 mt-12 text-12 l:text-13 leading-145 l:leading-100': !isProductCard,
      'mt-8 text-13 leading-13': isProductCard,
    })}>
      <CustomSelect
        options={quantityItems.map((item: { [x: string]: any; variantId: any; oneTimeQtySubtitle: boolean; qtySubtitle: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined }) => {
          return {
            value: item.variantId,
            label: (<span className={cx('flex flex-wrap gap-3 text-navy-blue', {
              'm:flex-col l:flex-row': isProductCard
            })}>
              <span className='font-medium'>
                {getUnitLabel(item[`option${packSizeOption.position}`], product.unitPriceLabel, product.unitPriceLabelPlural)} </span>
              {item.qtySubtitle && isSubscriptionOption && <span>({item.qtySubtitle})</span>}
              {item.oneTimeQtySubtitle && !isSubscriptionOption && <span>({item.oneTimeQtySubtitle})</span>}
            </span>)
          }
        })}
        onChange={(value: any) => {
          const variant = quantityItems.find((item: { variantId: any }) => item.variantId == value)
          router.replace(
            { query: { ...router.query, variant: variant.variantTitle } },
            undefined,
            { shallow: true }
          )
          setShowStickyAtcDrawer?.(false)
          setActiveVariant(variant)
        }}
        value={quantityItems.find(item => item.variantId == activeVariant?.variantId) ? activeVariant?.variantId : quantityItems[0]?.variantId}
        isProductCard={isProductCard}
      />
    </div>
  )
}

export default DropdownQuantitySelector