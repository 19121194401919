export function handlePopupClick(hasPopup, modalId, setActiveModalId) {
  if (hasPopup) setActiveModalId(modalId)
}

export function handleNavClick(callback) {
  callback()
}

export function getPromoBanners(banners, countryCode) {
  const cc = countryCode
  const banner = banners.find((b) => b.country.countryCode === cc)
  if (cc && (!banner || !banner?.isEnabled)) {
    const otherBanner = banners.find((b) => b.country.countryCode === 'OT')
    if (otherBanner) return otherBanner
  }
  return banner || {}
}

export function handleCountrySelection(selectedCountryCode, setFlagExpanded, countryCode, setCountryCode) {
  setFlagExpanded(false)

  if (selectedCountryCode === countryCode) return

  setCountryCode(selectedCountryCode)
}

export function getSpacing(spacing, mobileSpacing) {
  if (window.screen?.width > 768) {
    return spacing
  } else {
    return mobileSpacing
  }
}