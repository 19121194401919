import cx from 'classnames'
import blocksToHtml from '@sanity/block-content-to-html'
import textInlineSerializer from '../../serializers/textInlineSerializer'
import { InfoIcon } from 'components/Icons/InfoIcon'
import BlockContent from '@sanity/block-content-to-react'

export default function PromoMessage({
  message,
  handlePopupClick,
  modalPopup,
  setActiveModalId,
  getSpacing,
  spacing,
  mobileSpacing,
  isScreenLarge,
  getColorStyle,
  linkHoverColor,
  textColor,
  hasMultipleLines,
  textBlockSerializer,
  currentTime,
  flagVisible,
  GetCountdownInfo,
  thickerPromoBar
}) {
  return (
    <div
      onClick={(e) => {
        if (
          (e.target.tagName === 'A' ||
            (e?.target?.tagName === 'STRONG' &&
              e?.target?.parentElement?.tagName === 'A')) &&
          e.target.href !== 'undefined'
        ) {
          return
        }
        handlePopupClick(message, message.hasPopup ? modalPopup++ : 0, setActiveModalId)
      }}
      key={message._key}
      className="promo-bar__slide relative flex flex-col l:flex-row items-center justify-center min-w-full text-13 text-center transition-opacity duration-100"
      style={{ padding: `0px ${getSpacing(spacing, mobileSpacing)}px` }}
    >
      {message.type === 'discount' && !!message.content ? (
        <div className={cx({ 'underline cursor-pointer': message.hasPopup })}>
          {message.content}
        </div>
      ) : (
        <div>
          <div
            className={cx(
              {
                'cursor-pointer': message.hasPopup,
                underline: message.hasPopup && !message.showInfoIcon,
                'text-13 leading-18 font-medium': thickerPromoBar,
                'text-navy-blue': thickerPromoBar && !textColor,
                'flex': isScreenLarge
              },
              'space-x-4 items-center justify-center',
            )}
          >
            <span>
              {
                blocksToHtml({
                  blocks: isScreenLarge ? message?.desktopContent : message?.mobileContent,
                  serializers: textInlineSerializer('', getColorStyle(linkHoverColor)),
                })
              }
            </span>
            {message?.hasPopup && message?.showInfoIcon && (
              <InfoIcon
                style={{ color: textColor ? getColorStyle(textColor) : '#003057', display: 'inline', marginTop: isScreenLarge ? '' : '-1px' }}
                width={14}
                height={14}
              />
            )}
          </div>
          {hasMultipleLines && (
            <div className={cx({ 'text-navy-blue-muted': !textColor })}>
              {
                <BlockContent
                  blocks={
                    isScreenLarge
                      ? message.desktopContentLine2
                      : message.mobileContentLine2
                  }
                  serializers={textBlockSerializer('', getColorStyle(linkHoverColor))}
                />
              }
            </div>
          )}
        </div>
      )}
      {message.hasCountdown && (
        <div style={{ textDecoration: 'none' }}>
          {GetCountdownInfo(
            message.countdownStartDate,
            message.countdownDate,
            currentTime,
            message.countdownContent,
            flagVisible,
            isScreenLarge,
            textColor
          )}
        </div>
      )}
    </div>
  )
}