import { usePromoConfig } from 'hooks/usePromoConfig'
import useCartPrices from 'hooks/useCartPrices'
import { useCartTotals, useCountryCode } from 'context/globalStore'
import { getSubDiscountedPrice, getOnetimeDiscountedPrice } from 'lib/getDiscountedPrice'
import { formatNumberAsPrice, getPriceAsNumber } from 'lib/formatPrice'

export default function GetSubscriptionPLPFormattedPrice(product, discount = 0, activeVariant, activeVariantObj) {
  const discountObject = usePromoConfig({ product, location: 'PDP' })
  const { nonDiscountTotal: cartNonDiscountTotal } = useCartTotals()
  const { isPromoActivated } = useCartPrices()
  const subVariantObj = activeVariantObj
  const cartNonDiscountTotalNumber = getPriceAsNumber(cartNonDiscountTotal);
  const { countryCode } = useCountryCode()
  const oneTimePrice = formatNumberAsPrice(
    +getOnetimeDiscountedPrice(
      activeVariantObj?.price?.amount.replace('$', ''),
      product,
      discountObject,
      cartNonDiscountTotalNumber !== null && cartNonDiscountTotalNumber >= +discountObject?.thresholdAmount &&
      !discountObject?.hideStrikeoutPrice,
      countryCode
    ),
    activeVariantObj?.price?.currencyCode || 'USD'
  )
  const subPriceValueWithoutDiscount = subVariantObj?.price?.amount || activeVariant.price
  const subPrice = formatNumberAsPrice(
    +getSubDiscountedPrice(
      +subPriceValueWithoutDiscount,
      product,
      discountObject,
      isPromoActivated && !discountObject?.hideStrikeoutPrice
    ),
    subVariantObj?.price?.currencyCode || 'USD'
  )
  const displaySubDiscount = discountObject?.displayDiscountPrice && isPromoActivated && !discountObject?.hideStrikeoutPrice

  return (
    <div className="flex text-16 leading-16 font-medium">
      <div className='line-through mr-4' style={{ color: '#A1B1BE' }}>{oneTimePrice}</div>
      <div style={{
        color:
          displaySubDiscount
            ? discountObject?.promoTheme?.primaryColor?.hex
            : 'inherit',
      }}>{subPrice}</div>
    </div>
  )
}