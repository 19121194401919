import { useAutoDiscount } from 'context/globalStore'
import { useMemo } from 'react'
import { usePromoConfig } from './usePromoConfig'
import { findLowestPriceVariant } from './usePriceNotation'
import { isSubscriptionProduct } from 'components/Product/Product.helper'

export default function usePLPAutoDiscount(product) {
  const { autoDiscount: plpAutoDiscount } = product
  const { autoDiscount: globalAutoDiscount } = useAutoDiscount()

  const config = usePromoConfig({ product, location: 'PDP' })
  const {
    autoDiscountAmount = 0,
    autoDiscountText,
    enableAutoDiscount,
    autoDiscountSubscriptionAmount,
  } = useMemo(() => {
    const lowestPriceVariant = findLowestPriceVariant(product?.variants);
    const typeVariant = product?.type === 'productVariant'
    const oneTimeTypeVariant = typeVariant && product?.disableForSubscription;
    return {
      enableAutoDiscount: config?.enableAutoDiscount ?? false,
      autoDiscountAmount: oneTimeTypeVariant ? config?.oneTimeDiscountPct : (isSubscriptionProduct(product) && !lowestPriceVariant?.disableForSubscription) ? config?.subscriptionDiscountPct : config?.oneTimeDiscountPct || 0,
      autoDiscountSubscriptionAmount: config?.subscriptionDiscountPct ?? 0,
      autoDiscountText:
        config?.autoDiscountText ??
        globalAutoDiscount?.PLPAutoDiscountConfig?.autoDiscountOneTimeText ??
        '',
    }
  }, [
    config?.enableAutoDiscount,
    config?.oneTimeDiscountPct,
    config?.subscriptionDiscountPct,
    globalAutoDiscount?.PLPAutoDiscountConfig?.autoDiscountOneTimeText,
    plpAutoDiscount?.autoDiscountText,
  ])

  return {
    autoDiscountAmount,
    autoDiscountText,
    enableAutoDiscount,
    autoDiscountSubscriptionAmount,
  }
}
