import Image from 'components/Image'
import ProductBadge from 'components/ProductBadge'
import Link from 'components/Link'
import Bottomline from 'components/Bottomline'
import { screens } from 'lib/constants'
import useMedia from 'use-media'
import { useRef, useState, useEffect } from 'react'
import cx from 'classnames'
import * as analytics from 'lib/analytics'
import usePriceNotation from 'hooks/usePriceNotation'

export default function SearchResultProduct({
  as: Component = 'li',
  priceClass = '',
  titleClass = '',
  withClass = true,
  product,
  collection,
  index,
  enableSwatch = false,
  starRatingStyle = 1,
  ratingNumberTemplate = '',
  bottomPrice = true,
  closeSearchBar,
  searchTerm,
}) {
  const { formattedPrice } = usePriceNotation(product)
  const isSwatchEnable = enableSwatch && product?.plpSwatch?.enable

  const link = (slug) => ({
    _type: 'internalLink',
    reference: {
      _type: product._type,
      slug: slug,
      productSlug: product?.productSlug,
    },
  })
  const [pdpLink, setPdpLink] = useState(link(product.slug))

  const isScreenMedium = useMedia({ minWidth: screens.m })
  const isScreenExtraLarge = useMedia({ minWidth: screens.xl })

  const titleRef = useRef()

  function handleProductClick() {
    const variant = product.variants[0]
    const image =
      product?.shopifyImage?.asset?.url ||
      (variant && variant?.image?.src) ||
      variant?.siloImage?.asset?.url
    closeSearchBar()

    analytics.trackProductClickFromSearch(
      {
        id: (variant && variant.sku) || '',
        name: product.title,
        brand: 'Blueland',
        category: collection,
        variant: (variant && variant.variantTitle) || '',
        price: (product.cents / 100).toFixed(2).toString(),
        quantity: '1',
        position: index + 1 ?? 1,
        list: location.pathname,
        product_id: product.productId?.toString(),
        variant_id: (variant && variant.variantId)?.toString() || '',
        image: image,
        inventory: '99999',
      },
      searchTerm
    )

    analytics.trackProductClick({
      id: (variant && variant.sku) || '',
      name: product.title,
      brand: 'Blueland',
      category: collection,
      variant: (variant && variant.variantTitle) || '',
      price: (product.cents / 100).toFixed(2).toString(),
      quantity: '1',
      position: index + 1 ?? 1,
      list: location.pathname,
      product_id: product.productId?.toString(),
      variant_id: (variant && variant.variantId)?.toString() || '',
      image: image,
      inventory: '99999',
    })
  }

  const [variantImage, setVariantImage] = useState(product.thumbnailImage?.asset?.url ? product.thumbnailImage : product.shopifyImage)
  const [selectedOption, setSelectedOption] = useState('')
  const reviewTextColorStyle = { color: '#4D6E89', fontSize: '13px', lineHeight: '13px' }

  function getSwatchVariantIndex() {
    const { options: selectedOptionTitle, enable } = product.plpSwatch || {}
    if (!enable) return 0

    const option = product.options.find((o) => o.name === selectedOptionTitle)
    const index = option && option.values.indexOf(selectedOption)
    return index === -1 ? 0 : index
  }

  useEffect(() => {
    const { swatchItems } = product.plpSwatch || {}
    const items = isSwatchEnable ? swatchItems : []

    if (isSwatchEnable) selectOption(items[0].option)
  }, [])

  useEffect(() => {
    const { enable } = product.plpSwatch || {}
    if (!enable) {
      if (product.isEnabled) {
        setPdpLink(link(`${product?.slug?.current || product?.slug }`))
        return
      } else {
        return
      }
    }

    const position = getSwatchVariantIndex()
    setPdpLink(link(`${product?.slug?.current || product?.slug}?option=${position}`))
  }, [selectedOption])

  function selectOption(option) {
    setSelectedOption(option)

    const optionCount = product.options.length
    const variant = product.variants.find((v) => v[`option${optionCount}`] === option)
    variant?.media && setVariantImage(variant.media.image)
  }

  function starRatingDesign() {
    const { average_score, total_reviews } = product?.bottomline || {}

    return {
      averageScore: average_score,
      totalReviews: total_reviews,
      size: isScreenMedium ? 'large' : 'small',
      displayTotal: isScreenMedium ? true : false,
      starColor: 'text-royal-blue',
      ratingNumberTemplate,
      mode: 'search',
      textStyle: reviewTextColorStyle,
    }
  }

  return (
    <Component
      className={cx({
        'desktop w-1/2 s:w-1/3 px-7 l:px-0 mb-20 l:mb-40': withClass,
      })}
    >
      <div className="relative group flex flex-col l:flex-row">
        <div className="relative overflow-hidden rounded mb-10 l:mb-0">
          <img
            className={'rounded hidden l:block mr-13'}
            width={100}
            height={100}
            src={variantImage?.asset?.url}
            alt={ variantImage?.asset?.altText || 'Blueland: Eco-Friendly Cleaning Products'}
          />
          <Image
            width={100}
            height={100}
            image={variantImage}
            className={'block l:hidden'}
            sizes={`50vw, (min-width: ${screens.s}) 33vw, (min-width: ${screens.l}) 25vw`}
          />
          {product?.badges?.length && !isScreenExtraLarge ? (
            <div className="absolute top-5 l:top-7 xl:top-10 left-5 l:left-7 xl:left-10 space-x-5 z-1 text-0">
              {product.badges?.slice(0,2).map(({ _id, label, background, textColor, disabled }) => {
                return (
                  <ProductBadge
                    key={_id}
                    background={background}
                    textColor={textColor}
                    size={'small'}
                    disabled={disabled}
                  >
                    {label}
                  </ProductBadge>
                )
              })}
            </div>
          ) : null}
        </div>

        <div>
          <h2
            ref={titleRef}
            className={cx(
              titleClass ||
                'flex justify-between items-start text-13 leading-18.46 m:text-16 l:leading-17 max-w-150 l:max-w-200 font-medium mb-5'
            )}
          >
            <Link className="font-medium" link={pdpLink} onClick={handleProductClick}>
              {product.title}
              <span onClick={handleProductClick} className="absolute inset-0 z-1"></span>
            </Link>
          </h2>

          {!(product.isYotpoDisabled || product.isPlpStarRatingHidden) && (
            <div className="mb-10 l:mb-12">
              <Bottomline {...starRatingDesign()} />
            </div>
          )}
          <div
            className={cx(
              priceClass ||
                'text-13 leading-18.46 l:text-16 l:leading-22.72  text-navy-blue-muted space-x-10',
              {
                'hidden l:block': bottomPrice,
              }
            )}
          >
            {formattedPrice}
          </div>
          {bottomPrice && (
            <div
              className={cx(
                priceClass || 'block l:hidden mt-10 text-13 text-navy-blue-muted space-x-5'
              )}
            >
              {formattedPrice}
            </div>
          )}
        </div>
      </div>
    </Component>
  )
}
