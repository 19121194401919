import { useEffect } from 'react'
import { on } from 'martha'

export default function useClickOutside(ref, cb) {
  useEffect(() => {
    let off = on(document, 'click', (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        cb(event)
      }
    })

    return off
  }, [ref])
}
