import React, { useEffect, useState, useRef } from 'react';
import SearchResultComponent from 'components/SearchResult';

const SearchDropDownComponent = ({ toggle, closeSearchBar, globals, searchConfig, searchDropDown }) => {
  const [promoBarHeight, setPromoBarHeight] = useState(0);
  const searchDropDownContentRef = useRef(null);

  useEffect(() => {
    const promoBar = document.querySelector('.promo-bar');
    if (promoBar) {
      setPromoBarHeight(promoBar.offsetHeight + 14);
    }
  }, [searchDropDown]);

  useEffect(() => {
    if (searchDropDownContentRef.current) {
      searchDropDownContentRef.current.style.paddingTop = `${promoBarHeight}px`;
    }
  }, [promoBarHeight]);

  function CloseIcon() {
    return (
      <svg viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          width="1"
          height="9"
          rx="0.5"
          transform="matrix(-0.707109 -0.707104 0.707109 -0.707105 0.707092 7.07111)"
          fill="#4D6E89"
        />
        <rect
          width="1"
          height="9"
          rx="0.5"
          transform="matrix(0.707109 -0.707104 0.707109 0.707105 6.10352e-05 0.707092)"
          fill="#4D6E89"
        />
      </svg>
    )
  }

  return (
    <>
      <div
        className={'hidden l:block w-full h-screen'}
        onClick={() => closeSearchBar()}
      >
        <div
          className={' absolute top-full hidden l:block  transition origin-top-left w-full'}
          style={{ marginLeft: '-6rem' }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className={
              'bg-white top-full  border-t border-pattens-blue rounded-b shadow-overlay transition origin-top-left w-full rounded-bl-lg rounded-br-lg'
            }
          >
            <div className={'searchDropDownContent'} ref={searchDropDownContentRef}>
              <div className={'flex w-full items-start justify-between'}>
                <h2 className={'text-15 leading-15 mb-30 font-medium letterSpacing-3'}>
                  {searchConfig?.title}
                </h2>
                <div className={'w-20 h-20 cursor-pointer'} onClick={() => closeSearchBar()}>
                  {CloseIcon()}
                </div>
              </div>

              <div className={'productsMainContainer flex items-start justify-start'}>
                <SearchResultComponent
                  closeSearchBar={() => closeSearchBar()}
                  searchConfig={searchConfig}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'fixed searchDropDownMask top-0 block l:hidden transition left-0 w-full'}>
        <div
          className={
            'bg-white top-full  border-t border-pattens-blue rounded-b shadow-overlay transition origin-top-left w-full rounded-bl-lg rounded-br-lg'
          }
        >
          <div className={'searchDropDownContent px-15 pb-20'} ref={searchDropDownContentRef}>
            <div className="flex items-center mb-20 justify-between">
              <h2 className={' text-24 leading-26 font-medium'}>Search</h2>
              <div
                className={'z-10 right-10 cursor-pointer w-20 h-20'}
                onClick={() => closeSearchBar()}
              >
                {CloseIcon()}
              </div>
            </div>
            <div className={'productsMainContainer pb-150 flex items-start justify-start'}>
              <SearchResultComponent
                globals={globals}
                closeSearchBar={() => closeSearchBar()}
                searchConfig={searchConfig}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SearchDropDownComponent