exports.screens = {
  '2xs': '375px',
  xs: '400px',
  s: '650px',
  m: '768px',
  l: '1024px',
  xl: '1280px',
  xxl: '1440px',
  '2xl': '1536px',
  '2k': '1920px',
  '4k': '2560px',
}

exports.easings = {
  DEFAULT: 'cubic-bezier(0.23, 1, 0.32, 1)',
  'in-quad': 'cubic-bezier(0.55, 0.085, 0.68, 0.53)',
  'in-cubic': 'cubic-bezier(0.55, 0.055, 0.675, 0.19)',
  'in-quart': 'cubic-bezier(0.895, 0.03, 0.685, 0.22)',
  'in-quint': 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
  'in-sine': 'cubic-bezier(0.47, 0, 0.745, 0.715)',
  'in-expo': 'cubic-bezier(0.95, 0.05, 0.795, 0.035)',
  'in-circ': 'cubic-bezier(0.6, 0.04, 0.98, 0.335)',
  'in-back': 'cubic-bezier(0.6, -0.28, 0.735, 0.045)',
  'out-quad': 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
  'out-cubic': 'cubic-bezier(0.215, 0.61, 0.355, 1)',
  'out-quart': 'cubic-bezier(0.165, 0.84, 0.44, 1)',
  'out-quint': 'cubic-bezier(0.23, 1, 0.32, 1)',
  'out-sine': 'cubic-bezier(0.39, 0.575, 0.565, 1)',
  'out-expo': 'cubic-bezier(0.19, 1, 0.22, 1)',
  'out-circ': 'cubic-bezier(0.075, 0.82, 0.165, 1)',
  'out-back': 'cubic-bezier(0.175, 0.885, 0.32, 1.275)',
  'in-out-quad': 'cubic-bezier(0.455, 0.03, 0.515, 0.955)',
  'in-out-cubic': 'cubic-bezier(0.645, 0.045, 0.355, 1)',
  'in-out-quart': 'cubic-bezier(0.77, 0, 0.175, 1)',
  'in-out-quint': 'cubic-bezier(0.86, 0, 0.07, 1)',
  'in-out-sine': 'cubic-bezier(0.445, 0.05, 0.55, 0.95)',
  'in-out-expo': 'cubic-bezier(1, 0, 0, 1)',
  'in-out-circ': 'cubic-bezier(0.785, 0.135, 0.15, 0.86)',
  'in-out-back': 'cubic-bezier(0.68, -0.55, 0.265, 1.55)',
}

exports.colors = {
  transparent: 'transparent',
  'transparent-white': 'rgba(255, 255, 255, 0)',
  'transparent-pattens-blue': 'rgba(222, 234, 255, 0)',
  current: 'currentColor',
  black: '#000000',
  white: '#FFFFFF',
  'navy-blue': {
    DEFAULT: '#003057',
    muted: '#4D6E89',
  },
  'royal-blue': '#26459B',
  'bright-blue': '#3369FF',
  'malibu-blue': '#8BBCFF',
  'sky-blue': '#A7CCFF',
  'pattens-blue': '#DEEAFF',
  'alice-blue': '#F5F9FF',
  'mabel-blue': '#D5F7FF',
  'pale-orange': '#FFF9E9',
  'peach-orange': '#FFD396',
  oasis: '#F2FFC7',
  'blush-pink': '#FCE5FF',
  'bubblegum-pink': '#FF90F6',
  'solid-blue': '#4168F6',
  'hawkes-blue': '#D2DAE2',
  grey: '#E6EDFC',
  red: '#FF003B',
  'light-green': '#D6F4E4',
  'light-blue': '#F6F9FE',
  'light-grey': '#CBD3D9',
}

exports.Keys = {
  RETURN: 13,
  SPACE: 32,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
}

exports.Plans = {
  ONETIME: 'one-time',
  SUBSCRIPTION: 'subscription',
}

exports.SubscriptionTypes = {
  Kit: 'kit',
  Refills: 'refills',
}

exports.ProductHeroTypes = {
  PDP: 'pdp',
  QUICK_ADD: 'quickAdd',
}

exports.ProductDisplayOptions = {
  ONETIME_ONLY: 'onetime_only',
  SUBSCRIPTION_ONLY: 'subscription_only',
  DEFAULT: '',
}

exports.Sizes = {
  COMPACT: 'compact',
  SMALL: 'small',
  LARGE: 'large',
}

exports.ButtonVariants = {
  OUTLINED: 'outlined',
  CONTAINED: 'contained',
  WHITE_BTN: 'whiteBtn',
  TEXT_BTN: 'textBtn',
}

exports.productTestRuleset = {
  /*
   *  Each variation is an array containing two arrays
   *  First array contains normal products to be hidden
   *  Second array contains test products to be shown
   *  All products tagged as 'test-product' on Shopify are filtered away
   */
  // 'csk-ab': {
  //   'a': [[], []],
  //   'b': [['4669945610295'], ['6662455459895']]
  // },
  'hand-soap-abc': {
    a: [[], []],
    b: [
      ['4535579934775', '', '6607056502839', '6646894559287', '4426137501751'],
      ['6663633633335', '6663632748599', '6642055413815'],
    ],
    c: [
      ['4535579934775', '', '6607056502839', '6646894559287', '4426137501751'],
      ['6661791973431'],
    ],
  },
}

exports.DEFAULT_FREQUENCY = 3

exports.ARTICLES_PER_PAGE = 12

exports.SHOPIFY_CHECKOUT_STORAGE_KEY = 'blueland_checkout_id'
exports.AUTO_DISCOUNT_STORAGE_KEY = 'blueland_auto_discount'
exports.BLU_UTMPARAMS_STORAGE_KEY = 'blueland_utm_params'

exports.SELECTED_COUNTRY_CODE = 'selected_country_code'