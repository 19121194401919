import { buildSwatchCategories } from 'components/SubscriptionRefillsForm/helper'
import { getOptionSettingsData } from 'components/Product/Product.helper'
import PillSwatchCategories from '../../SubscriptionKitForm/PillSwatchCategories'
import cx from 'classnames'

export default function PillSwatch(position, swatchParam, selectedOptionParam, activeOptionValueSettings, product, activeVariant, handleSwatchSelect, isOptionAvailable) {
  const swatch = swatchParam || product.plpSwatch || {}
  const { swatchItems } = swatch
  const multiLineArrangement = swatch.multiLineArrangement
  const swatchCategories = buildSwatchCategories(swatchItems)
  const containsMultipleCategories = Object.keys(swatchCategories).length > 1

  const valueSettings = activeOptionValueSettings?.find(
    (s) => s.forOption === `${swatch.options}:${selectedOptionParam}`
  )
  const optionSettingsData = getOptionSettingsData(product);
  const optionSettings = optionSettingsData
  const settings = optionSettings?.find((s) => s.forOption === swatch.options)

  return (
    <div
      className={cx('gap-25 w-full')}
    >
      {Object.entries(swatchCategories).map(([category, items], i) => (
        <PillSwatchCategories
          index={category}
          key={i}
          multiLineArrangement={multiLineArrangement}
          category={containsMultipleCategories && category}
          items={items}
          position={position}
          handleSwatchSelect={handleSwatchSelect}
          isOptionAvailable={isOptionAvailable}
          selectedOption={selectedOptionParam}
          options={swatch.options}
          optionSettings={optionSettings}
          optionValueSettings={activeOptionValueSettings}
          showColorOnlyOnPlp={product?.plpSwatch?.showColorOnlyOnPlp}
          plpSwatch={true}
        />
      ))}
      {settings?.showDescription !== true && (valueSettings?.description || valueSettings?.text) && (
        <div className="text-13 font-normal text-navy-blue-muted">
          {valueSettings?.description || valueSettings.text || activeVariant.swatchDescription}
        </div>
      )}
    </div>
  )
}