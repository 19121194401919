import Link from 'next/link'
import Logo from 'components/Logo'
import * as analytics from 'lib/analytics'

export default function LogoLink() {
  return (
    <Link href="/">
      <a
        onClick={() => analytics.trackNavClick('Logo')}
        className="block p-10 absolute-center rounded focus:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-royal-blue hover:text-bright-blue transition"
      >
        <div className="w-120 s:w-140 flex justify-center">
          <div className="sr-only">Blueland</div>
          <Logo />
        </div>
      </a>
    </Link>
  )
}
