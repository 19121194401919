import React from 'react'
import  Image, { ImageProps } from 'next/image'
import { useNextSanityImage } from 'next-sanity-image'

import { sanityClient } from 'lib/sanity'

export interface Asset {
  originalFilename: string
  altText: string
  extension: string
  _rev: string
  _createdAt: string
  _id: string
  uploadId: string
  _type: string
  assetId: string
  size: number
  _updatedAt: string
  metadata: Metadata
  mimeType: string
  path: string
  sha1hash: string
  url: string
}

export interface Metadata {
  hasAlpha: boolean
  lqip: string
  dimensions: Dimensions
  isOpaque: boolean
  blurHash: string
  _type: string
  palette: Palette
}

export interface Dimensions {
  height: number
  _type: string
  width: number
  aspectRatio: number
}

export interface Palette {
  muted: Muted
  lightVibrant: LightVibrant
  darkVibrant: DarkVibrant
  lightMuted: LightMuted
  vibrant: Vibrant
  dominant: Dominant
  _type: string
  darkMuted: DarkMuted
}

export interface Muted {
  background: string
  _type: string
  foreground: string
  title: string
  population: number
}

export interface LightVibrant {
  foreground: string
  title: string
  population: number
  background: string
  _type: string
}

export interface DarkVibrant {
  title: string
  population: number
  background: string
  _type: string
  foreground: string
}

export interface LightMuted {
  title: string
  population: number
  background: string
  _type: string
  foreground: string
}

export interface Vibrant {
  background: string
  _type: string
  foreground: string
  title: string
  population: number
}

export interface Dominant {
  foreground: string
  title: string
  population: number
  background: string
  _type: string
}

export interface DarkMuted {
  _type: string
  foreground: string
  title: string
  population: number
  background: string
}

export interface Props {
  image?: {
    asset?: Asset
  }
  width?: number
  height?: number
  layout?: ImageProps['layout']
  fill?: boolean
  objectFit?: ImageProps['objectFit']
  quality?: number
  className?: string
  preserveAspectRatio?: boolean
  dpr?: number,
}

const NextSanityImage = ({
  image,
  width,
  height,
  layout,
  fill,
  quality = 100,
  objectFit = 'fill',
  className,
  preserveAspectRatio = false,
  dpr,
  ...rest
}: Props) => {
  const imageProps = useNextSanityImage(sanityClient, image || { asset: {} }, {
    imageBuilder: (imageUrlBuilder, options) => {
      const ratio = dpr ? dpr : typeof window !== 'undefined' ? window.devicePixelRatio : 1

      if (layout === 'fixed') {
        return imageUrlBuilder
          .width(width || 0)
          .height(height || 0)
          .quality(quality)
      }

      if (height && height > 0) {
        return imageUrlBuilder
          .width(width || options.width || Math.min(options.originalImageDimensions.width, 1920))
          .quality(quality)
          .height(height)
          .fit('clip')
      } else {
        return imageUrlBuilder
          .width(width || options.width || Math.min(options.originalImageDimensions.width, 1920))
          .quality(quality)
          .fit('clip')
      }
    },
  })

  if (!image || !image.asset) {
    console.error('Image or image asset is missing')
    return null
  }

  if (preserveAspectRatio && !height && !width) {
    console.error('please provide height or width to use preserveAspectRatio')
  }

  const aspectRatio = image.asset.metadata?.dimensions.aspectRatio || 1

  const getHeightFromAspectRatio = (
    aspectRatio: number,
    height: number | undefined,
    width: number | undefined
  ) => {
    if (height) {
      return {
        height: height,
        width: aspectRatio * height,
      }
    }
    if (width) {
      return {
        width: width,
        height: width / aspectRatio,
      }
    }
  }

  const loader = ({src, width, quality}) => {
    return `${src}&w=${width}`
  }

  const sizeProps = preserveAspectRatio
    ? getHeightFromAspectRatio(aspectRatio, height, width)
    : {
      width: width || imageProps?.width,
      height: height || imageProps?.height,
    }

  // @ts-ignore
  delete imageProps.loader
  return (
    <Image
      {...imageProps}
      alt={image.asset?.altText || 'Blueland: Eco-Friendly Cleaning Products'}
      layout={fill ? 'fill' : layout}
      sizes="(max-width: 600px) 50vw, (max-width: 900px) 33vw, 25vw"
      {...sizeProps}
      loader={loader}
      priority
      className={className}
      objectFit={objectFit}
      {...rest}
    />
  )
}

export default NextSanityImage
