import Image from 'components/Image'
import ProductBadge from 'components/ProductBadge'
import Link from 'components/Link'
import Bottomline from 'components/Bottomline'
import DividerLine from 'components/DividerLine'
import { screens } from 'lib/constants'
import useMedia from 'use-media'
import Button from './Button'
import { useState, useEffect, useMemo, useCallback } from 'react'
import cx from 'classnames'
import * as analytics from 'lib/analytics'
import { useAddCartItems, useLastVisitedPDP } from 'context/globalStore'
import getColorStyle from 'lib/getColorStyle'
import usePriceNotation from 'hooks/usePriceNotation'
import PLPCalloutTile from './PLPCalloutTile'
import usePLPAutoDiscount from 'hooks/usePLPAutoDiscount'
import NextSanityImage from './NextSanityImage'
import useCollectionImage from '../hooks/useCollectionImage'
import { usePromoConfig } from 'hooks/usePromoConfig'
import useCartPrices from 'hooks/useCartPrices'
import {getProductDefaultVariant, isSubscriptionProduct} from 'components/Product/Product.helper'
import useShopifyProductPlus from 'hooks/useShopifyProductPlus'
import formatPriceWithCurrency from './helpers/formatPriceWithCurrency'
import Tooltip from './Tooltip/Tooltip'
import { InfoIcon } from 'components/Icons/InfoIcon'
import GetSubscriptionPLPDetails from './CollectionProduct/Components/GetSubscriptionPlpDetails'
import handleProductClick from './CollectionProduct/helpers/handleProductClick'
import GetSubscriptionPLPFormattedPrice from './CollectionProduct/Components/GetSubscriptionPLPFormattedPrice'

// Path: components/CollectionProduct.js
export default function CollectionProduct({
  as: Component = 'li',
  buttonClass = '',
  mobileButton = {
    variant: 'outlined',
    size: 'small',
  },
  priceClass = '',
  titleClass = '',
  atcOptionClass = '',
  withClass = true,
  bestSellerClass = '',
  product,
  collection,
  index,
  enableQuickAdd,
  showQuickAddPrice = true,
  inlineAddingState = false,
  enableSwatch = false,
  enablePLPClick = true,
  starRatingStyle = 1,
  starRatingColor = undefined,
  ratingNumberTemplate = '',
  bottomPrice = true,
  imageHeight,
  imageWidth,
  mode = 'normal',
  subscriptionPLPEnabled = false,
  subscriptionPLPDiscount = 0,
  subscriptionPLPOneTimeProductsSwitch = false,
  showSecondaryImage = true,
  showTitleAndPriceRow = true,
  showProductBadges = true,
  enableProductLink = false,
  globals = {},
  textStyle = {},
  starSize = null,
  showUpwardsPrice = true,
  variation = null,
  showPlpTile = true,
  tooltipText = '',
  showQuantitySelector
}) {
  const { autoDiscountAmount, autoDiscountText, enableAutoDiscount } = usePLPAutoDiscount(product)
  const collectionImgDimensions = useCollectionImage(subscriptionPLPEnabled)
  const { lastVisitedPDP, setLastVisitedPDP } = useLastVisitedPDP()
  const { isPromoActivated } = useCartPrices()

  const { variants: shopifyVariants } = useShopifyProductPlus(product)
  let { formattedPrice, buttonPrice } = usePriceNotation(product, undefined, showUpwardsPrice)

  const priceWithCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: shopifyVariants?.[0]?.price?.currencyCode || 'USD',
  });

  const oneTimePrice = formatPriceWithCurrency(priceWithCurrency, product?.variants?.[0]?.price)

  const [isAdding, setIsAdding] = useState(false)
  const [atcOptions, setAtcOptions] = useState(null)
  const isSwatchEnable =
    enableSwatch && product?.plpSwatch?.enable && product?.type !== 'productVariant'
  const addToCart = useAddCartItems()
  const link = useCallback(
    (slug) => ({
      _type: 'internalLink',
      reference: {
        _type: product._type,
        slug: slug,
        productSlug: product?.productSlug,
      },
    }),
    [product._type, product?.productSlug]
  )
  const [pdpLink, setPdpLink] = useState(link(product.slug?.current || product.slug))
  const defaultVariant = getProductDefaultVariant(product)
  const [activeVariant, setActiveVariant] = useState(defaultVariant || product?.variants?.find(
    (variant) =>
      (!variant?.sellingPlans ||
        !!variant?.sellingPlans?.find((plan) => plan.type == 'subscription')) &&
      !variant.disableForSubscription
  ))

  const isScreenMedium = useMedia({ minWidth: screens.m })
  const isScreenLarge = useMedia({ minWidth: screens.l })
  const isScreenExtraLarge = useMedia({ minWidth: screens.xl })

  const { compareOverlay } = product
  const isCompareEnabled = isScreenExtraLarge && compareOverlay?.isEnabled
  const [isCompareActive, setIsCompareActive] = useState(false)
  const [variantImage, setVariantImage] = useState(product.thumbnailImage ?? product.shopifyImage)
  const [variant, setVariant] = useState(product.variants?.[0])
  const [mainOptionPosition, setMainOptionPosition] = useState(0)
  const [selectedOption, setSelectedOption] = useState('')
  const [isAvailable, setIsAvailable] = useState(true)
  const YOU_MAY_ALSO_LIKE = 'youMayAlsoLike';
  const CART_BEST_SELLERS = 'cartBestSellers';

  const promoConfig = usePromoConfig({ product, location: 'PDP' })
  const enableAutoDiscountThreshold = promoConfig?.enableAutoDiscount && !promoConfig?.hideStrikeoutPrice && isPromoActivated
  const autoDiscount = {
    autoDiscountAmount: isSubscriptionProduct(product)
      ? promoConfig?.subscriptionDiscountPct
      : promoConfig?.oneTimeDiscountPct || 0,
    enableAutoDiscount,
    hideStrikeoutPrice: promoConfig?.hideStrikeoutPrice,
  }
  const activeVariantObj = useMemo(
    () => shopifyVariants?.find((v) => v.cleanId === activeVariant?.variantId),
    [activeVariant?.variantId, shopifyVariants]
  )
  const packSizeOptions = product?.options?.find((option) => {
    return ["pack size", "count", "recurring quantity", "refill quantity", 'quantity', 'bathroom count'].includes(option?.name?.toLowerCase())
  })

  const quantityItems = useMemo(() => {
    return product?.variants?.filter(variant =>
      (((!variant?.sellingPlans || !!variant?.sellingPlans?.find(plan => plan.type == 'onetime')) && !variant.disableForOneTime && subscriptionPLPEnabled)
      ) && product.options.every(option =>
        (option._key == packSizeOptions?._key ? true : activeVariant[`option${option.position}`] == variant[`option${option.position}`])
      )
    );
  }, [activeVariant, packSizeOptions?._key, product.options, product.variants]);

  useEffect(() => {
    setVariantImage(product.thumbnailImage ?? product.shopifyImage)
  }, [product.shopifyImage, product.thumbnailImage]);

  useEffect(() => {
    if (variation === 'b' && product?.variants?.[0]?.altImageForABTest) {
      setVariantImage(product?.variants?.[0]?.altImageForABTest)
    } else if (
      product?.altImageForABTest &&
      variation === 'b' &&
      !product?.variants?.[0]?.altImageForABTest
    ) {
      setVariantImage(product?.altImageForABTest)
    }
  }, [variation, product, product?.variants])

  function handleCompareClick() {
    setIsCompareActive(!isCompareActive)
  }

  function handleItemAtc(variant = null) {
    if (lastVisitedPDP?.id !== product?._id) {
      setLastVisitedPDP({
        id: product?._id,
        slug: product?.slug,
      })
    }

    const allPacks = product?.options.find((option) => option.name === 'Pack Size')
    const packs = getAvailablePacks(allPacks, product?.variants)
    if (product?.type === 'productVariant') {
      addItemInCart(null, null, product)
    } else if (variant) {
      addItemInCart(null, null, variant)
    } else if (!packs || packs?.values?.length < 1) addItemInCart()
    else setAtcOptions(packs)
  }

  const getActVariant = (_title, value, position) => {
    const title = _title
      .split(' / ')
      .map((option, i) => (i + 1 === position ? value : option))
      .join(' / ')
    return product.variants.find((v) => v.variantTitle === title)
  }

  async function addItemInCart(optionValue, optionPosition, forcedVariant = null) {
    setAtcOptions(null)
    setIsAdding(true)

    const swatchVariantIndex = getSwatchVariantIndex()
    const selectedVariant = product.variants.find(
      (v) => v.variantTitle === (forcedVariant ?? variant).variantTitle
    )
    const variantToAdd = optionValue
      ? getActVariant(selectedVariant.variantTitle, optionValue, optionPosition)
      : selectedVariant || product.variants[swatchVariantIndex]

    const data = {
      product,
      payload: {
        variant: variantToAdd,
        planProducts: [product],
        quantity: 1,
      },
      shouldSetIsAdding: !inlineAddingState,
    }

    await addToCart(data, false, true)
    analytics.trackAddToCart({
      product,
      variantToAdd,
      quantity: 1,
    })
    setIsAdding(false)
  }

  function getAtcOptionsForBestSellers({ values, position }) {
    return (
      <div className="group rounded-pill mt-10 l:mt-10 bg-white flex l:inline-block overflow-hidden border border-navy-blue">
        {values?.map((v, vi) => (
          <button
            key={vi}
            onClick={(e) => {
              addItemInCart(v, position)
              e.preventDefault()
            }}
            className={cx(
              'relative font-medium text-15 leading-16 inline-block px-10 py-15  first:border-l-0 border border-l-1 border-r-0 border-t-0 border-b-0 border-navy-blue pointer-events-auto z-1'
            )}
          >
            {screens.s ? `${v} Pack` : `Pack ${v}`}
            <div className="bg-gradient-to-r from-pattens-blue via-blush-pink to-pale-orange transition-opacity opacity-0 group-btn-hover:opacity-100 group-btn-disabled:opacity-100 -z-1"></div>
          </button>
        ))}
      </div>
    )
  }

  function getAtcOptions({ values, position }) {
    // Prepare data with only available for One Time purchase options
    let filteredValues
    const selectedVariant = product?.variants.find((v) => v.variantTitle === variant.variantTitle)
    const { swatchItems } = product?.plpSwatch || {}
    let optionCount
    if (product?.plpSwatch?.swatchItems) {
      optionCount = product?.options?.find((_option) =>
        _option?.values?.includes(swatchItems[0]?.option)
      )?.position
    }
    const optionPosition = mainOptionPosition || optionCount
    if (optionPosition) {
      filteredValues = product.variants
        .filter(
          (variant) =>
            variant[`option${optionPosition}`].includes(
              selectedVariant[`option${optionPosition}`]
            ) && variant?.disableForOneTime !== true
        )
        .map((value) => value[`option${position}`])
    } else {
      let optionPosition
      const allFilteredVariants = product.variants.reduce((acc, variant) => {
        for (let i = 1; i <= 3; i++) {
          if (values?.includes(variant[`option${i}`]) && variant.disableForOneTime !== true) {
            optionPosition = i
            acc.add(variant[`option${optionPosition}`])
          }
        }
        return acc
      }, new Set())
      filteredValues = Array.from(allFilteredVariants)
    }
    return (
      <div className="group rounded-pill mt-10 l:mt-0 bg-white flex l:inline-block max-w-max overflow-hidden border border-navy-blue">
        {filteredValues.map((v, vi) => (
          <button
            key={vi}
            onClick={(e) => {
              addItemInCart(v, position)
              e.preventDefault()
            }}
            className={cx(
              atcOptionClass ||
              'relative group-btn font-medium text-12 leading-16 l:text-18 inline-block px-5 s:px-10 l:px-15 py-5 first:border-l-0 border border-l-1 border-r-0 border-t-0 border-b-0 border-navy-blue pointer-events-auto z-1'
            )}
          >
            {!/^\d+$/.test(v)
              ? v
              : screens.s
                ? `${v} Pack`
                : `Pack ${v}`
            }
            <div className="absolute inset-0 bg-gradient-to-r from-pattens-blue via-blush-pink to-pale-orange transition-opacity opacity-0 group-btn-hover:opacity-100 group-btn-disabled:opacity-100 -z-1"></div>
          </button>
        ))}
      </div>
    )
  }

  const getSwatchVariantIndex = useCallback(() => {
    const { options: selectedOptionTitle, enable } = product.plpSwatch || {}
    if (!enable) return 0

    const option = product.options.find((o) => o.name === selectedOptionTitle)
    const index = option && option.values.indexOf(selectedOption)

    return index === -1 ? 0 : index
  }, [product.options, product.plpSwatch, selectedOption])

  useEffect(() => {
    let shopifyVariant = shopifyVariants?.find(
      (variantFromShopify) => variant?.variantId === variantFromShopify?.cleanId
    )
    if (variant?.soldOutSettings?.isSoldOut || product?.soldOutSettings?.isSoldOut || activeVariant?.disableForOneTime || shopifyVariant?.available === false) {
      setIsAvailable(false)
    } else {
      setIsAvailable(true)
    }
  }, [variant, product])

  const selectOption = useCallback(
    (option, item = null) => {
      setSelectedOption(option)
      const optionCount = product.options.find((_option) =>
        _option.values.includes(option)
      )?.position
      const variant = product.variants?.find((v) => v[`option${optionCount}`] === option)
      setVariant(variant)
      setMainOptionPosition(optionCount)
      if (variant?.mediaGallery?.[0]) {
        if (variation === 'b' && variant?.altImageForABTest) {
          setVariantImage(variant?.altImageForABTest)
        } else if (product?.altImageForABTest && variation === 'b' && !variant?.altImageForABTest) {
          setVariantImage(product?.altImageForABTest)
        } else {
          setVariantImage(variant?.mediaGallery?.[0]?.image)
        }
      }
    },
    [product?.options?.length, product?.variants, variation]
  )

  const scentOption = product?.options?.find(
    ({ name, values }) => name === 'Scent' && values.length > 1
  )

  const packSizeOption = product?.options?.find(
    ({ name, values }) => name === 'Pack Size' && values.length > 1
  )

  const characterOption = product?.options?.find(
    ({ name, values }) => name === 'Character' && values.length > 1
  )

  const amountOption = product?.options?.find(
    ({ name, values }) => name === 'Amount' && values.length > 1
  )

  const colorOption = product?.options?.find(
    ({ name, values }) => name === 'Color' && values.length > 1
  )

  const countOption = product?.options?.find(
    ({ name, values }) => name === 'Count' && values.length > 1
  )

  const sizeOption = product?.options?.find(
    ({ name, values }) => name === 'Size' && values.length > 1
  )

  const sinkCountOption = product?.options?.find(
    ({ name, values }) => name === 'Sink Count' && values.length > 1
  )

  const bathroomCountOption = product?.options?.find(
    ({ name, values }) => name === 'Bathroom Count' && values.length > 1
  )

  const tabletCountOption = product?.options?.find(
    ({ name, values }) => name === 'Tablet Count' && values.length > 1
  )

  const typeOption = product?.options?.find(
    ({ name, values }) => name === 'Type' && values.length > 1
  )

  const cleanerTypeOption = product?.options?.find(
    ({ name, values }) => name === 'Cleaner Type' && values.length > 1
  )

  const options = [
    scentOption,
    packSizeOption,
    characterOption,
    amountOption,
    colorOption,
    countOption,
    sizeOption,
    sinkCountOption,
    bathroomCountOption,
    tabletCountOption,
    typeOption,
    cleanerTypeOption,
  ].filter((x) => !!x)

  const quantityLabel =
    options.find((x) => x.name == 'Pack Size')?.name ?? options.find((x) => x.name == 'Count')?.name
  let quantityPosition = options.find((x) => x.name === quantityLabel)?.position

  function removeQuantity(title) {
    return title
      ?.toString()
      .split(' / ')
      .map((option, i) => (i + 1 === quantityPosition ? undefined : option))
      .filter((x) => !!x)
      .join(' / ')
  }

  const productVariantImage = useMemo(() => {
    const itemsGroup =
      product.type === 'productVariant'
        ? product.variants.reduce((acc, variant) => {
          const title = removeQuantity(variant.variantTitle)
          acc[title] = [
            ...(acc[title] ?? []),
            ...(variant?.mediaGallery?.map((media) => ({
              ...media,
              variantId: variant.variantId,
            })) ?? []),
          ]
          return acc
        }, {})
        : {}

    const variantImage =
      itemsGroup[removeQuantity(product.variantTitle)]?.[0]?.image ??
      product?.mediaGallery?.[0]?.image ??
      product?.shopifyImage

    return variantImage
  }, [product])

  useEffect(() => {
    const { swatchItems } = product.plpSwatch || {}
    const items = isSwatchEnable ? swatchItems : []

    if (isSwatchEnable && items && items[0]) selectOption(items[0].option)
  }, [isSwatchEnable, product.plpSwatch, selectOption])

  useEffect(() => {
    const { enable } = product.plpSwatch || {}
    if (!enable) {
      if (product.isEnabled) {
        setPdpLink(link(`${product.slug?.current || product.slug}`))
        return
      } else {
        return
      }
    }

    const isOneTimeOnly =
      product.type === 'productVariant' &&
      product?.sellingPlans?.length === 1 &&
      product?.sellingPlans?.[0]?.type === 'onetime'

    const position = getSwatchVariantIndex()
    setPdpLink(
      link(
        `${product.slug?.current || product.slug}?option=${position}${product.type === 'productVariant'
          ? `&variant=${encodeURIComponent(product.variantTitle)}${isOneTimeOnly ? '&plan=one-time' : ''
          }`
          : ''
        }`
      )
    )
  }, [
    getSwatchVariantIndex,
    link,
    product.isEnabled,
    product.plpSwatch,
    product.slug,
    selectedOption,
  ])

  function showAutoDiscountMessage() {
    if (enableAutoDiscountThreshold && promoConfig?.autoDiscountText && product?.title !== "Gift Card") {
      return (
        <div
          className="font-medium text-11 leading-11 m:text-13 m:leading-18 mt-3 l:mt-0"
          style={{ color: promoConfig?.promoTheme?.primaryColor?.hex ?? '#3369FF' }}
        >
          {promoConfig?.autoDiscountText.replace('$discountAmount', autoDiscount?.autoDiscountAmount)}
        </div>
      )
    } else return null
  }

  function swatch() {
    const { swatchItems, options: selectedOptionTitle } = product.plpSwatch || {}
    const swatchOptions = swatchItems?.map((item) => item.option)
    let optionPosition
    const filteredVariants = product?.variants?.filter((variant) => {
      for (let i = 1; i <= 3; i++) {
        if (swatchOptions?.includes(variant[`option${i}`]) && variant.disableForOneTime !== true) {
          optionPosition = i
          return true
        }
      }
      return false
    })
    let filteredItems = swatchItems?.filter((item) => {
      let variant = filteredVariants?.find(
        (variant) => variant[`option${optionPosition}`] === item?.option
      )
      return variant && variant?.disableForOneTime !== true
    })
    const items = isSwatchEnable ? swatchItems : []
    const hasColor = filteredItems?.some(item => item.color || item.colorStack)
    return (
      isSwatchEnable && hasColor && (
        <>
          <div className="relative flex flex-col xl:flex-row xl:items-center z-3 mt-5 xl:max-h-10 mb-6">
            <ul
              style={{ willChange: 'opacity' }}
              className="product-option-swatch flex l:opacity-0 group-hover:opacity-100"
            >
              {filteredItems?.slice(0, 7).map(({ _key, color, option }) => (
                <li
                  onClick={() => selectOption(option, filteredItems)}
                  className="relative w-12 h-12 mr-12 rounded last:mr-0 cursor-pointer"
                  key={_key}
                  style={{ backgroundColor: getColorStyle({ color }) }}
                >
                  <span
                    style={{ backgroundColor: getColorStyle({ color }) }}
                    className={cx(
                      'absolute left-0 top-0 right-0 bottom-0 bg-clip-content p-2 bg-white rounded-pill opacity-0 z-5',
                      {
                        'opacity-40': option === selectedOption,
                      }
                    )}
                  ></span>
                </li>
              ))}
            </ul>
            <span className="mt-8 xl:mb-0 xl:mt-0 xl:ml-12 xl:pl-10 xl:border-l text-12 leading-12 font-serif italic text-navy-blue-muted l:opacity-0 group-hover:opacity-100">
              {selectedOptionTitle}: {selectedOption}
            </span>
            <div className="absolute hidden l:block text-12 s:text-14 font-serif italic text-navy-blue-muted lowercase opacity-100 group-hover:opacity-0 -z-1">
              {filteredItems?.length} {selectedOptionTitle}s available
            </div>
          </div>
        </>
      )
    )
  }

  function plpDescriptor() {
    return (
      product.plpDescriptor && (
        <>
          <div className=" descxz text-13 text-navy-blue-muted leading-18">
            {' '}
            {product.plpDescriptor}{' '}
          </div>
        </>
      )
    )
  }

  function starRatingDesign() {
    const { average_score, total_reviews } = product?.bottomline || {}

    return {
      averageScore: average_score,
      totalReviews: total_reviews,
      size: starSize || (starRatingStyle === 1 ? 12.5 : isScreenMedium ? 'large' : 14),
      displayTotal: true,
      starColor: starRatingColor
        ? starRatingColor
        : starRatingStyle === 1
          ? 'text-malibu-blue'
          : 'text-royal-blue',
      ratingNumberTemplate,
      textStyle,
    }
  }

  const secondaryImage = useMemo(() => {
    if (!showSecondaryImage) {
      return null
    }
    let images = []
    if (product?.mediaGallery && product.mediaGallery?.length > 0) {
      images = product.mediaGallery?.filter(
        (asset) =>
          asset._type === 'imageAsset' &&
          asset.isHoverImage &&
          ((asset.image?.asset?.assetId !== variantImage?.asset?.assetId &&
            asset.image?.asset?.originalFilename !== variantImage?.asset?.originalFilename) ||
            asset.image?.asset?._ref !== variantImage?.asset?._ref)
      )
    }

    if (variant?.mediaGallery && variant?.mediaGallery?.length > 1) {
      const vImages = variant?.mediaGallery?.filter(
        (asset) =>
          asset._type === 'imageAsset' &&
          asset.isHoverImage &&
          ((asset.image?.asset?.assetId !== variantImage?.asset?.assetId &&
            asset.image?.asset?.originalFilename !== variantImage?.asset?.originalFilename) ||
            asset.image?.asset?._ref !== variantImage?.asset?._ref)
      )
      if (vImages?.length) {
        images = vImages
      }
    }

    if (images && images.length > 0 && images[0]?.image) {
      return images[0]?.image
    }

    return null
  }, [
    product.mediaGallery,
    showSecondaryImage,
    variant?.media,
    variantImage?.asset?.assetId,
    variantImage?.asset?.originalFilename,
    variant,
  ])

  if (product._type === 'plpCalloutTile' && showPlpTile) {
    return <PLPCalloutTile plpTile={product} />
  }

  return (
    <Component
      onMouseLeave={() => setAtcOptions(null)}
      onClick={() => {
        handleProductClick(product, collection, index, location)
      }}
      className={cx(`product-card ${bestSellerClass}`, {
        'desktop px-7 l:px-10 xl:px-12 mb-20 l:mb-50': withClass,
        'h-100p': subscriptionPLPEnabled,
      })}
    >
      <div className={cx('relative', {
        'h-100p flex flex-col l:justify-between': subscriptionPLPEnabled,
        'group': !subscriptionPLPEnabled,
      })}>
        <div className="flex flex-col items-between">
          <Link link={pdpLink}>
            <div className="relative overflow-hidden rounded mb-10 l:mb-15">
              <div className="relative group-btn">
                <div
                  className={cx('product-image relative inset-0 z-3 opacity-100', {
                    'xl:group-btn-hover:opacity-0 xl:transition-opacity xl:duration-1000':
                      !!secondaryImage,
                  })}
                >
                  <NextSanityImage
                    width={collectionImgDimensions?.width ?? 300}
                    height={collectionImgDimensions?.height ?? 300}
                    image={product.type === 'productVariant' ? productVariantImage : variantImage}
                    quality={100}
                    layout="responsive"
                    dpr={1}
                  />
                </div>

                {secondaryImage && (
                  <div className="opacity-0 group-btn-hover:opacity-100 transition-opacity inset-0 absolute hidden xl:block">
                    <NextSanityImage
                      width={collectionImgDimensions?.width ?? 300}
                      height={collectionImgDimensions?.height ?? 300}
                      image={secondaryImage}
                      quality={100}
                      layout="responsive"
                      dpr={1}
                    />
                  </div>
                )}
              </div>
              <span className="absolute inset-0 z-1"></span>

              {product?.badges?.length && showProductBadges ? (
                <div className="absolute top-10 l:top-16 left-10 z-3 text-0 flex flex-wrap gap-x-5 gap-y-2">
                  {product.badges?.slice(0, subscriptionPLPEnabled ? 1 : 2).map(({ _id, label, background, textColor, disabled }) => {
                    return mode === CART_BEST_SELLERS ? (
                      <ProductBadge
                        key={_id}
                        background={background}
                        textColor={textColor}
                        size={'small'}
                        disabled={disabled}
                      >
                        {label}
                      </ProductBadge>
                    ) : (
                      <ProductBadge
                        key={_id}
                        background={background}
                        textColor={textColor}
                        size={isScreenExtraLarge ? 'large' : isScreenMedium ? 'medium' : 'small'}
                        disabled={disabled}
                      >
                        {label}
                      </ProductBadge>
                    )
                  })}
                  {subscriptionPLPEnabled && (
                    <ProductBadge size={isScreenExtraLarge ? 'large' : 'mobileAdapt'} background={{ color: { hex: "#FFDCD3" } }}>
                      20% Subscription Savings
                    </ProductBadge>
                  )}
                </div>
              ) : subscriptionPLPEnabled ? (
                <div className="absolute top-10 l:top-16 left-10 z-3 text-0 flex flex-wrap gap-x-5 gap-y-2">
                  <ProductBadge size={isScreenExtraLarge ? 'large' : isScreenMedium ? 'medium' : 'small'} background={{ color: { hex: "#FFDCD3" } }}>
                    20% Subscription Savings
                  </ProductBadge>
                </div>
              ) : null}

              {isScreenExtraLarge && mode !== CART_BEST_SELLERS ? (
                <div className="absolute z-6 bottom-0 inset-x-0 text-center pb-20 transition opacity-0 transform translate-y-5 group-hover:opacity-100 group-hover:translate-y-0 pointer-events-none">
                  {enableQuickAdd ? (
                    atcOptions ? (
                      getAtcOptions(atcOptions)
                    ) : (
                      <Button
                        variant="contained"
                        size="large"
                        className={cx('quick-add-btn min-w-200 pointer-events-auto', buttonClass)}
                        tabIndex={-1}
                        disabled={!isAvailable || isAdding || undefined}
                        onClick={(e) => {
                          e.preventDefault()
                          handleItemAtc(mode === YOU_MAY_ALSO_LIKE ? defaultVariant : null)
                        }}
                      >
                        {!isAvailable ? (
                          'Out of Stock'
                        ) : isAdding ? (
                          'Adding…'
                        ) : (
                          <>
                            Quick Add{' '}
                            {showQuickAddPrice && (
                              <>
                                <span aria-hidden="true" className="mx-5">
                                  |
                                </span>{' '}
                                {buttonPrice}
                              </>
                            )}
                          </>
                        )}
                      </Button>
                    )
                  ) : null}
                </div>
              ) : null}
              {isCompareEnabled ? (
                <div
                  className={cx('absolute inset-10 rounded bg-white bg-opacity-75 transition z-3', {
                    'transform translate-y-5 opacity-0 pointer-events-none': !isCompareActive,
                  })}
                  style={{ backdropFilter: 'blur(15px)' }}
                  inert={isCompareActive ? null : ''}
                >
                  <div className="relative w-full h-full">
                    <button
                      className="absolute top-0 right-0 p-10 focus:outline-none z-1"
                      onClick={handleCompareClick}
                    >
                      <div className="w-8">
                        <CloseIcon />
                      </div>
                    </button>
                    <div className="absolute inset-0 overflow-y-auto hide-scrollbar z-5">
                      <div className="w-full pt-40 pb-30 px-20">
                        <h3 className="text-16 font-medium mb-25">{compareOverlay.headline}</h3>
                        {compareOverlay?.whatsIncludedTitle ? (
                          <DividerLine as="h4" className="mb-15">
                            {compareOverlay.whatsIncludedTitle}
                          </DividerLine>
                        ) : null}
                        <ul className="list-disc pl-20 space-y-5">
                          {compareOverlay.whatsIncluded.map((item, i) => (
                            <li key={i} className="text-13">
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </Link>
          {showTitleAndPriceRow && (
            <div className={cx('flex flex-col-reverse')}>
              <h2 className={cx('mb-2', { 'min-h-52 l:min-h-85': mode === CART_BEST_SELLERS })}>
                <div
                  className={cx(
                    titleClass ||
                    'flex justify-between items-start text-13 xl:text-16 leading-13 xl:leading-18'
                  )}
                >
                  {enablePLPClick !== false ||
                    (mode !== CART_BEST_SELLERS && !subscriptionPLPEnabled) ? (
                    <Link
                      className={cx('font-medium', {
                        'hover:text-royal-blue': subscriptionPLPEnabled,
                      })}
                      link={pdpLink}
                      onClick={() => handleProductClick(product, collection, index, location)}
                    >
                      {product?.type === 'productVariant'
                        ? product.title ?? product.variantTitle
                        : product.title}
                      <span className={cx(' z-3', { 'inset-0': !subscriptionPLPEnabled })}></span>
                    </Link>
                  ) : (
                    <>
                      <span className={'font-medium'}>
                        {product?.type === 'productVariant'
                          ? product.title ?? product.variantTitle
                          : product.title}
                      </span>
                    </>
                  )}
                  {mode !== CART_BEST_SELLERS && !subscriptionPLPEnabled ? (
                    <span
                      className={cx(
                        priceClass ||
                        'text-13 xl:text-16 leading-13 xl:leading-22 text-navy-blue-muted space-x-6 whitespace-nowrap',
                        {
                          'hidden l:block': bottomPrice,
                        }
                      )}
                    >
                      {formattedPrice}
                    </span>
                  ) : mode !== CART_BEST_SELLERS && subscriptionPLPEnabled ? (
                    <div>
                      <div className='flex justify-end'>
                        {GetSubscriptionPLPFormattedPrice(product, subscriptionPLPDiscount, activeVariant, activeVariantObj)}
                        <Tooltip
                          className="ml-5 z-5 h-16 text-13 m:text-16 leading-13"
                          position={
                            isScreenLarge && index % 3 === 2
                              ? 'leftAllDevices'
                              : (isScreenMedium && index % 2 !== 0) || (!isScreenMedium && !isScreenLarge)
                                ? 'left'
                                : 'default'
                          }
                          text={
                            <span dangerouslySetInnerHTML={{ __html: tooltipText }}></span>
                          }
                          size={'default'}
                        >
                          <InfoIcon className="w-16 h-16" />
                        </Tooltip>
                      </div>
                      {showAutoDiscountMessage()}
                    </div>
                  ) : (
                    <span
                      className={cx(
                        priceClass ||
                        'text-13 xl:text-16 leading-13 xl:leading-22 text-navy-blue-muted space-x-6 whitespace-nowrap',
                        {
                          'hidden l:block': bottomPrice && mode !== CART_BEST_SELLERS,
                        }
                      )}
                    >
                      {mode === CART_BEST_SELLERS ? oneTimePrice : formattedPrice}
                    </span>
                  )}
                </div>
              </h2>
              {(
                mode === CART_BEST_SELLERS
                  ? product.showReviewsInCart !== false
                  : product.showPLPReviews !== false && product?.bottomline
              ) ? (
                <div className="mb-4">
                  <Bottomline mode={mode} {...starRatingDesign()} />
                </div>
              ) : null}
            </div>
          )}
          {product?.subscriptionPlpDescription && subscriptionPLPEnabled && (
            <div className='text-13 leading-17 mb-12'>
              {product?.subscriptionPlpDescription}
            </div>
          )}
        </div>
        {plpDescriptor()}
        {
          subscriptionPLPEnabled &&
          GetSubscriptionPLPDetails(product, subscriptionPLPOneTimeProductsSwitch, activeVariant, setActiveVariant, quantityItems, packSizeOptions, subscriptionPLPEnabled, showQuantitySelector, selectOption)
        }
        {swatch()}
        {showAutoDiscountMessage() && !subscriptionPLPEnabled && (
          <div className='hidden l:block'>
            {showAutoDiscountMessage()}
          </div>
        )}
        {
          mode != CART_BEST_SELLERS && bottomPrice && (
            <span className={cx(priceClass || 'l:hidden text-13 flex gap-3 text-navy-blue-muted mt-4')}>
              {formattedPrice}
            </span>
          )
        }
        {showAutoDiscountMessage() && !subscriptionPLPEnabled && (
          <div className='l:hidden'>
            {showAutoDiscountMessage()}
          </div>
        )}
        {
          mode === CART_BEST_SELLERS && (
            <>
              <div className="relative text-center flex justify-center pb-20 z-4 transition opacity-100 l:opacity-100  group-hover:opacity-100 pointer-events-none">
                {atcOptions ? (
                  getAtcOptionsForBestSellers(atcOptions)
                ) : (
                  <>
                    <Button
                      variant="contained"
                      size="large"
                      className={cx('block w-full pointer-events-auto')}
                      tabIndex={-1}
                      onClick={(e) => {
                        e.preventDefault()
                        handleItemAtc()
                      }}
                    >
                      {isAdding ? 'Adding...' : <> Quick Add</>}
                    </Button>
                  </>
                )}
              </div>
            </>
          )
        }
        {
          enablePLPClick === false && !subscriptionPLPEnabled && (
            <>
              {atcOptions ? (
                getAtcOptions(atcOptions)
              ) : (
                <Button
                  variant="outlined"
                  size="small"
                  className={cx('block l:hidden mb-16 mt-10 quick-add-btn w-165 pointer-events-auto')}
                  tabIndex={-1}
                  onClick={(e) => {
                    e.preventDefault()
                    handleItemAtc(mode === YOU_MAY_ALSO_LIKE ? defaultVariant : null)
                  }}
                >
                  {isAdding ? 'Adding...' : <>Add</>}
                </Button>
              )}
            </>
          )
        }
      </div>
      {
        isCompareEnabled ? (
          <Button
            variant="outlined"
            size="compact"
            className="mt-15 flex items-center space-x-5"
            onClick={handleCompareClick}
          >
            <div>Compare</div>
            <div
              className={cx('w-7 transition-transform', {
                'transform rotate-45': isCompareActive,
              })}
            >
              <PlusIcon />
            </div>
          </Button>
        ) : null
      }
    </Component>
  )
}

function CloseIcon() {
  return (
    <svg viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        width="1"
        height="9"
        rx="0.5"
        transform="matrix(-0.707109 -0.707104 0.707109 -0.707105 0.707092 7.07111)"
        fill="#4D6E89"
      />
      <rect
        width="1"
        height="9"
        rx="0.5"
        transform="matrix(0.707109 -0.707104 0.707109 0.707105 6.10352e-05 0.707092)"
        fill="#4D6E89"
      />
    </svg>
  )
}

function PlusIcon() {
  return (
    <svg viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.28985 3.23029V0.5H2.71014V3.23029H0V4.71162H2.71014V7.5H4.28985V4.71162H7V3.23029H4.28985Z"
        className="fill-current"
      />
    </svg>
  )
}

function getAvailablePacks(packs, variants) {
  const availablePacks = []
  packs?.values?.forEach((pack) => {
    const isPackAvailable = variants?.some((variant) => {
      if (variant?.option1 === pack && !variant?.disableForOneTime) {
        return true
      }
    })

    if (isPackAvailable) availablePacks.push(pack)
  })

  return { ...packs, values: availablePacks }
}
