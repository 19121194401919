import { useEffect, useRef, useState } from "react";

export default function useGorgiasChatPositioning() {
  const defaultStylesRef = useRef({
  });

  const updateChatButtonPosition = (customStyles) => {
    const chatButton: HTMLElement | null = document.querySelector('#gorgias-chat-container #chat-button');

    if (chatButton) {
      if (customStyles) {
        const styles = { ...defaultStylesRef.current, ...customStyles };

        for (const property in styles) {
          if (
            defaultStylesRef.current[property] == null ||
            defaultStylesRef.current[property] == undefined ||
            defaultStylesRef.current[property] == ''
          ) {
            defaultStylesRef.current[property] = chatButton.style[property];
          }
          chatButton.style[property] = styles[property];
        }
      }
    }
  };

  const updateChatPosition = (customStyles) => {
    updateChatButtonPosition(customStyles);
  };

  return {
    updateChatPosition,
  };
}






























// export default function useGorgiasChatPositioning() {

//     const defaultStylesRef = useRef({
//       left: '20px',
//       right: ''
//     });
//     const updateChatButtonPosition = (customStyles) => {
//         const chatButton: HTMLElement | null = document.querySelector('#gorgias-chat-container #chat-button');
//         const chatWindow: HTMLElement | null = document.querySelector('#gorgias-chat-container #chat-window');
//         if (chatButton) {
//             if (customStyles) {

//                 const styles = {...defaultStylesRef.current, ...customStyles}
//                 for (const property in styles) {
                    
//                     if (defaultStylesRef.current[property] == null || defaultStylesRef.current[property] == undefined || defaultStylesRef.current[property] == '' ) {
//                         defaultStylesRef.current[property] = chatButton.style[property]
//                     }
//                     console.log({property, style: styles[property]})
//                     chatButton.style[property] = styles[property];
//                     if (chatWindow) {
//                       chatWindow.style[property] = styles[property];
//                     }
//                 }
//             }
//         }
//     };

//     const updateChatPosition = (customStyles) => {
//         updateChatButtonPosition(customStyles);
//     };

//     return {
//         updateChatPosition,
//     };
// }

export const useDraggableChatButton__WIP = (targetElementSelector = '#gorgias-chat-container') => {

  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const chatContainer: any = document.querySelector(targetElementSelector + ' #chat-button')
    const chatIframe: any = document.querySelector(targetElementSelector + ' #chat-button');
    if (!chatIframe) return;
    const chatDocument = chatIframe.contentDocument;
    console.log({chatDocument})
    const chatButton: HTMLElement | null = chatDocument.querySelector('button');
    console.log({chatButton, chatIframe, chatContainer})
    if (!chatButton) return;
    const handleMouseDown = (e) => {
        console.log('mousedown')
      setIsDragging(true);
      console.log({isDragging})

      const rect = chatContainer.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      setOffset({ x, y });

      chatContainer.style.cursor = 'grabbing';
    };

    const handleMouseMove = (e) => {
      if (!isDragging) return;
        console.log({isDragging})

      const x = e.clientX - offset.x;
      const y = e.clientY - offset.y;

      chatContainer.style.bottom = `${window.innerHeight - y}px`;
      chatContainer.style.right = `${window.innerWidth - x}px`;
    };

    const handleMouseUp = () => {
      if (isDragging) {
        setIsDragging(false);
        chatContainer.style.cursor = 'grab';
      }
    };
    console.log('middle')

    // chatContainer.setAttribute('draggable', 'true');
    chatContainer.setAttribute('draggable', 'true'); 

    chatButton.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      // Cleanup when the component unmounts
      chatButton.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, offset, targetElementSelector]);

  return null; // This hook doesn't render any additional UI elements
};

